import { QueryParamConfigMap, StringParam } from 'use-query-params';

export interface ArticleQuery {
  searchValue?: string;
  author?: string;
  highlighted: boolean;
  componentDisplayName?: string;
  componentIds?: string[];
  componentTypeIds?: string[];
  tagIds?: string[];
  integrationsscheinIds?: string[];
  limit?: number;
  sort: string;
}

export interface ArticleFilterQueryParamConfigMap extends QueryParamConfigMap {
  sort: typeof StringParam;
}

export const articleFilterQueryParamConfigMap: ArticleFilterQueryParamConfigMap = {
  sort: StringParam,
};

export enum ArticleSort {
  TitleDesc = 'title:desc',
  TitleAsc = 'title:asc',
}
