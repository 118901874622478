import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CollapsableSideCard.scss';

export interface CollapsableSideCardProps {
  isHidden: boolean;
  onHideToggle?: (isHidden: boolean) => void;
  header?: ReactNode;
  children?: ReactNode;
}

export default function CollapsableSideCard({ isHidden, onHideToggle, header, children }: CollapsableSideCardProps) {
  const [isMenuHidden, setIsMenuHidden] = useState(false);
  useEffect(() => setIsMenuHidden(isHidden), [isHidden]);

  const hideMenu = () => {
    setIsMenuHidden((s) => !s);
    if (onHideToggle) onHideToggle(isMenuHidden);
  };
  return (
    <div className={classNames('collapsable-side-menu', { isCollapsed: isMenuHidden })}>
      <span className="top-spacer">
        <FontAwesomeIcon
          icon={isMenuHidden ? faAngleDoubleRight : faAngleDoubleLeft}
          className="hide-menu-btn"
          onClick={hideMenu}
          onKeyPress={hideMenu}
          role="button"
          tabIndex={0}
        />
      </span>
      {header && <div className="menu-header">{header}</div>}
      <div className="menu-content">{children}</div>
    </div>
  );
}
