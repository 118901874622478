import { KnowledgeBaseArticleTag } from '../models/knowledge-base/KnowledgeBaseArticleTagModel';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { KNOWLEDGE_BASE_API_PREFIX } from './KnowledgeBaseApi';

export function useGetKnowledgeBaseArticleTags(options?: QueryOptions<KnowledgeBaseArticleTag[]>) {
  return useGetQuery<KnowledgeBaseArticleTag[]>(
    ServerStateKeysEnum.KnowledgeBaseArticleTagAll,
    `${KNOWLEDGE_BASE_API_PREFIX}/tag`,
    options,
  );
}

export function useGetKnowledgeBaseArticleTag(tagId: string, options?: QueryOptions<KnowledgeBaseArticleTag[]>) {
  return useGetQuery<KnowledgeBaseArticleTag[]>(
    [ServerStateKeysEnum.TagDetails, tagId],
    `${KNOWLEDGE_BASE_API_PREFIX}/tag/${tagId}`,
    options,
  );
}

export function useGetFrequentTags(limit: number, options?: QueryOptions<KnowledgeBaseArticleTag[]>) {
  return useGetQuery<KnowledgeBaseArticleTag[]>(
    ServerStateKeysEnum.FrequentTags,
    `${KNOWLEDGE_BASE_API_PREFIX}/tag/frequent?limit=${limit}`,
    options,
  );
}
