import { FetchError } from '../react-query/FetchError';

export type ErrorContext = {
  error: FetchError;
  options: {
    suppressErrorToast: boolean;
    customMessage: string | undefined;
  };
};

export enum ErrorHandlingResult {
  NOT_HANDLED,
  HANDLED,
}

/**
 * An error handler is responsible for handling occurring errors.
 * If a handler was able to fully resolve/handle an error, it should return {@link ErrorHandlingResult.HANDLED}.
 * Otherwise, it must return {@link ErrorHandlingResult.NOT_HANDLED} or nothing at all.
 * This indicates that another handler might continue to try to handle the error.
 */
export type ErrorHandler = (error: ErrorContext) => ErrorHandlingResult | void;
