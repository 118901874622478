import { Link } from 'react-router-dom';
import { useState } from 'react';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { ComponentItem } from '../../models/operation/ComponentModel';
import { useGetContractById } from '../../react-query/ContractManagementSystemApi';
import NavigationRoutes from '../../routing/NavigationRoutes';
import { useTranslationText } from '../../translation/TranslationHooks';
import ListItemWrapper from '../../ui/list-item-wrapper/ListItemWrapper';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { ComponentPatchSubmit } from '../../models/patch/ComponentPatchSubmitType';
import Modal from '../../ui/modal/Modal';

interface ExistingContractItemProps {
  sourceComponent: ComponentItem;
  currentComponent: ComponentItem;
  onSubmit?: ComponentPatchSubmit;
  readOnly?: boolean;
}

export function ExistingContractItem({
  sourceComponent,
  currentComponent,
  onSubmit,
  readOnly = false,
}: ExistingContractItemProps) {
  const [isModalVisible, toggleModal] = useState<boolean>(false);

  const { t } = useTranslationText('components');
  const { t: tCommon } = useTranslationText('commons');
  const { t: tContractManagement } = useTranslationText('contractManagements');

  const { isLoading, data: contract } = useGetContractById(sourceComponent.assignedContractId!);
  const canDelete = !readOnly && onSubmit !== undefined;

  return (
    <LoadingSpinner isLoading={isLoading}>
      {contract ? (
        <ListItemWrapper
          mode="item"
          field="serviceTime"
          onRemove={canDelete ? () => toggleModal(true) : undefined}
          itemLink={NavigationRoutes.ContractId(sourceComponent.assignedContractId!)}
          deletePermission={UserResourcePermissions.Contract.Delete}
        >
          <div
            className="component-contract-item"
            data-role="component-contract-item"
            data-contract-internal-number={contract.internalNumber}
          >
            <div className="contract">
              <span>
                {contract.internalNumber}, {contract.contractor.companyName}
              </span>
              <div className="source-info">
                <span className="spacer" />
                {sourceComponent === currentComponent ? (
                  <>{t('directlyAssigned')}</>
                ) : (
                  <>
                    {t('inheritedFrom')}{' '}
                    <Link to={NavigationRoutes.ComponentId(sourceComponent.id)}>{sourceComponent.displayName}</Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </ListItemWrapper>
      ) : (
        <>{tContractManagement('contractDoesNotExist')}</>
      )}
      {canDelete && (
        <Modal
          isVisible={isModalVisible}
          title={t('contractRelationDelete')}
          cancelBtnText={tCommon('abort')}
          confirmBtnText={tCommon('delete')}
          onCancel={() => toggleModal(false)}
          onClose={() => toggleModal(false)}
          onConfirm={() => onSubmit!(undefined, 'assignedContractId', true)}
        >
          <p>{t('warningContractRelationDelete')}</p>
        </Modal>
      )}
    </LoadingSpinner>
  );
}
