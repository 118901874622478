import classNames from 'classnames';
import { ComponentType, ReactNode, useCallback, useState } from 'react';
import { GroupBase, MenuPlacement, MultiValue, MultiValueGenericProps, MultiValueRemoveProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import {
  createCustomMultiValueRemove,
  DropdownIndicator,
  multiClassNames,
  multiStyles,
} from '../multi-dropdowns-utils/MultiDropdownUtils';

export interface Option {
  readonly label: string;
  readonly value: string;
}

interface MultiSelectCreatableDropdownProps<T> {
  isError?: boolean;
  createLabel: (inputValue: string) => ReactNode;
  isLoading?: boolean;
  noOptionsMessage?: string | null;
  onChange: (newValues: MultiValue<T>) => void;
  onCreateOption: (inputValue: string) => void;
  placeholder?: string;
  values: T[];
  options?: T[];
  menuPlacement?: MenuPlacement;
  hideDropdown?: boolean;
  size?: BulmaSize;
}

export default function MultiSelectCreatableDropdown<T>({
  isError,
  createLabel,
  isLoading,
  noOptionsMessage,
  onChange,
  onCreateOption,
  placeholder,
  values,
  options,
  menuPlacement,
  hideDropdown,
  size = 'is-normal',
}: MultiSelectCreatableDropdownProps<T>) {
  const FormatCreateLabel = useCallback(
    (inputValue: string) => <div data-role="multi-select-create-button">{createLabel(inputValue)}</div>,
    [createLabel],
  );

  const MultiValueLabel = useCallback(
    ({ innerProps, data }: MultiValueGenericProps<Option>) => (
      <div {...innerProps} data-role={`label-${data.label}`}>
        {data.label}
      </div>
    ),
    [],
  );

  const components = hideDropdown
    ? { MultiValueLabel, DropdownIndicator: null }
    : { MultiValueLabel, DropdownIndicator };

  const [menuIsClosed, setMenuIsClosed] = useState(true);

  const handleMenuOpen = () => setMenuIsClosed(false);
  const handleMenuClose = () => setMenuIsClosed(true);

  return (
    <CreatableSelect
      className={classNames('aos-multiple-select', size, {
        'is-error': isError,
        'is-closed': menuIsClosed,
      })}
      classNamePrefix="react-select"
      formatCreateLabel={FormatCreateLabel}
      isClearable
      isLoading={isLoading}
      isMulti
      options={options}
      noOptionsMessage={() => noOptionsMessage}
      onChange={onChange}
      onCreateOption={onCreateOption}
      placeholder={placeholder}
      value={values}
      inputId="creatable-select-input"
      menuPlacement={menuPlacement}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      unstyled
      components={{
        MultiValueRemove: createCustomMultiValueRemove(size) as
          | ComponentType<MultiValueRemoveProps<T, true, GroupBase<T>>>
          | undefined,
        ...components,
      }}
      styles={multiStyles}
      classNames={multiClassNames<T>()}
    />
  );
}
