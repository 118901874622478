import { Button } from '@aos/styleguide-react';
import { useTranslationText } from '../../translation/TranslationHooks';

interface AosFilterMenuButtonProps {
  toggleMenu: () => void;
}

export default function AosFilterMenuButton({ toggleMenu }: AosFilterMenuButtonProps) {
  const { t } = useTranslationText('commons');
  return (
    <Button
      key="filter-btn-all-tickets"
      data-role="filter-menu-btn"
      onClick={toggleMenu}
      icon="filter"
      size="is-small"
      isConfirm
    >
      {t('filterButton')}
    </Button>
  );
}
