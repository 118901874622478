export interface ContractRole {
  key: string;
  translationKey: string;
}

export enum ContractRoles {
  faultReport = 'FAULTREPORT',
  projectManagement = 'PROJECTMANAGEMENT',
  technicallyResponsible = 'TECHNICALLYRESPONSIBLE',
  contractManager = 'CONTRACTMANAGER',
}
