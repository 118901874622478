import { faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Highlighter from 'react-highlight-words';
import { Tag } from '@aos/styleguide-react';
import { sortKnowledgeBaseArticleTagDesc } from '../../../models/knowledge-base/KnowledgeBaseFunctions';
import { KnowledgeBaseArticleTag } from '../../../models/knowledge-base/KnowledgeBaseArticleTagModel';
import './ArticleTagView.scss';

interface ArticleTagViewProps {
  tags: KnowledgeBaseArticleTag[];
  highlightTags?: string[];
}

export default function ArticleTagView({ tags, highlightTags }: ArticleTagViewProps) {
  return tags.length > 0 ? (
    <div className="article-tags">
      <ul className="tag-list">
        <FontAwesomeIcon icon={faTags} className="mr-2 mb-1" />
        {sortKnowledgeBaseArticleTagDesc(tags).map((tag) => (
          <li key={tag.id} className="mr-1 mt-0" data-role={`tag-${tag.title}`}>
            <Tag color="is-secondary" size="is-normal">
              <Highlighter
                highlightClassName="highlight"
                searchWords={highlightTags ?? []}
                autoEscape
                textToHighlight={tag.title}
              />
            </Tag>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <></>
  );
}
