import { SyntheticEvent } from 'react';

const IFrameNavigator = (event: SyntheticEvent<HTMLIFrameElement>) => {
  const iframe = event.target as HTMLIFrameElement;
  if (iframe.contentWindow !== null) {
    if (!iframe.contentWindow.location.pathname.startsWith('/d')) {
      window.location.replace(iframe.contentWindow.location.href);
    }
  }
};

export default IFrameNavigator;
