import { useState } from 'react';
import TabItem from '../../../models/operation/TabItemModel';
import Tabs from '../../../ui/tabs/Tabs';
import TaskHistory from '../../history/TaskHistory';
import TabsEnum from '../../tabs/TabsEnum';
import TaskCommentsTab from '../../comment/TaskCommentsTab';

interface TaskTabsProps {
  taskId: number;
}

function TaskTabs({ taskId }: TaskTabsProps) {
  const [activeTab, setActiveTab] = useState<TabsEnum>(TabsEnum.Comments);

  const tabItems: TabItem[] = [
    {
      label: TabsEnum.Comments,
      content: <TaskCommentsTab taskId={taskId} />,
    },
    {
      label: TabsEnum.History,
      content: <TaskHistory taskId={taskId} />,
    },
  ];

  return (
    <div className="task-tabs">
      <Tabs items={tabItems} activeTab={activeTab} onClick={setActiveTab} />{' '}
    </div>
  );
}

export default TaskTabs;
