import { ComponentAttribute, ComponentItem } from '../../models/operation/ComponentModel';
import { useTranslationText } from '../../translation/TranslationHooks';

export function ComponentsAttributes(props: {
  component?: ComponentItem;
  renderElement: (attribute: ComponentAttribute) => JSX.Element;
}) {
  const { t } = useTranslationText('components');
  const { component, renderElement } = props;
  const existAttributes = component?.attributes.length !== undefined && component?.attributes.length > 0;
  return existAttributes ? (
    <div className="component-attributes-attributes">{component?.attributes.map(renderElement)}</div>
  ) : (
    <span className="component-attributes-placeholder">{t('noAttributesFound')}</span>
  );
}
