import './KnowledgeBaseResultItem.scss';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { convert } from 'html-to-text';
import { useMemo } from 'react';
import { KnowledgeBaseArticle } from '../../models/knowledge-base/KnowledgeBaseModel';
import NavigationRoutes from '../../routing/NavigationRoutes';
import ArticleTagView from '../article/article-tag/ArticleTagView';
import { cutLongTextToImportantPart } from './searchFunction';

interface KnowledgeBaseResultItemProps {
  article: KnowledgeBaseArticle;
  highlightTextPart: string;
}

export default function KnowledgeBaseResultItem({ article, highlightTextPart }: KnowledgeBaseResultItemProps) {
  const { title, content, descriptionShort, author, tags = [], updatedAt } = article;

  const highlightedTextPartSplit = useMemo(() => {
    if (highlightTextPart.startsWith('tag:')) {
      return [highlightTextPart.replace('tag:', '').trim()];
    }
    return [highlightTextPart.replace('tag:', '').trim()];
  }, [highlightTextPart]);
  const htmlDecodedContent = useMemo(() => (content ? convert(content) : ''), [content]);

  const cutImportDescription = useMemo(
    () => cutLongTextToImportantPart(descriptionShort, highlightTextPart),
    [descriptionShort, highlightTextPart],
  );

  const cutImportContent = useMemo(
    () => cutLongTextToImportantPart(htmlDecodedContent, highlightTextPart),
    [highlightTextPart, htmlDecodedContent],
  );

  const hasWordInText = (word: string, text: string) => text.toLowerCase().includes(word.toLowerCase());

  return (
    <Link className="knowledge-base--result-item" to={NavigationRoutes.KnowledgeBaseArticleId(article.id)}>
      <div className="kb--title">
        <span>
          <Highlighter
            highlightClassName="highlight"
            searchWords={highlightedTextPartSplit}
            autoEscape
            textToHighlight={title}
          />
        </span>
      </div>
      <div className="kb--tags">
        <ArticleTagView tags={tags} highlightTags={highlightedTextPartSplit} />
      </div>
      <div className="kb--meta-data">
        <span>
          <Trans
            i18nKey="knowledgeBases.changedAtWithVariables.text"
            values={{
              date: new Date(updatedAt).toLocaleDateString(),
            }}
          />
        </span>
        <span className="author">{author}</span>
      </div>
      <div className="kb--description">
        <Highlighter
          highlightClassName="highlight"
          searchWords={highlightedTextPartSplit}
          autoEscape
          textToHighlight={cutImportDescription}
        />
      </div>
      {content && hasWordInText(highlightTextPart, content) && (
        <div className="kb--content">
          <Highlighter
            highlightClassName="highlight"
            searchWords={highlightedTextPartSplit}
            autoEscape
            textToHighlight={cutImportContent}
          />
        </div>
      )}
    </Link>
  );
}
