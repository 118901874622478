import { ReactSelectOption } from '../../search-dropdown/SearchDropdown';
import { UserModel } from '../../../models/operation/UserModel';
import { mapUsersToSimpleOptions, mapUserToSimpleOption } from '../../../models/operation/TicketFunctions';

export const getUserSelectOptions = (
  currentUserId: string,
  additionalOption: ReactSelectOption,
  users: UserModel[] | undefined,
) => {
  if (!users) return [];
  const options: ReactSelectOption[] = [];
  const currentUser = users.find((user) => user.id === currentUserId);
  if (currentUser) {
    options.push(mapUserToSimpleOption(currentUser));
  }
  options.push(additionalOption);
  options.push(...mapUsersToSimpleOptions(users.filter((user) => user.id !== currentUserId)));
  return options;
};
