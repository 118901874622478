import TextInput from '../text-input/TextInput';
import FormFieldWrapper from '../form-field-wrapper/FormFieldWrapper';

interface TextLabelProps {
  label?: string;
  placeholder?: string;
  value?: string | number;
  dataRole?: string;
  isUpdated?: boolean;
  isRequired?: boolean;
}

function TextLabel({ label, placeholder, value, dataRole, isUpdated, isRequired }: TextLabelProps) {
  return (
    <FormFieldWrapper label={label} isUpdated={isUpdated} isRequired={isRequired}>
      <TextInput placeholder={placeholder} readOnly value={value} dataRole={dataRole} />
    </FormFieldWrapper>
  );
}

export default TextLabel;
