import classNames from 'classnames';
import { DayOfWeek, weekdays } from '../../models/contract-management/ServiceTimeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import './dayOfWeekPicker.scss';

interface DayOfWeekBadgeProps {
  day: DayOfWeek;
  active: boolean;
  onClick?: (day: DayOfWeek, active: boolean) => void;
  disabled?: boolean;
}

function DayOfWeekBadge({ day, active, onClick, disabled }: DayOfWeekBadgeProps) {
  const { t } = useTranslationText('commons');

  function handleOnClick() {
    if (onClick !== undefined) {
      onClick(day, !active);
    }
  }

  return (
    <button
      type="button"
      className={classNames('day-badge', 'is-primary', { active, disabled })}
      onClick={handleOnClick}
      data-role={day}
      disabled={disabled}
    >
      {t(`dayBadge-${day.toLowerCase()}`)}
    </button>
  );
}

interface DayOfWeekPickerProps {
  selectedDays: DayOfWeek[];
  onChange?: (days: DayOfWeek[]) => void;
  disabled?: boolean;
}

export function DaysOfWeekPicker({ selectedDays, onChange, disabled }: DayOfWeekPickerProps) {
  function handleOnClick(day: DayOfWeek, selected: boolean) {
    if (onChange !== undefined) {
      const newValue = selected ? [...selectedDays, day] : selectedDays.filter((d) => d !== day);
      const sortedValue = weekdays.filter((d) => newValue.includes(d));
      onChange(sortedValue);
    }
  }

  return (
    <div className="days-of-week">
      {weekdays.map((day) => (
        <DayOfWeekBadge
          key={day}
          day={day}
          active={selectedDays.includes(day)}
          onClick={handleOnClick}
          disabled={disabled}
        />
      ))}
    </div>
  );
}
