import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { CreateContractModel, PatchContractModel } from '../../../models/contract-management/ContractManagementModel';
import { EditServiceTime } from '../../EditServiceTime/EditServiceTime';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { CollapsibleWithButtons } from '../../../ui/collapsible/CollapsibleWithButtons';

interface CreateServiceTimesFormProps {
  form: UseFormReturn<CreateContractModel, object>;
}

export function CreateServiceTimesFormCollapsible({ form }: CreateServiceTimesFormProps) {
  const { control } = form;
  const { append, fields, remove } = useFieldArray({
    control,
    name: `serviceTimes`,
  });
  const { t } = useTranslationText('contractManagements');
  const { t: tCommon } = useTranslationText('commons');

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('serviceTimes')}
      badgeCount={fields.length}
      addBtnClicked={() => append({ publicHolidaysIncluded: false, days: [] })}
      addPermission={UserResourcePermissions.Contract.Create}
    >
      {fields.length === 0 && tCommon('noItemsFoundAdd')}
      <div className="create-service-times">
        {fields.map((field, index: number) => (
          <EditServiceTime
            // TODO: Leider bisher keine bessere Lösung als diese type assertion gefunden und für besser befunden als 2 Komponenten, die bis auf den UseFormReturn-Typ identisch sind
            form={form as UseFormReturn<PatchContractModel>}
            key={field.id}
            index={index}
            onRemove={() => remove(index)}
          />
        ))}
      </div>
    </CollapsibleWithButtons>
  );
}
