import { mapTValuesToLoadOptions, OptionLabelBaseProps, SelectOption } from '@/ui/select/SelectUtils';
import { Criticality } from '@/components/criticality/Criticality';
import CriticalityTag from '@/components/criticality/CriticalityTag';

export function mapCriticalityToOption(criticality: Criticality): SelectOption<Criticality> {
  return {
    value: criticality,
    label: criticality,
    payload: criticality,
  };
}

export function mapCriticalitiesToOptions(criticalities: Criticality[]): SelectOption<Criticality>[] {
  return criticalities.map((criticality) => mapCriticalityToOption(criticality));
}

export function criticlaityLoadOptions(criticalities: Criticality[]) {
  return mapTValuesToLoadOptions<Criticality>(criticalities, mapCriticalitiesToOptions);
}

export function CriticalityOptionLabel({ payload }: OptionLabelBaseProps<Criticality>) {
  return <CriticalityTag criticality={payload} />;
}

interface MultiCriticalityValueProps {
  data: SelectOption<Criticality>;
  handleDelete: (deletedCriticality: Criticality) => void;
}

export function MultiCriticalityValue({ data, handleDelete }: MultiCriticalityValueProps) {
  return <CriticalityTag criticality={data.payload} handleDelete={handleDelete} />;
}
