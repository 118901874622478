import { YupType } from '../translation/YupLocal';

const createComponentValidationSchema = (validation: YupType) =>
  validation
    .object({
      displayName: validation.string().required().max(255).trim(),
      displayNameAlt1: validation.string().notRequired().max(255).trim(),
      componentTypeId: validation.string().notRequired().max(255).trim(),
    })
    .required();

export default createComponentValidationSchema;
