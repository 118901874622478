import './ListItemWrapper.scss';
import { faCheck, faTimes, faTrash, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';

interface ListItemWrapperProps {
  children: React.ReactNode | React.ReactNode[];
  mode?: 'update' | 'item';
  onRemove?: () => void;
  onAccept?: () => void;
  onCancel?: () => void;
  isLoading?: boolean;
  itemLink?: string;
  field: string;
  index?: number;
  renderAboveActionButtons?: React.ReactNode;
  deletePermission?: UserPermission;
  hasLabelSpacing?: boolean;
  deleteDisabled?: boolean;
}

export default function ListItemWrapper({
  children,
  mode,
  onRemove,
  onCancel,
  onAccept,
  deletePermission,
  field,
  index,
  renderAboveActionButtons,
  itemLink,
  isLoading,
  hasLabelSpacing = false,
  deleteDisabled,
}: ListItemWrapperProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const LoadingButton = <button type="button" className="list-item-btn is-loading" aria-label="loading" />;

  const hasActions = onRemove || onAccept || onCancel || itemLink;

  const OpenButton = useMemo(
    () =>
      itemLink && (
        <button
          type="button"
          className="list-item-btn"
          onClick={() => navigate(itemLink, { state: location.state })}
          data-role={`open-${field}-${index}`}
          data-for={`open-${field}-${index}`}
        >
          <FontAwesomeIcon icon={faUpRightFromSquare} />
        </button>
      ),
    [field, index, itemLink, location.state, navigate],
  );

  const AddCancelButton = useMemo(
    () => (
      <>
        {onAccept && (
          <button
            type="button"
            className="list-item-btn"
            onClick={() => onAccept()}
            data-role={`add-${field}-${index}`}
            data-for={`add-${field}-${index}`}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        )}
        {onCancel && (
          <button
            type="button"
            className="list-item-btn"
            onClick={() => onCancel()}
            data-role={`cancel-${field}-${index}`}
            data-for={`cancel-${field}-${index}`}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </>
    ),
    [field, index, onAccept, onCancel],
  );

  const RemoveButton = useMemo(
    () =>
      onRemove && (
        <UserRoleCheck requiredPermission={deletePermission}>
          <button
            type="button"
            className="list-item-btn"
            onClick={() => onRemove()}
            data-role={`remove-${field}-${index}`}
            data-for={`remove-${field}-${index}`}
            disabled={deleteDisabled}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </UserRoleCheck>
      ),
    [deletePermission, field, index, deleteDisabled, onRemove],
  );

  const ActionButtons = useCallback(
    () => (
      <>
        {(mode ?? 'item') === 'update' ? (
          AddCancelButton
        ) : (
          <div className="additional-actions-wrapper ">
            {renderAboveActionButtons && <div>{renderAboveActionButtons}</div>}
            <div className="action-buttons">
              {OpenButton}
              {RemoveButton}
            </div>
          </div>
        )}
      </>
    ),
    [AddCancelButton, OpenButton, RemoveButton, mode, renderAboveActionButtons],
  );

  return (
    <div className="list-item-wrapper">
      <div className="list-item-content">{children}</div>
      {hasActions && (
        <div
          className={classNames('actions', {
            'label-spacing': hasLabelSpacing,
          })}
        >
          {isLoading ? LoadingButton : <ActionButtons />}
        </div>
      )}
    </div>
  );
}
