import parse from 'html-react-parser';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { TicketCommentModel } from '../../../../models/operation/TicketModel';
import './CommentListingItem.scss';
import DateFormats from '../../../../models/date-formats/DateFormats';
import { CommentType } from '../../../../models/operation/CreateCommentModel';
import NavigationRoutes from '../../../../routing/NavigationRoutes';

interface CommentListingItemProps {
  comment: TicketCommentModel;
}

function getCommentTranslation(comment: TicketCommentModel) {
  switch (comment.type) {
    case CommentType.QUESTION:
      return (
        <Trans
          i18nKey="comments.userAskedQuestionOnDate.text"
          values={{
            author: comment.author,
            date: `${dayjs(comment.createdAt).format(DateFormats.FULLDATE_HOURS_MINS)}`,
            taskId: comment.taskId,
          }}
          components={{
            1: <span className="highlight" />,
            2: <Link to={NavigationRoutes.TaskId(comment.taskId!)} className="highlight-task" />,
          }}
        />
      );
    case CommentType.TASK_COMMENT:
      return (
        <Trans
          i18nKey="comments.userCommentTaskOnDate.text"
          values={{
            author: comment.author,
            date: `${dayjs(comment.createdAt).format(DateFormats.FULLDATE_HOURS_MINS)}`,
            taskId: comment.taskId,
          }}
          components={{
            1: <span className="highlight" />,
            2: <Link to={NavigationRoutes.TaskId(comment.taskId!)} className="highlight-task" />,
          }}
        />
      );
    case CommentType.COMMENT:
    default:
      return (
        <Trans
          i18nKey="comments.userCommentOnDate.text"
          values={{
            author: comment.author,
            date: `${dayjs(comment.createdAt).format(DateFormats.FULLDATE_HOURS_MINS)} `,
          }}
          components={{ 1: <span className="highlight" /> }}
        />
      );
  }
}

export default function CommentListingItem({ comment }: CommentListingItemProps) {
  return (
    <div
      className={classNames('comment-listing-item', {
        isQuestion: comment.type === CommentType.QUESTION,
      })}
    >
      <div className="comment-header">{getCommentTranslation(comment)}</div>
      <div className="comment-text" data-role="comment-text">
        {parse(comment.text ?? '')}
      </div>
    </div>
  );
}
