import logo from '@e21x/autobahn-styleguide/assets/logo.svg';
import { useTranslationText } from '../../translation/TranslationHooks';
import './Header.scss';

export default function Header() {
  const { t } = useTranslationText('commons');

  return (
    <div className="layout-header">
      <header className="header">
        <span />
        <span className="is-primary is-bold">{t('systemTitle')}</span>
        <img src={logo} alt="Autobahn-Logo" className="responsive-logo" />
      </header>
    </div>
  );
}
