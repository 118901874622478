import { ComponentRelationDto, RelationFilter } from '../models/operation/RelationTypeModel';
import { QueryOptions, useGetQuery } from './GetQuery';
import { queryStringFromObject } from './QueryUtils';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { MonitoringApiPrefix } from './MonitoringApi';
import { Page } from '../models/pagination/Pagination';

export default function useGetRelations(
  componentId: string,
  filter?: RelationFilter,
  options?: QueryOptions<Page<ComponentRelationDto>>,
) {
  const queryString = queryStringFromObject(filter);
  const path = `${MonitoringApiPrefix}/component/${componentId}/relation${filter ? `?${queryString}` : ''}`;

  return useGetQuery<Page<ComponentRelationDto>>(
    [ServerStateKeysEnum.ComponentRelations, componentId, queryString],
    path,
    options,
  );
}
