import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, ControllerRenderProps, FieldError, useForm } from 'react-hook-form';
import React, { useCallback, useEffect } from 'react';
import FormFieldWrapper from '../form-field-wrapper/FormFieldWrapper';

type FormPatchProps<T, V, E extends FieldError> = {
  validationSchema?: any;
  patchProperty: keyof T;
  defaultValue?: V;
  afterValidate?: (isFieldValid: boolean, property: keyof T, value: V) => void;
  render: (
    { field }: { field: ControllerRenderProps },
    property: keyof T,
    isFormValid: boolean,
    error?: E,
  ) => JSX.Element;
  context?: object;
  label?: string;
};

export default function FormPatchWrapper<T, V, E extends FieldError>({
  validationSchema,
  patchProperty,
  defaultValue,
  afterValidate,
  render,
  context,
  label,
}: FormPatchProps<T, V, E>): React.ReactElement<FormPatchProps<T, V, E>> {
  const { handleSubmit, control, formState, setValue, trigger, watch } = useForm(
    validationSchema
      ? {
          mode: 'onChange',
          resolver: yupResolver(validationSchema),
          context,
        }
      : { mode: 'onChange' },
  );

  const triggerValidation = useCallback(async () => trigger(patchProperty.toString()), [patchProperty, trigger]);

  useEffect(() => {
    setValue(patchProperty.toString(), defaultValue);
  }, [defaultValue, patchProperty, setValue]);

  useEffect(() => {
    const subscription = watch((value) => {
      triggerValidation()
        .then((isFieldValid) => {
          if (afterValidate !== undefined) {
            afterValidate(isFieldValid, patchProperty, value[patchProperty.toString()]);
          }
        })
        .catch((error) => console.error('Fehler bei Patch Validierung', error));
    });
    return () => subscription.unsubscribe();
  }, [afterValidate, patchProperty, triggerValidation, watch]);

  // handleSubmit(() => {} notwendig, weil hier eine OnSubmit Funktion erwartet wird. Diese benutzen wir allerdings,
  // nicht, weil wir keine Submit-Buttons auf einzelnen Komponenten haben, die wir validiert haben.
  return (
    <form name={`form-${patchProperty.toString()}`} onSubmit={handleSubmit(() => {})} className="is-fullwidth">
      <FormFieldWrapper error={formState.errors?.[patchProperty.toString()] as E} label={label} onlyError>
        <Controller
          name={patchProperty.toString()}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) =>
            render({ field }, patchProperty, formState.isValid, formState.errors?.[patchProperty.toString()] as E)
          }
        />
      </FormFieldWrapper>
    </form>
  );
}
