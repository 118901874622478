import RenderIfVisible from 'react-render-if-visible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { MouseEvent, useState } from 'react';
import { Tag } from '@aos/styleguide-react';
import LinkTableCell, { NavigationParams } from '../../ui/link-table-cell/LinkTableCell';
import { SummarizedComponent } from '../../models/operation/ComponentModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import { DescendentComponentStatusBar } from '../child-component-status/DescendentComponentStatusBar';
import { ChildComponents } from './ChildComponents';
import { columnVisible, UserOverviewColumnConfig, UserOverviewColumnType } from './UserOverviewConfig';
import { MultiSelectItem } from '../../ui/multiselect/multiselect-item/MultiSelectItem';
import ComponentsMultiSelectContext from '../ComponentsMultiSelectContext';
import Tooltip from '../../ui/tooltip/Tooltip';
import FavoriteStar from '../../ui/favorite-star/FavoriteStar';
import { componentStatusToColor } from '../component-status-tag/ComponentStatusTag';

interface ComponentTableRowProps {
  component: SummarizedComponent;
  config?: Map<UserOverviewColumnType, UserOverviewColumnConfig>;
  level?: number;
  relationType?: string;
  canExpand?: (src: SummarizedComponent) => boolean;
  onExpand?: (src: SummarizedComponent, relationType?: string) => SummarizedComponent[] | undefined;
  isLast?: boolean;
  isSmall?: boolean;
}

function saveComponentToggleInStorage(saveComponent: boolean, component: SummarizedComponent): boolean {
  const storage = localStorage.getItem('open-components');
  let openComponents: string[] = storage ? JSON.parse(storage) : [];
  if (saveComponent) {
    openComponents.push(component.id);
  } else {
    openComponents = openComponents.filter((it) => it !== component.id);
  }
  localStorage.setItem('open-components', JSON.stringify(Array.from(new Set(openComponents).values())));
  return saveComponent;
}

function checkComponentToggleInStorage(component: SummarizedComponent): boolean {
  const storage = localStorage.getItem('open-components');
  const openComponents: string[] = storage ? JSON.parse(storage) : [];
  return openComponents.find((it) => it === component.id) !== undefined;
}

export function ComponentTableRow({
  component,
  config,
  relationType,
  canExpand,
  onExpand,
  level,
  isLast,
  isSmall = false,
}: ComponentTableRowProps) {
  const { t } = useTranslationText('components');

  const [isExpanded, setExpanded] = useState<boolean>(checkComponentToggleInStorage(component));

  const toggleExpand = (e: MouseEvent) => {
    e.preventDefault();
    setExpanded((current) => saveComponentToggleInStorage(!current, component));
  };

  const stateNavigationParams: NavigationParams = {
    pathname: component.id,
    state: { relation: relationType },
  };

  return (
    <>
      <RenderIfVisible
        key={component.id}
        defaultHeight={41}
        stayRendered
        rootElement="tr"
        rootElementClass="is-clickable"
        placeholderElement="tr"
        placeholderElementClass="is-clickable"
        data-id={component?.id}
        data-role="component-item"
        data-level={level ?? 0}
      >
        {columnVisible(UserOverviewColumnType.DISPLAY_NAME, config) && (
          <LinkTableCell
            className={`tree-data-cell ${!isExpanded && `is-collapsed`}`}
            dataRole="component-displayName"
            to={stateNavigationParams}
            level={level}
            canExpand={canExpand && canExpand(component)}
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
            isLast={isLast}
            hasTreeStructure
            isSmall={isSmall}
          >
            <MultiSelectItem context={ComponentsMultiSelectContext} item={component} requireKeyModifier>
              <div className="component-display-name is-truncated-2">{component.displayName}</div>
            </MultiSelectItem>
          </LinkTableCell>
        )}
        {columnVisible(UserOverviewColumnType.TYPE, config) && (
          <LinkTableCell isSmall={isSmall} isTruncated hasTreeStructure to={stateNavigationParams}>
            {component.componentType}
          </LinkTableCell>
        )}
        {columnVisible(UserOverviewColumnType.STATUS, config) && (
          <LinkTableCell isSmall={isSmall} hasTreeStructure to={stateNavigationParams}>
            <Tag id={component.id} color={componentStatusToColor(component.status)}>
              {t(`status-${component.status.toLocaleLowerCase()}`)}
            </Tag>
            <Tooltip anchorId={component.id} content={t(`status-${component.status.toLocaleLowerCase()}`)} />
          </LinkTableCell>
        )}
        {columnVisible(UserOverviewColumnType.MAINTENANCE, config) && (
          <LinkTableCell isSmall={isSmall} hasTreeStructure to={stateNavigationParams}>
            {component.inMaintenance && <FontAwesomeIcon icon={faWrench} />}
          </LinkTableCell>
        )}
        {columnVisible(UserOverviewColumnType.DISPLAY_NAME, config) && (
          <LinkTableCell isSmall={isSmall} hasTreeStructure to={stateNavigationParams}>
            {component.isFavorite && (
              <span className="component-favorite">
                <FavoriteStar tooltipText={t('favorite')} />
              </span>
            )}
          </LinkTableCell>
        )}
        {columnVisible(UserOverviewColumnType.CHILD_STATUS, config) && (
          <LinkTableCell
            isSmall={isSmall}
            hasTreeStructure
            to={stateNavigationParams}
            dataRole="component-descendent-component-stats"
          >
            {component.descendentComponentStats && (
              <DescendentComponentStatusBar descendentComponentStats={component.descendentComponentStats} />
            )}
          </LinkTableCell>
        )}
      </RenderIfVisible>

      {isExpanded && onExpand ? (
        <ChildComponents
          level={(level ?? 0) + 1}
          component={component}
          config={config}
          onExpand={onExpand}
          canExpand={canExpand}
          relationType={relationType}
          isSmall={isSmall}
        />
      ) : undefined}
    </>
  );
}
