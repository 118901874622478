import { useAuth } from 'react-oidc-context';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import WidgetWrapper from '../../WidgetWrapper';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import WidgetArticleView from '../widget-content/WidgetArticleView';
import { useGetKnowledgeBaseArticles } from '../../../../react-query/KnowledgeBaseApi';
import DateFormats from '../../../../models/date-formats/DateFormats';

export default function OwnedArticles() {
  const { t } = useTranslationText('knowledgeBases');
  const { user } = useAuth();
  const {
    data: articles = [],
    isLoading,
    isError,
  } = useGetKnowledgeBaseArticles(
    { limit: 5, sort: 'created_at:DESC', author: user?.profile.email },
    { enabled: !!user?.profile.email },
  );

  return (
    <WidgetWrapper
      title={t('ownedArticles')}
      isLoading={isLoading}
      isError={isError}
      noContent={t('noOwnCreatedArticles')}
    >
      {articles.map((article) => (
        <WidgetArticleView
          key={article.id}
          id={article.id}
          title={article.title}
          subTitle={
            <Trans
              i18nKey="knowledgeBases.createdAtDate.text"
              values={{
                date: `${dayjs(article.createdAt).format(DateFormats.FULLDATE_HOURS_MINS)}`,
              }}
            />
          }
        />
      ))}
    </WidgetWrapper>
  );
}
