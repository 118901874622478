export type ActorKind = 'ANONYMOUS' | 'USER' | 'SERVICE' | 'AUTOMATION_RULE' | 'MONITORING' | 'EMAIL';

export const AllActorKinds: ActorKind[] = ['ANONYMOUS', 'USER', 'SERVICE', 'AUTOMATION_RULE', 'MONITORING', 'EMAIL'];

export interface Actor {
  kind: ActorKind;
  id?: string;
  email?: string;
}

export function toQueryParam(a: Actor): string {
  return a.id ? `${a.kind}:${a.id}` : a.kind;
}

export function fromQueryParam(queryParam: string): Actor {
  const splitIndex = queryParam.indexOf(':');
  if (splitIndex !== -1) {
    return {
      kind: queryParam.substring(0, splitIndex),
      id: queryParam.substring(splitIndex + 1),
    } as Actor;
  }

  return {
    kind: queryParam,
  } as Actor;
}

export function fromQueryParams(queryParams?: string[]): Actor[] | undefined {
  return queryParams?.map(fromQueryParam);
}
