import { SingleValue } from 'react-select';
import SingleSelectDropdown from '../single-select-dropdown/SingleSelectDropdown';
import { UserPermission } from '../../auth/UserPermission';

interface ReactSelectOption {
  label: string;
  value: string;
}

interface SingleValueInputProps {
  onChange: (values: string) => void;
  placeholder?: string;
  value?: string;
  requiredPermission: UserPermission;
  options: ReactSelectOption[];
  isDisabled?: boolean;
}

export default function SingleValueInput({
  onChange,
  placeholder,
  value,
  requiredPermission,
  options,
  isDisabled,
}: SingleValueInputProps) {
  const handleChange = (option: SingleValue<ReactSelectOption>) => {
    onChange(option?.value ?? '');
  };

  const selectValue = options.find((aRole) => aRole.value === value);

  return (
    <SingleSelectDropdown
      disabled={isDisabled}
      options={options}
      requiredPermission={requiredPermission}
      onChange={handleChange}
      placeholder={placeholder}
      value={selectValue}
    />
  );
}
