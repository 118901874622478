import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { useCallback } from 'react';
import { useTranslationText } from '../../../translation/TranslationHooks';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '../../../ui/text-input/TextInput';
import { PatchContractModel } from '../../../models/contract-management/ContractManagementModel';
import SingleValueInput from '../../../ui/single-value-input/SingleValueInput';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { CreateSupportContactPersonModel } from '../../../models/contract-management/SupportContactPersonModel';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';
import { ContractRole, ContractRoles } from '../../../models/contract-management/ContractRole';
import { mapContractRolesToOptions } from '../../../models/contract-management/ContractFunctions';
import { SwitchInput } from '../../../ui/switch/SwitchInput';

interface EditSupportContactPersonProps {
  form: UseFormReturn<PatchContractModel>;
  index: number;
  onRemove: () => void;
  contractRoles: ContractRole[] | undefined;
}

export function EditSupportContactPerson({ form, index, onRemove, contractRoles }: EditSupportContactPersonProps) {
  const { t } = useTranslationText('contractManagements');

  const { control, formState } = form;

  const getErrors = useCallback(
    (field: keyof CreateSupportContactPersonModel) =>
      formState.errors?.supportContactPersons !== undefined &&
      formState.errors?.supportContactPersons[index] !== undefined
        ? formState?.errors?.supportContactPersons.at?.(index)?.[field]
        : undefined,
    [formState.errors?.supportContactPersons, index],
  );

  const supportContactPersons: CreateSupportContactPersonModel[] = form.getValues('supportContactPersons') ?? [];
  const isFaultReportContactPerson = supportContactPersons[index].role === ContractRoles.faultReport;

  const availableRoles = isFaultReportContactPerson
    ? contractRoles?.filter((aContractRole) => aContractRole.key === ContractRoles.faultReport)
    : contractRoles?.filter((aContractRole) => aContractRole.key !== ContractRoles.faultReport);

  const CompanyNameInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<PatchContractModel, `supportContactPersons.${typeof index}.companyName`>;
    }) => (
      <FormFieldWrapper error={getErrors('companyName')} label={t('supportContactCompanyNameLabel')} isRequired>
        <TextInput
          placeholder={t('supportContactCompanyNamePlaceholder')}
          dataRole={`contract-support-contact-person-company-name-${index}`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('companyName')}
          key={`contract-support-contact-person-company-name-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const PhoneNumberInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<PatchContractModel, `supportContactPersons.${typeof index}.phoneNumber`>;
    }) => (
      <FormFieldWrapper error={getErrors('phoneNumber')} label={t('phoneNumberLabel')}>
        <TextInput
          placeholder={t('phoneNumberPlaceholder')}
          dataRole={`contract-support-contact-person-phone-number-${index}`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('phoneNumber')}
          key={`contract-support-contact-person-phone-number-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const MobileNumberInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<PatchContractModel, `supportContactPersons.${typeof index}.mobileNumber`>;
    }) => (
      <FormFieldWrapper error={getErrors('mobileNumber')} label={t('mobileNumberLabel')}>
        <TextInput
          placeholder={t('mobileNumberPlaceholder')}
          dataRole={`contract-support-contact-person-mobile-number-${index}`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('mobileNumber')}
          key={`contract-support-contact-person-mobile-number-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const TelefaxInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<PatchContractModel, `supportContactPersons.${typeof index}.telefax`>;
    }) => (
      <FormFieldWrapper error={getErrors('telefax')} label={t('faxNumberLabel')}>
        <TextInput
          placeholder={t('faxNumberPlaceholder')}
          dataRole={`contract-support-contact-person-telefax-${index}`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('telefax')}
          key={`contract-support-contact-person-telefax-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const EmailInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<PatchContractModel, `supportContactPersons.${typeof index}.email`>;
    }) => (
      <FormFieldWrapper error={getErrors('email')} label={t('emailLabel')}>
        <TextInput
          placeholder={t('emailPlaceholder')}
          dataRole={`contract-support-contact-person-email-${index}`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('email')}
          key={`contract-support-contact-person-email-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const RoleInput = useCallback(
    ({ field }: { field: ControllerRenderProps<PatchContractModel, `supportContactPersons.${typeof index}.role`> }) => (
      <FormFieldWrapper error={getErrors('role')} label={t('roleLabel')} isRequired>
        <SingleValueInput
          onChange={(value) => field.onChange(value)}
          value={field.value}
          requiredPermission={UserResourcePermissions.Contract.Create}
          options={mapContractRolesToOptions(t, availableRoles)}
          placeholder={t('supportContactRolePlaceholder')}
          isDisabled={isFaultReportContactPerson}
        />
      </FormFieldWrapper>
    ),
    [availableRoles, getErrors, isFaultReportContactPerson, t],
  );
  const NotifyForNewTaskInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<PatchContractModel, `supportContactPersons.${typeof index}.notifyForNewTask`>;
    }) => (
      <FormFieldWrapper error={getErrors('notifyForNewTask')} label={t('notifyForNewTaskLabel')}>
        <SwitchInput
          id={`contract-support-contact-person-notify-for-new-task-${index}-switch`}
          onChange={field.onChange}
          checked={field.value ?? false}
          isError={getErrors('notifyForNewTask') !== undefined}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  return (
    <ListItemWrapper
      mode="item"
      field="contactPersons"
      index={index}
      onRemove={isFaultReportContactPerson ? undefined : onRemove}
      deletePermission={UserResourcePermissions.Contract.Create}
    >
      <div className="horizontal-wrapper">
        <Controller name={`supportContactPersons.${index}.role`} control={control} render={RoleInput} />
      </div>
      <div className="horizontal-wrapper">
        <Controller name={`supportContactPersons.${index}.companyName`} control={control} render={CompanyNameInput} />
        <Controller name={`supportContactPersons.${index}.email`} control={control} render={EmailInput} />
      </div>
      <div className="horizontal-wrapper">
        <Controller name={`supportContactPersons.${index}.phoneNumber`} control={control} render={PhoneNumberInput} />
        <Controller name={`supportContactPersons.${index}.telefax`} control={control} render={TelefaxInput} />
      </div>
      <div className="horizontal-wrapper">
        <Controller name={`supportContactPersons.${index}.mobileNumber`} control={control} render={MobileNumberInput} />
        <Controller
          name={`supportContactPersons.${index}.notifyForNewTask`}
          control={control}
          render={NotifyForNewTaskInput}
        />
      </div>
    </ListItemWrapper>
  );
}
