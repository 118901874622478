import React, { useEffect, useState } from 'react';
import { InputActionMeta, SingleValue } from 'react-select';
import './SearchDropdown.scss';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserPermission } from '../../auth/UserPermission';
import SingleSelectDropdown from '../single-select-dropdown/SingleSelectDropdown';

// TODO ADB-558: refactor ReactSelectOption
export interface ReactSelectOption {
  readonly label: string;
  readonly value: any;
}

interface SearchDropdownProps<T> {
  isError?: boolean;
  isLoading?: boolean;
  noOptionsMessage?: string;
  isLoadingMessage?: string;
  options: ReactSelectOption[];
  onChange: (selectedOption: SingleValue<ReactSelectOption>) => void;
  placeholder?: string;
  value?: T;
  onInputChange?: (value: string, action?: InputActionMeta) => void;
  tabIndex?: number;
  autoFocus?: boolean;
  mapValueToSelectOption?: (value: T) => SingleValue<ReactSelectOption>;
  requiredPermission: UserPermission;
  isClearable?: boolean;
  openMenuOnClick?: boolean;
  isSmall?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  filterOption?: (option: FilterOptionOption<ReactSelectOption>, inputValue: string) => boolean;
}

export default function SearchDropdown<T>({
  isError,
  isLoading,
  noOptionsMessage,
  isLoadingMessage,
  options,
  onChange,
  placeholder,
  value,
  onInputChange,
  tabIndex,
  autoFocus,
  mapValueToSelectOption,
  requiredPermission,
  isClearable = false,
  openMenuOnClick = true,
  isSmall,
  isRequired = false,
  isDisabled = false,
  filterOption,
}: SearchDropdownProps<T>): React.ReactElement<SearchDropdownProps<T>> {
  const [selectOption, setSelectOption] = useState<SingleValue<ReactSelectOption | undefined>>(undefined);
  const { hasPermission } = useAuthUser();

  useEffect(() => {
    if (value && mapValueToSelectOption) {
      setSelectOption(mapValueToSelectOption(value));
    } else {
      setSelectOption(undefined);
    }
  }, [mapValueToSelectOption, value]);

  return (
    <SingleSelectDropdown
      options={options}
      onChange={onChange}
      requiredPermission={requiredPermission}
      isClearable={isClearable}
      isSearchable
      isLoading={isLoading}
      openMenuOnClick={openMenuOnClick}
      onInputChange={onInputChange}
      size={isSmall ? 'is-small' : 'is-normal'}
      tabIndex={tabIndex}
      value={selectOption}
      disabled={isDisabled || !hasPermission(requiredPermission)}
      autoFocus={autoFocus}
      isRequired={isRequired}
      placeholder={placeholder}
      isLoadingMessage={() => isLoadingMessage}
      noOptionsMessage={() => noOptionsMessage}
      isError={isError}
      filterOption={filterOption}
    />
  );
}
