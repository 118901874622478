import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ExistingContractItem } from '../../components/component-contract-item/ExistingContractItem';
import { useLongParamId } from '../../custom-hooks/UseParamId';
import { ComponentItem } from '../../models/operation/ComponentModel';
import { CreateTaskDto } from '../../models/operation/CreateTaskDto';
import { TaskDto, TaskState } from '../../models/operation/TaskDto';
import { useGetContractById } from '../../react-query/ContractManagementSystemApi';
import { MutationKey, MutationPath, usePostMutation } from '../../react-query/MutationQueries';
import { useTranslationText } from '../../translation/TranslationHooks';
import { ContractSupportDetails } from './ContractSupportDetails';
import ContractTaskItem from './contract-task-item/ContractTaskItem';
import './TicketContract.scss';
import { invalidateTicketHistory, invalidateTicketTasks } from '../../react-query/InvalidationQueries';
import Modal from '../../ui/modal/Modal';

interface TicketContractProps {
  sourceComponent: ComponentItem;
  currentComponent: ComponentItem;
  tasks?: TaskDto[];
}

export function TicketContractItem({ tasks, sourceComponent, currentComponent }: TicketContractProps) {
  const { id: ticketId } = useLongParamId();
  const { t } = useTranslationText('contractManagements');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tTickets } = useTranslationText('tickets');
  const { t: tCommon } = useTranslationText('commons');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const { data: contract } = useGetContractById(sourceComponent.assignedContractId!);

  const { mutate, isPending } = usePostMutation<CreateTaskDto, TaskDto>(MutationKey.PostTask, {
    onSuccess: async () => {
      toast.success(tSuccess('taskStartedSuccessfully'));
      await invalidateTicketTasks(queryClient, ticketId);
      await invalidateTicketHistory(queryClient, ticketId);
    },
    onError: () => {
      toast.error(tError('taskCouldNotBeStarted'));
    },
  });

  const unfinishedTaskLength = tasks?.filter((task) => task.state !== TaskState.DONE)?.length ?? 0;

  const startTask = () => {
    mutate({
      body: {
        contractId: sourceComponent.assignedContractId!,
      },
      path: MutationPath.CreateTask(ticketId),
    });
    setIsModalVisible(false);
  };

  return (
    <div className="ticket-contract">
      <div className="contract">
        <div className="contract-data">
          <ExistingContractItem sourceComponent={sourceComponent} currentComponent={currentComponent} readOnly />
          <div className="ticket-contract-details">
            {contract && <ContractSupportDetails contract={contract} />}

            {unfinishedTaskLength === 0 && !Number.isNaN(ticketId) && (
              <div className="actions">
                <button
                  type="button"
                  className={classNames('button icon-button action-button', {
                    'is-loading': isPending,
                  })}
                  onClick={() => setIsModalVisible(true)}
                  data-role={`start-task-${currentComponent?.displayName}`}
                >
                  <FontAwesomeIcon icon={faHandshake} className="toggle" /> <span>{t('startTask')}</span>
                </button>
                <Modal
                  isVisible={isModalVisible}
                  title={t('taskCreate')}
                  cancelBtnText={tCommon('abort')}
                  confirmBtnText={tCommon('start')}
                  onCancel={() => setIsModalVisible(false)}
                  onConfirm={startTask}
                  onClose={() => setIsModalVisible(false)}
                >
                  <p>{t('warningTaskCreate')}</p>
                </Modal>
              </div>
            )}
          </div>
          {tasks?.length !== 0 && !Number.isNaN(ticketId) && (
            <div className="ticket-contract-tasks">
              <div className="is-divider" data-content={tTickets('allTasks')} />
              {tasks?.map((task) => <ContractTaskItem task={task} key={`contract-task-${task.id}`} />)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
