import React from 'react';
import { Link } from 'react-router-dom';
import './LinkTableCell.scss';
import { Icon, Table as AosTable } from '@aos/styleguide-react';

interface IndentLineProps {
  horizontalLine: boolean;
  isLast: boolean;
}

function IndentLine({ horizontalLine, isLast }: IndentLineProps) {
  let entry: string = 'entry';
  if (!horizontalLine) {
    entry = 'no-entry';
  } else if (isLast) {
    entry = 'last-entry';
  }
  return (
    <div className="tree-level">
      <div className={`tree-line ${entry}`}>
        <div className="vertical-border">
          <div className="horizontal-border" />
        </div>
      </div>
    </div>
  );
}

export interface NavigationParams {
  pathname: string;
  search?: string;
  hash?: string;
  state?: object;
}

interface LinkTableRowProps {
  children: React.ReactNode;
  level?: number;
  to: string | NavigationParams;
  dataRole?: string;
  className?: string;
  isLast?: boolean;
  canExpand?: boolean;
  isExpanded?: boolean;
  toggleExpand?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  hasTreeStructure?: boolean;
  isTruncated?: boolean;
  isSmall?: boolean;
}

export default function LinkTableCell({
  children,
  level,
  to,
  dataRole,
  className,
  isLast,
  canExpand,
  isExpanded,
  toggleExpand,
  hasTreeStructure,
  isTruncated,
  isSmall = false,
}: LinkTableRowProps) {
  return (
    <AosTable.DataCell className={className} data-role={dataRole}>
      {level &&
        Array(level)
          .fill(0)
          .map((_, index) => (
            <IndentLine
              horizontalLine={index === level - 1}
              isLast={!!(level === index + 1 && isLast && !isExpanded)}
            />
          ))}
      {canExpand && (
        <button
          className={`tree-expand-button ${!isExpanded && `is-collapsed`}`}
          type="button"
          onClick={toggleExpand}
          aria-label={isExpanded ? 'Collapse' : 'Expand'}
        >
          <Icon name="chevron-down" size={isSmall ? undefined : 'is-large'} />
        </button>
      )}
      <Link className="link-item" to={to}>
        <div className={`${hasTreeStructure ? 'tree-data-cell-content' : ''} ${isTruncated ? 'is-truncated-2' : ''}`}>
          <div>{children}</div>
        </div>
      </Link>
    </AosTable.DataCell>
  );
}
