import { MultiValue } from 'react-select';
import MultiSelectDropdown, { ReactSelectOption } from '../../multi-select-dropdown/MultiSelectDropdown';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { AllComponentStatus } from '../../../models/operation/ComponentModel';

interface ComponentStatusFilterProps {
  label?: string;
  onChange: (states: string[]) => void;
  selectedStatus: (string | null)[] | null | undefined;
}

function formatOptionLabel(opt: ReactSelectOption<string>) {
  return <span data-role={`component-status-${opt.value}`}>{opt.label}</span>;
}

export default function ComponentStatusFilter({ label, selectedStatus, onChange }: ComponentStatusFilterProps) {
  const { t } = useTranslationText('components');

  const componentStatusOptions = AllComponentStatus.map((status) => ({
    value: status,
    label: t(`status-${status.toLowerCase()}`),
  }));

  const handleOnChange = (states: MultiValue<ReactSelectOption<string>>) =>
    onChange(states.map((state) => state.value));

  const getMappedValues = () => componentStatusOptions.filter(({ value }) => selectedStatus?.includes(value));

  return (
    <>
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <MultiSelectDropdown
        onChange={handleOnChange}
        options={componentStatusOptions}
        openMenuOnClick
        isSearchable={false}
        placeholder={t('selectState')}
        mappedValues={getMappedValues()}
        formatOptionLabel={formatOptionLabel}
      />
    </>
  );
}
