import TextArea from '../text-area/TextArea';
import FormFieldWrapper from '../form-field-wrapper/FormFieldWrapper';

interface TextAreaLabelProps {
  label?: string;
  placeholder?: string;
  value?: string;
  isUpdated?: boolean;
  autosizeOnLoad?: boolean;
}

export default function TextAreaLabel({ label, placeholder, value, isUpdated, autosizeOnLoad }: TextAreaLabelProps) {
  return (
    <FormFieldWrapper label={label} isUpdated={isUpdated}>
      <TextArea placeholder={placeholder} readOnly value={value} autosizeOnLoad={autosizeOnLoad} />
    </FormFieldWrapper>
  );
}
