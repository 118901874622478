import MultiSelectOption from '../multiselect-option/MultiSelectOption';
import { Dropdown, DropdownItemsFactory } from '../../dropdown/Dropdown';
import { MultiSelect } from '../MultiSelectContext';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface MultiSelectDropdownProps<T> {
  context: React.Context<MultiSelect<T> | undefined>;
  renderItems: DropdownItemsFactory;
}

function MultiSelectDropdown<T>({ context, renderItems }: MultiSelectDropdownProps<T>) {
  const { t } = useTranslationText('commons');
  return (
    <MultiSelectOption context={context}>
      <Dropdown title={<>{t('bulkActions')}</>} renderItems={renderItems} />
    </MultiSelectOption>
  );
}

export default MultiSelectDropdown;
