import { Link } from 'react-router-dom';
import { Collapsible } from '@aos/styleguide-react';
import { useGetKnowledgeBaseArticles } from '../../react-query/KnowledgeBaseApi';
import NavigationRoutes from '../../routing/NavigationRoutes';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { useTranslationText } from '../../translation/TranslationHooks';
import ArticleCard from '../../knowledge-base/article/article-card/ArticleCard';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import './TicketArticles.scss';
import { TicketComponent } from '../../models/operation/TicketModel';

interface ArticlesProps {
  ticketComponent: TicketComponent;
}

function Articles({ ticketComponent }: Readonly<ArticlesProps>) {
  const { data: articles = [], isLoading } = useGetKnowledgeBaseArticles({
    componentDisplayName: ticketComponent.displayName,
    componentTypeIds: [ticketComponent.componentTypeId],
    componentIds: [ticketComponent.id],
    sort: 'created_at:desc',
  });
  const { t } = useTranslationText('tickets');

  const articleElements = articles?.map((article) => (
    <div key={article.id}>
      <ArticleCard article={article} />
      <div className="list-separator" />
    </div>
  ));

  return (
    <LoadingSpinner isLoading={isLoading}>
      {articleElements?.length ? (
        <div className="ticket-articles ">{articleElements}</div>
      ) : (
        <Link to={NavigationRoutes.KnowledgeBaseDashboard} className="no-articles">
          {t('noTicketArticlesFound')}
        </Link>
      )}
    </LoadingSpinner>
  );
}

interface TicketArticlesProps {
  ticketComponent?: TicketComponent | null;
}

export default function TicketArticles({ ticketComponent }: Readonly<TicketArticlesProps>) {
  const { t } = useTranslationText('tickets');

  return (
    <UserRoleCheck requiredPermission={UserResourcePermissions.Article.Read}>
      <Collapsible
        header={t('suggestedKnowledgeBaseArticles')}
        isInitiallyCollapsed={false}
        className="kb-article-suggestions"
      >
        {ticketComponent ? (
          <Articles ticketComponent={ticketComponent} />
        ) : (
          <Link to={NavigationRoutes.KnowledgeBaseDashboard} className="no-articles">
            {t('noTicketArticlesFound')}
          </Link>
        )}
      </Collapsible>
    </UserRoleCheck>
  );
}
