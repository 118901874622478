import { Criticality } from '@/components/criticality/Criticality';
import { Icon, Tag } from '@aos/styleguide-react';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleMinus,
  faCirclePlus,
  faCircleQuestion,
  IconName,
} from '@fortawesome/free-solid-svg-icons';
import { TagColor } from '@aos/styleguide-react/dist/common/constants';
import { IconSize } from '@aos/styleguide-react/dist/stories/elements/icon/Icon';
import { useTranslationText } from '@/translation/TranslationHooks';
import './CriticalityTag.scss';

interface CriticalityDetails {
  color: TagColor;
  iconName: IconName;
}

function getCriticalityDetails(criticality: Criticality): CriticalityDetails {
  switch (criticality) {
    case Criticality.CRITICAL:
      return {
        color: 'is-danger',
        iconName: faCircleExclamation.iconName,
      };
    case Criticality.MAJOR:
      return {
        color: 'is-warning',
        iconName: faCirclePlus.iconName,
      };
    case Criticality.MINOR:
      return {
        color: 'is-primary',
        iconName: faCircleMinus.iconName,
      };
    case Criticality.NONE:
      return {
        color: 'is-success',
        iconName: faCircleCheck.iconName,
      };
    default:
      return {
        color: 'is-default',
        iconName: faCircleQuestion.iconName,
      };
  }
}

interface CriticalityTagProps {
  criticality: Criticality;
  handleDelete?: (deletedCriticality: Criticality) => void;
  size?: IconSize;
}

function CriticalityTag({ criticality, handleDelete, size }: CriticalityTagProps) {
  const { t } = useTranslationText('components');

  const criticalityDetails = getCriticalityDetails(criticality);

  return (
    <Tag
      color={criticalityDetails.color}
      size={size}
      onDelete={handleDelete ? () => handleDelete(criticality) : undefined}
    >
      <Icon name={criticalityDetails.iconName} size={size} className="criticality-tag-icon" />
      <span>{t(criticality)}</span>
    </Tag>
  );
}

export default CriticalityTag;
