import React, { useState } from 'react';
import { OptionsOrGroups } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import { BaseComponentItem } from '@/models/operation/ComponentModel';
import useCustomFetch from '../../../react-query/CustomFetch';
import Select from '../../../ui/select/Select';
import '../ComponentSelect.scss';
import {
  ComponentMenuOptionLabel,
  ComponentOptionLabel,
  loadComponentsOptions,
  mapComponentsToOptions,
  MultiComponentValue,
} from '../ComponentSelectUtils';
import { useTranslationText } from '@/translation/TranslationHooks';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';
import {
  mapOptionsToTValues,
  MultiSelectOptionLabel,
  SelectOption,
  SelectPaginationAdditional,
} from '@/ui/select/SelectUtils';

interface MultiComponentSelectProps {
  onChange: (components: BaseComponentItem[] | null) => void;
  value: BaseComponentItem[] | null;
  label?: string;
  disableLabel?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  size?: BulmaSize;
}

function MultiComponentSelect({
  onChange,
  value,
  label,
  disableLabel,
  isRequired,
  isDisabled,
  size,
}: MultiComponentSelectProps) {
  const { t } = useTranslationText('components');
  const customFetch = useCustomFetch();
  const { currentTenant } = useCurrentTenant();

  const [isError, setIsError] = useState(false);

  function handleDelete(deletedId: string) {
    onChange(value?.filter((oldValue) => oldValue.id !== deletedId) ?? null);
  }

  const loadOptions = async (
    searchInput: string,
    _prev: OptionsOrGroups<SelectOption<BaseComponentItem>, never>,
    pageToLoad?: SelectPaginationAdditional,
  ) => loadComponentsOptions(searchInput, pageToLoad ?? { page: 0 }, setIsError, customFetch);

  return (
    <Select<BaseComponentItem, never, SelectPaginationAdditional, true>
      onChange={(newSelectedComponents) => onChange(mapOptionsToTValues<BaseComponentItem>(newSelectedComponents))}
      value={mapComponentsToOptions(value ?? [])}
      loadOptions={loadOptions}
      openMenuOnClick={false}
      noOptionsMessage={() => t('noComponentForSearch')}
      loadingMessage={() => t('componentsAreLoading')}
      placeholder={t('emptyComponentSearchField')}
      requiredPermission={UserResourcePermissions.Component.Read}
      label={label ?? t('assignedComponents')}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isError={isError}
      cacheUniqs={[currentTenant]}
      formatOptionLabel={(opt, metadata) =>
        MultiSelectOptionLabel({
          opt,
          selectedValueIds: value?.map((component) => component.id) ?? [],
          OptionLabel: () =>
            ComponentOptionLabel({
              payload: opt.payload,
              isOption: metadata.context === 'menu',
            }),
          MenuOptionLabel: () => ComponentMenuOptionLabel({ payload: opt.payload }),
          metadata,
        })
      }
      size={size ?? 'is-small'}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components,react/prop-types
        MultiValue: ({ data }) => <MultiComponentValue data={data} handleDelete={handleDelete} />,
      }}
      additional={{ page: 0 }}
      disableLabel={disableLabel}
    />
  );
}

export default MultiComponentSelect;
