import './History.scss';
import { keyForTicketHistoryModel } from '../../models/operation/TicketModel';
import { useGetTicketHistory } from '../../react-query/TicketingSystemApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import HistoryItem from './item/HistoryItem';
import { TicketSeverities } from '../../models/operation/TicketSeveritiesModel';
import { TicketStates } from '../../models/operation/TicketStatesModel';
import { useTranslationText } from '../../translation/TranslationHooks';

interface TicketHistoryProps {
  ticketId: number;
  severities: TicketSeverities;
  states: TicketStates;
  lastHistoryUpdate: number;
}

export default function TicketHistory({ ticketId, severities, states, lastHistoryUpdate }: TicketHistoryProps) {
  const { t: tError } = useTranslationText('errorTexts');

  const {
    data: ticketHistory,
    isLoading: isTicketHistoryLoading,
    isError,
  } = useGetTicketHistory(ticketId, lastHistoryUpdate);

  return (
    <div className="history" data-role="ticket-history">
      <LoadingSpinner isLoading={isTicketHistoryLoading} errors={isError ? tError('404_history') : undefined}>
        {(ticketHistory ?? []).map((record, index) => (
          <HistoryItem
            key={keyForTicketHistoryModel(record)}
            testId={ticketHistory ? ticketHistory.length - index : 0}
            eventTranslationKey={record.eventTranslationKey}
            actor={record.actor}
            createdAt={record.createdAt}
            payload={record.payload}
            severities={severities}
            states={states}
          />
        ))}
      </LoadingSpinner>
    </div>
  );
}
