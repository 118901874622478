import { Tag } from '@aos/styleguide-react';
import { useRelationTypeTranslations } from '../../../translation/TranslationHooks';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import { toRelationSpec } from '../../../models/operation/RelationTypeFunctions';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';
import { ComponentRelationDto } from '../../../models/operation/RelationTypeModel';
import ComponentStatusTag from '../../component-status-tag/ComponentStatusTag';
import FavoriteStar from '../../../ui/favorite-star/FavoriteStar';

interface ComponentRelationItemProps {
  relation: ComponentRelationDto;
  onDelete?: () => void;
  index: number;
}

function ComponentRelationItem({ relation, onDelete, index }: ComponentRelationItemProps) {
  const relSpec = toRelationSpec(relation);
  const { translateRelation } = useRelationTypeTranslations();

  const other = relation.source ?? relation.target!;

  return (
    <ListItemWrapper
      field="serviceTime"
      index={index}
      onRemove={onDelete}
      itemLink={NavigationRoutes.ComponentId(other.id)}
      deletePermission={UserResourcePermissions.ComponentRelation.Delete}
    >
      <div
        className="component-relation-details"
        data-role="component-relation-item"
        data-component-display-name={other.displayName}
      >
        <div className="component-relation-item">
          <Tag>{translateRelation(relSpec)}</Tag>
          <div className="is-flex">
            {other.isFavorite && (
              <div className="mr-2">
                <FavoriteStar />
              </div>
            )}
            {other.displayName}
          </div>
        </div>
        <ComponentStatusTag status={other.status} />
      </div>
    </ListItemWrapper>
  );
}

export default ComponentRelationItem;
