import { DropzoneInputProps } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import { useTranslationText } from './translation/TranslationHooks';

export function DropzoneHoverBox(props: { inputProps: DropzoneInputProps; dragActive: boolean }) {
  const { inputProps, dragActive } = props;
  const { t } = useTranslationText('commons');
  return (
    <>
      <input {...inputProps} />
      {dragActive && (
        <div className="isDragActive">
          <div className="border dropContent">
            <FontAwesomeIcon className="uploadIcon" icon={faCloudUpload} />
            <span className="is-font-large">{t('dropUpload')}</span>
          </div>
        </div>
      )}
    </>
  );
}
