import dayjs from 'dayjs';
import { Tag } from '@aos/styleguide-react';
import DateFormats from '../../../models/date-formats/DateFormats';
import { getTaskStateColor, TaskDto } from '../../../models/operation/TaskDto';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import { useTranslationText } from '../../../translation/TranslationHooks';
import LinkTableCell from '../../../ui/link-table-cell/LinkTableCell';
import TaskOpenQuestion from '../../../ui/task-open-question/TaskOpenQuestion';
import { ContractorDisplay } from './ContractorDisplay';
import FavoriteStar from '../../../ui/favorite-star/FavoriteStar';

interface TaskTableItemProps {
  task: TaskDto;
  index: number;
}

export function TaskTableItem({ task, index }: TaskTableItemProps) {
  const { t } = useTranslationText('tickets');

  return (
    <tr className="is-clickable" key={task.id} data-id={task.id} data-role="task-item" data-row={index}>
      <LinkTableCell to={NavigationRoutes.TaskId(task.id)} dataRole="task-id">
        {task.id}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.TaskId(task.id)} dataRole="task-state">
        <Tag color={getTaskStateColor(task.state)} size="is-normal">
          {t(task.state.toLocaleLowerCase())}
        </Tag>
        {task.hasOpenQuestions ? <TaskOpenQuestion /> : ''}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.TaskId(task.id)} dataRole="task-ticket-title">
        {task.ticketSnapshot.title}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.TaskId(task.id)} dataRole="task-contractor">
        <ContractorDisplay contractId={task.contractId} />
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.TaskId(task.id)} dataRole="task-created-at">
        {dayjs(task.createdAt).format(DateFormats.FULLDATE)}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.TaskId(task.id)} dataRole="task-favorite">
        {task.favorite && <FavoriteStar tooltipText={t('favorite')} />}
      </LinkTableCell>
    </tr>
  );
}
