import { MultiValue } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { mapTaskStatesToOptions } from '../../models/operation/TaskFunctions';
import { TaskQuery } from '../../models/tasks/TaskQuery';
import { useGetTaskStates } from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import MultiSelectDropdown from '../multi-select-dropdown/MultiSelectDropdown';
import { ReactSelectOption } from '../search-dropdown/SearchDropdown';
import FormFieldWrapper from '../form-field-wrapper/FormFieldWrapper';

interface TaskStateFilterProps {
  label?: string;
  selectedStates: (string | null)[] | null | undefined;
  onChange: (name: keyof TaskQuery, value: string[]) => void;
  size?: BulmaSize;
}

function formatOptionLabel(opt: ReactSelectOption) {
  return <span data-role={`state-${opt.value}`}>{opt.label}</span>;
}

export default function TaskStateFilter({ label, selectedStates, onChange, size = 'is-small' }: TaskStateFilterProps) {
  const { t } = useTranslationText('tickets');

  const { data: states, isLoading: isLoadingTaskStates, isError: isTaskStatesError } = useGetTaskStates();

  const handleOnChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    onChange(
      'states',
      selectedOptions.map((value) => value.value),
    );
  };

  const mappedValues = mapTaskStatesToOptions(
    t,
    states?.filter((state) => selectedStates?.includes(state.key)),
  );

  return (
    <FormFieldWrapper label={label} size={size} isLoading={isLoadingTaskStates}>
      <MultiSelectDropdown
        isError={isTaskStatesError}
        isLoading={isLoadingTaskStates}
        onChange={handleOnChange}
        options={mapTaskStatesToOptions(t, states)}
        openMenuOnClick
        isSearchable={false}
        placeholder={t('selectTaskState')}
        mappedValues={mappedValues}
        formatOptionLabel={formatOptionLabel}
        size={size}
      />
    </FormFieldWrapper>
  );
}
