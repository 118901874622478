import React from 'react';
import classNames from 'classnames';
import './LoadingSpinner.scss';
import { useTranslationText } from '@/translation/TranslationHooks';
import ErrorMessage from '../error-message/ErrorMessage';

export interface LoadingSpinnerProps {
  children?: React.ReactNode;
  isLoading: boolean;
  absolute?: boolean;
  showContent?: boolean;
  errors?: React.ReactNode | React.ReactNode[];
}

export default function LoadingSpinner({
  children,
  isLoading,
  showContent,
  absolute,
  errors = undefined,
}: LoadingSpinnerProps) {
  const { t } = useTranslationText('errorTexts');
  const content = showContent ? children : <></>;
  if (isLoading) {
    return (
      <>
        <div
          className={classNames({
            spinnerWrapper: true,
            absolute,
          })}
        >
          <div className="loadingSpinner">
            <div />
            <div />
            <div />
          </div>
        </div>
        {content}
      </>
    );
  }

  if (errors && Array.isArray(errors) && errors.filter((m) => m !== undefined && m !== null).length > 0) {
    return (
      <div id="error-wrapper">
        {errors.map((err) => (
          <ErrorMessage title={t('error')} message={err} key={err?.toString()} />
        ))}
      </div>
    );
  }
  if (errors && !Array.isArray(errors)) {
    return (
      <div id="error-wrapper">
        <ErrorMessage title={t('error')} message={errors} />
      </div>
    );
  }
  return <>{children}</>;
}
