import queryString from 'query-string';
import { KnowledgeBaseArticle, KnowledgeBaseArticleComment } from '../models/knowledge-base/KnowledgeBaseModel';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { ArticleQuery } from '../models/knowledge-base/ArticleQuery';
import { MediaUrlBuilders } from '../models/media/Media';

export const KNOWLEDGE_BASE_API_PREFIX = '/api/knowledgeBase';

export function useGetKnowledgeBaseArticle(articleId: string, options?: QueryOptions<KnowledgeBaseArticle>) {
  return useGetQuery<KnowledgeBaseArticle>(
    [ServerStateKeysEnum.ArticleDetails, articleId],
    `${KNOWLEDGE_BASE_API_PREFIX}/article/${articleId}`,
    options,
  );
}

export function useGetKnowledgeBaseArticleComments(
  articleId: string,
  options?: QueryOptions<KnowledgeBaseArticleComment[]>,
) {
  return useGetQuery<KnowledgeBaseArticleComment[]>(
    [ServerStateKeysEnum.ArticleComments, articleId],
    `${KNOWLEDGE_BASE_API_PREFIX}/article/${articleId}/comment`,
    options,
  );
}

export function useGetSimilarKnowledgeBaseArticle(articleId: string, options?: QueryOptions<KnowledgeBaseArticle[]>) {
  return useGetQuery<KnowledgeBaseArticle[]>(
    [ServerStateKeysEnum.SimilarArticles, articleId],
    `${KNOWLEDGE_BASE_API_PREFIX}/article/${articleId}/similar`,
    options,
  );
}

export function useGetKnowledgeBaseArticles(
  query?: Partial<ArticleQuery>,
  options?: QueryOptions<KnowledgeBaseArticle[]>,
) {
  const urlParams = query ? `?${queryString.stringify(query)}` : '';
  const path = `${KNOWLEDGE_BASE_API_PREFIX}/article${urlParams}`;
  return useGetQuery<KnowledgeBaseArticle[]>([ServerStateKeysEnum.KnowledgeBaseArticleAll, query], path, options);
}

export const ArticleMediaUrlBuilders: MediaUrlBuilders = {
  mediaSource: (f) => `${KNOWLEDGE_BASE_API_PREFIX}/articleMedia/${f.id}/data`,
  mediaThumbnail: (f) => `${KNOWLEDGE_BASE_API_PREFIX}/articleMedia/${f.id}/thumbnail/data`,
  mediaDownload: (f) => `${KNOWLEDGE_BASE_API_PREFIX}/articleMedia/${f.id}/download`,
};
