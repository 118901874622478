import { Criticality } from '@/components/criticality/Criticality';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import Select from '@/ui/select/Select';
import { useTranslationText } from '@/translation/TranslationHooks';
import { mapOptionsToTValues, MultiSelectOptionLabel } from '@/ui/select/SelectUtils';
import {
  CriticalityOptionLabel,
  criticlaityLoadOptions,
  mapCriticalitiesToOptions,
  MultiCriticalityValue,
} from '@/components/criticality/CriticalitySelectUtils';

interface MultiCriticalitySelectProps {
  onChange: (criticalities: Criticality[] | null) => void;
  value: Criticality[] | null;
  label?: string;
  disableLabel?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  size?: BulmaSize;
}

function MultiCriticalitySelect({
  onChange,
  value,
  label,
  disableLabel,
  isRequired,
  isDisabled,
  size,
}: MultiCriticalitySelectProps) {
  const { t } = useTranslationText('components');
  const criticalities = Object.values(Criticality);

  function handleDelete(deletedCriticality: Criticality) {
    onChange(value ? value.filter((oldValue) => oldValue.valueOf() !== deletedCriticality) : null);
  }

  return (
    <Select<Criticality, never, never, true>
      value={mapCriticalitiesToOptions(criticalities.filter((criticality) => value?.includes(criticality)) ?? [])}
      onChange={(newCriticalities) => onChange(mapOptionsToTValues(newCriticalities))}
      loadOptions={() => criticlaityLoadOptions(criticalities)}
      label={disableLabel ? undefined : label}
      isSearchable={false}
      isRequired={isRequired}
      isDisabled={isDisabled}
      size={size ?? BulmaSize.Small}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      placeholder={t('selectCriticalities')}
      formatOptionLabel={(opt, metadata) =>
        MultiSelectOptionLabel({ opt, selectedValueIds: value ?? [], OptionLabel: CriticalityOptionLabel, metadata })
      }
      components={{
        MultiValue: ({ data }) => <MultiCriticalityValue data={data} handleDelete={handleDelete} />,
      }}
    />
  );
}

export default MultiCriticalitySelect;
