import { useCallback } from 'react';
import { Field, Label, TextInput } from '@aos/styleguide-react';
import { Actor } from '../models/actor/Actor';
import { useGetUser } from '../react-query/TicketingSystemApi';
import { UserModel, userToDisplayName } from '../models/operation/UserModel';
import { useActorKindTranslation } from './actor-label/ActorLabel';

interface ActorInputProps {
  actor: Actor;
  readOnly: boolean;
}

function userDisplayNameWithMail(user: UserModel): string {
  const displayname = userToDisplayName(user);
  return user.email ? `${displayname} [${user.email}]` : displayname;
}

function UserActorTextInput({ actor, readOnly }: ActorInputProps) {
  const { data: user, isLoading } = useGetUser(actor.id!, { suppressErrorToast: true });
  return isLoading ? (
    <TextInput id={`actor-text-${actor?.id}`} readOnly={readOnly} value="" />
  ) : (
    <TextInput id={`actor-text-${actor?.id}`} readOnly value={user ? userDisplayNameWithMail(user) : actor?.id} />
  );
}

function DefaultActorTextInput({ actor, readOnly }: Readonly<ActorInputProps>) {
  const actorKindTranslation = useActorKindTranslation();
  const displayname = actor.email
    ? `${actorKindTranslation(actor.kind)} [${actor.email}]`
    : actorKindTranslation(actor.kind);
  return <TextInput id={`actor-text-${actor?.id}`} readOnly={readOnly} value={displayname} />;
}

interface ActorTextInputProps {
  actor: Actor;
  readOnly?: boolean;
  label?: string;
}

export default function ActorTextInput({ actor, label, readOnly = false }: Readonly<ActorTextInputProps>) {
  const ActorLabel = useCallback(
    () => <>{label && <Label htmlFor={`actor-text-${actor?.id}`}>{label}</Label>}</>,
    [actor?.id, label],
  );

  if (actor?.kind === 'USER') {
    return (
      <Field>
        <ActorLabel />
        {actor?.id ? (
          <UserActorTextInput actor={actor} readOnly={readOnly} />
        ) : (
          <DefaultActorTextInput actor={actor} readOnly={readOnly} />
        )}
      </Field>
    );
  }
  return (
    <Field>
      <ActorLabel />
      <DefaultActorTextInput actor={actor} readOnly={readOnly} />
    </Field>
  );
}
