import classNames from 'classnames';

import { useNavigate } from 'react-router';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import CreateTicketModel from '../../models/operation/CreateTicketModel';
import { MutationKey, MutationPath, usePostMutation } from '../../react-query/MutationQueries';
import {
  TicketMediaUrlBuilders,
  useGetSeverities,
  useGetStates,
  useGetTypes,
} from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import TextInput from '../../ui/text-input/TextInput';
import { TicketContracts } from '../contract/TicketContracts';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import {
  getSeverityIcon,
  getTypeIcon,
  mapOptionToSeverity,
  mapOptionToState,
  mapOptionToType,
  mapSeveritiesToOptions,
  mapSeverityToOption,
  mapStatesToOptions,
  mapStateToOption,
  mapTypesToOptions,
  mapTypeToOption,
  mapUsersToOptions,
  mapUsersToSimpleOptions,
  mapUserToOption,
} from '../../models/operation/TicketFunctions';
import ticketValidationSchema from '../TicketValidationSchema';
import useYupLocal from '../../translation/YupLocal';
import SearchDropdown, { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import { Ticket } from '../../models/operation/TicketModel';
import { UserModel } from '../../models/operation/UserModel';
import useGetAllUsers from '../../react-query/getUsers';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import useErrorsCollector from '../../custom-hooks/UseErrorsCollector';
import RichTextEditor from '../../ui/rich-text/RichTextEditor';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import ActionBar from '../../ui/action-bar/ActionBar';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import './CreateTicket.scss';
import TicketArticles from '../articles/TicketArticles';
import { TicketState } from '../../models/operation/TicketStatesModel';
import Media from '../../ui/media/Media';
import SingleSelectDropdown from '../../ui/single-select-dropdown/SingleSelectDropdown';
import { TicketSeverity } from '../../models/operation/TicketSeveritiesModel';
import { useGetComponent, useGetDescendentComponentStats } from '../../react-query/ComponentApi';
import NavigationRoutes from '../../routing/NavigationRoutes';
import ComponentStatusTag from '../../components/component-status-tag/ComponentStatusTag';
import { ComponentDescendentComponents } from '../../components/component-detail-items/ComponentDescendentComponents';
import SingleComponentSelect from '../../components/component-selects/SingleComponentSelect/SingleComponentSelect';
import { allowImagesAndPdf } from '../../models/media/Media';
import { TicketType } from '../../models/operation/TicketTypesModel';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { SwitchInput } from '../../ui/switch/SwitchInput';
import MultiSelectDropdown from '../../ui/multi-select-dropdown/MultiSelectDropdown';
import { BaseComponentItem } from '../../models/operation/ComponentModel';

export interface CreateTicketRouteState {
  component: BaseComponentItem;
}

function formattedSeverityLabel(opt: ReactSelectOption) {
  return (
    <>
      <span className="severity-icon-wrapper">{getSeverityIcon(opt.value)}</span>
      {opt.label}
    </>
  );
}

function formattedStateLabel(opt: ReactSelectOption) {
  return <span data-role={`state-${opt.value}`}>{opt.label}</span>;
}

export function formattedTypeLabel(opt: ReactSelectOption) {
  return (
    <>
      <span className="type-icon-wrapper">{getTypeIcon(opt.value)}</span>
      {opt.label}
    </>
  );
}

export default function CreateTicket() {
  const [preventNavigation, setPreventNavigation] = useState<boolean>(true);
  const [ticketComponent, setTicketComponent] = useState<BaseComponentItem | null>(null);
  const [severity, setSeverity] = useState<TicketSeverity | null>(null);
  const [ticketState, setTicketState] = useState<TicketState | null>(null);
  const [ticketType, setTicketType] = useState<TicketType | null>(null);
  const [reportableProcess, setReportableProcess] = useState<boolean>(false);
  const location = useLocation();
  const locationState = location.state as CreateTicketRouteState;

  useEffect(() => {
    setTicketComponent(locationState?.component);
  }, [locationState]);

  const { t } = useTranslationText('tickets');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');

  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();

  const { handleSubmit, control, formState } = useForm<CreateTicketModel>({
    mode: 'onChange',
    resolver: yupResolver(ticketValidationSchema(yup)),
  });

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const navigate = useNavigate();
  const { data: states, isLoading: isStatesLoading, error: getStatesFetchError } = useGetStates();
  const { data: types, isLoading: isTypesLoading, error: getTypesFetchError } = useGetTypes();
  const { data: severities, isLoading: isSeverityLoading, error: getSeverityFetchError } = useGetSeverities();

  const { data: users, isLoading: areUsersLoading, isError: isUsersError, setUserQuery } = useGetAllUsers();
  const { data: component } = useGetComponent(ticketComponent?.id!, {
    enabled: !!ticketComponent,
    suppressErrorToast: true,
  });

  const { mutate, isPending } = usePostMutation<CreateTicketModel, Ticket>(MutationKey.PostTicket, {
    onSuccess: () => {
      navigate(-1);
      toast.success(tSuccess('createTicket'));
    },
    onError: () => {
      toast.error(tError('createTicketError'));
    },
  });

  const onSubmit = (ctm: CreateTicketModel) => {
    setPreventNavigation(false);

    mutate({
      body: {
        title: ctm.title,
        severity: severity?.key ?? 'LOW',
        description: ctm.description,
        assignedComponent: ticketComponent,
        media: ctm.media,
        assigneeId: ctm.assigneeId,
        state: ticketState?.key ?? 'TODO',
        reportableProcess,
        type: ticketType?.key ?? 'DISTURBANCE',
        observerIds: ctm.observerIds,
      },
      path: MutationPath.CreateTicket,
    });
  };

  const { data: descendentComponentStats, isLoading: isDescendentComponentStatsLoading } =
    useGetDescendentComponentStats(ticketComponent?.id || '', 'part-of', { enabled: !!ticketComponent });

  const shouldShowStatusForDescendentComponents =
    descendentComponentStats &&
    descendentComponentStats.healthy + descendentComponentStats.disrupted + descendentComponentStats.failed > 0;

  const TitleInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'title'> }) => (
      <FormFieldWrapper error={formState.errors?.title} label={t('titleLabel')} isRequired>
        <TextInput
          placeholder={t('titlePlaceholder')}
          dataRole="ticket-title"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.title}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.title, t],
  );

  const DescriptionTextArea = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'description'> }) => (
      <FormFieldWrapper error={formState.errors?.description} label={t('descriptionLabel')} onlyError>
        <RichTextEditor
          id="ticketDescription"
          placeholder={t('descriptionPlaceholder')}
          onChange={field.onChange}
          value={field.value}
          error={formState.errors?.description?.message}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.description, t],
  );

  const AssigneeSelect = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'assigneeId'> }) => (
      <FormFieldWrapper
        error={formState.errors?.assigneeId}
        label={t('assignedUser')}
        isRequired
        isLoading={areUsersLoading}
      >
        <SearchDropdown<UserModel | null>
          isError={isUsersError}
          onChange={(value) => field?.onChange(value?.value)}
          onInputChange={setUserQuery}
          isLoading={areUsersLoading}
          noOptionsMessage={t('noSuggestionsUsers')}
          options={mapUsersToOptions(users?.content)}
          isLoadingMessage={t('usersAreLoading')}
          mapValueToSelectOption={mapUserToOption}
          value={users?.content?.find((user) => user.id === field.value) || null}
          placeholder={t('assignUser')}
          requiredPermission={UserResourcePermissions.Ticket.Create}
          isRequired
        />
      </FormFieldWrapper>
    ),
    [areUsersLoading, formState.errors?.assigneeId, isUsersError, setUserQuery, t, users?.content],
  );

  const Attachments = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'media'> }) => (
      <Media
        mutationPath={MutationPath.UploadTicketMedia()}
        mutationKey={MutationKey.PostUploadTicketMedia}
        media={field.value}
        urlBuilders={TicketMediaUrlBuilders}
        isEditable
        isSideView
        supportedFileTypes={allowImagesAndPdf}
        onMediaChange={field.onChange}
        label={t('attachments')}
      />
    ),
    [t],
  );

  const ObserversSelect = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateTicketModel, 'observerIds'> }) => (
      <FormFieldWrapper
        error={formState.errors?.observerIds}
        label={t('assignedObservers')}
        isLoading={areUsersLoading}
      >
        <MultiSelectDropdown
          isError={isUsersError}
          onChange={(selectedOptions) => field?.onChange(selectedOptions.map((option) => option.value) ?? [])}
          isLoading={areUsersLoading}
          noOptionsMessage={t('noSuggestionsUsers')}
          options={mapUsersToOptions(users?.content)}
          loadingMessage={t('usersAreLoading')}
          placeholder={t('assignObservers')}
          requiredPermission={UserResourcePermissions.Ticket.Create}
          mappedValues={mapUsersToSimpleOptions(users?.content.filter((user) => field.value?.includes(user.id)))}
          openMenuOnClick
        />
      </FormFieldWrapper>
    ),
    [areUsersLoading, formState.errors?.observerIds, isUsersError, t, users?.content],
  );

  const errors = useErrorsCollector([
    {
      fetchError: getSeverityFetchError,
      errorText: '404_severity',
    },
    {
      fetchError: getStatesFetchError,
      errorText: '404_ticketsStates',
    },
    {
      fetchError: getTypesFetchError,
      errorText: '404_ticketsStates',
    },
  ]);

  return (
    <LoadingSpinner isLoading={isSeverityLoading || isStatesLoading} errors={errors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ActionBar
          right={
            <button
              className={classNames('button is-primary', { 'is-loading': isPending })}
              type="submit"
              aria-label="create-button"
              data-role="create-ticket-submit"
              disabled={!formState.isValid || !formState.isDirty}
            >
              <span>{t('save')}</span>
            </button>
          }
        />
        <div className="columns is-5">
          <div className="create-ticket column is-7">
            <div className="wrapper">
              <div className="severities-dropdown">
                <FormFieldWrapper label={t('fieldSeverity')} isLoading={isSeverityLoading}>
                  <SingleSelectDropdown
                    isLoading={isSeverityLoading}
                    onChange={(value) => setSeverity(mapOptionToSeverity(severities, value))}
                    options={mapSeveritiesToOptions(t, severities)}
                    requiredPermission={UserResourcePermissions.Ticket.Create}
                    value={mapSeverityToOption(t, severity ?? severities?.find((aSeverity) => aSeverity.key === 'LOW'))}
                    formatOptionLabel={formattedSeverityLabel}
                  />
                </FormFieldWrapper>
              </div>
              <Controller name="title" control={control} defaultValue="" render={TitleInput} />
            </div>

            <div className="component-wrapper">
              <div className="component-and-link-wrapper">
                <SingleComponentSelect
                  onChange={(prevTicketComponent) => setTicketComponent(prevTicketComponent)}
                  value={component ?? null}
                />
                {component && (
                  <>
                    <Link
                      to={NavigationRoutes.ComponentId(component.id)}
                      className="without-label-wrapper"
                      title={t('linkToComponentTooltip', { componentName: component.displayName })}
                    >
                      <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </Link>
                    <div className="without-label-wrapper">
                      <ComponentStatusTag status={component.status} />
                    </div>
                  </>
                )}
              </div>
              {component && (
                <div className="descendant-component-status-wrapper">
                  {shouldShowStatusForDescendentComponents && (
                    <ComponentDescendentComponents
                      label={t('statusDescendentComponents')}
                      isLoading={isDescendentComponentStatsLoading}
                      descendentComponentStats={descendentComponentStats}
                    />
                  )}
                </div>
              )}
            </div>

            <Controller name="description" control={control} defaultValue="" render={DescriptionTextArea} />
          </div>

          <div className="create-ticket column is-5">
            <FormFieldWrapper label={t('fieldState')} isLoading={isStatesLoading}>
              <SingleSelectDropdown
                requiredPermission={UserResourcePermissions.Ticket.Create}
                isLoading={isStatesLoading}
                options={mapStatesToOptions(t, states)}
                value={mapStateToOption(t, ticketState ?? states?.find((state) => state.key === 'TODO'))}
                onChange={(newState) => setTicketState(mapOptionToState(states, newState))}
                formatOptionLabel={formattedStateLabel}
              />
            </FormFieldWrapper>
            <FormFieldWrapper label={t('fieldType')} isLoading={isTypesLoading}>
              <SingleSelectDropdown
                requiredPermission={UserResourcePermissions.Ticket.Create}
                isLoading={isTypesLoading}
                options={mapTypesToOptions(t, types)}
                value={mapTypeToOption(t, ticketType ?? types?.find((type) => type.key === 'DISTURBANCE'))}
                onChange={(newType) => setTicketType(mapOptionToType(types, newType))}
                formatOptionLabel={formattedTypeLabel}
              />
            </FormFieldWrapper>
            <Controller render={AssigneeSelect} name="assigneeId" control={control} />
            <Controller render={ObserversSelect} name="observerIds" control={control} />
            <div className="is-flex-grow">
              <UserRoleCheck
                requiredPermission={UserResourcePermissions.TicketReportableProcess.Create.and(
                  UserResourcePermissions.TicketReportableProcess.Delete,
                )}
              >
                <FormFieldWrapper label={t('reportableProcess')}>
                  <SwitchInput
                    id="ticket-reportable-process"
                    onChange={setReportableProcess}
                    checked={reportableProcess}
                    secondLabel={reportableProcess ? 'Ja' : 'Nein'}
                  />
                </FormFieldWrapper>
              </UserRoleCheck>
            </div>
            <div className="create-ticket">
              <TicketContracts component={component} areTasksLoading={false} />
              <TicketArticles ticketComponent={component} />
              <Controller name="media" control={control} defaultValue={[]} render={Attachments} />
            </div>
          </div>
        </div>
      </form>
    </LoadingSpinner>
  );
}
