import { Tag } from '@aos/styleguide-react';
import { useRelationTypeTranslations } from '../../../translation/TranslationHooks';
import { toRelationSpec } from '../../../models/operation/RelationTypeFunctions';
import type { TicketRelationDto } from '../../../models/operation/RelationTypeModel';

interface TicketRelationTagProps {
  relation: TicketRelationDto;
}

export function TicketRelationTag({ relation }: TicketRelationTagProps) {
  const { translateRelation } = useRelationTypeTranslations();

  function capitalize(passedString: string) {
    return passedString
      .split('')
      .map((char, index) => (index === 0 ? char.toUpperCase() : char))
      .join('');
  }

  return <Tag>{capitalize(translateRelation(toRelationSpec(relation)))}</Tag>;
}
