import React from 'react';
import { useTranslationText } from '../../translation/TranslationHooks';
import { SwitchInput } from '../switch/SwitchInput';
import { TaskQuery } from '../../models/tasks/TaskQuery';
import FormFieldWrapper from '../form-field-wrapper/FormFieldWrapper';

interface TaskFavoritesFilterProps {
  isChecked: boolean;
  onChange: (name: keyof TaskQuery, value: any) => void;
  secondLabel?: string;
}

function TaskFavoritesFilter({ isChecked = false, onChange, secondLabel }: TaskFavoritesFilterProps) {
  const { t } = useTranslationText('tickets');
  return (
    <FormFieldWrapper label={t('favoriteTickets')} size="is-small">
      <SwitchInput
        id="taskFavoritesFilter"
        checked={isChecked}
        onChange={(value) => onChange('onlyFavorites', value || undefined)}
        secondLabel={secondLabel}
        isSmall
      />
    </FormFieldWrapper>
  );
}

export default TaskFavoritesFilter;
