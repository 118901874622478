import './TicketCommentsTab.scss';
import CommentListing from './comment-listing/CommentListing';
import { useGetTaskComments } from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { CreateTaskComment } from './create-comment/CreateComment';
import { CommentType } from '../../models/operation/CreateCommentModel';

interface TaskCommentsTabProps {
  taskId: number;
}

export default function TaskCommentsTab({ taskId }: TaskCommentsTabProps) {
  const { t: tError } = useTranslationText('errorTexts');

  const { data: comments, isLoading: commentsAreLoading, isError } = useGetTaskComments(taskId);

  return (
    <div className="comments-tab">
      <UserRoleCheck
        requiredPermission={UserResourcePermissions.TicketComment.Create.or(UserResourcePermissions.TaskComment.Create)}
      >
        <CreateTaskComment taskId={taskId} type={CommentType.TASK_COMMENT} />
      </UserRoleCheck>
      {comments && (
        <CommentListing
          comments={comments}
          isLoading={commentsAreLoading}
          error={isError ? tError('404_comments') : undefined}
        />
      )}
    </div>
  );
}
