import dayjs from 'dayjs';
import { ActiveAlert } from '../../models/operation/AlertModel';
import LinkTableCell from '../../ui/link-table-cell/LinkTableCell';
import { DescendentComponentStatusBar } from '../child-component-status/DescendentComponentStatusBar';
import { Criticality } from '../criticality/Criticality';
import { SpecialHeaderTexts } from './ColumnConfig';
import CriticalityTag from '@/components/criticality/CriticalityTag';

function optionallyLeadingZero(value: string | number) {
  const trimmedValue = value.toString().trim();
  return trimmedValue.length > 1 ? trimmedValue : `0${trimmedValue}`;
}

function convertTimestamp(timestamp: string) {
  const dayjsTimestamp = dayjs(timestamp);
  return [
    `${optionallyLeadingZero(dayjsTimestamp.date())}.${optionallyLeadingZero(dayjsTimestamp.month() + 1)}.${dayjsTimestamp.year()}`,
    `${optionallyLeadingZero(dayjsTimestamp.hour())}:${optionallyLeadingZero(dayjsTimestamp.minute())}`,
  ];
}

interface ActiveAlertTdProps {
  columnName: string;
  activeAlertInfo: ActiveAlert;
  link: string;
}

export function ActiveAlertTd({ columnName, activeAlertInfo, link }: ActiveAlertTdProps) {
  switch (columnName) {
    case SpecialHeaderTexts.DESCENDENT_COMPONENT_STATS: {
      return (
        <LinkTableCell key={`${activeAlertInfo.id}_${columnName}`} to={link}>
          {!!activeAlertInfo.descendentComponentStats && (
            <DescendentComponentStatusBar descendentComponentStats={activeAlertInfo.descendentComponentStats} />
          )}
        </LinkTableCell>
      );
    }
    case SpecialHeaderTexts.CRITICALITY: {
      const criticality =
        Criticality[
          activeAlertInfo.columns[SpecialHeaderTexts.CRITICALITY].toUpperCase() as keyof typeof Criticality
        ] ?? Criticality.NONE;

      return (
        <LinkTableCell key={`${activeAlertInfo.id}_${columnName}`} to={link}>
          <CriticalityTag criticality={criticality} />
        </LinkTableCell>
      );
    }
    case SpecialHeaderTexts.TIMESTAMP: {
      return (
        <LinkTableCell key={`${activeAlertInfo.id}_${columnName}`} to={link}>
          {convertTimestamp(activeAlertInfo.columns[columnName]).join(' | ')}
        </LinkTableCell>
      );
    }
    default: {
      return (
        <LinkTableCell key={`${activeAlertInfo.id}_${columnName}`} to={link}>
          {activeAlertInfo.columns[columnName]}
        </LinkTableCell>
      );
    }
  }
}
