export interface UserModel {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  username: string;
  phone?: string;
}

export interface UserFilterModel {
  userQuery?: string;
  size?: string;
  page?: string;
  userIds?: string[];
}

function initialsFromSplit(splitChar: string, value: string): string | undefined {
  if (value.indexOf(splitChar) === -1) {
    return undefined;
  }
  const parts = value.split(splitChar).filter((part) => part.length >= 1);
  if (parts.length > 1) {
    return `${parts[0][0].toLocaleUpperCase()}${parts[1][0].toLocaleUpperCase()}`;
  }
  return undefined;
}

function initialsFromComposedValue(value: string): string {
  const parts = ['_', '.', ' ', '-'].map((s) => initialsFromSplit(s, value)).filter((e) => e !== undefined);

  if (parts.length > 0) {
    return parts[0] as string;
  }

  if (value.length >= 2) {
    return value.substring(0, 2).toLocaleUpperCase();
  }
  return value.toUpperCase();
}

export function userToInitials(user: UserModel): string {
  if (user.firstName && user.lastName) {
    return `${user.firstName.at(0)}${user.lastName.at(0)}`.toLocaleUpperCase();
  }
  if (user.username && user.username.length > 0) {
    return initialsFromComposedValue(user.username);
  }
  return user.id.substring(0, 2).toLocaleUpperCase();
}

export function userToDisplayName(user: UserModel): string {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user.username) {
    return user.username;
  }
  return user.id;
}

export function userToDisplayNameWithMail(user: UserModel): string {
  if (user.email) {
    return `${userToDisplayName(user)} [${user.email}]`;
  }
  return userToDisplayName(user);
}
