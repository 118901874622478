import Highlighter from 'react-highlight-words';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { KnowledgeBaseComponent } from '../../../models/knowledge-base/KnowledgeBaseModel';

interface ArticleComponentViewProps {
  components: KnowledgeBaseComponent[];
}

export const sortKnowledgeBaseArticleComponentDesc = (
  items: KnowledgeBaseComponent[] | undefined,
): KnowledgeBaseComponent[] =>
  // TODO: ADB-2188
  // I don't know why but sometimes this function gets handed items that are neither undefined, null nor an array of
  // KnowledgeBaseComponent but some unknown object
  items && items instanceof Array ? [...items].sort((a, b) => a.displayName.localeCompare(b.displayName)) : [];

export function ArticleComponentView({ components }: ArticleComponentViewProps) {
  const { t } = useTranslationText('knowledgeBases');

  if (components.length === 0) {
    return <></>;
  }

  return (
    <div className="mb-1">
      <label className="label" htmlFor={t('components')}>
        {t('components')}
      </label>

      <ul className="is-inline ml-0">
        {sortKnowledgeBaseArticleComponentDesc(components).map((component) => (
          <li key={component.id} className="tag is-light mr-1" data-role={`component-${component.displayName}`}>
            <Highlighter
              highlightClassName="highlight"
              searchWords={[]}
              autoEscape
              textToHighlight={component.displayName}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
