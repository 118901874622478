import React from 'react';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { ComponentFilter } from '../../../models/operation/ComponentQuery';

interface ComponentIPAddressFilterProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  componentFilter?: ComponentFilter;
  onFilterChange: (ipAddress: string) => void;
}

export default function ComponentIPAddressFilter({
  label,
  componentFilter,
  onFilterChange,
  ...props
}: ComponentIPAddressFilterProps) {
  const { t } = useTranslationText('components');

  return (
    <>
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <div className="control search-input-wrapper is-flex-grow-1">
        <input
          {...props}
          value={componentFilter?.ipAddress ?? ''}
          className={`input search-input ${props.className ?? ''}`}
          placeholder={t('ipAddressFilter')}
          onChange={(e) => onFilterChange(e.target.value)}
        />
      </div>
    </>
  );
}
