import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { components, ControlProps, GroupBase, MultiValueRemoveProps, OptionProps } from 'react-select';
import classNames from 'classnames';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { Delete, MultipleSelectTrigger } from '@aos/styleguide-react';
import FavoriteStar from '../favorite-star/FavoriteStar';

export interface ReactSelectOption {
  readonly label: string;
  readonly value: any;
}

// TODO ADB-558: refactor ReactSelectOption
export interface JSXSelectOption {
  readonly label: JSX.Element;
  readonly value: any;
  labelText: string;
}

export const noClientSideFiltering = () => true;

export const filterSelectOptionLabel = (option: FilterOptionOption<JSXSelectOption>, inputValue: string) =>
  option.data.labelText.toLowerCase().includes(inputValue.toLowerCase());

export const multiStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  clearIndicator: () => ({ display: 'none' }),
  control: () => ({}),
  multiValue: () => ({}),
};

export const multiClassNames = <T,>() => ({
  control: (state: ControlProps<T, true, GroupBase<T>>) =>
    classNames('multiple-select-input', state.isFocused && 'is-focused'),
  menuList: () => 'multiple-select-list',
  option: (state: OptionProps<T, true, GroupBase<T>>) =>
    classNames('multiple-select-list-item', state.isSelected && 'is-active'),
  multiValue: () => 'aos-tag',
  valueContainer: () => 'multiple-select-selected',
});

export function DropdownIndicator() {
  return <MultipleSelectTrigger />;
}

interface CustomMultiValueRemoveProps extends MultiValueRemoveProps {
  size?: BulmaSize;
}

function MultiValueRemove({ size, ...props }: CustomMultiValueRemoveProps) {
  return (
    <components.MultiValueRemove {...props}>
      <Delete size={size} />
    </components.MultiValueRemove>
  );
}

export const createCustomMultiValueRemove = (size: BulmaSize) =>
  function CustomMultiValueRemove(props: MultiValueRemoveProps) {
    return <MultiValueRemove {...props} size={size} />;
  };

export function formatOptionLabelJSX(opt: JSXSelectOption): JSX.Element {
  return (
    <span className="select-item-text">
      {opt.value.isFavorite && <FavoriteStar />}
      {opt.label}
    </span>
  );
}

export function formatOptionLabelReact(opt: ReactSelectOption): JSX.Element {
  return (
    <span className="select-item-text">
      {opt.value?.isFavorite && <FavoriteStar />}
      {opt.label}
    </span>
  );
}
