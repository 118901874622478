export type SnmpVersion = 'VERSION_1' | 'VERSION_2' | 'VERSION_3';
export const snmpVersions: SnmpVersion[] = ['VERSION_1', 'VERSION_2', 'VERSION_3'];

export type SnmpAuthProtocol = 'MD5' | 'SHA' | 'SHA224' | 'SHA256' | 'SHA384' | 'SHA512';
export const snmpAuthProtocols: SnmpAuthProtocol[] = ['MD5', 'SHA', 'SHA224', 'SHA256', 'SHA384', 'SHA512'];

export type SnmpSecurityLevel = 'NO_AUTH_NO_PRIV' | 'AUTH_NO_PRIV' | 'AUTH_PRIV';
export const snmpSecurityLevels: SnmpSecurityLevel[] = ['NO_AUTH_NO_PRIV', 'AUTH_NO_PRIV', 'AUTH_PRIV'];

export type SnmpEncryptionAlgorithm = 'DES' | 'AES' | 'AES192' | 'AES192C' | 'AES256' | 'AES256C';
export const snmpEncryptionAlgorithms: SnmpEncryptionAlgorithm[] = [
  'DES',
  'AES',
  'AES192',
  'AES192C',
  'AES256',
  'AES256C',
];

export interface SnmpV3Auth {
  protocol?: SnmpAuthProtocol;
  username?: string;
  password?: string;
  securityLevel: SnmpSecurityLevel;
  contextName?: string;
  encryptionAlgorithm?: SnmpEncryptionAlgorithm;
  preSharedKey?: string;
}

export interface SnmpField {
  id: string | undefined;
  oid: string;
  name: string;
  metricIsTagged: boolean;
}

export interface SnmpTable {
  id: string | undefined;
  oid: string;
  name: string;
  fields: SnmpField[];
}
