import { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import { BaseComponentType } from './ComponentTypeModel';

export const sortComponentTypeDesc = (items: BaseComponentType[] | undefined): BaseComponentType[] =>
  items?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

export const sortAndMapComponentTypesToOption = (items: BaseComponentType[] | undefined): ReactSelectOption[] =>
  sortComponentTypeDesc(items)?.map((item) => ({
    value: `${item.id}`,
    label: item.name,
  })) ?? [];
