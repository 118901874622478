export interface ServiceTimeModel {
  id: string;
  days: DayOfWeek[];
  begin?: string;
  end?: string;
  publicHolidaysIncluded: boolean;
}

export interface CreateServiceTimeModel {
  days: DayOfWeek[];
  begin?: string; // hh:mm:ss
  end?: string; // hh:mm:ss
  publicHolidaysIncluded?: boolean;
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const weekdays = Object.values(DayOfWeek);
