import { useNavigate } from 'react-router';
import { Button } from '@aos/styleguide-react';
import { To } from 'react-router-dom';
import UserRoleCheck from '../../auth/UserRoleCheck';

type NavigateButtonProps = {
  to: To;
  text: string;
} & React.ComponentProps<typeof Button> &
  Omit<React.ComponentProps<typeof UserRoleCheck>, 'children'>;

function NavigateButton({ requiredPermission, to, text, ...otherProps }: NavigateButtonProps) {
  const navigate = useNavigate();

  return (
    <UserRoleCheck requiredPermission={requiredPermission}>
      <Button onClick={() => navigate(to)} {...otherProps}>
        {text}
      </Button>
    </UserRoleCheck>
  );
}

export default NavigateButton;
