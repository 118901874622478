import { UserPermission } from './UserPermission';

export class AndUserPermission implements UserPermission {
  private readonly permissions: UserPermission[];

  constructor(permissions: UserPermission[]) {
    this.permissions = permissions;
  }

  matches(roleNames: string[]): boolean {
    return this.permissions.length > 0 && this.permissions.every((p: UserPermission) => p.matches(roleNames));
  }
}
