import { ArrayParam, NumberParam, QueryParamConfigMap, StringParam } from 'use-query-params';
import { MaintenanceStatus } from './MaintenanceStatus';

export interface MaintenanceQuery {
  title: string;
  status: MaintenanceStatus[];
  maintenanceBetweenAfter: string;
  maintenanceBetweenUntil: string;
  components: string[];
  page?: number;
  size?: number;
  sort?: string;
}

export interface MaintenanceQueryParamConfigMap extends QueryParamConfigMap {
  title: typeof StringParam;
  status: typeof ArrayParam;
  maintenanceBetweenAfter: typeof StringParam;
  maintenanceBetweenUntil: typeof StringParam;
  components: typeof ArrayParam;
  page: typeof NumberParam;
  size: typeof NumberParam;
  sort: typeof StringParam;
}

export const maintenanceQueryParamConfigMap = {
  title: StringParam,
  status: ArrayParam,
  maintenanceBetweenAfter: StringParam,
  maintenanceBetweenUntil: StringParam,
  components: ArrayParam,
  page: NumberParam,
  size: NumberParam,
  sort: StringParam,
};

export enum MaintenanceSort {
  CreatedAtDesc = 'createdAt:desc',
  CreatedAtAsc = 'createdAt:asc',
}
