import { useEffect } from 'react';
import useTimeout from './useTimeout';

export const DEFAULT_DEBOUNCE_DELAY = 500;

export default function useDebounceForDependency(
  callback: () => void,
  dependencies: any[],
  delay: number = DEFAULT_DEBOUNCE_DELAY,
) {
  const { reset, clear, isRunning } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);

  // call clear on first render to skip initial debouncing
  useEffect(clear, [clear]);

  return isRunning;
}
