import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import DateFormats from '../../../models/date-formats/DateFormats';
import './ArticleEditedCreated.scss';

interface ArticleEditedProps {
  author?: string;
  currentEditor?: string;
  updatedAt?: string;
  createdAt?: string;
}
function ArticleEditedCreated({
  author = '-',
  currentEditor = '-',
  createdAt = '-',
  updatedAt = '-',
}: ArticleEditedProps) {
  return (
    <div className="article-edited-created">
      <span>
        <Trans
          i18nKey="knowledgeBases.lastEditedAtBy.text"
          values={{
            currentEditor,
            date: `${dayjs(updatedAt).format(DateFormats.FULLDATE_HOURS_MINS)}`,
          }}
          components={{
            1: <span className="date-highlight" />,
            2: <span className="user-highlight" />,
          }}
        />
      </span>
      <span>
        <Trans
          i18nKey="knowledgeBases.createdAtBy.text"
          values={{
            author,
            date: `${dayjs(createdAt).format(DateFormats.FULLDATE_HOURS_MINS)}`,
          }}
          components={{
            1: <span className="date-highlight" />,
            2: <span className="user-highlight" />,
          }}
        />
      </span>
    </div>
  );
}

export default ArticleEditedCreated;
