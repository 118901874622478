import './MultiSelectItem.scss';
import { MouseEvent, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { MultiSelect } from '../MultiSelectContext';
import { CheckboxInput } from '../../checkbox-input/CheckboxInput';

interface GeneralMultiSelectItemProps<T> {
  children: React.ReactNode;
  requireKeyModifier?: boolean;
  item: T;
}

interface MultiSelectItemProps<T> extends GeneralMultiSelectItemProps<T> {
  context: React.Context<MultiSelect<T> | undefined>;
}

interface MultiSelectItemWithContextProps<T> extends GeneralMultiSelectItemProps<T> {
  multiSelect: MultiSelect<T>;
}

function MultiSelectItemWithContext<T>({
  multiSelect,
  item,
  children,
  requireKeyModifier,
}: MultiSelectItemWithContextProps<T>) {
  const selfRef = useRef<HTMLDivElement>(null);

  const isSelectionClick = (e: MouseEvent<HTMLElement>) => {
    if (requireKeyModifier !== true) {
      return true;
    }
    return e.shiftKey || e.ctrlKey || e.metaKey;
  };

  const isSelected = multiSelect.isSelected(item);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (!isSelectionClick(e)) {
      return;
    }
    e.preventDefault();
    multiSelect.setSelected(item, !isSelected, e.shiftKey);
  };

  const isActive = multiSelect.selected.length > 0;
  const lastSelected = multiSelect.lastSelectedElement === item;

  useEffect(() => multiSelect.register(selfRef, item), [multiSelect, item]);

  return (
    <div
      ref={selfRef}
      onClick={handleClick}
      className={classNames({
        'multiselect-item': true,
        'multiselect-active': isSelected || isActive,
        'multiselect-selected': isSelected,
        'multiselect-last-selected': lastSelected && isActive,
      })}
    >
      {isActive && (
        <div className="multiselect-checkbox">
          <CheckboxInput
            checked={isSelected}
            size="is-small"
            onCheckedChange={() => multiSelect.setSelected(item, !isSelected, false)}
            preventDefault
          />
        </div>
      )}
      {children}
    </div>
  );
}

export function MultiSelectItem<T>({ children, context, requireKeyModifier, item }: MultiSelectItemProps<T>) {
  return (
    <context.Consumer>
      {(value) =>
        value !== undefined ? (
          <MultiSelectItemWithContext multiSelect={value} item={item} requireKeyModifier={requireKeyModifier}>
            {children}
          </MultiSelectItemWithContext>
        ) : (
          <>{children}</>
        )
      }
    </context.Consumer>
  );
}
