import { CollapsibleProps } from '@aos/styleguide-react/dist/stories/components/collapsible/Collapsible';
import { Collapsible } from '@aos/styleguide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { UserPermission } from '@/auth/UserPermission';
import { useTranslationText } from '@/translation/TranslationHooks';
import './CollapsibleWithButtons.scss';
import UserRoleCheck from '@/auth/UserRoleCheck';

export interface CollapsibleWithButtonsProps extends CollapsibleProps {
  addBtnClicked?: () => void;
  saveBtnClicked?: () => void;
  isSaving?: boolean;
  isValid?: boolean;
  discardBtnClicked?: () => void;
  dirtyState?: boolean;
  addPermission?: UserPermission;
  addButtonDisabled?: boolean;
}

export function CollapsibleWithButtons({
  children,
  saveBtnClicked,
  isSaving,
  isValid,
  discardBtnClicked,
  dirtyState = false,
  addBtnClicked,
  addPermission,
  addButtonDisabled,
  ...collapsibleProps
}: Readonly<CollapsibleWithButtonsProps>) {
  const { t } = useTranslationText('commons');

  return (
    <Collapsible {...collapsibleProps}>
      {children}
      {!addButtonDisabled && (
        <div className="collapsible-footer">
          <div className="collapsible-add">
            {addBtnClicked && (
              <UserRoleCheck requiredPermission={addPermission}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    addBtnClicked();
                  }}
                  type="button"
                  className="add-item-btn"
                  disabled={addButtonDisabled}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="pl-2">{t('add')}</span>
                </button>
              </UserRoleCheck>
            )}
          </div>
          <div className="collapsible-action">
            {saveBtnClicked && dirtyState && (
              <UserRoleCheck requiredPermission={addPermission}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    saveBtnClicked();
                  }}
                  type="button"
                  className={classNames('button', 'footer-button', 'action-btn', 'save-btn', {
                    'is-loading': !!isSaving,
                  })}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </UserRoleCheck>
            )}
            {discardBtnClicked && dirtyState && (
              <UserRoleCheck requiredPermission={addPermission}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    discardBtnClicked();
                  }}
                  type="button"
                  className="footer-button action-btn discard-btn"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </UserRoleCheck>
            )}
          </div>
        </div>
      )}
    </Collapsible>
  );
}
