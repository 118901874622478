import { ObjectSchema } from 'yup';
import { YupType } from '../translation/YupLocal';
import { ComponentTypeModel } from '../models/operation/ComponentTypeModel';

const componentTypeValidationSchema = (validation: YupType, t: (key: string) => string) =>
  validation
    .object({
      name: validation.string().required().max(255).trim(),
      description: validation.string().nullable().trim().optional(),
      componentSelector: validation.object({
        query: validation.string().trim().required(),
        labels: validation.array().min(1, t('labelsError')).of(validation.string().trim().required()),
      }),
      displayNameSelector: validation.object({
        query: validation.string().trim().required(),
        label: validation.string().nullable().trim().max(255).optional(),
      }),
      iconUrl: validation.string().nullable().optional().notRequired(),
      attributes: validation
        .array()
        .of(
          validation.object({
            name: validation.string().trim().max(255).required(),
            valueSelector: validation.object({
              query: validation.string().trim().required(),
              label: validation.string().nullable().trim().max(255).optional(),
            }),
          }),
        )
        .optional(),
      alertingRules: validation
        .array()
        .of(
          validation.object({
            title: validation.string().trim().max(255).required(),
            query: validation.string().trim().required(),
            status: validation.string().trim().required(),
            notifyAfterSeconds: validation
              .number()
              .integer(t('notifyAfterError'))
              .typeError(t('notifyAfterError'))
              .moreThan(-1, t('notifyAfterError'))
              .default(60)
              .required(),
            description: validation.string().trim().required(),
          }),
        )
        .optional(),
      relationTemplates: validation
        .array()
        .of(
          validation.object({
            name: validation.string().trim().max(255).required(),
            relationType: validation.string().required(),
            isReverse: validation.boolean().required(),
            componentSelector: validation.object({
              query: validation.string().trim().required(),
              labels: validation.array().min(1, t('labelsError')).of(validation.string().trim().required()),
            }),
          }),
        )
        .optional(),
    })
    .required() as ObjectSchema<ComponentTypeModel>;

export default componentTypeValidationSchema;
