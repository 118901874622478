import { useState } from 'react';
import useDebounceForDependency from './useDebounceForDependency';

export default function useDebouncedValue<T>(value: T, delay?: number): [T, boolean] {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const isDebouncing = useDebounceForDependency(() => setDebouncedValue(value), [value], delay);

  return [debouncedValue, isDebouncing];
}
