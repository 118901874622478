import { Table as AosTable } from '@aos/styleguide-react';
import TableHeader, { TableColumn } from '../ui/table-header/TableHeader';
import { useGetSystemStates } from '../react-query/StatusApi';
import { SystemStatusModel } from '../models/operation/StatusModel';
import StatusTableTag from './StatusTableTag';
import './StatusTable.scss';
import TableCell from '../ui/table-cell/TableCell';

export default function StatusTable() {
  const { data: systemStates } = useGetSystemStates({
    refetchInterval: 1000,
  });

  function getAllStageNames(states: SystemStatusModel[] | undefined): string[] {
    if (!states) {
      return [];
    }

    return Object.keys(states[0].stageStates);
  }

  const allStageNames = getAllStageNames(systemStates);

  const stageColumns = allStageNames.map((stageName) => ({
    translationKey: stageName,
    columnName: stageName,
    property: stageName,
    disableSort: true,
  }));

  const tableColumns: TableColumn[] = [
    {
      translationKey: 'systemsAndEnvironments',
      property: 'systemsAndEnvironments',
      disableSort: true,
    },
    ...stageColumns,
  ];

  return (
    <div className="status-table">
      <h1 className="status-title">Autobahn OS - Status</h1>
      <AosTable>
        <TableHeader columns={tableColumns} translationSection="statuses" currentSort={undefined} />
        <AosTable.Body>
          {systemStates?.map((system) => (
            <AosTable.Row key={system.systemId}>
              <TableCell>{system.systemName}</TableCell>
              {allStageNames.map((stage) => (
                <TableCell key={`${system.systemId}-${stage}`} verticalCenter>
                  <StatusTableTag status={system.stageStates[stage]} />
                </TableCell>
              ))}
            </AosTable.Row>
          ))}
        </AosTable.Body>
      </AosTable>
    </div>
  );
}
