import { Dispatch, ReactNode, SetStateAction } from 'react';
import queryString from 'query-string';
import { Tag } from '@aos/styleguide-react';
import { Response } from 'react-select-async-paginate';
import { DEFAULT_PAGE_SIZE_DROPDOWN } from '../../models/pagination/Pagination';
import { ComponentFilterQuery } from '../../react-query/ComponentApi';
import { ComponentSort } from '../../models/operation/ComponentQuery';
import { MonitoringApiPrefix } from '../../react-query/MonitoringApi';
import { BaseComponentItem } from '../../models/operation/ComponentModel';
import FavoriteStar from '../../ui/favorite-star/FavoriteStar';
import { OptionLabelBaseProps, SelectOption, SelectPaginationAdditional } from '../../ui/select/SelectUtils';
import { useTranslationText } from '@/translation/TranslationHooks';

export function mapComponentToOption(component: BaseComponentItem): SelectOption<BaseComponentItem> {
  return {
    value: component.id,
    label: component.displayName,
    payload: component,
  };
}

export function mapComponentsToOptions(components: BaseComponentItem[]): SelectOption<BaseComponentItem>[] {
  return components.map((component) => mapComponentToOption(component));
}

export async function loadComponentsOptions(
  search: string,
  { page }: SelectPaginationAdditional,
  setIsError: Dispatch<SetStateAction<boolean>>,
  customFetch: (path: string, init?: RequestInit | undefined) => Promise<any>,
  pageSize: number = DEFAULT_PAGE_SIZE_DROPDOWN,
): Promise<Response<SelectOption<BaseComponentItem>, never, SelectPaginationAdditional>> {
  setIsError(false);

  if (search === '') {
    return { options: [] };
  }

  const filter: ComponentFilterQuery = {
    search,
    page,
    size: pageSize,
    sort: ComponentSort.DisplayNameAsc,
  };
  const path = `${MonitoringApiPrefix}/component?${queryString.stringify(filter)}`;

  const componentItemPage = await customFetch(path);

  if (componentItemPage === null) {
    setIsError(true);
    return { options: [] };
  }

  return {
    options: mapComponentsToOptions(componentItemPage.content),
    hasMore: !componentItemPage.last,
    additional: { page: page + 1 },
  };
}

export function ComponentOptionLabel({ payload, isOption }: OptionLabelBaseProps<BaseComponentItem>): ReactNode {
  const { t } = useTranslationText('components');

  return (
    <div className="component-label-container">
      <span className="select-item-text primary-text-container">
        {payload.isFavorite && <FavoriteStar />}
        {payload.displayName}
      </span>
      {isOption && (
        <div className="secondary-text-container">
          <div>
            <span className="attribute-name">{t('alternativeName')}</span>: <span>{payload.displayNameAlt1}</span>
          </div>
          {payload.permanentId && (
            <div>
              <span className="attribute-name">{t('permanentId')}</span>: <span>{payload.permanentId}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export function ComponentMenuOptionLabel({ payload }: OptionLabelBaseProps<BaseComponentItem>) {
  return <Tag>{payload.displayName}</Tag>;
}

interface MultiComponentValueProps {
  data: SelectOption<BaseComponentItem>;
  handleDelete: (deletedComponentId: string) => void;
}

export function MultiComponentValue({ data, handleDelete }: MultiComponentValueProps) {
  return <Tag onDelete={() => handleDelete(data.value)}>{data.payload.displayName}</Tag>;
}
