import queryString from 'query-string';
import { encodeQueryParams } from 'use-query-params';
import { Contract } from '../models/contract-management/ContractManagementModel';
import { ContractQuery, contractQueryParamConfigMap } from '../models/contract-management/ContractQuery';
import { Contractor } from '../models/contract-management/ContractorModel';
import { MediaUrlBuilders } from '../models/media/Media';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { ContractorQuery, ContractorQueryParamConfigMap } from '../models/contract-management/ContractorQuery';
import { Page, Page2 } from '../models/pagination/Pagination';
import { ContractRole } from '../models/contract-management/ContractRole';
import { ContractManagementApiPrefix } from './ContractManagementApi';
import TenantEnum from '../user/tenant-context/TenantEnum';
import { useCurrentTenant } from '../user/tenant-context/CurrentTenantContext';

export function pathForAllContractsWithFilter(selectedTenants: TenantEnum[], filter?: Partial<ContractQuery>) {
  const queryParams = { ...filter, selectedTenants };
  const encodedQueryParams = encodeQueryParams(contractQueryParamConfigMap, queryParams);
  return `${ContractManagementApiPrefix}/contract?${queryString.stringify(encodedQueryParams)}`;
}

export function useGetAllContracts(options?: QueryOptions<Page2<Contract>>, query?: Partial<ContractQuery>) {
  const { currentTenant } = useCurrentTenant();
  const path = pathForAllContractsWithFilter([currentTenant], query);
  return useGetQuery<Page2<Contract>>([ServerStateKeysEnum.ContractAll, query], path, options);
}

export function useGetContractsQuickSearch(searchValue: string, size: number, options?: QueryOptions<Page<Contract>>) {
  const path = `${ContractManagementApiPrefix}/contract/search?query=${searchValue}&size=${size}`;
  return useGetQuery<Page<Contract>>([ServerStateKeysEnum.ContractSearch, searchValue, size.toString()], path, {
    ...options,
    enabled: searchValue.length > 0,
  });
}

export function useGetAllContractors(options?: QueryOptions<Page<Contractor>>, query?: Partial<ContractorQuery>) {
  const path = `${ContractManagementApiPrefix}/contractor${
    query ? `?${queryString.stringify(encodeQueryParams(ContractorQueryParamConfigMap, query))}` : ''
  }`;
  return useGetQuery<Page<Contractor>>([ServerStateKeysEnum.ContractorAll, query], path, options);
}

export function useGetContractById(contractId: string, options?: QueryOptions<Contract>) {
  return useGetQuery<Contract>(
    [ServerStateKeysEnum.Contract, contractId],
    `${ContractManagementApiPrefix}/contract/${contractId}`,
    { ...options, suppressErrorToast: true },
  );
}

export function useGetContractRoles(options?: QueryOptions<ContractRole[]>) {
  return useGetQuery<ContractRole[]>(
    ServerStateKeysEnum.ContractRole,
    `${ContractManagementApiPrefix}/contract/role`,
    options,
  );
}

export const ContractMediaUrlBuilders: MediaUrlBuilders = {
  mediaSource: (file) => `${ContractManagementApiPrefix}/contractMedia/${file.id}/data`,
  mediaThumbnail: (file) => `${ContractManagementApiPrefix}/contractMedia/${file.id}/thumbnail/data`,
  mediaDownload: (file) => `${ContractManagementApiPrefix}/contractMedia/${file.id}/download`,
};

export function useGetContractorById(contractorId: string, options?: QueryOptions<Contractor>) {
  return useGetQuery<Contractor>(
    [ServerStateKeysEnum.Contractor, contractorId],
    `${ContractManagementApiPrefix}/contractor/${contractorId}`,
    options,
  );
}

export function useGetContractorHasContracts(contractorId: string, options?: QueryOptions<boolean>) {
  return useGetQuery<boolean>(
    [ServerStateKeysEnum.Contractor, contractorId, 'hasContracts'],
    `${ContractManagementApiPrefix}/contractor/${contractorId}/has-contracts`,
    options,
  );
}
