import { RefObject } from 'react';

export interface ElementItem<T> {
  element: HTMLElement;
  item: T;
}

export function getItemsInDocumentOrder<T>(elementOrder: Map<RefObject<HTMLElement | null>, T>): ElementItem<T>[] {
  return Array.from(elementOrder)
    .map((e) => ({ item: e[1], element: e[0].current }))
    .filter((e) => e.element !== null)
    .map((e) => ({ item: e.item, element: e.element as HTMLElement }))
    .sort((a, b) => a.element?.compareDocumentPosition(b.element));
}

export function distinct<T>(items: T[]): T[] {
  return [...new Set(items)];
}

export function addSelectedItem<T>(items: T[], ...newItems: T[]) {
  return distinct([...items, ...newItems]);
}

export function removeSelectedItem<T>(items: T[], ...itemsToBeRemoved: T[]) {
  return items.filter((selected) => itemsToBeRemoved.indexOf(selected) === -1);
}

export function updatedSelectedItems<T>(items: T[], appendItems: boolean, ...itemsToBeAddedOrRemoved: T[]) {
  return appendItems
    ? addSelectedItem(items, ...itemsToBeAddedOrRemoved)
    : removeSelectedItem(items, ...itemsToBeAddedOrRemoved);
}
