import { ObjectSchema } from 'yup';
import { YupType } from '../../translation/YupLocal';
import CreateArticleModel from '../../models/knowledge-base/CreateArticleModel';

const articleValidationSchema = (validation: YupType) =>
  validation
    .object({
      title: validation.string().required().max(255).trim(),
      descriptionShort: validation.string().required().trim(),
      highlighted: validation.boolean().default(false),
      content: validation.string().nullable(),
    })
    .required() as ObjectSchema<CreateArticleModel>;

export default articleValidationSchema;
