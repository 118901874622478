import { useEffect } from 'react';

export default function usePreventNavigation(isPreventing: boolean, message: string): void {
  // TODO: BMS-620 Route blocken
  // Browser blocks tab close && page refresh
  useEffect(() => {
    if (isPreventing) {
      window.onbeforeunload = () => message;
      return () => {
        window.onbeforeunload = () => undefined;
      };
    }
  }, [message, isPreventing]);
}
