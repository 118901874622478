import { ComponentItem } from './ComponentModel';
import { Ticket } from './TicketModel';

export interface RelationType {
  identifier: string;
}

export const RelationDirections = {
  FORWARD: 'forward',
  REVERSE: 'reverse',
};
export type RelationDirection = (typeof RelationDirections)[keyof typeof RelationDirections];

export interface RelationRefDto {
  id: string;
}

export interface CreateRelationDto {
  target: RelationRefDto;
  type: string;
}

export interface ComponentRelationDto {
  id: string;
  type: string;
  target: ComponentItem | undefined;
  source: ComponentItem | undefined;
}

export interface TicketRelationDto {
  id: string;
  type: string;
  target: Ticket | undefined;
  source: Ticket | undefined;
}

export interface ReverseComponentRelationDto extends ComponentRelationDto {
  target: undefined;
}

export interface ForwardComponentRelationDto extends ComponentRelationDto {
  source: undefined;
}

export interface RelationSpec {
  identifier: string;
  type: RelationType;
  direction: RelationDirection;
}

export interface RelationFilter {
  type?: string;
  size?: string;
  page?: string;
  sort?: string;
}
