import { ErrorHandler, ErrorHandlingResult } from './ErrorHandler';
import useApiErrorHandlers from '../custom-hooks/useApiErrorHandlers';

/**
 * This error handler resolves HTTP 401 responses by redirecting the user to the login page.
 */
export function useUnauthorizedErrorHandler(): ErrorHandler {
  const { handleUnauthorizedError } = useApiErrorHandlers();
  return (ctx) => {
    if (ctx.error.isUnauthorizedError()) {
      handleUnauthorizedError(ctx.error);
      return ErrorHandlingResult.HANDLED;
    }
    return ErrorHandlingResult.NOT_HANDLED;
  };
}
