import dayjs from 'dayjs';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import UserRoleCheck from '../../../auth/UserRoleCheck';
import DateFormats from '../../../models/date-formats/DateFormats';
import { getTaskStateColor, TaskDto, TaskState } from '../../../models/operation/TaskDto';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import { useTranslationText } from '../../../translation/TranslationHooks';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';
import './ContractTaskItem.scss';
import ContractTimeItem from './ContractTimeItem';
import TaskStateMenu from './task-state-menu/TaskStateMenu';
import ActorLabel from '../../../ui/actor-label/ActorLabel';
import TaskOpenQuestion from '../../../ui/task-open-question/TaskOpenQuestion';
import { useGetReactionTimeForTask, useGetRestorationTimeForTask } from '../../../react-query/TicketingSystemApi';

interface ContractTaskItemProps {
  task: TaskDto;
}

export default function ContractTaskItem({ task }: ContractTaskItemProps) {
  const { t } = useTranslationText('tickets');
  const { t: tContract } = useTranslationText('contractManagements');

  const { data: reactionTime } = useGetReactionTimeForTask(task.id);
  const { data: restorationTime } = useGetRestorationTimeForTask(task.id);

  return (
    <div className="contract-task-item">
      <ListItemWrapper
        field="tasks"
        itemLink={NavigationRoutes.TaskId(task.id)}
        renderAboveActionButtons={
          task.state !== TaskState.DONE && (
            <UserRoleCheck requiredPermission={UserResourcePermissions.Task.Update}>
              <TaskStateMenu task={task} />
            </UserRoleCheck>
          )
        }
      >
        <div className="row">
          <div className="content-wrapper">
            <div>
              {t('startedBy')} <ActorLabel actor={task?.createdBy} /> {t('at')}{' '}
              {dayjs(task.createdAt).format(DateFormats.FULLDATE)}{' '}
              {dayjs(task.createdAt).format(DateFormats.HOURS_MINS)}
            </div>
            {task.hasOpenQuestions ? <TaskOpenQuestion /> : ''}
            <div className={`state is-small tag is-light ${getTaskStateColor(task.state)} `}>
              {t(task.state.toLocaleLowerCase())}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="content-wrapper">
            <ContractTimeItem time={reactionTime?.durationInMinutes} label={tContract('responseHours')} />
            <ContractTimeItem time={restorationTime?.durationInMinutes} label={tContract('recoveryHours')} />
          </div>
        </div>
      </ListItemWrapper>
    </div>
  );
}
