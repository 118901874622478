import { toast } from 'react-toastify';
import { ErrorHandler, ErrorHandlingResult } from './ErrorHandler';
import useApiErrorHandlers from '../custom-hooks/useApiErrorHandlers';

/**
 * This error handler displays a toasty notification for every given error.
 *
 * The behavior is as follows:
 * - If {@link ErrorContext.options.suppressErrorToast} is true, the handler will do nothing.
 * - If a {@link ErrorContext.options.customMessage} is given,
 * the toasty will display that specific message (without a prefix).
 * - In all other cases the {@link Error.message} will be displayed
 * using a prefix text given by the translation entry "errorTexts.generalError".
 */
export function useToastyErrorHandler(): ErrorHandler {
  const { showErrorToasty } = useApiErrorHandlers();
  return (ctx) => {
    const { suppressErrorToast, customMessage } = ctx.options;
    if (suppressErrorToast) {
      return ErrorHandlingResult.NOT_HANDLED;
    }
    if (customMessage !== undefined) {
      toast.error(customMessage);
    } else {
      showErrorToasty(ctx.error);
    }
    return ErrorHandlingResult.HANDLED;
  };
}
