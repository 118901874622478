import { TagColor } from '@aos/styleguide-react/dist/common/constants';
import { Actor } from '../actor/Actor';
import { TaskTicketSnapshot } from './TaskTicketSnapshot';

export interface TaskDto {
  id: number;
  contractId: string;
  contractInternalNumber: string;
  contractorCompanyName: string;
  ticketSnapshot: TaskTicketSnapshot;
  state: TaskState;
  hasOpenQuestions: boolean;
  createdAt: string;
  createdBy: Actor;
  favorite: boolean;
}

export enum TaskState {
  TODO = 'TODO',
  WORKING = 'WORKING',
  PAUSED = 'PAUSED',
  DONE = 'DONE',
}

export function getTaskStateColor(state: string): TagColor {
  switch (state) {
    case TaskState.TODO:
      return 'is-danger';
    case TaskState.PAUSED:
      return 'is-warning';
    case TaskState.WORKING:
      return 'is-primary';
    case TaskState.DONE:
      return 'is-success';
    default:
      return 'is-default';
  }
}
