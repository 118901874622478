export const cutLongTextToImportantPart = (text: string, searchTerm: string, visibleCharCount?: number) => {
  const textIndex = text.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase());
  if (textIndex > -1) {
    const highlightTextStartIndex = textIndex;
    const highlightTextEndIndex = textIndex + searchTerm.length;
    const maxChars = text.length;
    const showedChars = visibleCharCount ?? 160;
    const isAtTheEnd = maxChars - highlightTextStartIndex < showedChars;
    const isAtTheStart = showedChars > highlightTextEndIndex;

    if (isAtTheStart) {
      return text;
    }
    if (isAtTheEnd) {
      return `...${text.substr(maxChars - showedChars - highlightTextEndIndex)}`;
    }
    return `...${text.substr(highlightTextStartIndex - showedChars / 3, showedChars)}`; // Suchbegriff im ersten drittel des Textes
  }
  return text;
};
