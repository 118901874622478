import { QueryOptions, useGetQuery } from './GetQuery';
import { MonitoringApiPrefix } from './MonitoringApi';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { SystemModel } from '../models/operation/SystemModel';

export function useGetSystems(options?: QueryOptions<SystemModel[]>) {
  return useGetQuery<SystemModel[]>(ServerStateKeysEnum.System, `${MonitoringApiPrefix}/system`, options);
}

export function useGetSystem(systemId: string, options?: QueryOptions<SystemModel>) {
  return useGetQuery<SystemModel>(
    [ServerStateKeysEnum.SystemDetails, systemId],
    `${MonitoringApiPrefix}/system/${systemId}`,
    options,
  );
}
