import './HasSeveritiesCondtion.scss';
import { RuleCondition } from '@/models/operation/AutomationRuleModel';
import { GenericConditionConfigurationProps } from '../GenericConditionConfigurationProps';
import MultiSeveritySelect from '@/ticketing/severity-selects/multi-severity-select/MultiSeveritySelect';
import { TicketSeverity } from '@/models/operation/TicketSeveritiesModel';

interface HasSeveritiesConfiguration extends RuleCondition {
  type: 'hasSeverities';
  severities: string[];
}

export default function HasSeveritiesCondition({
  condition,
  handleConfigurationUpdate,
  onClear,
}: GenericConditionConfigurationProps<HasSeveritiesConfiguration>) {
  const handleChange = (selectedSeverities: TicketSeverity[] | null): void => {
    if (selectedSeverities && selectedSeverities.length > 0) {
      handleConfigurationUpdate({
        type: 'hasSeverities',
        severities: selectedSeverities.map((severity) => severity.key),
      } as HasSeveritiesConfiguration);
    } else {
      onClear();
    }
  };

  return <MultiSeveritySelect onChange={handleChange} value={condition?.severities ?? null} />;
}
