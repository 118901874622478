import { YupType } from '../translation/YupLocal';

const componentValidationSchema = (validation: YupType) =>
  validation
    .object({
      displayName: validation.string().required().max(255).trim(),
      displayNameAlt1: validation.string().notRequired().max(255).trim(),
      manufacturer: validation.string().notRequired().max(255).trim(),
      model: validation.string().notRequired().max(255).trim(),
      serialNumber: validation.string().notRequired().max(255).trim(),
      location: validation.string().notRequired().max(255).trim(),
      documentation: validation.string().notRequired(),
    })
    .required();

export default componentValidationSchema;
