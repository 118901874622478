import { QueryParamConfig, useQueryParams } from 'use-query-params';
import { useEffect } from 'react';
import { useCurrentTenant } from '../user/tenant-context/CurrentTenantContext';

type ParamConfigMapWithPage<T> = T;
export default function useQueryParamsWithPageReset<T extends Record<string, QueryParamConfig<any, any> | string>>(
  paramConfigMap: ParamConfigMapWithPage<T>,
  resetRecord?: string,
) {
  const [query, setQuery] = useQueryParams(paramConfigMap);
  const { currentTenant } = useCurrentTenant();

  useEffect(() => {
    if (resetRecord) {
      setQuery((prevQuery) => ({ ...prevQuery, [resetRecord]: undefined }));
    } else {
      setQuery((prevQuery) => ({ ...prevQuery, page: 0 }));
    }
  }, [currentTenant, resetRecord, setQuery]);

  return [query, setQuery] as const;
}
