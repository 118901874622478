import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { CreateSystemModel, UpdateSystemModel } from '../../models/operation/SystemModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import RichTextEditor from '../../ui/rich-text/RichTextEditor';
import TextInput from '../../ui/text-input/TextInput';

interface SystemDetailsProps {
  form: UseFormReturn<CreateSystemModel | UpdateSystemModel, object>;
}

export function SystemDetails({ form }: SystemDetailsProps) {
  const { t } = useTranslationText('systems');

  const { control, formState } = form;

  const NameInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateSystemModel | UpdateSystemModel, 'name'> }) => (
      <FormFieldWrapper error={formState.errors?.name} label={t('name')} isRequired>
        <TextInput
          placeholder={t('namePlaceholder')}
          dataRole="system-name"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.name}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.name, t],
  );

  const SupportContactPersonInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateSystemModel | UpdateSystemModel, 'supportContactPerson'> }) => (
      <FormFieldWrapper error={formState.errors?.supportContactPerson} label={t('supportContactPerson')} isRequired>
        <TextInput
          placeholder={t('supportContactPersonPlaceholder')}
          dataRole="system-support-contact-person"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.supportContactPerson}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.supportContactPerson, t],
  );

  const SupportContactAddressInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateSystemModel | UpdateSystemModel, 'supportContactAddress'> }) => (
      <FormFieldWrapper error={formState.errors?.supportContactAddress} label={t('supportContactAddress')} isRequired>
        <TextInput
          placeholder={t('supportContactAddressPlaceholder')}
          dataRole="system-support-contact-address"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.supportContactAddress}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.supportContactAddress, t],
  );

  const IntegrationsscheinNamingInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<CreateSystemModel | UpdateSystemModel, 'integrationsscheinNaming'>;
    }) => (
      <FormFieldWrapper error={formState.errors?.integrationsscheinNaming} label={t('integrationsscheinNaming')}>
        <TextInput
          placeholder={t('integrationsscheinNamingPlaceholder')}
          dataRole="system-integrationsschein-naming"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.integrationsscheinNaming}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.integrationsscheinNaming, t],
  );

  const DescriptionTextArea = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateSystemModel | UpdateSystemModel, 'description'> }) => (
      <FormFieldWrapper error={formState.errors?.description} label={t('description')}>
        <RichTextEditor
          id="systemDescription"
          placeholder={t('descriptionPlaceholder')}
          onChange={field.onChange}
          value={field.value}
          error={formState.errors?.description?.message}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.description, t],
  );

  return (
    <div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="name" control={control} render={NameInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-6">
          <Controller name="supportContactPerson" control={control} render={SupportContactPersonInput} />
        </div>
        <div className="column is-6">
          <Controller name="supportContactAddress" control={control} render={SupportContactAddressInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="integrationsscheinNaming" control={control} render={IntegrationsscheinNamingInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name="description" control={control} defaultValue="" render={DescriptionTextArea} />
        </div>
      </div>
    </div>
  );
}
