import { Collapsible } from '@aos/styleguide-react';
import {
  ComponentEditableInputProps,
  CoordinatesInput,
  DocumentationInput,
  LocationInput,
  ManufacturerInput,
  ModelInput,
  PurchaseDateInput,
  SerialNumberInput,
  SupportEndDateInput,
} from './ComponentEditableInputs';
import { ComponentsAttributes } from './ComponentsAttributes';
import ComponentAttributeItem from '../component-attribute/ComponentAttributeItem';
import { useTranslationText } from '../../translation/TranslationHooks';

export default function ComponentTechnicalData({
  component,
  variables,
  isMutationLoading,
  onSubmit,
}: Readonly<ComponentEditableInputProps>) {
  const { t } = useTranslationText('components');

  return (
    <Collapsible isInitiallyCollapsed header={t('technicalData')}>
      <div className="columns is-multiline is-inline-flex mt-1">
        <div className="column is-6">
          <SerialNumberInput
            component={component}
            variables={variables}
            isMutationLoading={isMutationLoading}
            onSubmit={onSubmit}
          />
        </div>
        <div className="column is-6">
          <ManufacturerInput
            component={component}
            variables={variables}
            isMutationLoading={isMutationLoading}
            onSubmit={onSubmit}
          />
        </div>
        <div className="column is-6">
          <ModelInput
            component={component}
            variables={variables}
            isMutationLoading={isMutationLoading}
            onSubmit={onSubmit}
          />
        </div>
        <div className="column is-6">
          <LocationInput
            component={component}
            variables={variables}
            isMutationLoading={isMutationLoading}
            onSubmit={onSubmit}
          />
        </div>
        <div className="column is-6">
          <PurchaseDateInput
            component={component}
            variables={variables}
            isMutationLoading={isMutationLoading}
            onSubmit={onSubmit}
          />
        </div>
        <div className="column is-6">
          <SupportEndDateInput
            component={component}
            variables={variables}
            isMutationLoading={isMutationLoading}
            onSubmit={onSubmit}
          />
        </div>
        <div className="column is-6">
          <CoordinatesInput
            component={component}
            variables={variables}
            isMutationLoading={isMutationLoading}
            onSubmit={onSubmit}
          />
        </div>
        <div className="column is-12">
          <DocumentationInput
            component={component}
            variables={variables}
            isMutationLoading={isMutationLoading}
            onSubmit={onSubmit}
          />
        </div>
        <div className="column is-12">
          <div className="is-divider" data-content={t('attributes')} />
          <ComponentsAttributes
            component={component}
            renderElement={(attribute) => <ComponentAttributeItem key={attribute.name} attribute={attribute} />}
          />
        </div>
      </div>
    </Collapsible>
  );
}
