import { Collapsible } from '@aos/styleguide-react';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { ComponentSelector } from '../../../models/operation/ComponentTypeModel';
import TextAreaLabel from '../../../ui/text-area-label/TextAreaLabel';

interface ComponentSelectorDetailsProps {
  componentSelector: ComponentSelector;
}

export function ComponentSelectorDetails({ componentSelector }: Readonly<ComponentSelectorDetailsProps>) {
  const { t } = useTranslationText('componentTypes');

  return (
    <Collapsible isInitiallyCollapsed={false} header={t('componentSelector')}>
      <TextAreaLabel label={t('componentSelectorQueryLabel')} value={componentSelector.query} autosizeOnLoad />
      <label className="label" htmlFor={t('componentSelectorLabelsLabel')}>
        {t('componentSelectorLabelsLabel')}
      </label>
      {componentSelector.labels.map((label) => (
        <li key={label} className="tag is-light mr-1">
          {label}
        </li>
      ))}
    </Collapsible>
  );
}
