import { useCallback } from 'react';
import { Controller, ControllerRenderProps, FieldError, FieldPath, UseFormReturn } from 'react-hook-form';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import TextArea from '../../ui/text-area/TextArea';
import TextInput from '../../ui/text-input/TextInput';

interface AttributeValueSelectorFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
  queryError: FieldError | undefined;
  query: FieldPath<ComponentTypeModel>;
  labelError: FieldError | undefined;
  label: FieldPath<ComponentTypeModel>;
}

export default function AttributeValueSelectorForm({
  form,
  queryError,
  query,
  labelError,
  label,
}: AttributeValueSelectorFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { control } = form;

  const QueryInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, any> }) => (
      <FormFieldWrapper error={queryError} label={t('attributeValueSelectorLabel')} isRequired>
        <TextArea
          placeholder={t('attributeValueSelectorPlaceholder')}
          onValueChange={field.onChange}
          value={field.value}
          error={queryError}
        />
      </FormFieldWrapper>
    ),
    [queryError, t],
  );

  const LabelInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, any> }) => (
      <FormFieldWrapper error={labelError} label={t('attributeValueLabelLabel')}>
        <TextInput
          placeholder={t('attributeValueLabelPlaceholder')}
          onValueChange={field.onChange}
          value={field.value}
          error={labelError}
        />
      </FormFieldWrapper>
    ),
    [labelError, t],
  );

  return (
    <>
      <Controller name={query} control={control} render={QueryInput} />
      <Controller name={label} control={control} render={LabelInput} />
    </>
  );
}
