import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import Select from '@/ui/select/Select';
import { TicketSeverity } from '@/models/operation/TicketSeveritiesModel';
import {
  mapSeveritiesToOptions,
  MultiSeverityValue,
  severityLoadOptions,
  SeverityOptionLabel,
} from '@/ticketing/severity-selects/SeveritySelectUtils';
import { mapOptionsToTValues, mapTValuesToLoadOptions, MultiSelectOptionLabel } from '@/ui/select/SelectUtils';
import { useGetSeverities } from '@/react-query/TicketingSystemApi';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import { useTranslationText } from '@/translation/TranslationHooks';

function findSeveritiesByKeys(keys: string[], severities: TicketSeverity[]): TicketSeverity[] {
  return severities.filter((severities) => keys.includes(severities.key));
}

interface MultiSeveritySelectProps {
  onChange: (severities: TicketSeverity[] | null) => void;
  value: string[] | null;
  label?: string;
  disableLabel?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  size?: BulmaSize;
}

function MultiSeveritySelect({
  onChange,
  value,
  label,
  disableLabel,
  isRequired,
  isDisabled,
  size,
}: MultiSeveritySelectProps) {
  const { t } = useTranslationText('tickets');
  const { t: tCommons } = useTranslationText('commons');
  const { data: severities = [], isLoading } = useGetSeverities();

  function handleDelete(deletedKey: string) {
    onChange(
      value
        ? findSeveritiesByKeys(
            value.filter((oldValue) => oldValue !== deletedKey),
            severities,
          )
        : null,
    );
  }

  return (
    <Select<TicketSeverity, never, never, true>
      value={mapSeveritiesToOptions(severities.filter((severity) => value?.includes(severity.key)) ?? [])}
      onChange={(newSelectedSeverities) => onChange(mapOptionsToTValues<TicketSeverity>(newSelectedSeverities))}
      loadOptions={() => severityLoadOptions(severities)}
      label={disableLabel ? undefined : label}
      isLoading={isLoading}
      isSearchable={false}
      isRequired={isRequired}
      isDisabled={isDisabled}
      size={size ?? BulmaSize.Small}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isMulti
      requiredPermission={UserResourcePermissions.Ticket.Read}
      loadingMessage={() => tCommons('severitiesAreLoading')}
      placeholder={t('selectSeverities')}
      formatOptionLabel={(opt, metadata) =>
        MultiSelectOptionLabel({
          opt,
          selectedValueIds: value?.map((severity) => severity) ?? [],
          OptionLabel: SeverityOptionLabel,
          metadata,
        })
      }
      components={{
        MultiValue: ({ data }) => <MultiSeverityValue data={data} handleDelete={handleDelete} t={t} />,
      }}
    />
  );
}

export default MultiSeveritySelect;
