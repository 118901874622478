import { useQueryClient } from '@tanstack/react-query';
import { TaskDto } from '../../../models/operation/TaskDto';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { MutationKey, MutationPath, useDeleteMutation, usePutMutation } from '../../../react-query/MutationQueries';
import { invalidateTask } from '../../../react-query/InvalidationQueries';
import { SwitchInput } from '../../../ui/switch/SwitchInput';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';

interface FavoriteInputProps {
  task: TaskDto;
}

function FavoriteInput({ task }: Readonly<FavoriteInputProps>) {
  const queryClient = useQueryClient();
  const { t } = useTranslationText('tickets');

  const { mutate } = usePutMutation<undefined, number[]>(MutationKey.PutFavoriteTask, {
    onSuccess: () => invalidateTask(queryClient, task.id),
  });

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteFavoriteTask, {
    onSuccess: () => invalidateTask(queryClient, task.id),
  });

  function handleOnChange(value: boolean) {
    if (value) {
      mutate({
        body: undefined,
        path: MutationPath.PutOrDeleteFavoriteTask(task.id.toString()),
      });
    } else {
      deleteMutate({
        path: MutationPath.PutOrDeleteFavoriteTask(task.id.toString()),
      });
    }
  }

  return (
    <FormFieldWrapper label={t('favoriteTask')}>
      <SwitchInput id="task-favorite" onChange={handleOnChange} checked={task.favorite} />
    </FormFieldWrapper>
  );
}

export default FavoriteInput;
