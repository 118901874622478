import { SnmpField, SnmpTable, SnmpV3Auth, SnmpVersion } from '../snmp/Snmp';

export type AgentModuleType = 'http-endpoint' | 'network-response' | 'snmp' | 'toml';

export const AgentModuleTypes: AgentModuleType[] = ['http-endpoint', 'network-response', 'snmp', 'toml'];

export type AgentModule = HttpModule | NetworkResponseModule | SnmpModule | TomlModule | BaseAgentModule;

export interface BaseAgentModule {
  id: string | undefined;
  type: AgentModuleType | undefined;
}

export interface HttpModule extends BaseAgentModule {
  type: 'http-endpoint';
  name: string;
  moduleMethod: string;
  url: string;
  body: string;
}

export type NetworkResponseProtocol = 'tcp' | 'udp';
export const NetworkResponseProtocols: NetworkResponseProtocol[] = ['tcp', 'udp'];

export interface NetworkResponseModule extends BaseAgentModule {
  type: 'network-response';
  protocol: NetworkResponseProtocol;
  host: string;
  port: number;
}

export interface SnmpModule extends BaseAgentModule {
  type: 'snmp';
  address: string;
  timeoutInSeconds?: number;
  version: SnmpVersion;
  community: string;
  auth?: SnmpV3Auth;
  fields: SnmpField[];
  tables: SnmpTable[];
}

export const defaultCreateAgentModule: BaseAgentModule = {
  id: undefined,
  type: undefined,
};

export interface TomlModule extends BaseAgentModule {
  type: 'toml';
  toml: string;
}
