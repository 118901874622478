import { RuleTrigger } from '../../../models/operation/AutomationRuleModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import TextInput from '../../../ui/text-input/TextInput';
import { GenericTriggerConfigurationProps } from './GenericTriggerConfigurationProps';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';

interface TicketUnchangedTriggerConfiguration extends RuleTrigger {
  type: 'ticketUnchanged';
  minutesSinceLastChange: number;
}

export default function TicketUnchangedTrigger({
  trigger,
  handleConfigurationUpdate,
  onClear,
}: GenericTriggerConfigurationProps<TicketUnchangedTriggerConfiguration>) {
  const { t } = useTranslationText('tickets');
  const handleChange = (value: string): void => {
    const numberValue: number = Number(value);
    if (!Number.isNaN(numberValue) && numberValue > 0) {
      handleConfigurationUpdate({
        type: 'ticketUnchanged',
        minutesSinceLastChange: numberValue,
      } as TicketUnchangedTriggerConfiguration);
    } else {
      onClear();
    }
  };

  return (
    <FormFieldWrapper label={t('minutesSinceLastChangeLabel')}>
      <TextInput type="number" onValueChange={handleChange} value={trigger?.minutesSinceLastChange ?? 0} />
    </FormFieldWrapper>
  );
}
