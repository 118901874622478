import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { useEffect, useState } from 'react';
import useGetComponentsSummarized from '../../react-query/ComponentApi';
import MultiComponentSelect from '../../components/component-selects/multi-component-select/MultiComponentSelect';
import { BaseComponentItem } from '../../models/operation/ComponentModel';

interface ComponentFilterProps {
  selectedComponentIds: (string | null)[] | null | undefined;
  onChange: (name: keyof ComponentInterface, value: string[]) => void;
  size?: BulmaSize;
  label?: string;
}

interface ComponentInterface {
  components: string[];
}

export default function ComponentFilter({
  selectedComponentIds,
  onChange,
  label,
  size = 'is-small',
}: ComponentFilterProps) {
  // get components for selected componentIds
  const { data: components, isSuccess } = useGetComponentsSummarized(
    {
      enabled: !!selectedComponentIds,
    },
    { componentIds: selectedComponentIds },
  );
  const [selectedComponents, setSelectedComponents] = useState<BaseComponentItem[] | null>(components?.content ?? null);

  useEffect(() => {
    if (isSuccess) setSelectedComponents(components.content);
  }, [components?.content, isSuccess]);

  function handleSelectedComponentChange(newSelectedComponents: BaseComponentItem[] | null) {
    setSelectedComponents(newSelectedComponents);
    onChange('components', newSelectedComponents?.map((component) => component.id) ?? []);
  }

  return (
    <MultiComponentSelect
      label={label}
      onChange={handleSelectedComponentChange}
      value={selectedComponents}
      size={size}
    />
  );
}
