import dayjs from 'dayjs';
import { ObjectSchema } from 'yup';
import { RollbackComplexity } from '../models/maintenance/RollbackComplexity';
import { Priority } from '../models/maintenance/Priority';
import { YupType } from '../translation/YupLocal';
import { BaseMaintenance, PatchBaseMaintenance } from '../models/maintenance/Maintenance';

const componentLocationValidation = (validation: YupType) =>
  validation.array().of(
    validation.object({
      componentId: validation.string().required(),
      description: validation.string().trim().optional().nullable(),
    }),
  );

export const patchComponentLocationsValidation = (validation: YupType) =>
  validation.object().shape({
    componentLocations: componentLocationValidation(validation),
  }) as unknown as ObjectSchema<PatchBaseMaintenance>;

const maintenanceRequestValidationSchema = (validation: YupType, t: (key: string) => string) =>
  validation
    .object({
      title: validation.string().max(255).required().nonNullable(),
      startOfAction: validation
        .date()
        .required()
        .test('startOfActionNotInPast', t('startOfActionNotInPastHint'), (value) => {
          const newDate = dayjs(value).startOf('minute');
          const now = dayjs().startOf('minute');
          return newDate.isValid() && newDate >= now;
        }),
      endOfAction: validation
        .date()
        .required()
        .when('startOfAction', ([startOfAction], schema) => {
          if (startOfAction) {
            const minValue = new Date((startOfAction as Date).getTime() + 1);
            return schema.min(minValue, t('endOfActionHint'));
          }
          return schema;
        }),
      priority: validation.string().required(),
      priorityReason: validation
        .string()
        .trim()
        .when('priority', {
          is: (priority: Priority) => priority === Priority.HIGH || priority === Priority.VERY_HIGH,
          then: (schema) => schema.required(),
        }),
      summary: validation.string().trim().required(),
      expectedInfluenceOnSystems: validation.string().trim().optional().nullable(),
      personResponsible: validation
        .object({
          firstName: validation.string().trim().max(255).required(),
          lastName: validation.string().trim().max(255).required(),
          phone: validation.string().trim().max(255).optional().nullable(),
        })
        .required(),
      involvedContractors: validation.array().of(
        validation.object({
          contractorId: validation.string().required(),
          name: validation.string().required(),
          contactPersons: validation.array().of(
            validation.object({
              lastName: validation.string().trim().max(255).required(),
              firstName: validation.string().trim().max(255).required(),
              phone: validation.string().trim().max(255).optional().nullable(),
              email: validation.string().trim().max(255).email(t('email')).optional().nullable(),
            }),
          ),
        }),
      ),
      detailedDescription: validation.string().trim().required(),
      componentLocations: componentLocationValidation(validation),
      rollbackComplexity: validation.string().required(),
      rollbackPrecautions: validation
        .string()
        .trim()
        .nullable()
        .when('rollbackComplexity', {
          is: (rollbackComplexity: RollbackComplexity) => rollbackComplexity === RollbackComplexity.HIGH,
          then: (schema) => schema.required(),
        }),
      additionalComments: validation.string().trim().optional().nullable(),
      maintenanceLocations: validation
        .array()
        .of(
          validation.object({
            componentId: validation.string().required(),
            description: validation.string().optional().nullable(),
          }),
        )
        .required()
        .min(1),
      maintenanceRequestDecline: validation.object({}).optional().nullable(),
      actionNumber: validation.string().optional().nullable(),
      author: validation.object().optional().nullable(),
      createdAt: validation.date().optional().nullable(),
      status: validation.string().optional().nullable(),
      alternativeTimes: validation
        .array()
        .of(
          validation.object({
            id: validation.number(),
            startOfAction: validation
              .date()
              .required()
              .test('startOfActionNotInPast', t('startOfActionNotInPastHint'), (value) => {
                const newDate = dayjs(value).startOf('minute');
                const now = dayjs().startOf('minute');
                return newDate.isValid() && newDate >= now;
              }),
            endOfAction: validation
              .date()
              .required()
              .when('startOfAction', ([startOfAction], schema) => {
                if (startOfAction) {
                  const minValue = new Date((startOfAction as Date).getTime() + 1);
                  return schema.min(minValue, t('endOfActionHint'));
                }
                return schema;
              }),
          }),
        )
        .test(
          'unique',
          t('uniqueTimes'),
          (values, context) =>
            new Set(
              values?.map(
                (value) =>
                  dayjs(value.startOfAction).format('DD.MM.YYYY, HH:mm') +
                  dayjs(value.endOfAction).format('DD.MM.YYYY, HH:mm'),
              ),
            ).add(
              dayjs(context.parent.startOfAction).format('DD.MM.YYYY, HH:mm') +
                dayjs(context.parent.endOfAction).format('DD.MM.YYYY, HH:mm'),
            ).size ===
            (values?.length ?? 0) + 1,
        ),
    })
    .required() as ObjectSchema<BaseMaintenance>;

export default maintenanceRequestValidationSchema;
