import { snakeToCamelCase } from '../../utils/CasingConversion';

export type TicketField =
  | 'TITLE'
  | 'DESCRIPTION'
  | 'SEVERITY'
  | 'STATE'
  | 'ASSIGNEE_ID'
  | 'ASSIGNED_COMPONENT'
  | 'MEDIA';

export const AllTicketFields: TicketField[] = [
  'TITLE',
  'DESCRIPTION',
  'SEVERITY',
  'STATE',
  'ASSIGNEE_ID',
  'ASSIGNED_COMPONENT',
  'MEDIA',
];

export function translationKey(ticketField: string) {
  return snakeToCamelCase(`FIELD_${ticketField}`);
}
