import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import GET_TRANSLATIONS from '../graphql/TranslationQuery';
import { TranslationSection } from '../graphql/TranslationSection';
import { RelationSpec } from '../models/operation/RelationTypeModel';
import initI18n from './TranslationManager';
import { StringMap } from './TranslationStringMap';

export type TTranslateFn = (key: string, options?: TOptions) => string;
type TOptions = StringMap;

export function useTranslationText(section: TranslationSection = '') {
  const { t } = useTranslation();
  return {
    t: (key: string, options?: TOptions): string =>
      options ? (t(`${section}.${key}.text`, options) as string) : t(`${section}.${key}.text`),
  };
}

export function useOptionalTranslationText(section: TranslationSection = '') {
  const { t } = useTranslationText(section);
  return {
    t: (key: string, options?: TOptions) => {
      const result = t(key, options);
      if (result === `${section}.${key}.text`) {
        return undefined;
      }
      return result;
    },
  };
}

export function useRelationTypeTranslations() {
  const { t } = useTranslation();
  return {
    forward: (key: string) => t(`relationTypes.${key}.forward`),
    reverse: (key: string) => t(`relationTypes.${key}.reverse`),
    translateRelation: (rel: RelationSpec) =>
      rel.direction === 'forward'
        ? t(`relationTypes.${rel.type.identifier}.forward`)
        : t(`relationTypes.${rel.type.identifier}.reverse`),
  };
}

export default function useTranslationQuery() {
  return useQuery(GET_TRANSLATIONS);
}

export function useTranslationInit() {
  const [isI18nInit, setIsI18nInit] = useState(false);
  const translationQueryResult = useTranslationQuery();

  useMemo(() => {
    if (translationQueryResult.data) {
      initI18n(translationQueryResult.data)
        ?.then(setIsI18nInit)
        .catch((error) => console.error(`I18N initialization failed: ${error}`));
    }
  }, [translationQueryResult.data]);

  return [isI18nInit, translationQueryResult.loading];
}
