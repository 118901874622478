import { useNavigate } from 'react-router';
import {
  Controller,
  ControllerRenderProps,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
  UseFormStateReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { ArticleMediaUrlBuilders, useGetKnowledgeBaseArticle } from '../../../react-query/KnowledgeBaseApi';
import LoadingSpinner from '../../../ui/loading-spinner/LoadingSpinner';
import { useTranslationText } from '../../../translation/TranslationHooks';
import TextInput from '../../../ui/text-input/TextInput';
import { MutationKey, MutationPath, usePutMutation } from '../../../react-query/MutationQueries';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import useYupLocal from '../../../translation/YupLocal';
import { KnowledgeBaseArticle } from '../../../models/knowledge-base/KnowledgeBaseModel';
import articleValidationSchema from '../ArticleValidationSchema';
import RichTextEditor from '../../../ui/rich-text/RichTextEditor';
import CreateArticleModel from '../../../models/knowledge-base/CreateArticleModel';
import ActionBar from '../../../ui/action-bar/ActionBar';
import TextArea from '../../../ui/text-area/TextArea';
import './EditArticle.scss';
import Media from '../../../ui/media/Media';
import usePreventNavigation from '../../../custom-hooks/PreventNavigation';
import ArticleTag from '../article-tag/ArticleTag';
import { imageTagToDocument } from '../../../ui/media/ImageToDocument';
import { ArticleComponentTypeSelection } from '../article-component-type/ArticleComponentTypeSelection';
import useParamId from '../../../custom-hooks/UseParamId';
import { ArticleComponentSelection } from '../article-component/ArticleComponentSelection';
import { SwitchInput } from '../../../ui/switch/SwitchInput';
import { StrapiFile } from '../../../models/strapi/StrapiFile';

function TitleInput({
  field,
  formState,
}: {
  field: ControllerRenderProps<CreateArticleModel, 'title'>;
  formState: UseFormStateReturn<CreateArticleModel>;
}) {
  const { t } = useTranslationText('knowledgeBases');
  return (
    <FormFieldWrapper error={formState.errors?.title} label={t('title')} isRequired>
      <TextInput
        placeholder={t('titlePlaceholder')}
        onValueChange={field.onChange}
        value={field.value}
        error={formState.errors?.title}
        dataRole="article-edit-title"
      />
    </FormFieldWrapper>
  );
}

function DescriptionShortTextArea({
  field,
  formState,
}: {
  field: ControllerRenderProps<CreateArticleModel, 'descriptionShort'>;
  formState: UseFormStateReturn<CreateArticleModel>;
}) {
  const { t } = useTranslationText('knowledgeBases');
  return (
    <FormFieldWrapper error={formState.errors?.descriptionShort} label={t('descriptionShort')} isRequired>
      <TextArea
        placeholder={t('descriptionShortPlaceholder')}
        onValueChange={field.onChange}
        value={field.value}
        error={formState.errors?.descriptionShort}
      />
    </FormFieldWrapper>
  );
}

function MediaSection({
  field,
  setValue,
  getValues,
}: {
  field: ControllerRenderProps<CreateArticleModel, 'media'>;
  setValue: UseFormSetValue<CreateArticleModel>;
  getValues: UseFormGetValues<CreateArticleModel>;
}) {
  const { t } = useTranslationText('knowledgeBases');
  return (
    <Media
      deletionPath={MutationPath.DeleteArticleMedia}
      mutationPath={MutationPath.UploadArticleMedia}
      mutationKey={MutationKey.PostUploadArticleMedia}
      media={field.value as unknown as StrapiFile[]}
      urlBuilders={ArticleMediaUrlBuilders}
      isEditable
      isSideView
      onMediaChange={field.onChange}
      onCopyImage={(url) => setValue('content', imageTagToDocument(url, getValues('content').toString()))}
      label={t('attachments')}
    />
  );
}

function ContentTextArea({
  field,
  formState,
}: {
  field: ControllerRenderProps<CreateArticleModel, 'content'>;
  formState: UseFormStateReturn<CreateArticleModel>;
}) {
  const { t } = useTranslationText('knowledgeBases');
  return (
    <FormFieldWrapper error={formState.errors?.content} label={t('content')} noGridControl>
      <RichTextEditor
        id="articleDescription"
        placeholder={t('contentPlaceholder')}
        onChange={field.onChange}
        value={field.value}
        error={formState.errors?.content?.message}
      />
    </FormFieldWrapper>
  );
}

function IsHighlightedSwitch({ field }: { field: ControllerRenderProps<CreateArticleModel, 'highlighted'> }) {
  const { t } = useTranslationText('knowledgeBases');
  return (
    <FormFieldWrapper label={t('highlight')}>
      <SwitchInput id="highlighted" onChange={field.onChange} checked={field.value} />
    </FormFieldWrapper>
  );
}

function ComponentTypes({ field }: { field: ControllerRenderProps<CreateArticleModel, 'componentTypes'> }) {
  return <ArticleComponentTypeSelection onChange={field.onChange} values={field.value} />;
}

function TagArea({ field }: { field: ControllerRenderProps<CreateArticleModel, 'tags'> }) {
  return <ArticleTag onChange={field.onChange} values={field.value} />;
}

function Components({ field }: { field: ControllerRenderProps<CreateArticleModel, 'components'> }) {
  return <ArticleComponentSelection onChange={field.onChange} values={field.value} />;
}

export default function EditArticle() {
  const { id: articleId, ErrorPage } = useParamId();
  const { t } = useTranslationText('knowledgeBases');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tCommon } = useTranslationText('commons');
  const { data, isLoading: articleLoading, isError } = useGetKnowledgeBaseArticle(articleId);
  const { yup } = useYupLocal();
  const navigate = useNavigate();
  const [preventNavigation, setPreventNavigation] = useState(true);
  const formDataRef = useRef<HTMLFormElement>(null);
  const { handleSubmit, control, formState, getValues, setValue } = useForm<CreateArticleModel>({
    mode: 'onChange',
    resolver: yupResolver(articleValidationSchema(yup)),
  });

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const { mutate, isPending } = usePutMutation<CreateArticleModel, KnowledgeBaseArticle>(MutationKey.PutArticle, {
    onSuccess: () => navigate(-1),
    onError: () => {
      toast.error(tError('articlePutError'));
    },
  });

  const onSubmit = (updateArticleModel: CreateArticleModel) => {
    setPreventNavigation(false);
    mutate({
      body: {
        title: updateArticleModel.title,
        descriptionShort: updateArticleModel.descriptionShort,
        content: updateArticleModel.content,
        highlighted: updateArticleModel.highlighted,
        media: updateArticleModel.media,
        tags: updateArticleModel.tags,
        componentTypes: updateArticleModel.componentTypes,
        components: updateArticleModel.components,
      },
      path: MutationPath.PutArticle(articleId),
    });
  };

  if (ErrorPage) {
    return ErrorPage;
  }

  return (
    <LoadingSpinner
      isLoading={isPending || articleLoading}
      errors={isError ? <Trans i18nKey="errorTexts.ArticleError.text" values={{ id: articleId }} /> : undefined}
    >
      <form onSubmit={handleSubmit(onSubmit)} ref={formDataRef}>
        <ActionBar
          right={
            <button
              className={classNames('button is-primary text-icon-button', { 'is-loading': isPending })}
              type="submit"
              aria-label="save-button"
              data-role="article-save-button"
              disabled={!formState.isValid || !formState.isDirty}
            >
              <span>
                {t('update')}
                <FontAwesomeIcon icon={faSave} />
              </span>
            </button>
          }
        />
        <div className="columns is-5 edit-article">
          <div className="column is-8">
            <Controller name="title" control={control} defaultValue={data?.title} render={TitleInput} />
            <Controller name="content" control={control} defaultValue={data?.content} render={ContentTextArea} />
          </div>
          <div className="column is-4">
            <Controller
              name="descriptionShort"
              control={control}
              defaultValue={data?.descriptionShort}
              render={DescriptionShortTextArea}
            />
            <Controller
              name="highlighted"
              control={control}
              defaultValue={data?.highlighted}
              render={IsHighlightedSwitch}
            />
            <Controller name="tags" control={control} defaultValue={data?.tags} render={TagArea} />
            <Controller name="components" control={control} defaultValue={data?.components} render={Components} />
            <Controller
              name="componentTypes"
              control={control}
              defaultValue={data?.componentTypes}
              render={ComponentTypes}
            />
            <Controller
              name="media"
              control={control}
              defaultValue={data?.media as unknown as StrapiFile[]}
              render={({ field }) => <MediaSection field={field} getValues={getValues} setValue={setValue} />}
            />
          </div>
        </div>
      </form>
    </LoadingSpinner>
  );
}
