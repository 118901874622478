import { useCallback, useMemo, useState } from 'react';
import { Collapsible } from '@aos/styleguide-react';
import { ComponentItem } from '../../models/operation/ComponentModel';
import { useGetAncestorComponents } from '../../react-query/ComponentApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import './TicketContract.scss';
import { TicketContractItem } from './TicketContractItem';
import { TaskDto } from '../../models/operation/TaskDto';

export interface TicketContractsProps {
  component: ComponentItem | undefined | null;
  taskData?: TaskDto[];
  areTasksLoading: boolean;
}

export function TicketContracts({ component, taskData, areTasksLoading }: Readonly<TicketContractsProps>) {
  const { t } = useTranslationText('tickets');

  const [components, setComponents] = useState<ComponentItem[]>([]);
  const { data, isLoading: areAncestorsLoading } = useGetAncestorComponents(component?.id!, 'part-of', {
    enabled: !!component,
  });

  useMemo(() => {
    if (!component) {
      setComponents([]);
    } else {
      setComponents(
        [component, ...(data || [])].filter((thisOrAncestorComponent) => !!thisOrAncestorComponent.assignedContractId),
      );
    }
  }, [component, data]);

  const tasksByContractId = useCallback(
    (contractId: string) => taskData?.filter((f) => f.contractId === contractId),
    [taskData],
  );

  const hasNoValues = useMemo(() => !components.some((c) => c.assignedContractId), [components]);

  return (
    <Collapsible header={t('contracts')} isInitiallyCollapsed={false}>
      {hasNoValues && t('noContracts')}
      <LoadingSpinner isLoading={areAncestorsLoading || areTasksLoading}>
        <div className="contract-items">
          {components.map((c) => (
            <TicketContractItem
              key={c.id}
              currentComponent={component!}
              sourceComponent={c}
              tasks={tasksByContractId(c.assignedContractId!)}
            />
          ))}
        </div>
      </LoadingSpinner>
    </Collapsible>
  );
}
