import { useEffect, useState } from 'react';

export interface RuntimeConfig {
  oauthAuthority: string;
  clientId: string;
  dashboardUrl: string;
  mapType: string;
}

export default function useRuntimeConfig(): [RuntimeConfig | undefined, boolean, Error | undefined] {
  const [runtimeConfig, setRuntimeConfig] = useState<RuntimeConfig>();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState<Error>();
  useEffect(() => {
    setIsLoading(true);
    const fetchConfig = async () => {
      const response = await fetch('/config/runtime.json');
      return (await response.json()) as RuntimeConfig;
    };
    fetchConfig()
      .then(
        (res) => setRuntimeConfig(res),
        (err) => setFetchError(new Error(`Error fetching the runtime configuration: ${err}`)),
      )
      .finally(() => setIsLoading(false));
  }, []);
  return [runtimeConfig, isLoading, fetchError];
}
