import { createContext, type PropsWithChildren, useEffect, useMemo, useState, useCallback } from 'react';
import Cookies from 'js-cookie';

export type Theme = 'light' | 'dark' | 'browser';
const ThemeCookieDuration: Cookies.CookieAttributes['expires'] = undefined; // No Cookie expiration
const ThemeCookieName = 'theme';
const CookieDomain = window.location.hostname.split('.').slice(-2).join('.');

type ThemeContextType = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

export const ThemeContext = createContext<ThemeContextType | 'not set'>('not set');

export function ThemeProvider({ children }: PropsWithChildren) {
  function getThemeFromCookies(): Theme {
    const storedTheme = Cookies.get(ThemeCookieName) as Theme;
    if (storedTheme) {
      return storedTheme;
    }
    return 'browser';
  }

  const [theme, setTheme] = useState<Theme>(getThemeFromCookies);

  function setThemeCookie(newTheme: 'light' | 'dark' | 'browser') {
    if (newTheme === 'browser') {
      // Delete cookie when theme is set to browser
      Cookies.remove(ThemeCookieName, { domain: CookieDomain });
    } else {
      // Set cookie when theme is set to light or dark
      Cookies.set(ThemeCookieName, newTheme, {
        expires: ThemeCookieDuration,
        domain: CookieDomain,
      });
    }
  }

  // ADB-2358: Temporarily enforce the Light theme until Dark Mode support is fully implemented and stable
  // https://lise.atlassian.net/browse/ADB-2358
  useEffect(() => {
    setTheme('light');
  }, []);

  const updateThemeAndCookie = useCallback((newTheme: Theme) => {
    setTheme(newTheme);
    setThemeCookie(newTheme);
  }, []);

  useEffect(() => {
    document.body.classList.remove('aos-light-theme');
    document.body.classList.remove('aos-dark-theme');
    if (theme === 'light') {
      document.body.classList.add('aos-light-theme');
    } else if (theme === 'dark') {
      document.body.classList.add('aos-dark-theme');
    }
  }, [theme]);

  const context = useMemo(() => ({ theme, setTheme: updateThemeAndCookie }), [theme, updateThemeAndCookie]);

  return <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>;
}
