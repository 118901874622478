import './History.scss';
import { keyForTicketHistoryModel } from '../../models/operation/TicketModel';
import { useGetTaskHistory } from '../../react-query/TicketingSystemApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import HistoryItem from './item/HistoryItem';
import { useTranslationText } from '../../translation/TranslationHooks';

interface TaskHistoryProps {
  taskId: number;
}

export default function TaskHistory({ taskId }: TaskHistoryProps) {
  const { t: tError } = useTranslationText('errorTexts');

  const { data: taskHistory, isLoading: isTicketHistoryLoading, isError } = useGetTaskHistory(taskId);

  return (
    <div className="history" data-role="task-history">
      <LoadingSpinner isLoading={isTicketHistoryLoading} errors={isError ? tError('404_history') : undefined}>
        {(taskHistory ?? []).map((record, index) => (
          <HistoryItem
            key={keyForTicketHistoryModel(record)}
            testId={taskHistory ? taskHistory.length - index : 0}
            eventTranslationKey={record.eventTranslationKey}
            actor={record.actor}
            createdAt={record.createdAt}
            payload={record.payload}
          />
        ))}
      </LoadingSpinner>
    </div>
  );
}
