import { Button, Icon, Modal, ModalCard, Table } from '@aos/styleguide-react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link, To } from 'react-router-dom';
import { ComponentProps, Dispatch, HTMLAttributes, ReactNode, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { type TicketRelationDto } from '@/models/operation/RelationTypeModel';
import TicketStateTag from '../../ticket-state-tag/TicketStateTag';
import { useTranslationText } from '@/translation/TranslationHooks';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import useToggle from '../../../custom-hooks/useToggle';
import { TicketingApiPrefix } from '@/react-query/TicketingApi';
import { MutationKey, useDeleteMutation } from '@/react-query/MutationQueries';
import { TicketRelationTag } from '../ticket-relation-tag/TicketRelationTag';
import './TicketRelationTable.scss';

interface TicketRelationHeaderProps {
  readOnly: boolean;
}

function TicketRelationHeader({ readOnly }: TicketRelationHeaderProps) {
  const { t } = useTranslationText('tickets');
  const { t: tCommons } = useTranslationText('commons');

  return (
    <Table.Header className="ticket-relation-table-header">
      <Table.Row>
        <Table.HeaderCell className="ticket-relation-table-header-cell relation-type-width">
          {tCommons('relationType')}
        </Table.HeaderCell>
        <Table.HeaderCell className="ticket-relation-table-header-cell ticket-number-width">
          {t('ticketNr')}
        </Table.HeaderCell>
        <Table.HeaderCell className="ticket-relation-table-header-cell">{t('ticket')}</Table.HeaderCell>
        <Table.HeaderCell className="ticket-relation-table-header-cell ticket-status-width">
          {tCommons('status')}
        </Table.HeaderCell>
        {!readOnly && (
          <Table.HeaderCell className="ticket-relation-table-header-cell relation-actions-width">
            {tCommons('action')}
          </Table.HeaderCell>
        )}
      </Table.Row>
    </Table.Header>
  );
}

interface TicketRelationTableCellProps {
  value: ReactNode;
  navigateTo?: To;
  width?: string;
  onDelete?: () => void;
}

function TicketRelationTableCell({ value, navigateTo, width, onDelete }: TicketRelationTableCellProps) {
  return (
    <Table.DataCell className={`${navigateTo ? '' : 'ticket-relation-table-cell-no-link-style'}`} style={{ width }}>
      {navigateTo ? (
        <Link to={navigateTo} className="link-item ticket-relation-table-cell">
          {value}
        </Link>
      ) : (
        <button
          type="button"
          className="ticket-relation-table-cell-button"
          onClick={() => {
            if (onDelete) onDelete();
          }}
        >
          {value}
        </button>
      )}
    </Table.DataCell>
  );
}

interface TicketRelationTableRowProps {
  ticketRelation: TicketRelationDto;
  setRelationIdToDelete: Dispatch<SetStateAction<string | null>>;
  toggleConfirmationModal: (newToggle?: boolean) => void;
  readOnly: boolean;
}

function TicketRelationTableRow({
  ticketRelation,
  setRelationIdToDelete,
  toggleConfirmationModal,
  readOnly,
}: TicketRelationTableRowProps) {
  const { t } = useTranslationText('tickets');

  function handleDelete(id: string) {
    setRelationIdToDelete(id);
    toggleConfirmationModal();
  }

  function findRelationPartner(relationDto: TicketRelationDto) {
    return relationDto.source ?? relationDto.target!;
  }

  const relationPartner = findRelationPartner(ticketRelation);
  const navigateTo = NavigationRoutes.TicketAllId(relationPartner.id);

  return (
    <Table.Row className="ticket-relation-table-row">
      <TicketRelationTableCell value={<TicketRelationTag relation={ticketRelation} />} navigateTo={navigateTo} />
      <TicketRelationTableCell value={relationPartner.id.toString()} navigateTo={navigateTo} />
      <TicketRelationTableCell
        value={<span className="is-truncated-1">{relationPartner.title}</span>}
        navigateTo={navigateTo}
      />
      <TicketRelationTableCell value={<TicketStateTag state={relationPartner.state} t={t} />} navigateTo={navigateTo} />
      {!readOnly && (
        <TicketRelationTableCell
          value={<Icon name={faTrash.iconName} />}
          onDelete={() => {
            handleDelete(ticketRelation.id);
          }}
        />
      )}
    </Table.Row>
  );
}

interface TicketRelationTableProps {
  ticketId: number;
  ticketRelations: TicketRelationDto[];
  onDeleteRelation: () => void;
  readOnly: boolean;
}

function TicketRelationTable({ ticketId, ticketRelations, onDeleteRelation, readOnly }: TicketRelationTableProps) {
  const { t } = useTranslationText('commons');
  const { t: tErrorTexts } = useTranslationText('errorTexts');
  const [relationIdToDelete, setRelationIdToDelete] = useState<string | null>(null);
  const [isConfirmationModalVisible, toggleConfirmationModal] = useToggle(false);

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteTicketRelation, {
    onSuccess: onDeleteRelation,
    onError: () => {
      toast.error(tErrorTexts('deleteTicketRelationError'));
    },
  });

  function onDelete() {
    if (relationIdToDelete) {
      deleteMutate({
        path: `${TicketingApiPrefix}/ticket/${ticketId}/relation/${relationIdToDelete}`,
      });
      setRelationIdToDelete(null);
    }
    toggleConfirmationModal();
  }

  return (
    <>
      <Table>
        <TicketRelationHeader readOnly={readOnly} />
        <Table.Body>
          {ticketRelations.map((ticketRelation) => (
            <TicketRelationTableRow
              key={ticketRelation.id}
              ticketRelation={ticketRelation}
              setRelationIdToDelete={setRelationIdToDelete}
              toggleConfirmationModal={toggleConfirmationModal}
              readOnly={readOnly}
            />
          ))}
        </Table.Body>
      </Table>
      <Modal isActive={isConfirmationModalVisible} portalRoot={document.body}>
        <ModalCard
          title={t('deleteTicketRelation')}
          content={t('warningTicketRelationDelete')}
          footer={
            <>
              <Button onClick={() => toggleConfirmationModal()}>{t('back')}</Button>
              <Button onClick={onDelete} isConfirm>
                {t('delete')}
              </Button>
            </>
          }
          onClose={() => {
            toggleConfirmationModal();
          }}
        />
      </Modal>
    </>
  );
}

export default TicketRelationTable;
