import { useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { MutationKey, MutationPath, usePostMutation } from '../../../react-query/MutationQueries';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { TicketCommentModel } from '../../../models/operation/TicketModel';
import RichTextEditor from '../../../ui/rich-text/RichTextEditor';
import useToggle from '../../../custom-hooks/useToggle';
import './CreateComment.scss';
import ServerStateKeysEnum from '../../../react-query/ServerStateKeysEnum';
import CreateCommentModel, { CommentType } from '../../../models/operation/CreateCommentModel';

interface BaseCreateCommentProps {
  id?: string;
  type?: CommentType;

  onCancel?: () => void;
  onSubmit?: () => void;
}

interface CreateCommentProps extends BaseCreateCommentProps {
  queryKey: ServerStateKeysEnum;
  identifier: number;
  mutationKey: MutationKey;
  mutationPath: MutationPath;
}

function CreateComment({
  id = 'ticketComment',
  queryKey,
  identifier,
  mutationKey,
  mutationPath,
  type = CommentType.COMMENT,
  onCancel = () => {},
  onSubmit = () => {},
}: CreateCommentProps) {
  const { t } = useTranslationText('comments');
  const { t: tError } = useTranslationText('errorTexts');
  const [text, setText] = useState('');
  const queryClient = useQueryClient();

  const [isCreateCommentVisible, toggleIsCreateCommentVisible] = useToggle(false);

  const { mutate, isPending } = usePostMutation<CreateCommentModel, TicketCommentModel>(mutationKey, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [queryKey, identifier] });
      setText('');
      toggleIsCreateCommentVisible();
    },
    onError: () => {
      toast.error(tError('createCommentError'));
    },
  });

  const handleSubmit = () => {
    mutate(
      { body: { text, type }, path: mutationPath },
      {
        onSuccess: () => onSubmit(),
      },
    );
  };

  if (isCreateCommentVisible || type === CommentType.QUESTION) {
    return (
      <div className="create-comment-container">
        <RichTextEditor
          id={id}
          placeholder={type === CommentType.QUESTION ? t('createQuestionPlaceholder') : t('createCommentPlaceholder')}
          onChange={setText}
          value={text}
          autofocus
          initHeight={200}
        />
        <div className="is-fluid is-flex is-justify-content-space-between is-align-items-center is-fullwidth is-flex-column-mobile">
          <button
            className="button is-danger is-outlined mt-2 is-fullwidth-mobile"
            type="button"
            onClick={() => {
              toggleIsCreateCommentVisible();
              setText('');
              onCancel();
            }}
          >
            {t('abort')}
          </button>
          <button
            className={classNames('button', 'is-primary', 'mt-2', 'is-fullwidth-mobile', { 'is-loading': isPending })}
            type="button"
            onClick={handleSubmit}
            data-role="save-comment-button"
          >
            {type === CommentType.QUESTION ? t('saveQuestion') : t('saveComment')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      {!isCreateCommentVisible && (
        <button
          className="input create-comment-button"
          type="button"
          onClick={() => {
            toggleIsCreateCommentVisible();
          }}
          data-role="create-comment-button"
        >
          {t('createComment')}
        </button>
      )}
    </div>
  );
}

interface CreateTicketCommentProps extends BaseCreateCommentProps {
  ticketId: number;
  queryKey?: ServerStateKeysEnum;
}

export function CreateTicketComment({
  id = 'ticketComment',
  ticketId,
  type = CommentType.COMMENT,
  queryKey = ServerStateKeysEnum.TicketComments,
  onCancel = () => {},
  onSubmit = () => {},
}: CreateTicketCommentProps) {
  return (
    <CreateComment
      id={id}
      queryKey={queryKey}
      identifier={ticketId}
      type={type}
      mutationKey={MutationKey.PostTicketComment}
      mutationPath={MutationPath.CreateTicketComment(ticketId)}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

interface CreateTaskCommentProps extends BaseCreateCommentProps {
  taskId: number;
}

export function CreateTaskComment({
  id = 'ticketComment',
  type = CommentType.COMMENT,
  taskId,
  onCancel = () => {},
  onSubmit = () => {},
}: CreateTaskCommentProps) {
  return (
    <CreateComment
      id={id}
      queryKey={ServerStateKeysEnum.TaskComments}
      identifier={taskId}
      type={type}
      mutationKey={MutationKey.PostTaskComment}
      mutationPath={MutationPath.CreateTaskComment(taskId)}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}
