import { Dispatch, useState } from 'react';
import { GroupBase, SingleValue } from 'react-select';
import queryString from 'query-string';
import { Response } from 'react-select-async-paginate';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import useCustomFetch from '../../../../react-query/CustomFetch';
import { UserResourcePermissions } from '../../../../auth/AuthUserRoles';
import { DEFAULT_PAGE_SIZE_DROPDOWN } from '../../../../models/pagination/Pagination';
import { MonitoringApiPrefix } from '../../../../react-query/MonitoringApi';
import JSXSearchDropdownPaginated from '../../../../ui/jsx-search-dropdown-paginated/JSXSearchDropdownPaginated';
import { JSXSelectOption } from '../../../../ui/multi-dropdowns-utils/MultiDropdownUtils';
import FormFieldWrapper from '../../../../ui/form-field-wrapper/FormFieldWrapper';

export interface ComponentTypeSuggestion {
  readonly id: string;
  readonly name: string;
}

interface SingleComponentTypeSelectProps {
  onChange: (componentTypeSuggestion: ComponentTypeSuggestion | null) => void;
  value: ComponentTypeSuggestion | null;
  label?: string;
}

const mapComponentTypeToOption = (componentType: ComponentTypeSuggestion | null): SingleValue<JSXSelectOption> => ({
  value: componentType,
  label: componentType?.name ? <>{componentType.name}</> : <></>,
  labelText: componentType?.name ?? '',
});

export const mapComponentTypesToOptions = (componentTypes?: ComponentTypeSuggestion[]) =>
  componentTypes?.map((componentType) => mapComponentTypeToOption(componentType)) ?? [];

const mapOptionToComponentType = (newValue: SingleValue<JSXSelectOption>) =>
  ({
    id: newValue?.value.id,
    name: newValue?.value.name,
  }) as ComponentTypeSuggestion;

export async function loadComponentTypeOptions(
  name: string,
  prev: JSXSelectOption[] | undefined,
  setIsError: Dispatch<React.SetStateAction<boolean>>,
  customFetch: (path: string, init?: RequestInit) => Promise<any>,
  pageSize: number = DEFAULT_PAGE_SIZE_DROPDOWN,
) {
  setIsError(false);

  if (name === '') {
    return { options: [] };
  }

  const page = prev ? Math.trunc(prev.length / pageSize) : 0;
  const filter = {
    name,
    page,
    size: pageSize,
  };
  const path = `${MonitoringApiPrefix}/componentType?${queryString.stringify(filter)}`;

  const componentTypeItemPage = await customFetch(path);

  if (componentTypeItemPage === null) {
    setIsError(true);
    return { options: [] };
  }

  return {
    options: mapComponentTypesToOptions(componentTypeItemPage.content),
    hasMore: !componentTypeItemPage.last,
  } as Response<JSXSelectOption, GroupBase<JSXSelectOption>, any>;
}

export default function SingleComponentTypeSelect({ onChange, value, label }: SingleComponentTypeSelectProps) {
  const { t } = useTranslationText('componentTypes');
  const [isError, setIsError] = useState(false);
  const customFetch = useCustomFetch();
  const [inputValue, setInputValue] = useState<string>();

  const loadOptions = async (searchInput: string, prev?: JSXSelectOption[]) =>
    loadComponentTypeOptions(searchInput, prev, setIsError, customFetch);

  return (
    <FormFieldWrapper label={label ?? t('componentType')}>
      <JSXSearchDropdownPaginated<ComponentTypeSuggestion | null>
        onChange={(newValue) => {
          onChange(mapOptionToComponentType(newValue));
        }}
        noOptionsMessage={t('addAssignedComponentType')}
        isLoadingMessage={t('isLoadingMessage')}
        mapValueToSelectOption={mapComponentTypeToOption}
        value={value}
        placeholder={t('addAssignedComponentType')}
        requiredPermission={UserResourcePermissions.ComponentType.Read}
        isClearable
        loadOptions={loadOptions}
        isError={isError}
        inputValue={inputValue}
        onInputChange={setInputValue}
      />
    </FormFieldWrapper>
  );
}
