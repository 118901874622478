import classNames from 'classnames';
import { CommentType } from '../../../models/operation/CreateCommentModel';
import { TaskDto } from '../../../models/operation/TaskDto';
import { useTranslationText } from '../../../translation/TranslationHooks';
import './CommentContainer.scss';
import { CreateTaskComment } from '../../comment/create-comment/CreateComment';

interface CommentContainerProps {
  show: boolean;
  setShow: () => void;
  task: TaskDto;
  onSubmit: () => void;
}

function QuestionCommentContainer({ show, setShow, task, onSubmit }: CommentContainerProps) {
  const { t } = useTranslationText('tickets');

  return (
    <div
      className={classNames('task-comment-container', {
        hide: !show,
      })}
    >
      <div className="comment-title">{t('questionAboutTask')}</div>
      <CreateTaskComment
        id="question"
        taskId={task.id}
        onCancel={setShow}
        onSubmit={onSubmit}
        type={CommentType.QUESTION}
      />
    </div>
  );
}

export default QuestionCommentContainer;
