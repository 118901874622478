import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useAuthUser } from '../../auth/UserRoleCheck';

import './selectDropdown.scss';
import { UserPermission } from '../../auth/UserPermission';

export interface SelectDropdownOption {
  value: string | undefined;
  displayText: string;
}

interface SelectDropdownProps {
  error?: string;
  isLoading?: boolean;
  label?: string;
  onChange: (value: string) => void;
  options: SelectDropdownOption[];
  selectedKey: string | undefined;
  dataRole: string;
  requiredPermission: UserPermission;
}

export default function SelectDropdown({
  error,
  isLoading = false,
  label,
  onChange,
  options,
  selectedKey,
  dataRole,
  requiredPermission,
}: SelectDropdownProps) {
  const [key, setKey] = useState<string | undefined>(selectedKey);
  const { hasPermission } = useAuthUser();

  useEffect(() => {
    setKey(selectedKey);
  }, [selectedKey]);

  return (
    <div className="field">
      {label && (
        <label htmlFor={label} className="label">
          {label}
        </label>
      )}
      <div className="control">
        <div className={classNames('select', `${dataRole}`, { 'is-loading': isLoading })}>
          <select
            data-role={dataRole}
            id={dataRole}
            onChange={(event) => {
              onChange(event.target.value);
              setKey(event.target.value);
            }}
            value={key}
            disabled={!hasPermission(requiredPermission)}
          >
            {options.map((optionElement) => (
              <option
                value={optionElement.value}
                data-role={`select-option-${optionElement.value}`}
                key={optionElement.value ?? 'default'}
              >
                {optionElement.displayText}
              </option>
            ))}
          </select>
        </div>
      </div>
      {error !== undefined && <p className="help is-danger">{error}</p>}
    </div>
  );
}
