export interface SupportContactPersonModel {
  id: string;
  companyName?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  telefax?: string;
  email?: string;
  role: string;
  notifyForNewTask: boolean;
}

export interface CreateSupportContactPersonModel {
  companyName?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  telefax?: string;
  email?: string;
  role: string;
  notifyForNewTask: boolean;
}

export const defaultSupportContactPerson: CreateSupportContactPersonModel = {
  companyName: '',
  email: '',
  phoneNumber: '',
  mobileNumber: '',
  telefax: '',
  role: '',
  notifyForNewTask: false,
};
