import { RuleTrigger } from '../../models/operation/AutomationRuleModel';
import { useTranslationText } from '../../translation/TranslationHooks';

interface RuleTriggerAddedProps {
  value?: RuleTrigger;
}

export default function RuleTriggerAdded({ value }: RuleTriggerAddedProps) {
  const { t } = useTranslationText('automations');

  return value ? <div className="added-trigger-item tag">{t(`${value.type}_title`)}</div> : <></>;
}
