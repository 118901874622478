import dayjs from 'dayjs';
import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { useAuthUser } from '../../../auth/UserRoleCheck';
import DateFormats from '../../../models/date-formats/DateFormats';
import { Agent } from '../../../models/monitoring/Agent';
import { useTranslationText } from '../../../translation/TranslationHooks';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import { SwitchInput } from '../../../ui/switch/SwitchInput';
import TextInput from '../../../ui/text-input/TextInput';

interface AgentDetailsFormProps {
  form: UseFormReturn<Agent, object>;
}

export function AgentDetailsForm({ form }: AgentDetailsFormProps) {
  const { t } = useTranslationText('agents');
  const { hasPermission } = useAuthUser();
  const { control, formState } = form;

  const HostNameInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, 'hostname'> }) => (
      <FormFieldWrapper error={formState.errors?.hostname} label={t('hostname')} isRequired>
        <TextInput
          dataRole="agent-hostname"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.hostname}
          readOnly
        />
      </FormFieldWrapper>
    ),
    [formState.errors.hostname, t],
  );

  const AcceptedInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, 'accepted'> }) => (
      <FormFieldWrapper
        error={formState.errors?.accepted}
        label={t('acceptance')}
        isDisabled={!hasPermission(UserResourcePermissions.Agent.Update)}
      >
        <SwitchInput
          checked={field.value}
          onChange={field.onChange}
          disabled={!hasPermission(UserResourcePermissions.Agent.Update)}
          id="accepted"
        />
      </FormFieldWrapper>
    ),
    [formState.errors.accepted, hasPermission, t],
  );

  const PlattformInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, 'plattform'> }) => (
      <FormFieldWrapper error={formState.errors?.plattform} label={t('plattform')} isRequired>
        <TextInput
          dataRole="agent-plattform"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.plattform}
          readOnly
        />
      </FormFieldWrapper>
    ),
    [formState.errors.plattform, t],
  );

  const ArchitectureInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, 'architecture'> }) => (
      <FormFieldWrapper error={formState.errors?.architecture} label={t('architecture')} isRequired>
        <TextInput
          dataRole="agent-architecture"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.architecture}
          readOnly
        />
      </FormFieldWrapper>
    ),
    [formState.errors.architecture, t],
  );

  const IdInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, 'id'> }) => (
      <FormFieldWrapper error={formState.errors?.id} label={t('id')} isRequired>
        <TextInput
          dataRole="agent-id"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.id}
          readOnly
        />
      </FormFieldWrapper>
    ),
    [formState.errors.id, t],
  );

  const LastSeenInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, 'lastSeen'> }) => (
      <FormFieldWrapper error={formState.errors?.lastSeen} isRequired label={t('lastSeen')}>
        <TextInput
          onValueChange={field.onChange}
          value={dayjs(field.value).format(DateFormats.FULLDATE_HOURS_MINS)}
          error={formState.errors?.lastSeen}
          readOnly
        />
      </FormFieldWrapper>
    ),
    [formState.errors.lastSeen, t],
  );

  const UseDefaultMonitoringInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, 'useDefaultMonitoring'> }) => (
      <FormFieldWrapper
        error={formState.errors?.useDefaultMonitoring}
        label={t('useDefaultMonitoringLabel')}
        isDisabled={!hasPermission(UserResourcePermissions.Agent.Update)}
      >
        <SwitchInput
          checked={field.value}
          onChange={field.onChange}
          disabled={!hasPermission(UserResourcePermissions.Agent.Update)}
          id="useDefaultMonitoring"
        />
      </FormFieldWrapper>
    ),

    [formState.errors.useDefaultMonitoring, hasPermission, t],
  );

  return (
    <div className="container">
      <div className="columns">
        <div className="column is-8">
          <Controller name="hostname" control={control} render={HostNameInput} />
        </div>
        <div className="column is-4">
          <Controller name="accepted" control={control} render={AcceptedInput} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-6">
          <Controller name="plattform" control={control} render={PlattformInput} />
        </div>
        <div className="column is-6">
          <Controller name="architecture" control={control} render={ArchitectureInput} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-6">
          <Controller name="id" control={control} render={IdInput} />
        </div>
        <div className="column is-6">
          <Controller name="lastSeen" control={control} render={LastSeenInput} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Controller name="useDefaultMonitoring" control={control} render={UseDefaultMonitoringInput} />
        </div>
      </div>
    </div>
  );
}
