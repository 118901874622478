import { Button } from '@aos/styleguide-react';
import { BulmaColor, BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { useTranslationText } from '../../translation/TranslationHooks';

interface AosExportButtonProps {
  href: string;
  size?: BulmaSize;
  color?: BulmaColor;
  isConfirm?: boolean;
}

export default function AosExportButton({ href, size = 'is-small', color, isConfirm }: AosExportButtonProps) {
  const { t } = useTranslationText('commons');
  return (
    <Button
      renderAs="a"
      type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      download
      href={href}
      size={size}
      icon="file-export"
      color={color}
      isConfirm={isConfirm}
    >
      {t('exportButton')}
    </Button>
  );
}
