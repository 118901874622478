import { Trans } from 'react-i18next';
import { Collapsible } from '@aos/styleguide-react';
import { KnowledgeBaseArticle } from '../../models/knowledge-base/KnowledgeBaseModel';
import { ComponentItem } from '../../models/operation/ComponentModel';
import { useGetKnowledgeBaseArticles } from '../../react-query/KnowledgeBaseApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';

interface ComponentRelatedArticlesProps {
  component: ComponentItem;
  title: string;
  renderElement: (article: KnowledgeBaseArticle) => JSX.Element;
}

export function ComponentRelatedArticles({ component, title, renderElement }: Readonly<ComponentRelatedArticlesProps>) {
  const {
    data: articles = [],
    isLoading: areArticlesLoading,
    isError: isGetArticlesError,
  } = useGetKnowledgeBaseArticles({
    componentDisplayName: component.displayName,
    componentTypeIds: [component.componentTypeId],
    componentIds: [component.id],
    sort: 'created_at:desc',
  });

  return (
    <LoadingSpinner
      isLoading={areArticlesLoading}
      errors={
        isGetArticlesError ? (
          <Trans i18nKey="errorTexts.articlesForComponentLoadingError.text" values={{ id: component.id }} />
        ) : undefined
      }
    >
      <Collapsible
        header={title}
        badgeCount={articles?.length ?? 0}
        isInitiallyCollapsed={articles?.length === undefined || articles?.length === 0}
        className="component-related-articles"
      >
        <div className="component-articles">{articles?.map(renderElement)}</div>
      </Collapsible>
    </LoadingSpinner>
  );
}
