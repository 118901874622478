import { ErrorHandler, ErrorHandlingResult } from './ErrorHandler';
import { useTranslationText } from '../translation/TranslationHooks';
import { createApiErrorResponseHandler } from './ApiError';

type ElementNotFoundExceptionDetails = {
  kind: 'elementNotFound';
  elementType: string;
  id: any;
};

function asElementNotFoundDetails(details: any): ElementNotFoundExceptionDetails | undefined {
  if (details?.kind === 'elementNotFound') {
    return details as ElementNotFoundExceptionDetails;
  }
  return undefined;
}

/**
 * This error handler tries to resolve a more detailed error message for 404 responses by
 * searching for a translation key matching the following pattern:
 *
 * errorTexts.elementNotFound.{resourceKind}.text
 *
 * If it is able to find such a translation, it will replace the customMessage with the rendered translation.
 * As this handler only modifies the error message to be displayed, it never fully resolves the error.
 * Instead, another error handler (like the {@link useToastyErrorHandler}) might choose to display the new error message
 * and thereby resolve the error.
 */
export function useResourceNotFoundErrorHandler(): ErrorHandler {
  const { t } = useTranslationText('errorTexts');
  return createApiErrorResponseHandler((ctx, apiError) => {
    if (ctx.options.customMessage !== '' && ctx.options.customMessage !== undefined) {
      return ErrorHandlingResult.NOT_HANDLED;
    }

    const details = asElementNotFoundDetails(apiError.details);
    if (!details) {
      return ErrorHandlingResult.NOT_HANDLED;
    }

    const key = `${details.kind}.${details.elementType}`;
    const fullKey = `errorTexts.${key}.text`;

    const customMessage = t(key, details);
    if (customMessage !== fullKey) {
      // Only override if there is an error text for this specific error
      ctx.options.customMessage = customMessage;
    }
    return ErrorHandlingResult.NOT_HANDLED;
  });
}
