import { Tag } from '@aos/styleguide-react';
import { TagColor } from '@aos/styleguide-react/dist/common/constants';
import { ComponentStatus } from '../../models/operation/ComponentModel';
import { useTranslationText } from '../../translation/TranslationHooks';

export function componentStatusToColor(status: ComponentStatus | undefined): TagColor {
  switch (status) {
    case 'DISRUPTED':
      return 'is-warning';
    case 'FAILED':
      return 'is-danger';
    case 'HEALTHY':
      return 'is-success';
    case 'UNKNOWN':
      return 'is-default';
    default:
      return 'is-default';
  }
}

interface ComponentStatusTagProps {
  status?: ComponentStatus;
}

export default function ComponentStatusTag({ status }: ComponentStatusTagProps) {
  const { t } = useTranslationText('components');

  return <Tag color={componentStatusToColor(status)}>{status && t(`status-${status.toLocaleLowerCase()}`)}</Tag>;
}
