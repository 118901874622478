import './KnowledgeBaseSearchBar.scss';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface KnowledgeBaseSearchBarProps {
  value: string;
  onChange: (searchValue: string) => void;
  isLoading: boolean;
}

export default function KnowledgeBaseSearchBar({ value, onChange, isLoading }: KnowledgeBaseSearchBarProps) {
  const { t } = useTranslationText('knowledgeBases');

  return (
    <div className="knowledge-base-search-bar">
      <div className="search-bar field has-addons">
        <div
          className={classNames('control is-medium has-icons-left is-fullwidth', {
            'is-loading': isLoading,
          })}
        >
          <input
            className="input is-medium"
            type="text"
            placeholder={t('searchbarPlaceholder')}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            data-role="knowledge-base-search-input"
            autoFocus
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
        <div className="control">
          {value.length > 0 && (
            <button type="button" className="clear-btn" onClick={() => onChange('')}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
