import { useAuth } from 'react-oidc-context';
import UserInfo from '../ui/user-info/UserInfo';
import './User.scss';
import { useTranslationText } from '@/translation/TranslationHooks';
import useRuntimeConfig from '../runtime-config/RuntimeConfig';
import { useGetUserInfo } from '@/react-query/GetUserSettings';
import { signOut } from '@/utils/Authentication';
import Changelog from '../changelog/Changelog';

export default function User() {
  const auth = useAuth();
  const { data: userInfo } = useGetUserInfo();
  const { t } = useTranslationText('configurations');
  const [runtimeConfig, isLoadingConfig] = useRuntimeConfig();

  if (!auth.user) {
    signOut(auth);
  }

  return (
    <div className="user-info-page">
      <div className="profile">
        <div className="profile-image">
          <UserInfo
            email={userInfo?.email}
            user={{
              id: userInfo?.id ?? '',
              email: userInfo?.email ?? '',
              firstName: userInfo?.firstName ?? '',
              lastName: userInfo?.lastName ?? '',
              username: userInfo?.username ?? '',
            }}
          />
        </div>
        <div className="profile-data">
          <div className="name">{userInfo?.fullName}</div>
          <div className="contact">
            <div>{userInfo?.email && `${userInfo?.email} - `}</div>
            <div>{userInfo?.mobilePhone ?? t('mobilePhoneNotConfigured')}</div>
          </div>
          {!isLoadingConfig && runtimeConfig && (
            <div>
              <a href={`${runtimeConfig.oauthAuthority}/account`} target="_blank" rel="noreferrer">
                {t('MANAGE_ACCOUNT')}
              </a>
            </div>
          )}
        </div>
      </div>

      <div className="changelog-outer-container">
        <div className="changelog-inner-container">
          <Changelog />
        </div>
      </div>
    </div>
  );
}
