import * as yup from 'yup';
import { useTranslationText } from './TranslationHooks';

interface YupLocalProperties {
  path: string;
  max: number;
}

export type YupType = typeof yup;

export default function useYupLocal() {
  const { t } = useTranslationText('errorTexts');

  const mixed = {
    default: `${t('fieldInvalid')}`,
    required: `${t('required')}`,
  };

  const string = {
    max: ({ path, max }: YupLocalProperties) => `${t(path)} ${t('notGreaterThan')} ${max} ${t('charactersLong')}`,
  };

  yup.setLocale({ string, mixed });

  return { yup };
}
