import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import dayjs from 'dayjs';
import DateFormats from '../../../models/date-formats/DateFormats';
import { useTranslationText } from '../../../translation/TranslationHooks';
import './TimeSlotPickerItem.scss';

interface TimeSlotPickerItemProps {
  startOfAction: Date;
  endOfAction: Date;
  index?: number;
  isChecked: boolean;
  updateSelectedTimeSlot: (startDate: Date, endDate: Date, index?: number) => void;
}

export function TimeSlotPickerItem({
  startOfAction,
  endOfAction,
  index,
  isChecked,
  updateSelectedTimeSlot,
}: TimeSlotPickerItemProps) {
  const { t } = useTranslationText('maintenances');
  return (
    <div
      className="time-slot-picker-item"
      onClick={() => {
        updateSelectedTimeSlot(startOfAction, endOfAction, index);
      }}
    >
      <FontAwesomeIcon
        icon={isChecked ? faCheckCircle : faCircle}
        className={classNames('checkbox is-font-medium', {
          isChecked,
        })}
      />
      <div>
        {dayjs(startOfAction).format(DateFormats.FULLDATE_HOURS_MINS)} -{' '}
        {dayjs(endOfAction).format(DateFormats.FULLDATE_HOURS_MINS)}
      </div>
      <div>{index !== undefined ? `${index + 1}. ${t('alternativeTime')}` : ''} </div>
    </div>
  );
}
