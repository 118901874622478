import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { BaseMaintenance } from '../../../models/maintenance/Maintenance';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { ContactPersonForm } from './ContactPersonForm';
import { CollapsibleWithButtons } from '../../../ui/collapsible/CollapsibleWithButtons';

interface ContactPersonFormCollapisbleProps {
  index: number;
  form: UseFormReturn<BaseMaintenance>;
  readonly: boolean;
}

export function ContactPersonFormCollapisble({
  index,
  form,
  readonly: readOnly,
}: Readonly<ContactPersonFormCollapisbleProps>) {
  const { t } = useTranslationText('maintenances');
  const { t: tCommon } = useTranslationText('commons');
  const { control } = form;

  const { append, fields, remove } = useFieldArray({ control, name: `involvedContractors.${index}.contactPersons` });

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('contactPersons')}
      badgeCount={fields.length}
      addBtnClicked={() =>
        append({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        })
      }
      addPermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
      addButtonDisabled={readOnly}
    >
      {fields.length === 0 && tCommon('noItemsFoundAdd')}
      {fields.map((field, contactPersonIndex: number) => (
        <ContactPersonForm
          key={field.id}
          contractorIndex={index}
          contactPersonIndex={contactPersonIndex}
          onRemove={() => remove(contactPersonIndex)}
          form={form}
          readonly={readOnly}
        />
      ))}
    </CollapsibleWithButtons>
  );
}
