import { useState } from 'react';
import ArticleCommentListing from './article-comment-listing/ArticleCommentListing';
import { useGetKnowledgeBaseArticleComments } from '../../../react-query/KnowledgeBaseApi';
import { useTranslationText } from '../../../translation/TranslationHooks';
import UserRoleCheck from '../../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { CreateArticleComment } from './create-article-comment/CreateArticleComment';
import { KnowledgeBaseArticleComment } from '../../../models/knowledge-base/KnowledgeBaseModel';

interface ArticleCommentsTabProps {
  articleId: string;
  comments: KnowledgeBaseArticleComment[];
}

export default function ArticleCommentsOverview({ articleId, comments }: ArticleCommentsTabProps) {
  const { t: tError } = useTranslationText('errorTexts');
  const [enableSeparateCommentFetching, setEnableSeparateCommentFetching] = useState(false);

  const {
    data: fetchedComments = [],
    isLoading: commentsAreLoading,
    isError,
    refetch,
  } = useGetKnowledgeBaseArticleComments(articleId, {
    enabled: enableSeparateCommentFetching,
  });

  return (
    <div className="comments-tab">
      <UserRoleCheck requiredPermission={UserResourcePermissions.ArticleComment.Create}>
        <CreateArticleComment
          articleId={articleId}
          onSubmit={() => {
            setEnableSeparateCommentFetching(true);
            void refetch();
          }}
        />
      </UserRoleCheck>
      {comments && (
        <ArticleCommentListing
          comments={enableSeparateCommentFetching ? fetchedComments : comments}
          isLoading={commentsAreLoading}
          error={isError ? tError('404_comments') : undefined}
        />
      )}
    </div>
  );
}
