import SingleTicketSelect, { SingleTicketSelectProps } from '@/ticketing/ticket-selects/SingleTicketSelect';
import { SelectOption, SelectPaginationAdditional } from '@/ui/select/SelectUtils';
import { Ticket } from '@/models/operation/TicketModel';
import { mapTicketToDisableableOption } from '@/ticketing/ticket-relations/create-ticket-relation-menu/single-ticket-select-with-disableable-options/SingleTicketSelectWithDisableableOptionsUtils';
import { Response } from 'react-select-async-paginate';

interface SingleTicketSelectWithDisableableOptionsProps extends SingleTicketSelectProps {
  ticketAvailableForRelation: (ticketId: number) => boolean;
}

function SingleTicketSelectWithDisableableOptions({
  excludeTickets,
  ticketAvailableForRelation,
  ...props
}: SingleTicketSelectWithDisableableOptionsProps) {
  function mapLoadOptions(loadOptions: Response<SelectOption<Ticket>, never, SelectPaginationAdditional>) {
    return {
      ...loadOptions,
      options: loadOptions.options.map((option) => ({
        ...option,
        isDisabled: ticketAvailableForRelation ? !ticketAvailableForRelation(option.payload.id) : undefined,
      })),
    };
  }

  return (
    <SingleTicketSelect
      customMapTicketToOption={mapTicketToDisableableOption}
      loadOptionMapper={mapLoadOptions}
      excludeTickets={excludeTickets}
      {...props}
    />
  );
}

export default SingleTicketSelectWithDisableableOptions;
