import { Control, Field, Label, TextInput } from '@aos/styleguide-react';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { useTranslationText } from '../../translation/TranslationHooks';
import { TicketQuery } from '../../models/operation/TicketQuery';

interface TitleFilterProps {
  label?: string;
  value?: string | null;
  onChange: (name: keyof TicketQuery, value: string) => void;
  size?: BulmaSize;
}

export default function TitleFilter({ label, value, onChange, size = 'is-small' }: TitleFilterProps) {
  const { t } = useTranslationText('tickets');

  return (
    <Field>
      {label && (
        <Label htmlFor={label} size={size}>
          {label}
        </Label>
      )}
      <Control rightIcon="search" size={size}>
        <TextInput
          value={value ?? ''}
          placeholder={t('titleFilterPlaceholder')}
          onChange={(e) => onChange('title', e.target.value)}
          size={size}
        />
      </Control>
    </Field>
  );
}
