import dayjs from 'dayjs';
import DateFormats from '../../models/date-formats/DateFormats';
import { Maintenance } from '../../models/maintenance/Maintenance';
import { UserModel } from '../../models/operation/UserModel';
import { Page } from '../../models/pagination/Pagination';
import NavigationRoutes from '../../routing/NavigationRoutes';
import LinkTableCell from '../../ui/link-table-cell/LinkTableCell';
import { formatDurationInMinutesToHours } from '../../utils/dateFormatting';

interface MaintenanceRequestRowProps {
  maintenance: Maintenance;
  users: Page<UserModel> | undefined;
  isGetUserError: boolean;
}

export default function MaintenanceRequestRow({ maintenance, users, isGetUserError }: MaintenanceRequestRowProps) {
  const createdBy = users?.content.find((user) => user.id === maintenance.author?.id ?? '');
  const startDate = dayjs(maintenance.startOfAction);
  const endDate = dayjs(maintenance.endOfAction);
  const duration = formatDurationInMinutesToHours(endDate.diff(startDate, 'minutes'));
  const diff = startDate.diff(new Date(), 'hours');
  return (
    <tr key={maintenance.id} className={diff >= 0 && diff <= 24 ? 'starts-soon' : ''}>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {maintenance.actionNumber}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {maintenance.title}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {!isGetUserError ? `${createdBy?.firstName || ''} ${createdBy?.lastName || ''}` : '?'}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {dayjs(maintenance.startOfAction).format(DateFormats.FULLDATE_HOURS_MINS)}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>{duration}</LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {dayjs(maintenance.createdAt).format(DateFormats.FULLDATE_HOURS_MINS)}
      </LinkTableCell>
    </tr>
  );
}
