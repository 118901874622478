import { ErrorHandler, ErrorHandlingResult } from './ErrorHandler';
import { useResourceNotFoundErrorHandler } from './ElementNotFound';
import { useUnauthorizedErrorHandler } from './UnauthorizedErrorHandler';
import { useToastyErrorHandler } from './ToastyErrorHandler';

/**
 * The default error handling tries to resolve errors using the following handling priority:
 * 1. Unauthorized redirect ({@link useUnauthorizedErrorHandler})
 * 2. Resolving specific resource not found error messages ({@link useResourceNotFoundErrorHandler})
 * 3. Displaying an error toasty ({@link useToastyErrorHandler})
 */
export function useDefaultErrorHandling(): ErrorHandler {
  const resourceNotFoundErrorHandler = useResourceNotFoundErrorHandler();
  const unauthorizedErrorHandler = useUnauthorizedErrorHandler();
  const toastyErrorHandler = useToastyErrorHandler();

  const handlers: ErrorHandler[] = [unauthorizedErrorHandler, resourceNotFoundErrorHandler, toastyErrorHandler];

  return (e) => {
    for (let i = 0; i < handlers.length; i += 1) {
      const handler = handlers[i];
      if (handler(e) === ErrorHandlingResult.HANDLED) {
        // Stop trying to use the next error handler as soon as
        // the first error handler was able to resolve it.
        return ErrorHandlingResult.HANDLED;
      }
    }
    return ErrorHandlingResult.NOT_HANDLED;
  };
}
