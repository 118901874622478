import { SingleValue } from 'react-select';
import { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import { Contract } from './ContractManagementModel';
import { ContractRole } from './ContractRole';
import { TTranslateFn } from '../../translation/TranslationHooks';
import { Contractor } from './ContractorModel';

export const mapContractItemToOption = (contract: Contract | null): SingleValue<ReactSelectOption> | null => ({
  label: contract ? `${contract?.internalNumber}, ${contract?.contractor.companyName}` : '',
  value: contract?.id ?? '',
});

export const mapContractItemsToOptions = (contracts: readonly Contract[] | undefined) =>
  contracts?.map((contract) => ({
    label: contract ? `${contract.internalNumber} ${contract.contractor.companyName}` : '',
    value: contract.id ?? '',
  })) ?? [];

export const mapContractRolesToOptions = (t: TTranslateFn, contractRoles?: ContractRole[] | undefined) =>
  contractRoles?.map((element) => ({
    value: element.key,
    label: t(element.translationKey),
  })) ?? [];

export const mapContractorsToOptions = (contractors: readonly Contractor[] | undefined) =>
  contractors?.map((contractor) => ({
    label: contractor?.companyName ?? '',
    value: contractor?.companyName ?? '',
  })) ?? [];
