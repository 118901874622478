import queryString from 'query-string';
import { Maintenance } from '../models/maintenance/Maintenance';
import { MaintenanceQuery } from '../models/maintenance/MaintenanceQuery';
import { Page } from '../models/pagination/Pagination';
import { QueryOptions, useGetQuery } from './GetQuery';
import { MonitoringApiPrefix } from './MonitoringApi';
import ServerStateKeysEnum from './ServerStateKeysEnum';

export function useGetAllMaintenanceRequests(
  options?: QueryOptions<Page<Maintenance>>,
  query?: Partial<MaintenanceQuery>,
) {
  const queryParams = query ? `?${queryString.stringify(query)}` : '';
  const path = `${MonitoringApiPrefix}/maintenance${queryParams}`;
  return useGetQuery<Page<Maintenance>>([ServerStateKeysEnum.MaintenanceRequest, query], path, options);
}

export function useGetMaintenanceRequest(maintenanceRequestId: string, options?: QueryOptions<Maintenance>) {
  return useGetQuery<Maintenance>(
    [ServerStateKeysEnum.MaintenanceRequest, maintenanceRequestId],
    `${MonitoringApiPrefix}/maintenance/${maintenanceRequestId}`,
    options,
  );
}
