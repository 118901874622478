import { TaskQuery } from '../../models/tasks/TaskQuery';
import { useTranslationText } from '../../translation/TranslationHooks';
import { SwitchInput } from '../switch/SwitchInput';
import FormFieldWrapper from '../form-field-wrapper/FormFieldWrapper';

interface OpenQuestionFilterProps {
  isChecked: boolean;
  onChange: (name: keyof TaskQuery, value: any) => void;
  secondLabel?: string;
}

export default function OpenQuestionFilter({ isChecked = false, onChange, secondLabel }: OpenQuestionFilterProps) {
  const { t } = useTranslationText('tickets');
  return (
    <FormFieldWrapper label={t('hasOpenQuestionsLabel')} size="is-small">
      <SwitchInput
        id="highlighedTicketsFilter"
        checked={isChecked}
        onChange={(value) => onChange('hasOpenQuestions', value || undefined)}
        secondLabel={secondLabel}
        isSmall
      />
    </FormFieldWrapper>
  );
}
