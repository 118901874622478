import { UseQueryOptions, useQuery, QueryKey } from '@tanstack/react-query';
import { useEffect } from 'react';
import useCustomFetch from './CustomFetch';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { useTranslationText } from '../translation/TranslationHooks';
import TenantEnum from '../user/tenant-context/TenantEnum';
import { useCurrentTenant } from '../user/tenant-context/CurrentTenantContext';
import { FetchError } from './FetchError';
import { ErrorContext } from '../error/ErrorHandler';
import { useDefaultErrorHandling } from '../error/DefaultErrorHandling';

type ServerStateQueryKey = [ServerStateKeysEnum, ...Array<unknown>] | ServerStateKeysEnum | TenantEnum;

export type QueryOptions<ResponseBodyType> = Omit<
  UseQueryOptions<ResponseBodyType, FetchError>,
  'queryFn' | 'queryKey'
> & { suppressErrorToast?: boolean; customErrorMessage?: string };

export function useGetQuery<ResponseBodyType>(
  queryKey: ServerStateQueryKey,
  path: string,
  options?: QueryOptions<ResponseBodyType>,
) {
  const errorHandler = useDefaultErrorHandling();
  const { t } = useTranslationText('errorTexts');
  const customFetch = useCustomFetch(t);

  const { currentTenant } = useCurrentTenant();

  const wrappedQueryKey = Array.isArray(queryKey) ? queryKey : [queryKey];

  wrappedQueryKey.push(currentTenant);

  const queryResult = useQuery({
    queryKey: wrappedQueryKey as QueryKey,
    queryFn: async () => {
      const response = await customFetch(path);
      return (await response) as ResponseBodyType;
    },
    ...options,
  });

  useEffect(() => {
    if (queryResult.isError) {
      const errorContext: ErrorContext = {
        error: queryResult.error as FetchError,
        options: {
          suppressErrorToast: options?.suppressErrorToast ?? false,
          customMessage: options?.customErrorMessage,
        },
      };
      errorHandler(errorContext);
    }
  }, [queryResult.isError, queryResult.error, errorHandler, options?.suppressErrorToast, options?.customErrorMessage]);

  return queryResult;
}
