import { Button, Navigation as AosNavigation } from '@aos/styleguide-react';
import { useEffect, useState } from 'react';
import navItems from '../../routing/NavigationData';
import { useTranslationText } from '../../translation/TranslationHooks';
import { Environment, useGetChangelog } from '../../react-query/GetChangelogApi';
import './Navigation.scss';
import NavItems from './navigation-components/NavItems';

export default function Navigation() {
  const FEEDBACK_FORM_LINK =
    'https://forms.office.com/pages/responsepage.aspx?id=sQlKIMBGj0eQ097o5rHXh8pIqEquiMlGu79civPOiXdUNkM4RU44V1RGTDVCMlpUWEsyMFU1RzQyNi4u';

  const { t } = useTranslationText('navigations');
  const { data: { environment } = { environment: 'default' } } = useGetChangelog();
  const [isCollapsed, setIsCollapsed] = useState(false);
  function toggleCollapsed() {
    setIsCollapsed((prevState) => !prevState);
  }

  useEffect(() => {
    document.querySelector('.navigation-collapse-button')?.addEventListener('click', toggleCollapsed);

    return () => document.querySelector('.navigation-collapse-button')?.removeEventListener('click', toggleCollapsed);
  }, []);

  return (
    <>
      <AosNavigation
        top={<NavItems navItems={navItems} />}
        bottom={
          environment === Environment.Demo && (
            <div className="feedback-button-container">
              <Button
                className="feedback-button"
                renderAs="a"
                href={FEEDBACK_FORM_LINK}
                target="_blank"
                color="is-danger"
                isConfirm
                isFullwidth
                icon="comment"
                size="is-small"
              >
                {isCollapsed ? '' : t('feedback')}
              </Button>
            </div>
          )
        }
      />
    </>
  );
}
