import queryString from 'query-string';
import { Response } from 'react-select-async-paginate';
import { Dispatch, SetStateAction } from 'react';
import { Ticket } from '@/models/operation/TicketModel';
import { OptionLabelBaseProps, SelectOption, SelectPaginationAdditional } from '@/ui/select/SelectUtils';
import { DEFAULT_PAGE_SIZE_DROPDOWN } from '@/models/pagination/Pagination';
import { TicketSort } from '@/models/operation/TicketQuery';
import { TicketFilterQuery, TicketingApiPrefix } from '@/react-query/TicketingApi';
import TicketStateTag from '../ticket-state-tag/TicketStateTag';
import TicketSeverityIcon from '../ticket-severity-icon/TicketSeverityIcon';
import './TicketSelect.scss';
import { useTranslationText } from '@/translation/TranslationHooks';

export function mapTicketToOption(ticket: Ticket): SelectOption<Ticket> {
  return {
    value: ticket.id.toString(),
    label: ticket.title,
    payload: ticket,
  };
}

export function mapTicketsToOptions(tickets: Ticket[]): SelectOption<Ticket>[] {
  return tickets.map((ticket) => mapTicketToOption(ticket));
}

export async function loadTicketsOptions(
  search: string,
  { page }: SelectPaginationAdditional,
  setIsError: Dispatch<SetStateAction<boolean>>,
  customFetch: (path: string, init?: RequestInit | undefined) => Promise<any>,
  excludeTickets: number[] = [],
  pageSize: number = DEFAULT_PAGE_SIZE_DROPDOWN,
): Promise<Response<SelectOption<Ticket>, never, SelectPaginationAdditional>> {
  setIsError(false);

  if (search === '') {
    return { options: [] };
  }

  const filter: TicketFilterQuery = {
    title: search,
    excludeTickets,
    page,
    size: pageSize,
    sort: TicketSort.CreatedAtDesc,
  };
  const path = `${TicketingApiPrefix}/ticket?${queryString.stringify(filter)}`;

  const ticketItemPage = await customFetch(path);

  if (ticketItemPage === null) {
    setIsError(true);
    return { options: [] };
  }

  return {
    options: mapTicketsToOptions(ticketItemPage.content),
    hasMore: !ticketItemPage.last,
    additional: { page: page + 1 },
  };
}

export function TicketOptionLabel({ payload, isOption }: OptionLabelBaseProps<Ticket>) {
  const { t } = useTranslationText('tickets');

  return isOption ? (
    <div className="ticket-select-option-label">
      <div className="ticket-select-option-label-left">
        <TicketSeverityIcon className="ticket-severity-icon" severity={payload.severity} />
        <span className="ticket-id-cell">{payload.id}</span>
        <span className="ticket-name-cell" title={payload.title}>
          {payload.title}
        </span>
      </div>
      <div className="ticket-select-option-label-right">
        <TicketStateTag className="ticket-state-tag" state={payload.state} t={t} />
      </div>
    </div>
  ) : (
    <span>{payload.title}</span>
  );
}
