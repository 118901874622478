import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import useDebounceForDependency from '../../../custom-hooks/useDebounceForDependency';

interface SearchBarProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  isLoading: boolean;
}

export function SearchBar({ isLoading, ...props }: SearchBarProps) {
  return (
    <div
      className={classNames('control has-icons-left search-input-wrapper is-flex-grow-1', {
        'is-loading': isLoading,
      })}
    >
      <input
        {...props}
        className={`input search-input ${props.className ?? ''}`}
        autoComplete={props.autoComplete ?? 'off'}
        type={props.type ?? 'text'}
      />

      <span className="icon is-left">
        <FontAwesomeIcon icon={faSearch} />
      </span>
    </div>
  );
}

interface DebouncedSearchBarProps extends Omit<SearchBarProps, 'onChange' | 'value'> {
  onChangeDebounced: (newValue: string) => void;
  value: string;
}

export function DebouncedSearchBar({ onChangeDebounced, value, ...props }: DebouncedSearchBarProps) {
  const [input, setInput] = useState(value);
  useEffect(() => {
    setInput(value);
  }, [value]);
  const isDebouncing = useDebounceForDependency(() => {
    onChangeDebounced(input);
  }, [input]);

  return (
    <SearchBar
      {...props}
      isLoading={props.isLoading || isDebouncing}
      onChange={(e) => setInput(e.target.value)}
      value={input}
    />
  );
}
