import { useId, useMemo } from 'react';
import classNames from 'classnames';
import { ComponentStatus } from '../../models/operation/ComponentModel';
import './descendentComponentStatusBar.scss';
import { useTranslationText } from '../../translation/TranslationHooks';
import { getComponentStatusBarItems } from './DescendentComponentStatusBar.model';
import { DescendentComponentStats } from '../../models/operation/ComponentQuery';
import Tooltip from '../../ui/tooltip/Tooltip';

interface DescendentComponentStatusBarItemProps {
  percentage: number;
  width: number;
  status: ComponentStatus;
}

function DescendentComponentStatusBarItem({ percentage, status, width }: DescendentComponentStatusBarItemProps) {
  const text = percentage > 0 ? `${percentage}%` : '';

  return (
    <div className={classNames('bar-item', status.toLowerCase())} style={{ width: `${width}%` }}>
      <span>{text}</span>
    </div>
  );
}

interface DescendentComponentStatusBarProps {
  readonly descendentComponentStats: DescendentComponentStats;
}

export function DescendentComponentStatusBar({ descendentComponentStats }: DescendentComponentStatusBarProps) {
  const anchorId = useId();
  const { healthy, disrupted, failed, unknown } = descendentComponentStats;
  const { t } = useTranslationText('components');
  const componentStatusBarItems = useMemo(
    () => getComponentStatusBarItems(healthy, disrupted, failed, unknown),
    [disrupted, failed, healthy, unknown],
  );

  function getToolTipText() {
    return `${t(`status-healthy`)}: ${healthy}; 
    ${t(`status-disrupted`)}: ${disrupted}; 
    ${t(`status-failed`)}: ${failed};
    ${t(`status-unknown`)}: ${unknown}`;
  }

  if (healthy === 0 && disrupted === 0 && failed === 0) {
    return <></>;
  }

  return (
    <>
      <div className="descendent-component-status-bar" id={anchorId}>
        {componentStatusBarItems.map((stat) => (
          <DescendentComponentStatusBarItem
            status={stat.key}
            width={stat.width}
            key={stat.key}
            percentage={stat.percentage}
          />
        ))}
      </div>
      <Tooltip anchorId={anchorId} content={getToolTipText()} />
    </>
  );
}
