import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import DateFormats from '../models/date-formats/DateFormats';

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(localizedFormat);

export function formatDateDifferenceFromNow(date: Date | string) {
  return dayjs(date).fromNow();
}

export function formatDateAndTime(date: Date | string) {
  return dayjs(date).format(DateFormats.FULLDATE_HOURS_MINS);
}

export function formatDurationInMinutesToHours(durationInMinutes: number): string {
  const dayjsDuration = dayjs.duration(durationInMinutes, 'minutes');
  const nbDays = dayjsDuration.get('day');
  const nbHours = dayjsDuration.get('hour');
  const dynamicFormats = [!!nbDays && 'D [Tage]', (!!nbHours || !!nbDays) && 'H [Stunden]', 'm [Minuten]']
    .filter(Boolean)
    .join(' ');
  return dayjsDuration.format(dynamicFormats);
}
