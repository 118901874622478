import React, { useCallback, useMemo, useState } from 'react';

export interface DropZoneContextInterface {
  isDropZoneEnabled: boolean;
  enableDropZone: () => void;
  disableDropZone: () => void;
  clearDroppedFiles: () => void;
  droppedFiles?: File[];
  onDrop: (fileList: File[]) => void;
}

export const DropZoneContext = React.createContext<DropZoneContextInterface>({
  isDropZoneEnabled: false,
  enableDropZone: () => {},
  clearDroppedFiles: () => {},
  disableDropZone: () => {},
  droppedFiles: [],
  onDrop: () => {},
});

export function DropZoneProvider({ children }: any) {
  const initState = {
    isDropZoneEnabled: false,
  } as DropZoneContextInterface;

  const [state, setState] = useState<DropZoneContextInterface>(initState);

  const onDrop = (droppedFiles: File[]) => {
    setState((prevState) => ({
      ...prevState,
      droppedFiles,
    }));
  };

  const enableDropZone = useCallback(() => {
    if (!state.isDropZoneEnabled) {
      setState((prevState) => ({
        ...prevState,
        isDropZoneEnabled: true,
      }));
    }
  }, [state.isDropZoneEnabled]);

  const disableDropZone = useCallback(() => {
    if (state.isDropZoneEnabled) {
      setState((prevState) => ({
        ...prevState,
        isDropZoneEnabled: false,
        droppedFiles: [],
      }));
    }
  }, [state.isDropZoneEnabled]);

  const clearDroppedFiles = useCallback(() => {
    if (state.isDropZoneEnabled) {
      setState((prevState) => ({
        ...prevState,
        droppedFiles: [],
      }));
    }
  }, [state.isDropZoneEnabled]);

  const providerValue = useMemo(
    () => ({
      ...state,
      enableDropZone,
      disableDropZone,
      onDrop,
      clearDroppedFiles,
    }),
    [clearDroppedFiles, disableDropZone, enableDropZone, state],
  );

  return <DropZoneContext.Provider value={providerValue}>{children}</DropZoneContext.Provider>;
}
