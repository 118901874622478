import Highlighter from 'react-highlight-words';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { sortComponentTypeDesc } from '../../../models/operation/ComponentTypeFunctions';
import { BaseComponentType } from '../../../models/operation/ComponentTypeModel';

interface ArticleComponentTypeViewProps {
  componentTypes: BaseComponentType[];
}

export function ArticleComponentTypeView({ componentTypes }: ArticleComponentTypeViewProps) {
  const { t } = useTranslationText('knowledgeBases');

  if (componentTypes.length === 0) {
    return <></>;
  }

  return (
    <div className="mb-1">
      <label className="label" htmlFor={t('componentType')}>
        {t('componentType')}
      </label>

      <ul className="is-inline ml-0">
        {sortComponentTypeDesc(componentTypes).map((componentType) => (
          <li key={componentType.id} className="tag is-light mr-1" data-role={`tag-${componentType.name}`}>
            <Highlighter
              highlightClassName="highlight"
              searchWords={[]}
              autoEscape
              textToHighlight={componentType.name}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
