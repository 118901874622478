import './DescendentComponentStatusTags.scss';
import { Tag } from '@aos/styleguide-react';
import { useTranslationText } from '../../translation/TranslationHooks';
import { DescendentComponentStats } from '../../models/operation/ComponentQuery';

export interface ChildComponentStatusProps {
  readonly descendentComponentStats: DescendentComponentStats | undefined;
}

export function DescendentComponentStatusTags({ descendentComponentStats }: ChildComponentStatusProps) {
  const { t } = useTranslationText('components');
  return (
    <div className="descendent-component-status-tags">
      <Tag color="is-success">
        {t(`status-healthy`)}: {descendentComponentStats?.healthy ?? 'n/a'}
      </Tag>
      <Tag color="is-warning">
        {t(`status-disrupted`)}: {descendentComponentStats?.disrupted ?? 'n/a'}
      </Tag>
      <Tag color="is-danger">
        {t(`status-failed`)}: {descendentComponentStats?.failed ?? 'n/a'}
      </Tag>
      <Tag color="is-secondary">
        {t(`status-unknown`)}: {descendentComponentStats?.unknown ?? 'n/a'}
      </Tag>
    </div>
  );
}
