import { RelationSpec } from '@/models/operation/RelationTypeModel';
import { SelectOption } from '@/ui/select/SelectUtils';

export function mapRelationSpecToDisableableOption(
  relationSpec: RelationSpec,
  relationAvailable?: (relationSpec: RelationSpec) => boolean,
): SelectOption<RelationSpec> {
  return {
    value: relationSpec.identifier,
    label: relationSpec.identifier,
    payload: relationSpec,
    isDisabled: relationAvailable ? !relationAvailable(relationSpec) : undefined,
  };
}

export function mapRelationSpecToDisableableOptions(
  relationSpecs: RelationSpec[],
  relationAvailable: (relationSpec: RelationSpec) => boolean,
): SelectOption<RelationSpec>[] {
  return relationSpecs.map((relationSpec) => mapRelationSpecToDisableableOption(relationSpec, relationAvailable));
}
