import { MultiValue } from 'react-select';
import useGetAllUsers from '../../../react-query/getUsers';
import { useTranslationText } from '../../../translation/TranslationHooks';
import MultiSelectDropdown from '../../../ui/multi-select-dropdown/MultiSelectDropdown';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import { RuleCondition } from '../../../models/operation/AutomationRuleModel';
import { GenericConditionConfigurationProps } from './GenericConditionConfigurationProps';
import { mapUsersToSimpleOptions } from '../../../models/operation/TicketFunctions';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';

interface HasAssigneeConfiguration extends RuleCondition {
  type: 'hasAssignee';
  userIds: string[];
}

export default function HasAssigneeCondition({
  condition,
  handleConfigurationUpdate,
  onClear,
}: GenericConditionConfigurationProps<HasAssigneeConfiguration>) {
  const { t: tTicket } = useTranslationText('tickets');

  const { data: usersData, isLoading, isError, setUserQuery } = useGetAllUsers();

  const handleChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    if (selectedOptions.length > 0) {
      handleConfigurationUpdate({
        type: 'hasAssignee',
        userIds: selectedOptions.map((option) => option.value),
      } as HasAssigneeConfiguration);
    } else {
      onClear();
    }
  };

  const selectedOptions = mapUsersToSimpleOptions(
    usersData?.content.filter((user) => condition?.userIds.includes(user.id)),
  );

  return (
    <FormFieldWrapper label={tTicket('assignedUsersConditionLabel')} isLoading={isLoading}>
      <MultiSelectDropdown
        isError={isError}
        isLoading={isLoading}
        onChange={handleChange}
        onInputChange={setUserQuery}
        options={mapUsersToSimpleOptions(usersData?.content)}
        openMenuOnClick
        placeholder={tTicket('assignUser')}
        mappedValues={selectedOptions}
      />
    </FormFieldWrapper>
  );
}
