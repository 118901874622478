import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { Control, Field, Label, TextArea as AosTextArea } from '@aos/styleguide-react';
import TextAreaLabel from '../text-area-label/TextAreaLabel';
import Editable, { EditableInputProps, EditingComponentProps } from './Editable';
import './EditableTextArea.scss';

function EditingTextAreaComponent({
  error,
  isEdited,
  isLoading,
  label,
  onCancel,
  onChange,
  onAcceptNewValue,
  placeholder,
  value,
  dataRole,
}: EditingComponentProps<string | undefined>) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    textAreaRef.current?.focus();
    textAreaRef.current?.setSelectionRange(textAreaRef.current.value.length, textAreaRef.current.value.length);
  }, []);

  return (
    <Field>
      {label && <Label htmlFor={label}>{label}</Label>}
      <Control>
        <AosTextArea
          className={classNames('editable-textarea', {
            'is-warning': isEdited,
          })}
          isError={error !== undefined}
          tabIndex={0}
          id={label}
          onChange={(e) => {
            if (onChange !== undefined) {
              onChange(e.target.value);
            }
          }}
          onBlur={() => {
            if (onAcceptNewValue) {
              onAcceptNewValue();
            }
          }}
          placeholder={placeholder}
          ref={textAreaRef}
          value={value}
          data-role={dataRole}
        />
      </Control>
      <Field className="is-grouped mt-2">
        <Control className="is-full-height" tabIndex={-1}>
          <button
            className={classNames('button', 'is-primary', {
              'is-loading': isLoading,
              'is-warning': isEdited,
            })}
            disabled={isLoading}
            onMouseDown={(e) => {
              e.preventDefault();
              if (onAcceptNewValue) {
                onAcceptNewValue();
              }
            }}
            type="submit"
            tabIndex={-1}
            data-role="save-button"
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </Control>
        <Control className="is-full-height">
          <button
            className="button"
            disabled={isLoading}
            onMouseDown={(e) => {
              e.preventDefault();
              onCancel();
            }}
            tabIndex={-1}
            type="button"
            data-role="cancel-button"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Control>
      </Field>
    </Field>
  );
}

export default function EditableTextArea({
  error,
  isLoading,
  label,
  placeholder,
  onAcceptNewValue,
  onChange,
  value,
  requiredPermission,
  dataRole,
}: EditableInputProps<string | undefined>) {
  return (
    <Editable<string | undefined>
      displayComponent={TextAreaLabel}
      editingComponent={EditingTextAreaComponent}
      error={error}
      dataRole={dataRole}
      isLoading={isLoading}
      label={label}
      placeholder={placeholder}
      onAcceptNewValue={onAcceptNewValue}
      onChange={onChange}
      value={value}
      requiredPermission={requiredPermission}
    />
  );
}
