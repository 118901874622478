import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { ComponentType } from '../../models/operation/ComponentTypeModel';
import { MutationKey, MutationPath, useUploadMutation } from '../../react-query/MutationQueries';
import { useTranslationText } from '../../translation/TranslationHooks';
import './ImportComponentType.scss';

export default function ImportComponentType() {
  const { t } = useTranslationText('componentTypes');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');

  const navigate = useNavigate();

  const { register, handleSubmit, watch } = useForm<ComponentTypeImportFormModel>({
    mode: 'onSubmit',
  });

  const { mutate, isPending } = useUploadMutation<FormData, ComponentType>(MutationKey.ImportComponentType, {
    onSuccess: () => {
      navigate(-1);
      toast.success(tSuccess('createComponent'));
    },
    onError: () => {
      toast.error(tError('createComponentError'));
    },
  });

  const importFile = watch('files');

  const onSubmit = (ctim: ComponentTypeImportFormModel) => {
    if (ctim.files?.item(0) !== undefined) {
      const body = new FormData();
      body.append('file', ctim.files[0]);
      mutate({ body, path: MutationPath.ImportComponentType });
    }
  };

  return (
    <div className="import-component-type">
      <div className="message is-warning">
        <div className="message-body">{t('importComponentTypeWarning')}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="file has-name is-primary">
          <label className="file-label" htmlFor="files">
            <input className="file-input" type="file" id="files" {...register('files')} required />
            <span className="file-cta">
              <span className="file-icon">
                <FontAwesomeIcon icon={faUpload} />
              </span>
              <span className="file-label">{t('chooseFile')}</span>
            </span>
            <span className="file-name">{importFile?.item(0)?.name || t('noFileSelected')}</span>
          </label>
        </div>
        <div className="is-right mt-2">
          <button
            className={classNames('button is-primary', { 'is-loading': isPending })}
            type="submit"
            disabled={!importFile?.item(0) || isPending}
          >
            {t('import')}
          </button>
        </div>
      </form>
    </div>
  );
}

interface ComponentTypeImportFormModel {
  files?: FileList;
}
