import { useAuth } from 'react-oidc-context';
import { useAutoSignIn } from './auth/useAutoSignIn';
import { CurrentTenantProvider } from './user/tenant-context/CurrentTenantProvider';
import { App } from './App';
import { ThemeProvider } from './ui/title-bar-items/ThemeProvider';

export function AppSignIn() {
  const auth = useAuth();

  useAutoSignIn();

  if (auth.isLoading) {
    return null;
  }

  if (!auth.isAuthenticated) {
    return null;
  }

  return (
    <ThemeProvider>
      <CurrentTenantProvider>
        <App />
      </CurrentTenantProvider>
    </ThemeProvider>
  );
}
