import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import Select from '../ui/select/Select';
import { RelationSpec } from '../models/operation/RelationTypeModel';
import { mapOptionToTValue, mapTValuesToLoadOptions, SelectOption } from '../ui/select/SelectUtils';
import {
  mapRelationSpecToOption,
  mapRelationSpecToOptions,
  RelationTypeOptionLabel,
} from './SingleRelationTypeSelectUtils';
import { useGetTicketRelationTypes } from '../react-query/RelationTypeApi';
import { useRelationTypeTranslations, useTranslationText } from '../translation/TranslationHooks';
import { toRelationSpecs } from '../models/operation/RelationTypeFunctions';
import { UserResourcePermissions } from '../auth/AuthUserRoles';

export interface SingleRelationTypeSelectProps {
  onChange: (relationSpec: RelationSpec | null) => void;
  value: RelationSpec | null;
  label?: string;
  disableLabel?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  size?: BulmaSize;
  customMapRelationSpecToOption?: (relationSpec: RelationSpec) => SelectOption<RelationSpec>;
  customMapRelationSpecsToOptions?: (relationSpecs: RelationSpec[]) => SelectOption<RelationSpec>[];
}

function SingleRelationTypeSelect({
  onChange,
  value,
  label,
  disableLabel,
  isRequired,
  isDisabled,
  size,
  customMapRelationSpecToOption,
  customMapRelationSpecsToOptions,
}: SingleRelationTypeSelectProps) {
  const { t } = useTranslationText('commons');
  const { translateRelation } = useRelationTypeTranslations();
  const { data: relationTypes = [], isLoading } = useGetTicketRelationTypes();

  async function loadOptions() {
    return mapTValuesToLoadOptions<RelationSpec>(
      toRelationSpecs(relationTypes),
      customMapRelationSpecsToOptions ? customMapRelationSpecsToOptions : mapRelationSpecToOptions,
    );
  }

  return (
    <Select<RelationSpec, never, never, false>
      onChange={(newValue) => onChange(mapOptionToTValue<RelationSpec>(newValue))}
      value={
        value === null
          ? null
          : customMapRelationSpecToOption
            ? customMapRelationSpecToOption(value)
            : mapRelationSpecToOption(value)
      }
      loadOptions={loadOptions}
      label={disableLabel ? undefined : label}
      isLoading={isLoading}
      isSearchable={false}
      formatOptionLabel={(opt) => <RelationTypeOptionLabel payload={translateRelation(opt.payload)} />}
      loadingMessage={() => t('relationTypesAreLoading')}
      placeholder={t('selectRelationType')}
      requiredPermission={UserResourcePermissions.RelationType.Read}
      isRequired={isRequired}
      isDisabled={isDisabled}
      size={size}
    />
  );
}

export default SingleRelationTypeSelect;
