import { QueryClient } from '@tanstack/react-query';
import ServerStateKeysEnum from './ServerStateKeysEnum';

export function invalidateTicketHistory(queryClient: QueryClient, ticketId: number): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.TicketHistory, ticketId] });
}

export function invalidateTicketDetails(queryClient: QueryClient, ticketId: number): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.TicketDetails, ticketId] });
}

export function invalidateTaskHistory(queryClient: QueryClient, taskId: number): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.TaskHistory, taskId] });
}

export function invalidateAllTasks(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.Tasks] });
}

export function invalidateAllTickets(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.TicketAll] });
}

export function invalidateTicketTasks(queryClient: QueryClient, ticketId: number): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.TicketTask, ticketId] });
}

export function invalidateAllAgents(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.Agent] });
}

export function invalidateAllArticles(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.KnowledgeBaseArticleAll] });
}

export function invalidateAllTags(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.KnowledgeBaseArticleTagAll] });
}

export function invalidateAllAutomations(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.AutomationRuleAll] });
}

export function invalidateAutomationRule(queryClient: QueryClient, automationRuleId: string): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.AutomationRule, automationRuleId] });
}

export function invalidateAllContracts(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.ContractAll] });
}

export function invalidateAllContractors(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.ContractorAll] });
}

export function invalidateAllComponentTypes(queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.ComponentType] });
}

export function invalidateSetting(section: string, condition: string, queryClient: QueryClient): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.UserSettings, section, condition] });
}

export function invalidateTask(queryClient: QueryClient, taskId: number): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.Task, taskId] });
}

export function invalidateComponentRelations(queryClient: QueryClient, componentId: string): Promise<void> {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.ComponentRelations, componentId] });
}

export function invalidateComponents(queryClient: QueryClient) {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.ComponentsAll] });
}

export function invalidateComponent(queryClient: QueryClient, componentId: string) {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.Component, componentId] });
}

export function invalidateSystems(queryClient: QueryClient) {
  return queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.System] });
}
