import TenantEnum from './TenantEnum';

class TenantUtil {
  public static toString(tenant: TenantEnum) {
    return (
      tenant.charAt(0) + tenant.slice(1).replaceAll('UE', 'Ü').replaceAll('AE', 'Ä').replaceAll('OE', 'Ö').toLowerCase()
    );
  }
}
export default TenantUtil;
