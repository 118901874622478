import { MultiSelect } from '../MultiSelectContext';

interface BaseMultiSelectOptionProps {
  children: React.ReactNode;
  showOnMissingSelection?: boolean;
}

interface PresentMultiSelectOptionProps<T> extends BaseMultiSelectOptionProps {
  multiSelect: MultiSelect<T>;
  showOnMissingSelection: boolean;
}

function PresentMultiSelectOption<T>({
  children,
  multiSelect,
  showOnMissingSelection,
}: PresentMultiSelectOptionProps<T>) {
  const hasSelection = multiSelect.selected.length > 0;
  const display = showOnMissingSelection ? !hasSelection : hasSelection;
  return display ? <>{children}</> : <></>;
}

interface MultiSelectOptionProps<T> extends BaseMultiSelectOptionProps {
  context: React.Context<MultiSelect<T> | undefined>;
}

function MultiSelectOption<T>({ children, context, showOnMissingSelection = false }: MultiSelectOptionProps<T>) {
  return (
    <context.Consumer>
      {(value) =>
        value !== undefined ? (
          <PresentMultiSelectOption multiSelect={value} showOnMissingSelection={showOnMissingSelection}>
            {children}
          </PresentMultiSelectOption>
        ) : (
          <>{children}</>
        )
      }
    </context.Consumer>
  );
}

export default MultiSelectOption;
