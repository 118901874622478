import { useAuth } from 'react-oidc-context';
import { toast } from 'react-toastify';
import { useTranslationText } from '../translation/TranslationHooks';
import { FetchError } from '../react-query/FetchError';

interface GlobalError {
  handleUnauthorizedError: (error: FetchError) => void;
  showErrorToasty: (error: FetchError) => void;
}

export default function useApiErrorHandlers(): GlobalError {
  const { t } = useTranslationText('errorTexts');
  const { signoutRedirect } = useAuth();

  const handleUnauthorizedError = async (error: FetchError) => {
    if (error?.isUnauthorizedError()) {
      await signoutRedirect();
    }
  };

  const showErrorToasty = (error: FetchError) => {
    if (error) {
      toast.error(t(`generalError`) + error.message, {
        toastId: error.status,
      });
    }
  };

  return { handleUnauthorizedError, showErrorToasty };
}
