import { faArrowRightLong, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Tag } from '@aos/styleguide-react';
import DateFormats from '../../../models/date-formats/DateFormats';
import { KnowledgeBaseArticle } from '../../../models/knowledge-base/KnowledgeBaseModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import './ArticleCard.scss';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import Tooltip from '../../../ui/tooltip/Tooltip';

interface ArticleCardProps {
  article: KnowledgeBaseArticle;
}

function ArticleCard({ article }: ArticleCardProps) {
  const { t } = useTranslationText('commons');

  return (
    <div className="article-card">
      <div className="content-wrapper">
        <div className="article-header" data-role="article-card-title" id={article.id.toString()}>
          {article.title}
        </div>
        <Tooltip anchorId={article.id.toString()} content={article.title} delayShow={200} />
      </div>
      {article?.tags?.length > 0 && (
        <div className="article-card-tags">
          <FontAwesomeIcon icon={faTags} />
          {article.tags?.map((tag) => (
            <Tag key={tag.id.toString()} color="is-secondary" size="is-small">
              {tag.title}
            </Tag>
          ))}
        </div>
      )}
      <div className="meta-data">
        <div className="updated-at-date">
          <span>{t('lastChangedAt')}</span>
          <span> {dayjs(article.updatedAt).format(DateFormats.FULLDATE_HOURS_MINS)}</span>
        </div>
        <div className="author">{article.author}</div>
      </div>
      <div className="separator" />
      <div className="description">
        {article.descriptionShort}
        <div className="action-wrapper">
          <Link key={article.id.toString()} to={NavigationRoutes.KnowledgeBaseArticleId(article.id)}>
            <span className="read-more-btn">
              {t('readMore')} <FontAwesomeIcon icon={faArrowRightLong} />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ArticleCard;
