import React from 'react';
import { TicketQuery } from '../../models/operation/TicketQuery';
import { useTranslationText } from '../../translation/TranslationHooks';
import { SwitchInput } from '../switch/SwitchInput';
import FormFieldWrapper from '../form-field-wrapper/FormFieldWrapper';

interface TicketFavoritesFilterProps {
  isChecked: boolean;
  onChange: (name: keyof TicketQuery, value: any) => void;
  secondLabel?: string;
}

function TicketFavoritesFilter({ isChecked = false, onChange, secondLabel }: TicketFavoritesFilterProps) {
  const { t } = useTranslationText('tickets');
  return (
    <FormFieldWrapper label={t('favoriteTickets')} size="is-small">
      <SwitchInput
        id="ticketFavoritesFilter"
        checked={isChecked}
        onChange={(value) => onChange('onlyFavorites', value || undefined)}
        secondLabel={secondLabel}
        isSmall
      />
    </FormFieldWrapper>
  );
}

export default TicketFavoritesFilter;
