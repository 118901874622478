import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { useCallback } from 'react';
import { Collapsible } from '@aos/styleguide-react';
import { CreateContractModel } from '../../../models/contract-management/ContractManagementModel';
import { ContractMediaUrlBuilders } from '../../../react-query/ContractManagementSystemApi';
import { MutationKey, MutationPath } from '../../../react-query/MutationQueries';
import { useTranslationText } from '../../../translation/TranslationHooks';
import DatePicker from '../../../ui/date-picker/DatePicker';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import Media from '../../../ui/media/Media';
import TextInput from '../../../ui/text-input/TextInput';
import { CreateServiceTimesFormCollapsible } from '../CreateServiceTimesForm/CreateServiceTimesFormCollapsible';
import TextArea from '../../../ui/text-area/TextArea';
import { dateByAddingDays } from '../../../models/dates/dateFunctions';

interface CreateContractFormProps {
  form: UseFormReturn<CreateContractModel, object>;
}

export default function CreateContractForm({ form }: CreateContractFormProps) {
  const { t } = useTranslationText('contractManagements');
  const { control, formState, watch } = form;

  // watch for changes of startDate & endDate to update minDate & maxDate of the date pickers accordingly
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const InternalNumberInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'internalNumber'> }) => (
      <FormFieldWrapper error={formState.errors?.internalNumber} label={t('internalContractNumberLabel')} isRequired>
        <TextInput
          placeholder={t('internalContractNumberPlaceholder')}
          dataRole="contract-internal-number"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.internalNumber}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.internalNumber, t],
  );

  const ExternalNumberInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'externalNumber'> }) => (
      <FormFieldWrapper error={formState.errors?.externalNumber} label={t('externalContractNumberLabel')}>
        <TextInput
          placeholder={t('externalContractNumberPlaceholder')}
          dataRole="contract-external-number"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.externalNumber}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.externalNumber, t],
  );

  const ContractualObjectInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'contractualObject'> }) => (
      <FormFieldWrapper error={formState.errors?.contractualObject} label={t('contractualObjectLabel')} isRequired>
        <TextInput
          placeholder={t('contractualObjectPlaceholder')}
          dataRole="contract-contractual-object"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.contractualObject}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.contractualObject, t],
  );

  const StartDateInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'startDate'> }) => (
      <DatePicker
        error={formState.errors?.startDate}
        placeholder={t('startDatePlaceholder')}
        onValueChange={field.onChange}
        value={field.value}
        isError={formState.errors?.startDate !== undefined}
        label={t('startDateLabel')}
        isRequired
        maxDate={dateByAddingDays(endDate, -1)} // maximum startDate is endDate - 1 day
      />
    ),
    [endDate, formState.errors?.startDate, t],
  );

  const EndDateInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'endDate'> }) => (
      <DatePicker
        error={formState.errors?.endDate}
        placeholder={t('endDatePlaceholder')}
        onValueChange={field.onChange}
        value={field.value}
        isError={formState.errors?.endDate !== undefined}
        label={t('endDateLabel')}
        minDate={dateByAddingDays(startDate, 1)} // minimum endDate is startDate + 1 day
      />
    ),
    [formState.errors?.endDate, startDate, t],
  );

  const WarrantyPeriodInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'warrantyPeriod'> }) => (
      <DatePicker
        error={formState.errors?.warrantyPeriod}
        placeholder={t('warrantyPeriodPlaceholder')}
        onValueChange={field.onChange}
        value={field.value}
        isError={formState.errors?.warrantyPeriod !== undefined}
        label={t('warrantyPeriodLabel')}
      />
    ),
    [formState.errors?.warrantyPeriod, t],
  );

  const TypeInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'type'> }) => (
      <FormFieldWrapper error={formState.errors?.type} label={t('type')}>
        <TextInput
          placeholder={t('type')}
          dataRole="type"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.type}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.type, t],
  );

  const SupportBeginInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'supportBeginHours'> }) => (
      <FormFieldWrapper error={formState.errors?.supportBeginHours} label={t('supportBeginHours')}>
        <TextInput
          placeholder={t('supportBeginHours')}
          dataRole="contract-support-begin-hours"
          onValueChange={field.onChange}
          value={field.value?.toString()}
          error={formState.errors?.supportBeginHours}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.supportBeginHours, t],
  );

  const ResponseHoursInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'responseHours'> }) => (
      <FormFieldWrapper error={formState.errors?.responseHours} label={t('responseHours')}>
        <TextInput
          placeholder={t('responseHours')}
          dataRole="contract-response-hours"
          onValueChange={field.onChange}
          value={field.value?.toString()}
          error={formState.errors?.responseHours}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.responseHours, t],
  );

  const RecoveryHoursInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'recoveryHours'> }) => (
      <FormFieldWrapper error={formState.errors?.recoveryHours} label={t('recoveryHours')}>
        <TextInput
          placeholder={t('recoveryHours')}
          dataRole="contract-recovery-hours"
          onValueChange={field.onChange}
          value={field.value?.toString()}
          error={formState.errors?.recoveryHours}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.recoveryHours, t],
  );

  const PenaltyInput = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'penalty'> }) => (
      <FormFieldWrapper error={formState.errors?.penalty} label={t('penalty')}>
        <TextInput
          placeholder={t('penalty')}
          dataRole="contract-penalty"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.penalty}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.penalty, t],
  );

  const Description = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'description'> }) => (
      <FormFieldWrapper error={formState.errors?.description} label={t('descriptionLabel')}>
        <TextArea
          placeholder={t('descriptionPlaceholder')}
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.description}
        />
      </FormFieldWrapper>
    ),
    [formState.errors?.description, t],
  );

  const Attachments = useCallback(
    ({ field }: { field: ControllerRenderProps<CreateContractModel, 'attachments'> }) => (
      <Media
        deletionPath={MutationPath.DeleteContractMedia}
        mutationPath={MutationPath.UploadContractMedia}
        mutationKey={MutationKey.PostContractMedia}
        media={field.value || []}
        urlBuilders={ContractMediaUrlBuilders}
        isEditable
        onMediaChange={field.onChange}
        showCopyToClipboardButton={false}
      />
    ),
    [],
  );

  return (
    <>
      <Collapsible isInitiallyCollapsed={false} header={t('contractDetails')}>
        <div className="horizontal-wrapper">
          <Controller name="internalNumber" control={control} defaultValue="" render={InternalNumberInput} />
          <Controller name="externalNumber" control={control} defaultValue="" render={ExternalNumberInput} />
        </div>
        <Controller name="contractualObject" control={control} defaultValue="" render={ContractualObjectInput} />
        <div className="horizontal-wrapper">
          <Controller name="startDate" control={control} render={StartDateInput} />
          <Controller name="endDate" control={control} render={EndDateInput} />
          <Controller name="warrantyPeriod" control={control} render={WarrantyPeriodInput} />
        </div>
        <div className="horizontal-wrapper">
          <Controller name="type" control={control} render={TypeInput} />
          <Controller name="penalty" control={control} render={PenaltyInput} />
        </div>
        <div className="horizontal-wrapper">
          <Controller name="supportBeginHours" control={control} render={SupportBeginInput} />
          <Controller name="responseHours" control={control} render={ResponseHoursInput} />
          <Controller name="recoveryHours" control={control} render={RecoveryHoursInput} />
        </div>
        <div className="horizontal-wrapper">
          <Controller name="description" control={control} render={Description} />
        </div>
      </Collapsible>

      <CreateServiceTimesFormCollapsible form={form} />

      <Collapsible isInitiallyCollapsed header={t('attachments')}>
        <Controller name="attachments" control={control} render={Attachments} />
      </Collapsible>
    </>
  );
}
