import { FieldError } from 'react-hook-form';
import { createRef, useEffect } from 'react';
import { TextArea as AosTextArea } from '@aos/styleguide-react';
import './textArea.scss';

interface TextAreaProps {
  onValueChange?: (newValue: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string;
  error?: FieldError;
  autosizeOnLoad?: boolean;
}

function TextArea({ value, onValueChange, placeholder, readOnly, error, autosizeOnLoad }: TextAreaProps) {
  const ref = createRef<HTMLTextAreaElement>();

  // autosize the height to the text height on page load
  useEffect(() => {
    if (ref && ref.current) {
      const defaultHeight = '8em'; // default textarea height value
      ref.current.style.height = autosizeOnLoad ? `${(ref?.current?.scrollHeight ?? 0) + 5}px` : defaultHeight;
    }
  }, []);

  return (
    <AosTextArea
      ref={ref}
      isError={error !== undefined}
      onChange={(event) => onValueChange?.(event.target.value)}
      placeholder={placeholder}
      readOnly={readOnly}
      value={value}
    />
  );
}

export default TextArea;
