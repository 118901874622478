import { Field, Label, Table as AosTable } from '@aos/styleguide-react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { useTranslationText } from '../../translation/TranslationHooks';
import { useGetKnowledgeBaseArticles } from '../../react-query/KnowledgeBaseApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import LinkTableCell from '../../ui/link-table-cell/LinkTableCell';
import './KnowledgeBaseTable.scss';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import TableHeader, { TableColumn } from '../../ui/table-header/TableHeader';
import { getNextSortState } from '../../ui/table-sort/TableSort';
import { ArticleSort } from '../../models/knowledge-base/ArticleQuery';
import NavigateButton from '../../generic-components/navigate-button/NavigateButton';
import ActionBar from '../../ui/action-bar/ActionBar';

const tableColumns: TableColumn[] = [
  { property: 'title', translationKey: 'title', width: '25%' },
  { property: 'descriptionShort', translationKey: 'descriptionShort' },
  { property: 'author', translationKey: 'author' },
];

export default function KnowledgeBaseTable() {
  const { t } = useTranslationText('knowledgeBases');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tCommons } = useTranslationText('commons');

  const [query, setQuery] = useQueryParams({
    sort: withDefault(StringParam, ArticleSort.TitleDesc),
  });

  function onSortChange(property: string, currentSortState: string | undefined) {
    const resetSortState = !query.sort.startsWith(property);
    const sortState = getNextSortState(currentSortState, resetSortState);
    setQuery({ ...query, sort: `${property}:${sortState}` });
  }

  const { data: knowledgeBaseArticles = [], isLoading, isError } = useGetKnowledgeBaseArticles(query);

  return (
    <div className="flex-container">
      <ActionBar
        right={
          <Field>
            <Label size="is-small">{tCommons('moreActions')}</Label>
            <NavigateButton
              requiredPermission={UserResourcePermissions.Article.Create}
              to="create"
              text={t('createNewArticle')}
              size="is-small"
              icon="add"
              isConfirm
            />
          </Field>
        }
      />
      <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_articles') : undefined}>
        <div className="knowledge-base overflow" data-role="knowledge-base">
          {knowledgeBaseArticles.length ? (
            <AosTable>
              <TableHeader
                columns={tableColumns}
                onSortChange={onSortChange}
                translationSection="knowledgeBases"
                currentSort={query.sort || undefined}
              />
              <AosTable.Body>
                {knowledgeBaseArticles.map((article) => (
                  <AosTable.Row className="is-clickable" key={article.id} data-id={article.id} data-role="article-item">
                    <LinkTableCell to={article.id.toString()} dataRole="titel-cell">
                      {article.title}
                    </LinkTableCell>
                    <LinkTableCell to={article.id.toString()} dataRole="shortdesc-cell">
                      {article.descriptionShort}
                    </LinkTableCell>
                    <LinkTableCell to={article.id.toString()} dataRole="content-cell">
                      {article.author}
                    </LinkTableCell>
                  </AosTable.Row>
                ))}
              </AosTable.Body>
            </AosTable>
          ) : (
            <>{t('noArticles')}</>
          )}
        </div>
      </LoadingSpinner>
    </div>
  );
}
