import './DetailInfoField.scss';
import classNames from 'classnames';

const DEFAULT_PLACEHOLDER = '-';

export interface DetailInfoFieldProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  placeholder?: string;
  value?: T;
  dataRole?: string;
  isUpdated?: boolean;
}

export default function DetailInfoField<T>({
  label,
  children,
  placeholder = DEFAULT_PLACEHOLDER,
  value,
  dataRole,
  isUpdated,
  className,
  ...otherDivProps
}: DetailInfoFieldProps<T>) {
  const isEmptyArray = (val: any) => Array.isArray(val) && val.length === 0;

  const displayText = <>{!value || isEmptyArray(children) ? placeholder : value}</>;

  const hasDisplayableChildren = children !== undefined && !isEmptyArray(children);

  return (
    <div className={`detail-info-field field ${className ?? ''}`} {...otherDivProps}>
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}
      <div
        id={label}
        className={classNames('control', {
          'value-of-field': !hasDisplayableChildren,
          'is-updated--success': isUpdated,
        })}
        data-role={dataRole}
      >
        {hasDisplayableChildren ? children : displayText}
      </div>
    </div>
  );
}
