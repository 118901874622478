import './Media.scss';
import { faCopy, faDownload, faFile, faFileImport, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from '@aos/styleguide-react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { StrapiFile } from '../../models/strapi/StrapiFile';
import { MediaUrlBuilders } from '../../models/media/Media';
import { isImage } from '../../models/strapi/StrapiFileFunctions';
import { toAbsoluteUrl } from '../../utils/BmsUrl';
import { useTranslationText } from '../../translation/TranslationHooks';
import Modal from '../modal/Modal';

interface MediaImageButtonProps {
  handleClick?: (e: MouseEvent) => void;
  icon: IconDefinition;
  downloadUrl?: string;
}

function MediaImageButton({ handleClick, icon, downloadUrl }: MediaImageButtonProps) {
  return (
    <Button
      download={!!downloadUrl}
      renderAs={downloadUrl ? 'a' : undefined}
      href={downloadUrl || undefined}
      isConfirm
      onClick={handleClick}
      icon={icon.iconName}
      color="is-secondary"
      size="is-small"
      type={downloadUrl ? undefined : 'button'}
    />
  );
}

export interface MediaImageProps {
  file: StrapiFile;
  urlBuilders: MediaUrlBuilders;
  isEditable?: boolean;
  onDelete: (id: number) => void;
  onCopyImage?: (url: string) => void;
  showCopyToClipboardButton: boolean;
}

export default function MediaImage({
  file,
  urlBuilders,
  isEditable,
  onDelete,
  onCopyImage,
  showCopyToClipboardButton,
}: MediaImageProps) {
  const sourceUrl = urlBuilders.mediaSource(file);
  const absoluteSourceUrl = toAbsoluteUrl(sourceUrl);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslationText('commons');

  return (
    <div className="media-image" data-role={`media-image-${file.name}`}>
      <div className="thumbnail">
        {file.thumbnail && isImage(file) ? (
          <img src={urlBuilders.mediaThumbnail(file)} alt={file.name} />
        ) : (
          <div className="default-image">
            <FontAwesomeIcon icon={faFile} />
          </div>
        )}
        <div className="media-actions">
          <a
            className="hidden-image-viewer"
            href={sourceUrl}
            target="_blank"
            rel="noreferrer"
            aria-label="hidden-image-viewer"
            data-rold="media-image-viewer"
          />
          {isEditable && onCopyImage && isImage(file) && (
            <MediaImageButton handleClick={() => onCopyImage && onCopyImage(sourceUrl)} icon={faFileImport} />
          )}
          {isEditable && showCopyToClipboardButton && (
            <MediaImageButton handleClick={() => navigator.clipboard.writeText(absoluteSourceUrl)} icon={faCopy} />
          )}
          <MediaImageButton icon={faDownload} downloadUrl={urlBuilders.mediaDownload(file)} />
          {isEditable && <MediaImageButton icon={faTrash} handleClick={() => setIsModalVisible(true)} />}
        </div>
      </div>
      <div className="metadata">
        <div className="filename">
          <span className="caption" data-role="media-image-filename">
            {file.name.replace(/\.[^/.]+$/, '')}
          </span>
          <span className="extension" data-role="media-image-extension">
            {file.ext}
          </span>
        </div>
        <span className="updated">{new Date(file.updatedAt).toLocaleString()}</span>
      </div>

      <Modal
        isVisible={isModalVisible}
        title={t('fileDelete')}
        cancelBtnText={t('abort')}
        confirmBtnText={t('delete')}
        onCancel={() => setIsModalVisible(false)}
        onConfirm={() => onDelete(file.id)}
        onClose={() => setIsModalVisible(false)}
      >
        <p>{t('warningFileDelete')}</p>
      </Modal>
    </div>
  );
}
