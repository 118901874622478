import './TicketAlerts.scss';
import { useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Collapsible } from '@aos/styleguide-react';
import { useGetAssociatedAlertIds } from '../../react-query/TicketingSystemApi';
import { useGetAlerts } from '../../react-query/AlertApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import DateFormats from '../../models/date-formats/DateFormats';
import { DEFAULT_PAGE } from '../../models/pagination/Pagination';
import { AssociatedAlertQuery } from '../../models/operation/AssociatedAlertQuery';
import Pagination from '../../ui/pagination/Pagination';

interface TicketAlertsProps {
  ticketId: number;
}

export function TicketAlerts({ ticketId }: Readonly<TicketAlertsProps>) {
  const { t } = useTranslationText('tickets');
  const { t: tAlerts } = useTranslationText('alerts');
  const [query, setQuery] = useState<AssociatedAlertQuery>({
    page: DEFAULT_PAGE,
    size: 5, // Don't use default size, otherwise the page will be much longer
  });

  function onPageChange(page: number) {
    setQuery({ ...query, page });
  }

  const { data: associatedAlertsPaged } = useGetAssociatedAlertIds(ticketId, query);

  const { data: alerts } = useGetAlerts(
    { ids: associatedAlertsPaged?.content },
    {
      enabled: (associatedAlertsPaged?.totalElements ?? 0) > 0,
    },
  );

  const hasPagination = associatedAlertsPaged && associatedAlertsPaged.totalPages > 1;

  return (associatedAlertsPaged?.totalElements ?? 0) > 0 ? (
    <Collapsible
      header={t('associatedAlerts')}
      badgeCount={associatedAlertsPaged?.totalElements ?? 0}
      isInitiallyCollapsed={false}
    >
      <div className="alert-table">
        {alerts?.map((a) => (
          <>
            <div key={`status-${a.id}`} className="alert-status">
              <span className={classNames('tag', a.status.toLocaleLowerCase())}>{tAlerts(a.status)}</span>
            </div>
            <div key={`title-${a.title}`} className="alert-title">
              {a.title}
            </div>
            <div key={`timestamp-${a.id}`} className="alert-time">
              {tAlerts('firstOccurrence')} {dayjs(a.timeOfFirstOccurrence).format(DateFormats.FULLDATE_HOURS_MINS)}
            </div>
            <div key={`description-${a.title}`} className="alert-description">
              {a.message}
            </div>
          </>
        ))}
      </div>
      {hasPagination && (
        <div className="mt-3">
          <Pagination
            currentPage={query.page ?? 0}
            totalPages={associatedAlertsPaged?.totalPages ?? 0}
            handleOnPageChange={onPageChange}
            size="is-small"
          />
        </div>
      )}
    </Collapsible>
  ) : (
    <></>
  );
}
