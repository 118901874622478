import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { BaseMaintenance, PatchBaseMaintenance } from '../../models/maintenance/Maintenance';
import { useTranslationText } from '../../translation/TranslationHooks';
import { ComponentForm } from './ComponentForm';
import { CollapsibleWithButtons } from '../../ui/collapsible/CollapsibleWithButtons';

interface CreateComponentFormCollapsibleProps {
  form: UseFormReturn<BaseMaintenance>;
}

export function CreateComponentFormCollapsible({ form }: Readonly<CreateComponentFormCollapsibleProps>) {
  const { t } = useTranslationText('maintenances');
  const { t: tCommon } = useTranslationText('commons');
  const { control } = form;

  const { append, fields, remove } = useFieldArray({ control, name: `maintenanceLocations` });

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('components')}
      badgeCount={fields.length}
      addBtnClicked={() => append({ componentId: '' })}
      addPermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
    >
      {fields.length === 0 && tCommon('noItemsFoundAdd')}
      {fields.map((field, index: number) => (
        <ComponentForm
          key={field.id}
          form={form as UseFormReturn<PatchBaseMaintenance>}
          onRemove={() => remove(index)}
          index={index}
        />
      ))}
    </CollapsibleWithButtons>
  );
}
