export default interface CreateCommentModel {
  text: string;
  type?: CommentType;
}

export enum CommentType {
  COMMENT = 'COMMENT',
  QUESTION = 'QUESTION',
  TASK_COMMENT = 'TASK_COMMENT',
}
