import { useQuery } from '@tanstack/react-query';
import { AllBmsServices, BmsService } from './ResourcePermissionFunction';
import useCustomFetch from '../react-query/CustomFetch';
import ServerStateKeysEnum from '../react-query/ServerStateKeysEnum';

export const fetchRoleMappings = async (
  customFetch: (path: string, init?: RequestInit) => Promise<any>,
  service: BmsService,
): Promise<Map<string, string[]>> => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 6000);

  const response = await customFetch(`/api/${service}/security/role`, { signal: controller.signal });

  clearTimeout(timeoutId);

  const mappingsObject = response as Object;
  const mappingMap = new Map<string, string[]>();
  Object.getOwnPropertyNames(mappingsObject).forEach((prop) =>
    mappingMap.set(prop, (mappingsObject as any)[prop] as string[]),
  );
  return mappingMap;
};

const orDefault = (prom: Promise<Map<string, string[]>>) => prom.catch(() => new Map<string, string[]>());

export const fetchAllRoleMappings = async (customFetch: (path: string, init?: RequestInit) => Promise<any>) => {
  const result = new Map<string, string[]>();
  const allServiceMappings = await Promise.all(
    AllBmsServices.map((service) => orDefault(fetchRoleMappings(customFetch, service))),
  );

  allServiceMappings.forEach((rolesForService) => {
    rolesForService.forEach((permissions, role) => {
      if (!result.has(role)) {
        result.set(role, permissions);
      } else {
        result.set(role, [...(result.get(role) as string[]), ...permissions]);
      }
    });
  });
  return result;
};

export const useRoleMappings = () => {
  const customFetch = useCustomFetch();
  return useQuery({
    queryKey: [ServerStateKeysEnum.RoleMappings],
    queryFn: async () => fetchAllRoleMappings(customFetch),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
