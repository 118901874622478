import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { TaskDto, TaskState } from '../../../../models/operation/TaskDto';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import { Dropdown } from '../../../../ui/dropdown/Dropdown';
import TaskStateMenuItem from './TaskStateMenuItem';
import './TaskStateMenu.scss';

interface TaskStateMenuProps {
  task: TaskDto;
}

export default function TaskStateMenu({ task }: TaskStateMenuProps) {
  const { t } = useTranslationText('tickets');
  const [isLoading, setIsLoading] = useState(false);

  const states = [TaskState.TODO, TaskState.WORKING, TaskState.PAUSED, TaskState.DONE].filter(
    (state) => state !== task.state,
  );

  return (
    <div className="task-state-context-menu" onClick={(e) => e.preventDefault()}>
      <Dropdown
        title={<FontAwesomeIcon icon={faEllipsisH} />}
        isLoading={isLoading}
        renderItems={() => [
          <div key="states-section">
            <div className="dropdown-item menu-catagory">
              <span>{t('moveToState')}</span>
            </div>
            {states.map((state) => (
              <TaskStateMenuItem
                state={state}
                task={task}
                setIsLoading={setIsLoading}
                key={`task-dropdown-${state}-${task.id}`}
              />
            ))}
          </div>,
        ]}
      />
    </div>
  );
}
