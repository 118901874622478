import { createContext, RefObject } from 'react';

export interface MultiSelect<T> {
  selected: T[];
  lastSelectedElement: T | undefined;
  setSelected(element: T, selected: boolean, rangeSelect?: boolean): void;
  clear(): void;
  register(element: RefObject<HTMLElement | null>, item: T): () => void;
  isSelected(element: T): boolean;
}

export function createMultiSelectContext<T>() {
  return createContext<MultiSelect<T> | undefined>(undefined);
}
