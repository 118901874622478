import { MultiValue } from 'react-select';
import { RuleTrigger } from '../../../models/operation/AutomationRuleModel';
import { ActorKind, AllActorKinds } from '../../../models/actor/Actor';
import { GenericTriggerConfigurationProps } from './GenericTriggerConfigurationProps';
import MultiSelectDropdown, {
  ReactSelectOption,
  sortByLabel,
} from '../../../ui/multi-select-dropdown/MultiSelectDropdown';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { useActorKindTranslation } from '../../../ui/actor-label/ActorLabel';

interface TicketCreatedConfiguration extends RuleTrigger {
  type: 'ticketCreated';
  includedActors: ActorKind[];
}

type ActorKindSelectOption = ReactSelectOption<ActorKind>;

export default function TicketCreatedTrigger({
  trigger,
  handleConfigurationUpdate,
  onClear,
}: GenericTriggerConfigurationProps<TicketCreatedConfiguration>) {
  const { t } = useTranslationText('actors');
  const actorKindTranslation = useActorKindTranslation();

  const toSelectOption = (k: ActorKind): ActorKindSelectOption => ({
    label: actorKindTranslation(k),
    value: k,
  });
  const fromSelectOption = (o: ActorKindSelectOption) => o.value as ActorKind;
  // Die Nutzer
  // - System/SERVICE (den alle Services außer Monitoring & Ticketing selbst zugwiesen bekommen) und
  // - Gast/ANONYMOUS
  // sollten keine Tickets erzeugen können, daher werden sie für diese Auswahl ausgeblendet
  const options = AllActorKinds.filter((kind) => kind !== 'SERVICE' && kind !== 'ANONYMOUS')
    .map(toSelectOption)
    .sort(sortByLabel);

  const handleChange = (selectOptions: MultiValue<ActorKindSelectOption>): void => {
    if (selectOptions && selectOptions.length > 0) {
      handleConfigurationUpdate({
        type: 'ticketCreated',
        includedActors: selectOptions.map(fromSelectOption),
      } as TicketCreatedConfiguration);
    } else {
      onClear();
    }
  };

  return (
    <MultiSelectDropdown
      onChange={handleChange}
      options={options}
      openMenuOnClick
      isSearchable
      placeholder={t('selectActors')}
      mappedValues={(trigger?.includedActors ?? []).map(toSelectOption)}
    />
  );
}
