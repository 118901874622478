import { toast } from 'react-toastify';
import { DecodedValueMap, QueryParamConfigMap } from 'use-query-params';
import { ReactNode, useMemo, useRef, useState } from 'react';
import {
  Button,
  Control,
  Field,
  FormHelper,
  FormHelpers,
  Label,
  Modal,
  ModalCard,
  TextInput,
} from '@aos/styleguide-react';
import { UseQueryResult } from '@tanstack/react-query';
import { useTranslationText } from '../translation/TranslationHooks';
import { MutationKey, MutationPath, usePutMutation } from '../react-query/MutationQueries';
import { FilterDropdownType } from './filter-dropdown/FilterDropdownType';
import './Modal.scss';

interface CreateFilterModalProps {
  query: DecodedValueMap<QueryParamConfigMap>;
  hasFilter: boolean;
  filterForm: ReactNode;
  createOrUpdateFilterMutationKey: MutationKey;
  putFilterMutationPath: MutationPath;
  useGetAllFilter: () => UseQueryResult<FilterDropdownType[]>;
  parseQueryToFilter: (name: string, query: any) => FilterDropdownType;
  applySelectedFilter: (filter: FilterDropdownType) => void;
}

function CreateFilterModal({
  query,
  hasFilter,
  filterForm,
  createOrUpdateFilterMutationKey,
  putFilterMutationPath,
  useGetAllFilter,
  parseQueryToFilter,
  applySelectedFilter,
}: CreateFilterModalProps) {
  const { t } = useTranslationText('commons');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMutationLoading, setIsMutationLoading] = useState(false);
  const { data: allFilter = [], refetch: refetchAllFilter } = useGetAllFilter();
  const triggerIsVisible = !query.name && hasFilter;
  function findFilterByName(name: string | undefined, providedFilter: FilterDropdownType[]) {
    if (!name) return null;
    return providedFilter.find((filter) => filter.name === name) ?? null;
  }
  const selectedFilter = useMemo<FilterDropdownType | null>(
    () => findFilterByName(query?.name, allFilter),
    [query, allFilter],
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const { mutate: createOrUpdateFilterMutation } = usePutMutation<FilterDropdownType, FilterDropdownType>(
    createOrUpdateFilterMutationKey,
    {
      onMutate: () => {
        setIsMutationLoading(true);
      },
      onSuccess: () => {
        setIsMutationLoading(false);
        void refetchAllFilter();
        setIsModalOpen(false);
      },
      onError: () => {
        setIsMutationLoading(false);
        setIsModalOpen(false);
        toast.error(t('errorCreatingUpdatingFilter'));
      },
    },
  );

  function handleSaveFilter() {
    if (inputRef.current == null) return;
    if (inputRef.current.value.length === 0) {
      setError(t('errorTitleRequired'));
      return;
    }
    if (allFilter.map((filter) => filter.name).includes(inputRef.current.value)) {
      setError(t('errorFiltersetTitleAlreadyExists'));
      return;
    }
    setError(undefined);
    const filterToSave: FilterDropdownType = {
      ...parseQueryToFilter(selectedFilter?.name!, query),
      name: inputRef.current.value,
      id: selectedFilter?.id,
    };
    createOrUpdateFilterMutation({
      body: filterToSave,
      path: putFilterMutationPath,
    });
    applySelectedFilter(filterToSave);
  }

  return (
    <div className="filterset">
      {triggerIsVisible && (
        <Button color="is-primary" isConfirm icon="filter" onClick={() => setIsModalOpen(true)}>
          {t('saveFiltersetButton')}
        </Button>
      )}
      <Modal isActive={isModalOpen} portalRoot={document.body}>
        <ModalCard
          title={t('saveFilterset')}
          content={
            <>
              <div className="filterset-title">
                <Field>
                  <Label size="is-normal" isRequired>
                    {t('labelFiltersetTitle')}
                  </Label>
                  <Control>
                    <TextInput placeholder={t('title')} isError={!!error} ref={inputRef} size="is-small" />
                  </Control>
                  <FormHelpers>
                    <FormHelper isError>{error}</FormHelper>
                  </FormHelpers>
                </Field>
              </div>
              <Label size="is-normal">{t('appliedFilters')}</Label>
              {filterForm}
            </>
          }
          onClose={() => {
            setIsModalOpen(false);
          }}
          footer={
            <>
              <Button onClick={() => setIsModalOpen(false)} icon="xmark" disabled={isMutationLoading}>
                {t('abort')}
              </Button>
              <Button isConfirm onClick={() => handleSaveFilter()} icon="floppy-disk" isLoading={isMutationLoading}>
                {t('confirmButtonFilterset')}
              </Button>
            </>
          }
        />
      </Modal>
    </div>
  );
}

export default CreateFilterModal;
