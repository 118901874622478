import { SummarizedComponent } from '../../models/operation/ComponentModel';
import { ComponentTableRow } from './ComponentTableRow';
import { UserOverviewColumnConfig, UserOverviewColumnType } from './UserOverviewConfig';

interface ChildComponentsProps {
  onExpand: (src: SummarizedComponent, relationType?: string) => SummarizedComponent[] | undefined;
  canExpand?: (src: SummarizedComponent) => boolean;
  component: SummarizedComponent;
  config?: Map<UserOverviewColumnType, UserOverviewColumnConfig>;
  level: number;
  relationType?: string;
  isSmall?: boolean;
}

export function ChildComponents({
  onExpand,
  canExpand,
  component,
  config,
  level,
  relationType,
  isSmall = false,
}: ChildComponentsProps) {
  const childComponents = onExpand(component, relationType);

  return (
    <>
      {childComponents
        ? childComponents.map((d, index) => (
            <ComponentTableRow
              level={level}
              config={config}
              onExpand={onExpand}
              canExpand={canExpand}
              key={d.id}
              component={d}
              relationType={relationType}
              isLast={index === childComponents.length - 1}
              isSmall={isSmall}
            />
          ))
        : undefined}
    </>
  );
}
