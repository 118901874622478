import { Trans } from 'react-i18next';
import { Collapsible } from '@aos/styleguide-react';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { useGetComponentType } from '../../react-query/ComponentTypeApi';
import useParamId from '../../custom-hooks/UseParamId';
import { ComponentSelectorDetails } from './component-selector-details/ComponentSelectorDetails';
import { DisplayNameSelectorDetails } from './display-name-selector-details/DisplayNameSelectorDetails';
import { RelationDetails } from './relation-details/RelationDetails';
import { AttributesDetails } from './attribute-details/AttributesDetails';
import { useTranslationText } from '../../translation/TranslationHooks';
import TextLabel from '../../ui/text-label/TextLabel';
import { ComponentTypeDetailsActionBar } from './ComponentTypeDetailsActionBar';

export function ComponentTypeDetails() {
  const { id: componentTypeId, ErrorPage } = useParamId();
  const { t } = useTranslationText('componentTypes');

  const {
    data: componentType,
    isLoading: componentTypeIsLoading,
    isError,
  } = useGetComponentType(componentTypeId, { gcTime: 0 });

  return (
    <LoadingSpinner
      isLoading={componentTypeIsLoading}
      errors={[
        isError ? <Trans i18nKey="errorTexts.ComponentTypeError.text" values={{ id: componentTypeId }} /> : undefined,
        ErrorPage,
      ]}
    >
      <ComponentTypeDetailsActionBar componentTypeId={componentTypeId} />

      <div className="columns is-align-items-flex-start">
        <div className="column is-7 edit-component-type-column">
          <Collapsible header={t('componentTypeDetails')} isInitiallyCollapsed={false}>
            <TextLabel label={t('nameLabel')} value={componentType?.name} />
            <TextLabel label={t('descriptionLabel')} value={componentType?.description} />
          </Collapsible>
          {/* Falls es sich um den logischen KomponentenTyp handelt, müssen die folgenden beiden Komponenten nicht dargestellt werden */}
          {componentType?.isEditable && componentType?.componentSelector && (
            <ComponentSelectorDetails componentSelector={componentType.componentSelector} />
          )}
          {componentType?.isEditable && componentType?.displayNameSelector && (
            <DisplayNameSelectorDetails displayNameSelector={componentType.displayNameSelector} />
          )}
          {componentType?.relationTemplates && componentType.relationTemplates.length > 0 && (
            <RelationDetails relations={componentType.relationTemplates} />
          )}
        </div>
        <div className="column is-5">
          {componentType?.attributes && componentType.attributes.length > 0 && (
            <AttributesDetails attributes={componentType.attributes} />
          )}
        </div>
      </div>
    </LoadingSpinner>
  );
}
