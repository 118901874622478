import { Collapsible } from '@aos/styleguide-react';
import { ComponentItem } from '../../models/operation/ComponentModel';
import useRuntimeConfig from '../../runtime-config/RuntimeConfig';

interface ComponentMetricsProps {
  title: string;
  component: ComponentItem;
}

export function ComponentMetrics({ title, component }: Readonly<ComponentMetricsProps>) {
  const [runtimeConfig] = useRuntimeConfig();
  const buildQueryParams = () => {
    let queryString = '?kiosk=tv&theme=light&orgId=1';
    if (component.technicalIdentifiers) {
      Object.keys(component.technicalIdentifiers).forEach((key) => {
        queryString += `&${encodeURIComponent(`var-${key}`)}=${encodeURIComponent(
          component.technicalIdentifiers[key],
        )}`;
      });
    }
    return queryString;
  };

  const queryParams = buildQueryParams();

  return (
    <Collapsible header={title} isInitiallyCollapsed={false}>
      <div className="component-attributes-dashboard-container">
        {component && runtimeConfig && (
          <iframe
            title={component.displayName}
            className="component-attributes-metrics-panel"
            src={`${runtimeConfig.dashboardUrl}/${component.dashboardId}${queryParams}`}
          />
        )}
      </div>
    </Collapsible>
  );
}
