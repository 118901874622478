import { jwtDecode } from 'jwt-decode';
import { useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import { OidcAccessToken } from './PermissionTypes';
import { useRoleMappings } from './RoleMappings';
import { UserPermission } from './UserPermission';

interface UserRoleCheckProps {
  requiredPermission?: UserPermission;
  children: React.ReactNode;
  renderNoPermission?: (missingPermission: UserPermission) => React.ReactNode;
}

export const useAuthUser = () => {
  const { user } = useAuth();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [userId, setUserId] = useState('');
  const roleMappings = useRoleMappings();
  useEffect(() => {
    if (user) {
      const accessToken = jwtDecode<OidcAccessToken>(user?.access_token);
      const givenRoles = accessToken.realm_access?.roles ?? [];
      const allRoles = [...givenRoles];
      if (roleMappings.data) {
        givenRoles.forEach((r) => {
          if (roleMappings.data.has(r)) {
            allRoles.push(...(roleMappings.data.get(r) as string[]));
          }
        });
      }
      setUserRoles(allRoles);
      setUserId(accessToken.sub);
    }
  }, [user, roleMappings.data]);

  const hasPermission = (permission: UserPermission) => permission.matches(userRoles);

  return { hasPermission, userId };
};

export default function UserRoleCheck({ requiredPermission, children, renderNoPermission }: UserRoleCheckProps) {
  const { hasPermission } = useAuthUser();
  if (requiredPermission === undefined || hasPermission(requiredPermission)) {
    return <>{children}</>;
  }
  if (renderNoPermission) {
    return <>{renderNoPermission(requiredPermission)}</>;
  }
  return <></>;
}
