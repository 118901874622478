import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import {
  AosLogoTitleBarItem,
  LastSyncTitleBarItem as AosLastSyncTitleBarItem,
  LogoutTitleBarItem as AosLogoutTitleBarItem,
  PageTitle as AosPageTitle,
  TitleBar as AosTitleBar,
  TitleBarItem as AosTitleBarItem,
  TitleBarItems as AosTitleBarItems,
} from '@aos/styleguide-react';
import './TitleBar.scss';
import { signOut } from '../../utils/Authentication';
import { useTranslationText } from '../../translation/TranslationHooks';
import { Environment, useGetChangelog } from '../../react-query/GetChangelogApi';
import { LayoutUserInfoTitleBarItem } from '../title-bar-items/LayoutUserInfoTitleBarItem';

export default function TitleBar() {
  const auth = useAuth();
  const { t } = useTranslationText('commons');
  const { data: { version, environment } = { version: '0.0.0', environment: 'default' } } = useGetChangelog();

  const lastSync: Date = new Date();

  return (
    <AosTitleBar
      left={
        <AosTitleBarItems>
          <AosLogoTitleBarItem<typeof Link> renderAs={Link} to="/" />
        </AosTitleBarItems>
      }
      center={
        <AosTitleBarItems className={`${environment === Environment.Demo ? 'demo' : ''}`}>
          <AosTitleBarItem>
            <AosPageTitle title={`${environment === Environment.Demo ? t('demotext') + version : t('systemTitle')}`} />
          </AosTitleBarItem>
        </AosTitleBarItems>
      }
      right={
        <AosTitleBarItems>
          {auth?.user && <LayoutUserInfoTitleBarItem user={auth.user} />}
          {lastSync !== null && <AosLastSyncTitleBarItem lastSync={lastSync} />}
          <AosLogoutTitleBarItem onClick={() => signOut(auth)} />
        </AosTitleBarItems>
      }
    />
  );
}
