import { useRoleMappings } from './auth/RoleMappings';
import LoadingSpinner from './ui/loading-spinner/LoadingSpinner';
import Router from './routing/Router';

export function App() {
  const { isLoading: isLoadingRoleMappings } = useRoleMappings();

  return (
    <LoadingSpinner isLoading={isLoadingRoleMappings}>
      <Router />
    </LoadingSpinner>
  );
}
