import { ArrayParam, JsonParam, NumberParam, QueryParamConfigMap, StringParam } from 'use-query-params';

export const ActiveAlertQueryParamConfigMap: ActiveAlertQueryParamConfigModel = {
  page: NumberParam,
  size: NumberParam,
  sort: StringParam,
  appliedFilter: JsonParam,
  searchText: StringParam,
  name: StringParam,
  unfilteredColumns: ArrayParam,
};

export interface ActiveAlertQueryParamConfigModel extends QueryParamConfigMap {
  page: typeof NumberParam;
  size: typeof NumberParam;
  sort: typeof StringParam;
  appliedFilter: typeof JsonParam;
  searchText: typeof StringParam;
  unfilteredColumns: typeof ArrayParam;
  name: typeof StringParam;
}
