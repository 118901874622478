import { mapContractItemsToOptions } from '../../models/contract-management/ContractFunctions';
import { ComponentItem } from '../../models/operation/ComponentModel';
import { useGetContractById } from '../../react-query/ContractManagementSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import { ComponentPatchSubmit } from '../../models/patch/ComponentPatchSubmitType';
import useGetContractComponent from '../useGetContractComponent';
import ContractSearchDropdown from './ContractSearchDropdown';

interface AssignContractItemProps {
  component: ComponentItem;
  onSubmit: ComponentPatchSubmit;
}

export function AssignContractItem({ component, onSubmit }: AssignContractItemProps) {
  const { t } = useTranslationText('components');

  const {
    data: contractsPaged,
    isError: isContractError,
    isLoading: isContractLoading,
    setFilter,
  } = useGetContractComponent();

  const {
    data: currentContract,
    isError: isCurrentContractError,
    isLoading: isCurrentContractLoading,
  } = useGetContractById(component?.assignedContractId!, {
    enabled: !!component?.assignedContractId,
  });

  return (
    <div className="contract-item">
      <ContractSearchDropdown
        contract={component?.assignedContractId ? currentContract : null}
        options={mapContractItemsToOptions(contractsPaged?.content)}
        onChange={(value) =>
          component?.assignedContractId !== value?.value && onSubmit(value?.value, 'assignedContractId', true)
        }
        isLoading={isContractLoading || isCurrentContractLoading}
        isError={isContractError || isCurrentContractError}
        onInputChange={setFilter}
        label={t('assignedContract')}
        placeholder={t('noAssignedContract')}
      />
    </div>
  );
}
