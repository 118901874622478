import { OrUserPermission } from './OrUserPermission';
import { AndUserPermission } from './AndUserPermission';
import { UserPermission } from './UserPermission';

export class UserPermissionBuilder implements UserPermission {
  private readonly permission: UserPermission;

  constructor(permission: UserPermission) {
    this.permission = permission;
  }

  matches(roleNames: string[]): boolean {
    return this.permission.matches(roleNames);
  }

  or(...other: UserPermission[]): UserPermissionBuilder {
    return new UserPermissionBuilder(new OrUserPermission([this, ...other]));
  }

  and(...other: UserPermission[]): UserPermissionBuilder {
    return new UserPermissionBuilder(new AndUserPermission([this, ...other]));
  }
}
