import { ArrayParam, BooleanParam, NumberParam, QueryParamConfigMap, StringParam } from 'use-query-params';

export interface TaskQuery {
  query: string;
  states: string[];
  contractIds: string[];
  startedAfter: string;
  startedBefore: string;
  hasOpenQuestions: boolean;
  size: number;
  page: number;
  sort: string;
  onlyFavorites: boolean;
  title: string;
  contractors: string[];
}

export const taskQueryParamConfigMap: TaskQueryParamConfigModel = {
  query: StringParam,
  states: ArrayParam,
  contractIds: ArrayParam,
  startedAfter: StringParam,
  startedBefore: StringParam,
  hasOpenQuestions: BooleanParam,
  size: NumberParam,
  page: NumberParam,
  sort: StringParam,
  onlyFavorites: BooleanParam,
  title: StringParam,
  contractors: ArrayParam,
};

export interface TaskQueryParamConfigModel extends QueryParamConfigMap {
  query: typeof StringParam;
  states: typeof ArrayParam;
  contractIds: typeof ArrayParam;
  startedAfter: typeof StringParam;
  startedBefore: typeof StringParam;
  hasOpenQuestions: typeof BooleanParam;
  size: typeof NumberParam;
  page: typeof NumberParam;
  sort: typeof StringParam;
  onlyFavorites: typeof BooleanParam;
  title: typeof StringParam;
  contractors: typeof ArrayParam;
}

export enum TaskSort {
  IdDesc = 'id:desc',
  IdAsc = 'id:asc',
  StateDesc = 'state:desc',
  StateAsc = 'state:asc',
  TitleDesc = 'ticketSnapshot.title:desc',
  TitleAsc = 'ticketSnapshot.title:asc',
  CreatedAtDesc = 'createdAt:desc',
  CreatedAtAsc = 'createdAt:asc',
}
