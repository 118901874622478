import { useId } from 'react';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslationText } from '../../translation/TranslationHooks';
import './TaskOpenQuestion.scss';
import Tooltip from '../tooltip/Tooltip';

interface TaskOpenQuestionProps {
  isMedium?: boolean;
}

export default function TaskOpenQuestion({ isMedium }: TaskOpenQuestionProps) {
  const { t } = useTranslationText('tickets');
  const anchorId = useId();

  return (
    <span
      className={classNames('tag', 'is-warning', 'task-question', { 'is-medium': isMedium, 'is-small': !isMedium })}
    >
      <FontAwesomeIcon icon={faQuestion} id={anchorId} />
      <Tooltip anchorId={anchorId} content={t('hasOpenQuesitions')} />
    </span>
  );
}
