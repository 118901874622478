import { SwitchInput } from '../../switch/SwitchInput';
import { useTranslationText } from '../../../translation/TranslationHooks';
import FormFieldWrapper from '../../form-field-wrapper/FormFieldWrapper';

interface ComponentWithoutContractFilterProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export default function ComponentWithoutContractFilter({ isChecked, onChange }: ComponentWithoutContractFilterProps) {
  const { t } = useTranslationText('components');

  return (
    <FormFieldWrapper label={t('withoutContract')}>
      <SwitchInput checked={isChecked} onChange={onChange} id="without-contract" />
    </FormFieldWrapper>
  );
}
