import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import { ComponentType, ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { MutationKey, MutationPath, usePostMutation } from '../../react-query/MutationQueries';
import { useTranslationText } from '../../translation/TranslationHooks';
import useYupLocal from '../../translation/YupLocal';
import ActionBar from '../../ui/action-bar/ActionBar';
import componentTypeValidationSchema from '../ComponentTypeValidationSchema';
import AttributesFormCollapsible from '../attributes-form/AttributesFormCollapsible';
import ComponentSelectorForm from '../component-selector-form/ComponentSelectorForm';
import './CreateComponentType.scss';
import DisplayNameSelectorForm from '../display-name-selector-form/DisplayNameSelectorForm';
import DetailsForm from '../details-form/DetailsForm';
import AlertingRulesFormCollapsible from '../alerting-rules-form/AlertingRulesFormCollapsible';

export default function CreateComponentType() {
  const [preventNavigation, setPreventNavigation] = useState<boolean>(true);

  const { t } = useTranslationText('componentTypes');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');

  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();

  const componentTypeFormReturn = useForm<ComponentTypeModel>({
    mode: 'onChange',
    resolver: yupResolver(componentTypeValidationSchema(yup, t)),
    defaultValues: {
      componentSelector: { labels: [] },
      displayNameSelector: {},
      relationTemplates: [],
    },
  });

  const { handleSubmit, formState } = componentTypeFormReturn;

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const navigate = useNavigate();

  const { mutate, isPending } = usePostMutation<ComponentTypeModel, ComponentType>(MutationKey.PostComponentType, {
    onSuccess: () => {
      navigate(-1);
      toast.success(tSuccess('createComponentType'));
    },
    onError: () => {
      toast.error(tError('createComponentTypeError'));
    },
  });

  const onSubmit = (cctm: ComponentTypeModel) => {
    setPreventNavigation(false);

    mutate({
      body: {
        name: cctm.name,
        description: cctm.description,
        componentSelector: cctm.componentSelector,
        attributes: cctm.attributes,
        displayNameSelector: cctm.displayNameSelector,
        relationTemplates: cctm.relationTemplates,
        alertingRules: cctm.alertingRules,
        iconUrl: cctm.iconUrl,
      },
      path: MutationPath.CreateComponentType,
    });
  };

  return (
    <form className="create-component-type" onSubmit={handleSubmit(onSubmit)}>
      <ActionBar
        right={
          <button
            className={classNames('button is-primary', { 'is-loading': isPending })}
            type="submit"
            aria-label="create-button"
            data-role="create-component-type-submit"
            disabled={!formState.isValid || !formState.isDirty}
          >
            <span>{t('save')}</span>
          </button>
        }
      />
      <div className="columns is-align-items-flex-start">
        <div className="column is-7">
          <DetailsForm form={componentTypeFormReturn} />
          <ComponentSelectorForm form={componentTypeFormReturn} />
          <DisplayNameSelectorForm form={componentTypeFormReturn} />
        </div>
        <div className="column is-5">
          <AttributesFormCollapsible form={componentTypeFormReturn} />
          <AlertingRulesFormCollapsible form={componentTypeFormReturn} />
        </div>
      </div>
    </form>
  );
}
