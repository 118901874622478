import { PermissionVerb } from './PermissionTypes';
import { UserPermission } from './UserPermission';

export const Verkehrszentralen = [
  'DEUTSCHLAND',
  'BERLIN',
  'FRANKFURT',
  'HALLE',
  'HAMBURG',
  'HANNOVER',
  'LEVERKUSEN',
  'MUENCHEN',
  'NUERNBERG',
  'STUTTGART',
];

export class DefaultUserPermission implements UserPermission {
  private readonly resource: string;

  private readonly verb: PermissionVerb;

  private readonly expectedRoleNames: string[];

  constructor(service: string, resource: string, verb: PermissionVerb) {
    this.resource = resource;
    this.verb = verb;

    this.expectedRoleNames = DefaultUserPermission.applyServicePrefix(service, [
      `*:*`,
      `*:${verb}`,
      `${resource}:*`,
      `${resource}:${verb}`,
    ]);
  }

  private static applyServicePrefix(service: string, roles: string[]): string[] {
    return ['*:bms:*', `*:bms:${service}`]
      .map((prefix) => roles.map((role) => `${prefix}:${role}`))
      .reduce((a, b) => [...a, ...b]);
  }

  private static stripVZPrefix(roleName: string): string {
    const prefix = Verkehrszentralen.find((vz) => roleName.startsWith(vz));
    if (prefix) {
      return roleName.replace(prefix, '*');
    }
    return roleName;
  }

  matchesRole(roleName: string): boolean {
    return this.expectedRoleNames.includes(DefaultUserPermission.stripVZPrefix(roleName));
  }

  matches(roleNames: string[]): boolean {
    return roleNames.findIndex((role) => this.matchesRole(role)) !== -1;
  }
}
