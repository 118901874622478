import { MultiValue } from 'react-select';
import { useGetStates } from '../../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../../translation/TranslationHooks';
import MultiSelectDropdown from '../../../ui/multi-select-dropdown/MultiSelectDropdown';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import { RuleCondition } from '../../../models/operation/AutomationRuleModel';
import { GenericConditionConfigurationProps } from './GenericConditionConfigurationProps';
import {
  getEnumKeyFromSelectOption,
  toReactSelectFromEnumObject,
  toReactSelectFromTranslationKey,
  toTranslationKeysFromEnumKeys,
} from '../../../utils/EnumSelectMapper';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';

interface HasStatesConfiguration extends RuleCondition {
  type: 'hasStates';
  states: string[];
}

export default function HasStatesCondition({
  condition,
  handleConfigurationUpdate,
  onClear,
}: GenericConditionConfigurationProps<HasStatesConfiguration>) {
  const { t: tTicket } = useTranslationText('tickets');
  const { data: statesData, isLoading, isError } = useGetStates();
  const enumKeySelector = (opt: ReactSelectOption) => getEnumKeyFromSelectOption(statesData, opt);

  const handleChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    if (selectedOptions.length > 0) {
      handleConfigurationUpdate({
        type: 'hasStates',
        states: selectedOptions.map(enumKeySelector),
      } as HasStatesConfiguration);
    } else {
      onClear();
    }
  };

  return (
    <FormFieldWrapper label={tTicket('statesConditionLabel')} isLoading={isLoading}>
      <MultiSelectDropdown
        isError={isError}
        isLoading={isLoading}
        onChange={handleChange}
        options={toReactSelectFromEnumObject(tTicket, statesData)}
        openMenuOnClick
        isSearchable={false}
        placeholder={tTicket('selectState')}
        mappedValues={toReactSelectFromTranslationKey(
          tTicket,
          toTranslationKeysFromEnumKeys(statesData, condition?.states),
        )}
      />
    </FormFieldWrapper>
  );
}
