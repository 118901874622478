import { Agent } from '../models/monitoring/Agent';
import { MonitoringApiPrefix } from './MonitoringApi';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';

export function useGetAllAgents(options?: QueryOptions<Agent[]>) {
  const path = `${MonitoringApiPrefix}/agent`;
  return useGetQuery<Agent[]>([ServerStateKeysEnum.Agent], path, options);
}

export function useGetAgent(agentId: string, options?: QueryOptions<Agent>) {
  return useGetQuery<Agent>([ServerStateKeysEnum.Agent, agentId], `${MonitoringApiPrefix}/agent/${agentId}`, options);
}
