import { useState } from 'react';
import { RuleCondition } from '../../../models/operation/AutomationRuleModel';
import { GenericConditionConfigurationProps } from './GenericConditionConfigurationProps';
import SingleComponentTypeSelect, {
  ComponentTypeSuggestion,
} from './SingleComponentTypeSelect/SingleComponentTypeSelect';
import { useGetComponentType } from '../../../react-query/ComponentTypeApi';

interface HasAssignedComponentTypeConfiguration extends RuleCondition {
  type: 'hasAssignedComponentType';
  componentTypeId: string;
}

export default function HasAssignedComponentTypeCondition({
  condition,
  handleConfigurationUpdate,
  onClear,
}: GenericConditionConfigurationProps<HasAssignedComponentTypeConfiguration>) {
  const [selectedComponentTypeId, setSelectedComponentTypeId] = useState(condition?.componentTypeId);

  const update = (id?: string) => {
    if (id) {
      handleConfigurationUpdate({
        type: 'hasAssignedComponentType',
        componentTypeId: id,
      } as HasAssignedComponentTypeConfiguration);
    }
  };

  const handleComponentTypeChanged = (componentType: ComponentTypeSuggestion | null) => {
    setSelectedComponentTypeId(componentType?.id);
    if (!componentType) {
      onClear();
    }
    update(componentType?.id);
  };

  const { data: selectedComponentType } = useGetComponentType(selectedComponentTypeId!, {
    enabled: !!selectedComponentTypeId,
  });

  return <SingleComponentTypeSelect onChange={handleComponentTypeChanged} value={selectedComponentType || null} />;
}
