import { Collapsible } from '@aos/styleguide-react';
import { ComponentTypeAttribute } from '../../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import TextLabel from '../../../ui/text-label/TextLabel';
import TextAreaLabel from '../../../ui/text-area-label/TextAreaLabel';
import './AttributeDetails.scss';

interface AttributesDetailsProps {
  attributes: ComponentTypeAttribute[];
}

export function AttributesDetails({ attributes }: Readonly<AttributesDetailsProps>) {
  const { t } = useTranslationText('componentTypes');

  return (
    <Collapsible isInitiallyCollapsed={attributes.length === 0} header={t('attributes')} badgeCount={attributes.length}>
      <div className="attributes">
        {attributes.map((attribute) => (
          <div className="attribute" key={attribute.name}>
            <TextLabel label={t('attributeNameLabel')} value={attribute.name} />

            <TextAreaLabel
              label={t('attributeValueSelectorLabel')}
              value={attribute.valueSelector.query}
              autosizeOnLoad
            />
            <TextLabel label={t('attributeValueLabelPlaceholder')} value={attribute.valueSelector.label} />
          </div>
        ))}
      </div>
    </Collapsible>
  );
}
