import { RelationType } from '../models/operation/RelationTypeModel';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { MonitoringApiPrefix } from './MonitoringApi';
import { TicketingApiPrefix } from './TicketingSystemApi';

function useGetComponentRelationTypes(options?: QueryOptions<RelationType[]>) {
  const path = `${MonitoringApiPrefix}/relationType`;
  return useGetQuery<RelationType[]>(ServerStateKeysEnum.RelationTypes, path, options);
}

function useGetTicketRelationTypes(options?: QueryOptions<RelationType[]>) {
  const path = `${TicketingApiPrefix}/relationType`;
  return useGetQuery<RelationType[]>(ServerStateKeysEnum.RelationTypes, path, options);
}

export { useGetComponentRelationTypes, useGetTicketRelationTypes };
