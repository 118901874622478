import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownItemContext } from '../../ui/dropdown/Dropdown';
import { useAuthUser } from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import ActionBar from '../../ui/action-bar/ActionBar';
import { MutationKey, MutationPath, usePostMutation } from '../../react-query/MutationQueries';
import { CreateDiscoveryDto, DiscoveryCollection } from '../../models/operation/DiscoveryModel';
import { useTranslationText } from '../../translation/TranslationHooks';

interface ComponentTypeDetailsActionBarProps {
  componentTypeId: string;
}

export function ComponentTypeDetailsActionBar({ componentTypeId }: ComponentTypeDetailsActionBarProps) {
  const { t } = useTranslationText('componentTypes');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tError } = useTranslationText('errorTexts');
  const { hasPermission } = useAuthUser();

  const { mutate: discoveryMutate, isPending: isRunningDiscovery } = usePostMutation<
    CreateDiscoveryDto,
    DiscoveryCollection
  >(MutationKey.PostComponentTypeDiscovery, {
    onSuccess: async () => {
      toast.success(tSuccess('executedComponentDiscovery'));
    },
    onError: (error) => {
      toast.error(`${tError('componentDiscovery')}: ${error.message}`);
    },
  });

  const runDiscovery = (ctx: DropdownItemContext) => {
    if (!isRunningDiscovery) {
      toast.info(t('discoveryStarted'));
      discoveryMutate({ path: MutationPath.RunComponentTypeDiscovery, body: { componentType: componentTypeId } });
    }
    ctx.closeDropdown();
  };

  const getItems = (ctx: DropdownItemContext) => {
    const renderItems: JSX.Element[] = [];

    if (hasPermission(UserResourcePermissions.Discovery.Create)) {
      renderItems.push(
        <button
          className="dropdown-item"
          type="button"
          data-role="discover-component-type"
          key="discover-component-type"
          onClick={() => runDiscovery(ctx)}
        >
          {t('discoveryRun')}
        </button>,
      );
    }

    if (hasPermission(UserResourcePermissions.ComponentType.Read)) {
      renderItems.push(
        <a
          download
          href={`/api/monitoring/componentType/${componentTypeId}`}
          className="dropdown-item"
          onClick={() => ctx.closeDropdown()}
        >
          {t('exportComponentType')}
        </a>,
      );
    }

    return renderItems;
  };

  return <ActionBar right={<Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />} />;
}
