import { useCallback, useState } from 'react';
import { Controller, ControllerRenderProps, useFieldArray, UseFormReturn } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Collapsible } from '@aos/styleguide-react';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { TTranslateFn, useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import { CreateSystemModel, StageNameSuggestions, UpdateSystemModel } from '../../models/operation/SystemModel';
import TextInput from '../../ui/text-input/TextInput';
import { SystemStageLinksForm } from './SystemStageLinksForm';
import DatePicker from '../../ui/date-picker/DatePicker';
import ListItemWrapper from '../../ui/list-item-wrapper/ListItemWrapper';
import { useGetKnowledgeBaseArticles } from '../../react-query/KnowledgeBaseApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import './SystemStageForm.scss';
import NavigationRoutes from '../../routing/NavigationRoutes';
import SingleSelectCreatableDropdown from '../../ui/single-select-creatable-dropdown/SingleSelectCreatableDropdown';
import { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import { CollapsibleWithButtons } from '../../ui/collapsible/CollapsibleWithButtons';

interface SystemStageProps {
  form: UseFormReturn<CreateSystemModel | UpdateSystemModel, object>;
  index: number;
  onRemove: () => void;
}

export function SystemStagesForm({ form, index, onRemove }: SystemStageProps) {
  const { t } = useTranslationText('systems');
  const { control, formState, getValues } = form;

  const integrationsscheinids = getValues(`stages.${index}.integrationsscheinIds`);

  const { data: articles = [], isLoading } = useGetKnowledgeBaseArticles({
    integrationsscheinIds: integrationsscheinids,
    sort: 'created_at:desc',
  });

  const {
    append: appendStageLink,
    fields: stageLinks,
    remove: removeStageLink,
  } = useFieldArray({
    control,
    name: `stages.${index}.links`,
  });

  const [stageNameInput, setStageNameInput] = useState<string | undefined>('');

  const mapStageNamesToOptions = (tr: TTranslateFn): ReactSelectOption[] =>
    StageNameSuggestions.map((stageName) => ({
      value: stageName,
      label: tr(`${stageName}`),
    }));

  function createLabel(stageName: string) {
    return <div>{stageName}</div>;
  }

  const NameInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<CreateSystemModel | UpdateSystemModel, `stages.${typeof index}.name`>;
    }) => (
      <>
        <FormFieldWrapper error={formState?.errors?.stages?.at?.(index)?.name} label={t('name')} isRequired>
          <SingleSelectCreatableDropdown
            placeholder={t('stageNamePlaceholder')}
            onChange={(selectedOption) => {
              field.onChange(selectedOption?.label);
              void form.trigger('stages');
              setStageNameInput(selectedOption?.value);
            }}
            value={field.value ? { label: field.value, value: stageNameInput } : undefined}
            key={`stages-${index}-name`}
            options={mapStageNamesToOptions(t) || []}
            createLabel={createLabel}
            onCreateOption={(inputValue) => {
              field.onChange(inputValue);
              setStageNameInput(undefined);
            }}
            isError={formState?.errors?.stages?.at?.(index)?.name !== undefined}
          />
        </FormFieldWrapper>
        {!formState?.errors?.stages?.at?.(index)?.name && formState?.errors?.stages && (
          <div className="help is-danger">{formState?.errors?.stages.message}</div>
        )}
      </>
    ),
    [form, formState?.errors?.stages, index, stageNameInput, t],
  );

  const VersionInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<CreateSystemModel | UpdateSystemModel, `stages.${typeof index}.version`>;
    }) => (
      <FormFieldWrapper error={formState?.errors?.stages?.at?.(index)?.version} label={t('version')}>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={formState?.errors?.stages?.at?.(index)?.version}
          key={`stages-${index}-version`}
        />
      </FormFieldWrapper>
    ),
    [formState?.errors?.stages, index, t],
  );

  const LastUpdatedInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<CreateSystemModel | UpdateSystemModel, `stages.${typeof index}.lastUpdated`>;
    }) => (
      <DatePicker
        error={formState?.errors?.stages?.at?.(index)?.lastUpdated}
        placeholder={t('lastUpdatedPlaceholder')}
        onValueChange={field.onChange}
        value={field.value}
        isError={formState?.errors?.stages?.at?.(index)?.lastUpdated !== undefined}
        label={t('lastUpdated')}
        key={`stages-${index}-lastUpdated`}
      />
    ),
    [formState?.errors?.stages, index, t],
  );

  const IntegrationsscheinNamingInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<
        CreateSystemModel | UpdateSystemModel,
        `stages.${typeof index}.integrationsscheinNaming`
      >;
    }) => (
      <FormFieldWrapper
        error={formState?.errors?.stages?.at?.(index)?.integrationsscheinNaming}
        label={t('integrationsscheinNaming')}
      >
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={formState?.errors?.stages?.at?.(index)?.integrationsscheinNaming}
          key={`stages-${index}-integrationsscheinNaming`}
        />
      </FormFieldWrapper>
    ),
    [formState?.errors?.stages, index, t],
  );

  return (
    <ListItemWrapper
      mode="item"
      field={`stages.${index}`}
      index={index}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.System.Update}
      key={`stages.${index}`}
    >
      <div className="horizontal-wrapper">
        <div className="columns is-align-items-flex-start">
          <div className="column is-11">
            <Controller name={`stages.${index}.name`} control={control} defaultValue="" render={NameInput} />
          </div>
        </div>
        <div className="columns is-align-items-flex-start">
          <div className="column is-11">
            <Controller name={`stages.${index}.version`} control={control} defaultValue="" render={VersionInput} />
          </div>
        </div>
        <div className="columns is-align-items-flex-start">
          <div className="column is-11">
            <Controller name={`stages.${index}.lastUpdated`} control={control} render={LastUpdatedInput} />
          </div>
        </div>
        <div className="columns is-align-items-flex-start">
          <div className="column is-11">
            <Controller
              name={`stages.${index}.integrationsscheinNaming`}
              control={control}
              defaultValue=""
              render={IntegrationsscheinNamingInput}
            />
          </div>
        </div>
        <div className="column is-11">
          <CollapsibleWithButtons
            header={t('systemStageLinks')}
            isInitiallyCollapsed={false}
            addPermission={UserResourcePermissions.System.CreateOrUpdate}
            addBtnClicked={() =>
              appendStageLink({
                url: '',
                linkType: 'SOURCE_CODE',
              })
            }
          >
            <div>
              {stageLinks.map((field, linkIndex) => (
                <SystemStageLinksForm
                  form={form}
                  stageIndex={index}
                  linkIndex={linkIndex}
                  onRemove={() => removeStageLink(linkIndex)}
                  key={field.id}
                />
              ))}
            </div>
          </CollapsibleWithButtons>
          {articles && (
            <Collapsible
              header={t('integrationsccheine')}
              isInitiallyCollapsed={false}
              className="integrationsscheine-content"
              badgeCount={articles?.length}
            >
              <LoadingSpinner isLoading={isLoading}>
                <div>
                  {articles?.map((article) => (
                    <Link
                      to={NavigationRoutes.KnowledgeBaseArticleId(article.id)}
                      key={NavigationRoutes.KnowledgeBaseArticleId(article.id)}
                    >
                      <div className="integrationsschein-item">{article.title}</div>
                    </Link>
                  ))}
                </div>
              </LoadingSpinner>
            </Collapsible>
          )}
        </div>
      </div>
    </ListItemWrapper>
  );
}
