import classNames from 'classnames';
import './Tabs.scss';
import TabItem from '../../models/operation/TabItemModel';
import TabsEnum from '../../ticketing/tabs/TabsEnum';
import { useTranslationText } from '../../translation/TranslationHooks';

interface TabsProps {
  items: TabItem[];
  activeTab: TabsEnum;
  onClick: (label: TabsEnum) => void;
}

export default function Tabs({ items, activeTab, onClick }: TabsProps) {
  const { t } = useTranslationText('tickets');

  const tabElements = items.map((item) => (
    <li
      className={classNames({ 'is-active': item.label === activeTab })}
      key={item.label}
      data-role={`tab-${item.label}`}
    >
      <a onClick={() => onClick(item.label)}>{t(item.label)}</a>
    </li>
  ));

  return (
    <>
      <div className="tabs is-left">
        <ul>{tabElements}</ul>
      </div>
      {items.find((item) => item.label === activeTab)?.content || ''}
    </>
  );
}
