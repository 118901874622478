import { FieldError } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import useYupLocal from '../../../translation/YupLocal';
import { createContractorValidationSchema } from '../../../models/contract-management/ContractorValidationSchema';
import { ServiceTimeModel } from '../../../models/contract-management/ServiceTimeModel';
import { StrapiFile } from '../../../models/strapi/StrapiFile';
import { Contractor } from '../../../models/contract-management/ContractorModel';
import { MutationKey, MutationPath, usePatchMutation } from '../../../react-query/MutationQueries';
import { Patch, replaceProperty } from '../../../models/patch/PatchModel';
import FormPatchWrapper from '../../../ui/form-patch-wrapper/FormPatchWrapper';
import EditableTextInput from '../../../ui/editable-inputs/EditableTextInput';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { EditAssigneeIds } from './EditAssigneeIds';

export interface ContractorProps {
  contractor: Contractor;
  onChange: () => void;
}

export default function ContractorEdit({ contractor, onChange }: ContractorProps) {
  const { t } = useTranslationText('contractManagements');
  const { t: tError } = useTranslationText('errorTexts');
  const { yup } = useYupLocal();
  const validationSchema = createContractorValidationSchema(yup, t);
  const { mutate, isPending: mutationIsLoading } = usePatchMutation<Patch, Contractor>(MutationKey.PatchContractor);
  function onSubmit(
    property: keyof Contractor,
    newValue?: string | number | Date | ServiceTimeModel[] | Contractor | StrapiFile[] | null | undefined | string[],
    isFormValid: boolean = true,
    saveCompleted?: (endEditing: boolean) => void,
  ) {
    if (isFormValid) {
      mutate(
        { body: [replaceProperty(property, newValue)], path: MutationPath.PatchContractor(contractor.id.toString()) },
        {
          onSuccess: async () => {
            onChange();
            saveCompleted?.(true);
          },
          onError: () => {
            saveCompleted?.(false);
            toast.error(<Trans i18nKey="errorTexts.patchError.text" values={{ component: tError(property) }} />);
          },
        },
      );
    }
  }

  return (
    <div className="contractor-details columns is-align-items-flex-start">
      <div className="column is-6">
        <FormPatchWrapper<Contractor, string, FieldError>
          validationSchema={yup.object({ companyName: validationSchema.fields.companyName })}
          patchProperty="companyName"
          defaultValue={contractor?.companyName}
          render={({ field }, property, isFormValid, error) => (
            <EditableTextInput
              error={error?.message}
              label={t('companyName')}
              isLoading={mutationIsLoading}
              onChange={(newValue) => field.onChange(newValue)}
              onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
              placeholder={t('companyName')}
              value={field.value}
              requiredPermission={UserResourcePermissions.Contractor.Update}
              dataRole="contractorCompanyName"
            />
          )}
        />
        <div className="horizontal-wrapper">
          <div className="three-quarters">
            <FormPatchWrapper<Contractor, string, FieldError>
              validationSchema={yup.object({ street: validationSchema.fields.street })}
              patchProperty="street"
              defaultValue={contractor?.street}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('streetLabel')}
                  isLoading={mutationIsLoading}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('streetPlaceholder')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contractor.Update}
                  dataRole="contractorStreet"
                />
              )}
            />
          </div>
          <div className="one-quarter">
            <FormPatchWrapper<Contractor, string, FieldError>
              validationSchema={yup.object({ houseNumber: validationSchema.fields.houseNumber })}
              patchProperty="houseNumber"
              defaultValue={contractor?.houseNumber}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('houseNumberLabel')}
                  isLoading={mutationIsLoading}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('houseNumberPlaceholder')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contractor.Update}
                  dataRole="contractorHouseNumber"
                />
              )}
            />
          </div>
        </div>
        <div className="horizontal-wrapper">
          <div className="one-quarter">
            <FormPatchWrapper<Contractor, string, FieldError>
              validationSchema={yup.object({ postalCode: validationSchema.fields.postalCode })}
              patchProperty="postalCode"
              defaultValue={contractor?.postalCode}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('postalCodeLabel')}
                  isLoading={mutationIsLoading}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('postalCodePlaceholder')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contractor.Update}
                  dataRole="contractorPostalCode"
                />
              )}
            />
          </div>
          <div className="three-quarters">
            <FormPatchWrapper<Contractor, string, FieldError>
              validationSchema={yup.object({ city: validationSchema.fields.city })}
              patchProperty="city"
              defaultValue={contractor?.city}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('cityLabel')}
                  isLoading={mutationIsLoading}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('cityPlaceholder')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contractor.Update}
                  dataRole="contractorCity"
                />
              )}
            />
          </div>
        </div>
        <FormPatchWrapper<Contractor, string, FieldError>
          validationSchema={yup.object({ phoneNumber: validationSchema.fields.phoneNumber })}
          patchProperty="phoneNumber"
          defaultValue={contractor?.phoneNumber}
          render={({ field }, property, isFormValid, error) => (
            <EditableTextInput
              error={error?.message}
              label={t('phoneNumberLabel')}
              isLoading={mutationIsLoading}
              onChange={(newValue) => field.onChange(newValue)}
              onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
              placeholder={t('phoneNumberPlaceholder')}
              value={field.value ?? ''}
              requiredPermission={UserResourcePermissions.Contractor.Update}
              dataRole="contractorPhoneNumber"
            />
          )}
        />
        <FormPatchWrapper<Contractor, string, FieldError>
          validationSchema={yup.object({ faxNumber: validationSchema.fields.faxNumber })}
          patchProperty="faxNumber"
          defaultValue={contractor?.faxNumber}
          render={({ field }, property, isFormValid, error) => (
            <EditableTextInput
              error={error?.message}
              label={t('faxNumberLabel')}
              isLoading={mutationIsLoading}
              onChange={(newValue) => field.onChange(newValue)}
              onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
              placeholder={t('faxNumberPlaceholder')}
              value={field.value ?? ''}
              requiredPermission={UserResourcePermissions.Contractor.Update}
              dataRole="contractorFaxNumber"
            />
          )}
        />
        <FormPatchWrapper<Contractor, string, FieldError>
          validationSchema={yup.object({ email: validationSchema.fields.email })}
          patchProperty="email"
          defaultValue={contractor?.email}
          render={({ field }, property, isFormValid, error) => (
            <EditableTextInput
              error={error?.message}
              label={t('emailLabel')}
              isLoading={mutationIsLoading}
              onChange={(newValue) => field.onChange(newValue)}
              onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
              placeholder={t('emailPlaceholder')}
              value={field.value ?? ''}
              requiredPermission={UserResourcePermissions.Contractor.Update}
              dataRole="contractorEmail"
            />
          )}
        />
      </div>
      <div className="column is-6">
        <EditAssigneeIds
          mutationIsLoading={mutationIsLoading}
          onChange={(newValue: string[]) => onSubmit('assigneeIds', newValue)}
          assignedUserIds={contractor?.assigneeIds || []}
        />
      </div>
    </div>
  );
}
