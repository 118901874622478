export type BmsUrl = string;

export function isRooted(url: BmsUrl): boolean {
  return url.startsWith('http://') || url.startsWith('https://');
}

export function joinUrl(base: BmsUrl, extension: BmsUrl): BmsUrl {
  if (isRooted(extension)) {
    throw Error(`Unable to join '${base}' and '${extension}', as the extension url is absolute`);
  }

  const canonicalBase = base.endsWith('/') ? base.substring(0, base.length - 1) : base;
  const canonicalExtension = extension.startsWith('/') ? extension.substring(1) : extension;

  return `${canonicalBase}/${canonicalExtension}`;
}

export function toAbsoluteUrl(url: BmsUrl): BmsUrl {
  if (isRooted(url)) {
    return url;
  }
  return joinUrl(window.location.origin, url);
}
