import { useState } from 'react';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import {
  ComponentRelationDto,
  CreateRelationDto,
  RelationRefDto,
  RelationSpec,
} from '../../../models/operation/RelationTypeModel';
import SearchDropdown, { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import { MutationKey, MutationPath, usePostMutation } from '../../../react-query/MutationQueries';
import { mapRelationSpecsToOptions, mapRelationSpecToOption } from '../../../models/operation/RelationTypeFunctions';
import { BaseComponentItem, ComponentItem } from '../../../models/operation/ComponentModel';
import { useShortcut, useShortcutEventHandler } from '../../../custom-hooks/Shortcuts';
import KeyCombination from '../../../custom-hooks/KeyCombination';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { useRelationTypeTranslations, useTranslationText } from '../../../translation/TranslationHooks';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';
import SingleComponentSelect from '../../component-selects/SingleComponentSelect/SingleComponentSelect';

interface CreateComponentRelationProps {
  relationSpecs: RelationSpec[];
  currentComponent: ComponentItem;
  onCancel?: () => void;
  onSuccess?: (componentRelation: ComponentRelationDto) => void;
}

function CreateComponentRelation({
  relationSpecs,
  currentComponent,
  onCancel,
  onSuccess,
}: CreateComponentRelationProps) {
  const { translateRelation } = useRelationTypeTranslations();
  const { t } = useTranslationText('components');

  const [relation, setRelation] = useState<RelationSpec>();
  const [otherComponent, setOtherComponent] = useState<BaseComponentItem | null>(null);

  const mapOptionToRelationSpec = (option: SingleValue<ReactSelectOption>): RelationSpec | undefined =>
    relationSpecs.find((e) => e.identifier === option?.value) ?? undefined;

  const { mutate, isPending: isCreating } = usePostMutation<CreateRelationDto, ComponentRelationDto>(
    MutationKey.PostComponentRelation,
    {
      onSuccess: onSuccess ? (createdRelation) => onSuccess(createdRelation) : () => {},
      onError: () => {
        toast.error(t('createRelationError'));
      },
    },
  );

  const apply = () => {
    if (otherComponent && relation) {
      const isForward = relation.direction === 'forward';
      const currentId = isForward ? currentComponent.id : otherComponent.id;
      const targetRef: RelationRefDto = {
        id: isForward ? otherComponent.id : currentComponent.id,
      } as RelationRefDto;
      const newRelation = {
        target: targetRef,
        type: relation.type.identifier,
      } as CreateRelationDto;

      mutate({
        body: newRelation,
        path: MutationPath.CreateComponentRelation(currentId),
      });
    }
  };

  useShortcut(KeyCombination.CancelAction, onCancel);

  return (
    <ListItemWrapper
      field="create-component-relation"
      onAccept={apply}
      onCancel={onCancel}
      mode="update"
      isLoading={isCreating}
      deletePermission={UserResourcePermissions.ComponentRelation.Create}
    >
      <div className="component-relation-list">
        <div>
          <SearchDropdown<RelationSpec | undefined>
            mapValueToSelectOption={mapRelationSpecToOption(translateRelation)}
            onChange={(opt) => setRelation(mapOptionToRelationSpec(opt))}
            value={relation}
            options={mapRelationSpecsToOptions(translateRelation)(relationSpecs)}
            autoFocus
            noOptionsMessage={t('noRelationType')}
            isLoadingMessage={t('relationTypesAreLoading')}
            placeholder={t('selectRelationType')}
            requiredPermission={UserResourcePermissions.RelationType.Read}
            isClearable
            isSmall
          />
        </div>
        <div
          onKeyUp={useShortcutEventHandler(KeyCombination.Submit, () =>
            otherComponent && relation ? apply() : undefined,
          )}
        >
          <SingleComponentSelect onChange={setOtherComponent} value={otherComponent} disableLabel />
        </div>
      </div>
    </ListItemWrapper>
  );
}

export default CreateComponentRelation;
