import { Tag, TagCloud } from 'react-tagcloud';
import { useMemo } from 'react';
import './ArticleTagCloud.scss';
import WidgetWrapper from '../../WidgetWrapper';
import '../../../KnowledgeBaseDashboard.scss';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import { useGetFrequentTags } from '../../../../react-query/KnowledgeBaseTagApi';

interface ArticleTagCloudProps {
  onTagClick: (tag: string) => void;
}

export default function ArticleTagCloud({ onTagClick }: ArticleTagCloudProps) {
  const { t } = useTranslationText('knowledgeBases');
  const {
    data: frequentTags,
    isLoading,
    isError,
  } = useGetFrequentTags(10, {
    refetchOnWindowFocus: false,
  });

  const tags = useMemo(
    () => frequentTags?.map((tag) => ({ key: tag.id.toString(), value: tag.title, count: tag.count }) as Tag) ?? [],
    [frequentTags],
  );

  return (
    <WidgetWrapper title={t('tagCloud')} isLoading={isLoading} isError={isError} noContent={t('noTaggedArticles')}>
      {frequentTags && frequentTags.length > 0 && (
        <TagCloud
          className="tag-cloud"
          shuffle={false}
          minSize={15}
          maxSize={25}
          colorOptions={{
            hue: '#53565A',
            luminosity: 'dark',
          }}
          tags={tags}
          onClick={(tag: { value: string; count: number; key?: string | undefined }) => onTagClick(tag.value)}
        />
      )}
    </WidgetWrapper>
  );
}
