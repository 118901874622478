import { Button } from '@aos/styleguide-react';
import { useTranslationText } from '../../translation/TranslationHooks';
import './FilterResetButton.scss';

interface FilterResetButtonProps {
  handleReset: () => void;
}

export default function FilterResetButton({ handleReset }: FilterResetButtonProps) {
  const { t: tCommons } = useTranslationText('commons');

  return (
    <Button icon="undo" color="is-ghost" isConfirm onClick={handleReset}>
      {tCommons('resetFilter')}
    </Button>
  );
}
