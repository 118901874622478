import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { TicketQuery } from '@/models/operation/TicketQuery';
import MultiSeveritySelect from '@/ticketing/severity-selects/multi-severity-select/MultiSeveritySelect';

function mapFilterSeverityToSelectableSeverity(
  filterSeverities: (string | null)[] | null | undefined,
): string[] | null {
  return (filterSeverities?.filter((severity) => severity !== null) as string[]) ?? null;
}

interface SeverityFilterProps {
  label?: string;
  selectedSeverities: (string | null)[] | null | undefined;
  onChange: (name: keyof TicketQuery, value: string[]) => void;
  size?: BulmaSize;
}

export default function SeverityFilter({
  label,
  selectedSeverities,
  onChange,
  size = 'is-small',
}: SeverityFilterProps) {
  return (
    <MultiSeveritySelect
      onChange={(selectedSeverities) =>
        onChange('severities', selectedSeverities?.map((severity) => severity.key) ?? [])
      }
      value={mapFilterSeverityToSelectableSeverity(selectedSeverities)}
      size={size}
      label={label}
    />
  );
}
