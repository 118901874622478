import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { Ticket } from '../../../models/operation/TicketModel';
import { TicketState } from '../../../models/operation/TicketStatesModel';
import { Patch, replaceProperty } from '../../../models/patch/PatchModel';
import { invalidateAllTickets } from '../../../react-query/InvalidationQueries';
import { MutationKey, MutationPath, usePatchMutation } from '../../../react-query/MutationQueries';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface MenuStateItemProps {
  state: TicketState;
  ticket: Ticket;
  setIsLoading: (state: boolean) => void;
}

export default function MenuStateItem({ state, ticket, setIsLoading }: MenuStateItemProps) {
  const { t } = useTranslationText('tickets');
  const { t: tError } = useTranslationText('errorTexts');

  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate, isPending: mutationIsLoading } = usePatchMutation<Patch, Ticket>(MutationKey.PatchTicket);
  const queryClient = useQueryClient();

  useEffect(() => {
    setIsLoading(mutationIsLoading);
  }, [mutationIsLoading, setIsLoading]);

  const onChanges = (newValue: string, ticketToChange: Ticket) => {
    mutate(
      { body: [replaceProperty('state', newValue)], path: MutationPath.PatchTicket(ticketToChange.id.toString()) },
      {
        onSuccess: async () => {
          searchParams.delete('pagePerState');
          setSearchParams(searchParams);
          await invalidateAllTickets(queryClient);
        },
        onError: () => {
          toast.error(tError('editTicketStateError'));
        },
      },
    );
  };

  return (
    <div
      className="dropdown-item"
      data-role={`ticket-menu-state-${state.key}`}
      onClick={() => onChanges(state.key, ticket)}
    >
      {t(state.translationKey)}
    </div>
  );
}
