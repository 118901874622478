import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { TaskDto, TaskState } from '../../../../models/operation/TaskDto';
import { Patch, replaceProperty } from '../../../../models/patch/PatchModel';
import { invalidateTicketTasks } from '../../../../react-query/InvalidationQueries';
import { MutationKey, MutationPath, usePatchMutation } from '../../../../react-query/MutationQueries';
import { useTranslationText } from '../../../../translation/TranslationHooks';

interface TaskStateMenuItemProps {
  state: TaskState;
  task: TaskDto;
  setIsLoading: (state: boolean) => void;
}

export default function TaskStateMenuItem({ state, task, setIsLoading }: TaskStateMenuItemProps) {
  const { t } = useTranslationText('tickets');
  const { t: tError } = useTranslationText('errorTexts');

  const { mutate, isPending: mutationIsLoading } = usePatchMutation<Patch, TaskDto>(MutationKey.PatchTask);
  const queryClient = useQueryClient();

  useEffect(() => {
    setIsLoading(mutationIsLoading);
  }, [mutationIsLoading, setIsLoading]);

  const onChanges = (newValue: string, taskToChange: TaskDto) => {
    mutate(
      {
        body: [replaceProperty('state', newValue), replaceProperty('hasOpenQuestions', false)],
        path: MutationPath.PatchTask(taskToChange.id),
      },
      {
        onSuccess: async () => {
          await invalidateTicketTasks(queryClient, taskToChange.ticketSnapshot.originalTicketId);
        },
        onError: () => {
          toast.error(tError('editTaskStateError'));
        },
      },
    );
  };

  return (
    <div
      className="dropdown-item"
      data-role={`task-menu-state-${state.toLocaleLowerCase()}`}
      onClick={() => onChanges(state, task)}
    >
      {t(state.toLocaleLowerCase())}
    </div>
  );
}
