import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { FieldError } from 'react-hook-form';
import { Collapsible } from '@aos/styleguide-react';
import { Contract, PatchContractModel } from '../../models/contract-management/ContractManagementModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import { MutationKey, MutationPath, usePatchMutation } from '../../react-query/MutationQueries';
import { Patch, replaceProperty } from '../../models/patch/PatchModel';
import FormPatchWrapper from '../../ui/form-patch-wrapper/FormPatchWrapper';
import EditableTextInput from '../../ui/editable-inputs/EditableTextInput';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import EditableDatePicker from '../../ui/editable-inputs/EditableDatePicker';
import EditableTextArea from '../../ui/editable-inputs/EditableTextArea';
import useYupLocal from '../../translation/YupLocal';
import { contractValidationSchema } from '../../models/contract-management/ContractValidationSchema';
import ChangeableAttachments from './ChangeableAttachments';
import EditableServiceTimes from './EditableServiceTimes/EditableServiceTimes';

interface ContractDetailsProps {
  contract: Contract;
  onChange: () => void;
  isFetching?: boolean;
}

export default function ContractDetails({ contract, onChange, isFetching }: Readonly<ContractDetailsProps>) {
  const { t } = useTranslationText('contractManagements');
  const { t: tError } = useTranslationText('errorTexts');
  const { yup } = useYupLocal();

  const validationSchema = contractValidationSchema(yup, t);

  const { mutate, isPending: isPatching } = usePatchMutation<Patch, Contract>(MutationKey.PatchContract);

  function onSubmit<K extends keyof PatchContractModel>(
    property: K,
    newValue?: PatchContractModel[K],
    isFormValid: boolean = true,
    saveCompleted?: (endEditing: boolean) => void,
  ) {
    if (contract !== undefined && isFormValid) {
      mutate(
        { body: [replaceProperty(property, newValue)], path: MutationPath.PatchContract(contract.id.toString()) },
        {
          onSuccess: async () => {
            onChange();
            saveCompleted?.(true);
          },
          onError: () => {
            saveCompleted?.(false);
            toast.error(<Trans i18nKey="errorTexts.patchError.text" values={{ component: tError(property) }} />);
          },
        },
      );
    }
  }

  return (
    <>
      <Collapsible isInitiallyCollapsed={false} header={t('contractDetails')}>
        <div className="columns">
          <div className="column is-half">
            <FormPatchWrapper<PatchContractModel, string, FieldError>
              validationSchema={yup.object({ internalNumber: validationSchema.fields.internalNumber })}
              patchProperty="internalNumber"
              defaultValue={contract?.internalNumber}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('internalContractNumberLabel')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('internalContractNumberLabel')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractInternalNumber"
                  options={{
                    isRequired: true,
                  }}
                />
              )}
            />
          </div>
          <div className="column is-half">
            <FormPatchWrapper<PatchContractModel, string, FieldError>
              patchProperty="externalNumber"
              validationSchema={yup.object({ externalNumber: validationSchema.fields.externalNumber })}
              defaultValue={contract?.externalNumber}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('externalContractNumberLabel')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('externalContractNumberLabel')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractExternalNumber"
                />
              )}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column is-fullwidth">
            <FormPatchWrapper<PatchContractModel, string, FieldError>
              patchProperty="contractualObject"
              validationSchema={yup.object({ contractualObject: validationSchema.fields.contractualObject })}
              defaultValue={contract?.contractualObject}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('contractualObjectLabel')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('contractualObjectLabel')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractContractualObject"
                  options={{
                    isRequired: true,
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column is-one-third">
            <FormPatchWrapper<PatchContractModel, Date, FieldError>
              patchProperty="startDate"
              validationSchema={yup.object({ startDate: validationSchema.fields.startDate })}
              context={contract}
              defaultValue={contract?.startDate}
              render={({ field }, property, isFormValid, error) => (
                <EditableDatePicker
                  error={error?.message}
                  label={t('startDateLabel')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('startDateLabel')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractStartDate"
                  options={{
                    isRequired: true,
                  }}
                />
              )}
            />
          </div>
          <div className="column is-one-third">
            <FormPatchWrapper<PatchContractModel, Date | undefined, FieldError>
              patchProperty="endDate"
              validationSchema={yup.object({ endDate: validationSchema.fields.endDate })}
              context={contract}
              defaultValue={contract?.endDate}
              render={({ field }, property, isFormValid, error) => (
                <EditableDatePicker
                  error={error?.message}
                  label={t('endDateLabel')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('endDateLabel')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractEndDate"
                  options={{
                    isClearable: true,
                  }}
                />
              )}
            />
          </div>
          <div className="column is-one-third">
            <FormPatchWrapper<PatchContractModel, Date | undefined, FieldError>
              patchProperty="warrantyPeriod"
              validationSchema={yup.object({ warrantyPeriod: validationSchema.fields.warrantyPeriod })}
              defaultValue={contract?.warrantyPeriod}
              render={({ field }, property, isFormValid, error) => (
                <EditableDatePicker
                  error={error?.message}
                  label={t('warrantyPeriodLabel')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('warrantyPeriodLabel')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractWarrantyPeriod"
                  options={{
                    isClearable: true,
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <FormPatchWrapper<PatchContractModel, string | undefined, FieldError>
              patchProperty="type"
              validationSchema={yup.object({ type: validationSchema.fields.type })}
              defaultValue={contract?.type}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('type')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('type')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractType"
                />
              )}
            />
          </div>
          <div className="column">
            <FormPatchWrapper<PatchContractModel, string | undefined, FieldError>
              patchProperty="penalty"
              validationSchema={yup.object({ penalty: validationSchema.fields.penalty })}
              defaultValue={contract?.penalty}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('penalty')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('penalty')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractPenalty"
                />
              )}
            />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <FormPatchWrapper<PatchContractModel, string | undefined, FieldError>
              patchProperty="supportBeginHours"
              validationSchema={yup.object({ supportBeginHours: validationSchema.fields.supportBeginHours })}
              defaultValue={contract?.supportBeginHours}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('supportBeginHours')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('supportBeginHours')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractSupportBeginHours"
                />
              )}
            />
          </div>
          <div className="column">
            <FormPatchWrapper<PatchContractModel, string | undefined, FieldError>
              patchProperty="responseHours"
              validationSchema={yup.object({ responseHours: validationSchema.fields.responseHours })}
              defaultValue={contract?.responseHours}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('responseHours')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('responseHours')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractResponseHours"
                />
              )}
            />
          </div>
          <div className="column">
            <FormPatchWrapper<PatchContractModel, string | undefined, FieldError>
              patchProperty="recoveryHours"
              validationSchema={yup.object({ recoveryHours: validationSchema.fields.recoveryHours })}
              defaultValue={contract?.recoveryHours}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextInput
                  error={error?.message}
                  label={t('recoveryHours')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('recoveryHours')}
                  value={field.value}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractRecoveryHours"
                />
              )}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <FormPatchWrapper<PatchContractModel, string | undefined, FieldError>
              patchProperty="description"
              validationSchema={yup.object({ description: validationSchema.fields.description })}
              defaultValue={contract?.description}
              render={({ field }, property, isFormValid, error) => (
                <EditableTextArea
                  error={error?.message}
                  label={t('descriptionLabel')}
                  isLoading={isPatching}
                  onChange={(newValue) => field.onChange(newValue)}
                  onAcceptNewValue={(value, callback) => onSubmit(property, value, isFormValid, callback)}
                  placeholder={t('descriptionLabel')}
                  value={field.value ?? ''}
                  requiredPermission={UserResourcePermissions.Contract.Update}
                  dataRole="contractDescription"
                />
              )}
            />
          </div>
        </div>
      </Collapsible>
      <EditableServiceTimes
        currentServiceTimes={contract.serviceTimes}
        onSubmit={onSubmit}
        isSaving={isPatching || isFetching}
      />
      <Collapsible
        isInitiallyCollapsed={contract?.attachments ? contract.attachments.length === 0 : true}
        header={t('attachments')}
      >
        <ChangeableAttachments onSubmit={onSubmit} contract={contract} />
      </Collapsible>
    </>
  );
}
