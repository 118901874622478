import useRuntimeConfig from '../runtime-config/RuntimeConfig';
import IFrameNavigator from '../dashboard/IFrameNavigator';

export default function Reports() {
  const [runtimeConfig] = useRuntimeConfig();

  return runtimeConfig?.dashboardUrl ? (
    <iframe
      title="reports"
      className="dashboard-frame"
      src={`${runtimeConfig.dashboardUrl}/Component-Reports?theme=light&kiosk`}
      onLoad={IFrameNavigator}
    />
  ) : (
    <></>
  );
}
