import queryString from 'query-string';
import { DecodedValueMap } from 'use-query-params';
import { AlertFilter, AlertItem, ActiveAlert } from '../models/operation/AlertModel';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { queryStringFromObject } from './QueryUtils';
import { MonitoringApiPrefix } from './MonitoringApi';
import { ActiveAlertQueryParamConfigModel } from '../models/operation/ActiveAlertQuery';
import { Page } from '../models/pagination/Pagination';
import { ActiveAlertFilterModel } from '../models/operation/ActiveAlertFilterModel';

export default function useGetAlertsForComponent(
  componentId: string,
  filter?: AlertFilter,
  options?: QueryOptions<AlertItem[]>,
) {
  return useGetQuery<AlertItem[]>(
    [ServerStateKeysEnum.AlertsForComponent, componentId],
    `${MonitoringApiPrefix}/component/${componentId}/alert${filter ? `?${queryStringFromObject(filter)}` : ''}`,
    options,
  );
}

export function pathForAllActiveAlertsWithFilter(query?: DecodedValueMap<ActiveAlertQueryParamConfigModel>) {
  const filterToStringify = { ...query };
  if (query?.appliedFilter) {
    filterToStringify.appliedFilter = JSON.stringify(query.appliedFilter);
  }
  if (query?.unfilteredColumns) {
    filterToStringify.unfilteredColumns = query?.unfilteredColumns;
  }

  const queryParams = query ? `&${queryString.stringify(filterToStringify)}` : '';

  return `${MonitoringApiPrefix}/alert?active${queryParams}`;
}

export function useGetAllActiveAlerts(
  options?: QueryOptions<Page<ActiveAlert>>,
  query?: DecodedValueMap<ActiveAlertQueryParamConfigModel>,
) {
  const path = pathForAllActiveAlertsWithFilter(query);
  return useGetQuery<Page<ActiveAlert>>([ServerStateKeysEnum.ActiveAlerts, query], path, options);
}

export function useGetAlerts(filter?: AlertFilter, options?: QueryOptions<AlertItem[]>) {
  return useGetQuery<AlertItem[]>(
    [ServerStateKeysEnum.Alerts, filter],
    `${MonitoringApiPrefix}/alert${filter ? `?${queryStringFromObject(filter)}` : ''}`,
    options,
  );
}

export function useGetActiveAlertFilter() {
  return useGetQuery<ActiveAlertFilterModel[]>(
    [ServerStateKeysEnum.ActiveAlertFilter],
    `${MonitoringApiPrefix}/active-alert-filter`,
  );
}
