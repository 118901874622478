import SingleRelationTypeSelect, { SingleRelationTypeSelectProps } from '@/relation-types/SingleRelationTypeSelect';
import { RelationSpec } from '@/models/operation/RelationTypeModel';
import {
  mapRelationSpecToDisableableOption,
  mapRelationSpecToDisableableOptions,
} from '@/ticketing/ticket-relations/create-ticket-relation-menu/single-relation-type-select-with-disableable-options/SingleRelationTypeSelectWithDisableableOptionsUtils';

interface SingleRelationTypeSelectWithDisableableOptionsProps extends SingleRelationTypeSelectProps {
  relationSpecAvailableForRelation: (relationSpec: RelationSpec) => boolean;
}

function SingleRelationTypeSelectWithDisableableOptions({
  relationSpecAvailableForRelation,
  ...props
}: SingleRelationTypeSelectWithDisableableOptionsProps) {
  return (
    <SingleRelationTypeSelect
      customMapRelationSpecToOption={mapRelationSpecToDisableableOption}
      customMapRelationSpecsToOptions={(relationSpecs) =>
        mapRelationSpecToDisableableOptions(relationSpecs, relationSpecAvailableForRelation)
      }
      {...props}
    />
  );
}

export default SingleRelationTypeSelectWithDisableableOptions;
