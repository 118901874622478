import classNames from 'classnames';
import RuleConditionItem from './RuleConditionItem';
import { useGetConditionTypes } from '@/react-query/AutomationApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { useTranslationText } from '@/translation/TranslationHooks';
import RuleConditionItemAdded from './RuleConditionItemAdded';
import { RuleCondition } from '@/models/operation/AutomationRuleModel';
import FormFieldWrapper from '@/ui/form-field-wrapper/FormFieldWrapper';

export interface ConditionsOverviewProps {
  conditions?: RuleCondition[];
  showOnlySummary?: boolean;
  onConditionConfigured: (condition: RuleCondition) => void;
  onContinueClicked: () => void;
  onUncheckCondition: (conditionConfiguration?: RuleCondition) => void;
}

export default function RuleConditionsOverview({
  conditions,
  showOnlySummary,
  onConditionConfigured,
  onContinueClicked,
  onUncheckCondition,
}: ConditionsOverviewProps) {
  const { data: conditionTypes, isLoading: isLoadingConditions, isError } = useGetConditionTypes();
  const { t } = useTranslationText('automations');
  const { t: tCommon } = useTranslationText('commons');

  return (
    <LoadingSpinner isLoading={isLoadingConditions} errors={isError && 'Condition load failed'}>
      <div className="rule-wrapper">
        <FormFieldWrapper label={t('selectConditionTitle')} />
        {!showOnlySummary && <p className="mb-5 mt-1">{t('selectConditionDescription')}</p>}
        <div className={classNames({ summaryGrid: showOnlySummary })}>
          {conditionTypes
            ?.sort((a, b) => a.localeCompare(b))
            .map((conditionType) => {
              const condition = (conditions ?? []).find((c) => c.type === conditionType);
              if (showOnlySummary) {
                return condition && <RuleConditionItemAdded key={conditionType} value={t(`${conditionType}_title`)} />;
              }
              return (
                <RuleConditionItem
                  key={conditionType}
                  conditionType={conditionType}
                  condition={condition}
                  handleChange={onConditionConfigured}
                  onUncheck={onUncheckCondition}
                />
              );
            })}
          {!showOnlySummary && (
            <div
              className={classNames('actions', { isVisible: conditions && !showOnlySummary })}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="is-divider" />
              <div className="is-flex is-justify-content-end">
                <button type="button" className="button is-primary" onClick={onContinueClicked}>
                  {tCommon('continue')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </LoadingSpinner>
  );
}
