import { ErrorContext, ErrorHandler, ErrorHandlingResult } from './ErrorHandler';

export type ApiError = {
  status: {
    code: number;
    reason: string;
  };
  message: string;
  details: any | null | undefined;
};

export function createApiErrorResponseHandler(
  handler: (ctx: ErrorContext, apiError: ApiError) => ErrorHandlingResult | void,
): ErrorHandler {
  return (ctx) => {
    if (ctx.error.isNetworkError) {
      return ErrorHandlingResult.NOT_HANDLED;
    }
    const apiError = ctx.error.body as ApiError;
    if (!apiError) {
      return ErrorHandlingResult.NOT_HANDLED;
    }
    if (apiError?.status && apiError.status.reason && apiError.status.code && apiError.message) {
      return handler(ctx, apiError);
    }
    return ErrorHandlingResult.NOT_HANDLED;
  };
}
