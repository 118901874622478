import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  NumericObjectParam,
  QueryParamConfigMap,
  StringParam,
} from 'use-query-params';

export interface TicketQuery {
  title: string;
  assigneeIds: string[];
  severities: string[];
  page: number;
  size: number;
  components: string[];
  componentTypeIds: string[]; // use-query-params does not support number array. Mapping to number array is done later
  states: string[];
  query: string;
  authors: string[];
  sort: string;
  onlyFavorites: boolean;
  createdAfter: string;
  createdUntil: string;
  modifiedAfter: string;
  modifiedUntil: string;
  type: string;
}

export const TicketQueryParamConfigMap: TicketQueryParamConfigModel = {
  title: StringParam,
  assigneeIds: ArrayParam,
  severities: ArrayParam,
  page: NumberParam,
  size: NumberParam,
  components: ArrayParam,
  componentTypeIds: ArrayParam,
  states: ArrayParam,
  query: StringParam,
  authors: ArrayParam,
  sort: StringParam,
  onlyFavorites: BooleanParam,
  createdAfter: StringParam,
  createdUntil: StringParam,
  modifiedAfter: StringParam,
  modifiedUntil: StringParam,
  pagePerState: NumericObjectParam,
  type: StringParam,
  name: StringParam,
};

export interface TicketQueryParamConfigModel extends QueryParamConfigMap {
  title: typeof StringParam;
  assigneeIds: typeof ArrayParam;
  severities: typeof ArrayParam;
  page: typeof NumberParam;
  size: typeof NumberParam;
  components: typeof ArrayParam;
  componentTypeIds: typeof ArrayParam;
  states: typeof ArrayParam;
  query: typeof StringParam;
  authors: typeof ArrayParam;
  sort: typeof StringParam;
  onlyFavorites: typeof BooleanParam;
  createdAfter: typeof StringParam;
  createdUntil: typeof StringParam;
  modifiedAfter: typeof StringParam;
  modifiedUntil: typeof StringParam;
  pagePerState: typeof NumericObjectParam;
  type: typeof StringParam;
  name: typeof StringParam;
}

export enum TicketSort {
  CreatedAtDesc = 'createdAt:desc',
  CreatedAtAsc = 'createdAt:asc',
  Relevance = 'relevance',
}
