import { KeyboardEvent, useCallback, useEffect } from 'react';
import { MetaOptions, ShortcutOptions } from '../models/shortcuts/ShortcutTypes';
import KeyCombination from './KeyCombination';

export type ShortcutHandler = (e: KeyboardEvent) => void;

const evaluateMetaOptions = (isPressed: boolean, options?: MetaOptions) => {
  if (options?.required ?? false) {
    return isPressed;
  }
  if (!(options?.accepted ?? false)) {
    return !isPressed;
  }
  return true;
};

const matchesMetaKeys = (event: KeyboardEvent, options?: ShortcutOptions) =>
  evaluateMetaOptions(event.shiftKey, options?.shift) &&
  evaluateMetaOptions(event.ctrlKey, options?.ctrl) &&
  evaluateMetaOptions(event.metaKey, options?.meta) &&
  evaluateMetaOptions(event.altKey, options?.alt);

const inputElementTags = ['textarea', 'input', 'select'];
const isInputElement = (element: Element) => inputElementTags.indexOf(element.tagName.toLowerCase()) !== -1;

const isActiveWithinCurrentEnvironment = (event: KeyboardEvent, options?: ShortcutOptions) => {
  if ((options?.disabledWithinInputs ?? true) && isInputElement(event.target as Element)) {
    return false;
  }
  return true;
};

const matches = (event: KeyboardEvent, combination: KeyCombination) =>
  event.key.toLowerCase() === combination.key &&
  matchesMetaKeys(event, combination.options) &&
  isActiveWithinCurrentEnvironment(event, combination.options);

export const useShortcut = (combination: KeyCombination, handler?: ShortcutHandler) => {
  const callback = useCallback(
    (event: any) => {
      const keyboardEvent = event as KeyboardEvent;
      if (matches(keyboardEvent, combination) && handler) {
        keyboardEvent.preventDefault();
        handler(keyboardEvent);
      }
    },
    [handler, combination],
  );

  useEffect(() => {
    document.addEventListener('keydown', callback, false);
    return () => {
      document.removeEventListener('keydown', callback, false);
    };
  });
};

export function useShortcutEventHandler<T extends Element>(
  combination: KeyCombination,
  handler?: ShortcutHandler,
): (e: KeyboardEvent<T>) => void {
  return (event: KeyboardEvent<T>) => {
    if (matches(event, combination) && handler) {
      event.preventDefault();
      handler(event);
    }
  };
}
