import { useCallback } from 'react';
import { Table as AosTable } from '@aos/styleguide-react';
import useToggle from '../../custom-hooks/useToggle';
import { ColumnConfigType, useColumnConfigContext } from './ColumnConfig';

interface TableHeadElementProps {
  value: string;
  handleMoveButtonClick: (headerText: string, moveLeft: boolean) => void;
  disableMoveLeft: boolean;
  disableMoveRight: boolean;
}

function TableHeadElement({ value, handleMoveButtonClick, disableMoveLeft, disableMoveRight }: TableHeadElementProps) {
  const [showMoveButtons, setShowMoveButtons] = useToggle(false);

  return (
    <th
      style={{ whiteSpace: 'nowrap' }}
      onMouseEnter={() => setShowMoveButtons(!showMoveButtons)}
      onMouseLeave={() => setShowMoveButtons(!showMoveButtons)}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '0.25rem' }}>
        <button
          type="button"
          style={{ opacity: `${showMoveButtons ? '100%' : 0}`, position: 'relative', top: 0, left: 0 }}
          onClick={() => handleMoveButtonClick(value, true)}
          disabled={disableMoveLeft}
        >
          {'<'}
        </button>
        <span>{value}</span>
        <button
          type="button"
          style={{ opacity: `${showMoveButtons ? '100%' : 0}`, position: 'relative', top: 0, left: 0 }}
          onClick={() => handleMoveButtonClick(value, false)}
          disabled={disableMoveRight}
        >
          {'>'}
        </button>
      </div>
    </th>
  );
}

export function ActiveAlertTableHeader() {
  const { columnConfig, dispatch } = useColumnConfigContext();
  const findOuterColumn = useCallback((outerIndex: number, ccValues: ColumnConfigType, left = true) => {
    const outerColumn = ccValues.find((column) => column.displayIndex === outerIndex);
    if (outerColumn?.visible) return outerColumn;
    return findOuterColumn(left ? outerIndex + 1 : outerIndex - 1, ccValues, left);
  }, []);
  const mostLeftVisibleHeader = findOuterColumn(0, columnConfig, true).headerText;
  const mostRightVisibleHeader = findOuterColumn(columnConfig.length - 1, columnConfig, false).headerText;

  const swapTableHeads = useCallback(
    (headerText: string, moveLeft: boolean) => {
      dispatch({ type: moveLeft ? 'move-left' : 'move-right', configToChange: headerText });
    },
    [dispatch],
  );
  return (
    <AosTable.Header className="sticky-header">
      <AosTable.Row>
        {columnConfig
          .filter((column) => column.visible)
          .toSorted((elemA, elemB) => (elemA.displayIndex < elemB.displayIndex ? -1 : 1))
          .map((column) => (
            <TableHeadElement
              value={column.headerText}
              handleMoveButtonClick={swapTableHeads}
              disableMoveLeft={mostLeftVisibleHeader === column.headerText}
              key={column.headerText}
              disableMoveRight={mostRightVisibleHeader === column.headerText}
            />
          ))}
      </AosTable.Row>
    </AosTable.Header>
  );
}
