import classNames from 'classnames';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import { German } from 'flatpickr/dist/l10n/de';
import { useState } from 'react';
import dayjs from 'dayjs';
import { FieldError, Merge } from 'react-hook-form';
import { FLATPICKR_DATE_FORMAT, FLATPICKR_DATETIME_FORMAT } from '../../models/dates/dateConstants';
import FormFieldWrapper from '../form-field-wrapper/FormFieldWrapper';

interface DatePickerProps {
  allowInput?: boolean;
  enableTime?: boolean;
  isError?: boolean;
  label?: string;
  onValueChange?: (newDate: Date) => void;
  placeholder?: string;
  readOnly?: boolean;
  value: Date | undefined;
  isLoading?: boolean;
  isRequired?: boolean;
  isUpdated?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  error?: FieldError | Merge<FieldError, (FieldError | undefined)[]> | undefined;
}

export default function DatePicker({
  allowInput = true,
  enableTime = false,
  isError,
  label,
  onValueChange,
  placeholder,
  readOnly,
  value,
  isRequired = false,
  isLoading,
  isUpdated = false,
  minDate,
  maxDate,
  disabled = false,
  error,
}: DatePickerProps) {
  const [isUpdatedInternal, setIsUpdated] = useState(isUpdated);
  return (
    <FormFieldWrapper
      error={error}
      isUpdated={isUpdatedInternal}
      isLoading={isLoading}
      isRequired={isRequired}
      label={label}
      noGridControl
    >
      <Flatpickr
        className={classNames('input', {
          'is-danger': isError,
        })}
        onChange={([newDate]) => {
          onValueChange?.(newDate);
          setIsUpdated(false);
          setIsUpdated(true);
        }}
        onClose={() => {
          setIsUpdated(false);
          setIsUpdated(true);
        }}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        value={value ? dayjs(value).format('DD.MM.YYYY, HH:mm') : ''}
        options={{
          enableTime: (enableTime && !readOnly) ?? false,
          noCalendar: readOnly,
          allowInput: allowInput && !readOnly,
          dateFormat: enableTime ? FLATPICKR_DATETIME_FORMAT : FLATPICKR_DATE_FORMAT,
          locale: German,
          minDate,
          maxDate,
        }}
      />
    </FormFieldWrapper>
  );
}
