import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { getSeverityIcon } from '../../../models/operation/TicketFunctions';
import { useGetUser } from '../../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { TaskTicketSnapshot } from '../../../models/operation/TaskTicketSnapshot';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import UserRoleCheck from '../../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import DateFormats from '../../../models/date-formats/DateFormats';

interface TicketInfoProps {
  ticketSnapshot: TaskTicketSnapshot;
}

function TicketInfo({ ticketSnapshot }: TicketInfoProps) {
  const { t } = useTranslationText('tickets');
  const { t: tCommon } = useTranslationText('commons');

  const { data: user } = useGetUser(ticketSnapshot.assigneeId!, {
    enabled: !!ticketSnapshot.assigneeId,
  });

  const { data: author } = useGetUser(ticketSnapshot.author.id!, {
    enabled: !!ticketSnapshot.author.id,
  });

  return (
    <>
      <div className="ticket-component">
        <div className="label">{t('assignedComponent')}</div>
        {ticketSnapshot.assignedComponent && (
          <div className="component-link-wrapper">
            <div>{ticketSnapshot.assignedComponent?.displayName}</div>
            <UserRoleCheck requiredPermission={UserResourcePermissions.Component.Read}>
              <Link
                to={NavigationRoutes.ComponentId(ticketSnapshot.assignedComponent?.id)}
                className="link"
                title={t('linkToComponentTooltip', { componentName: ticketSnapshot.assignedComponent?.displayName })}
              >
                <FontAwesomeIcon icon={faUpRightFromSquare} />
              </Link>
            </UserRoleCheck>
          </div>
        )}
      </div>
      <div className="ticket-severity">
        <span className="label">{t('fieldSeverity')}</span>
        <div className="severity-content">
          <span className="severity-icon-wrapper">{getSeverityIcon(ticketSnapshot.severity!)}</span>
          <span>{t(ticketSnapshot.severity.toLowerCase()!)}</span>
        </div>
      </div>
      <div className="ticket-assignee">
        <span className="label">{t('assignedServiceUsersLabel')}</span>
        <span>{user ? `${user?.firstName} ${user?.lastName}` : t('noAssignedUser')} </span>
        {user && (
          <a className="email" href={`mailTo:${user?.email}`}>
            {user?.email}
          </a>
        )}
      </div>
      <UserRoleCheck requiredPermission={UserResourcePermissions.Ticket.Read}>
        <div className="ticket-author">
          <span className="label">{t('authorUsersLabel')}</span>
          <span>{author ? `${author?.firstName} ${author?.lastName}` : t('noAssignedUser')} </span>
          {user && (
            <a className="email" href={`mailTo:${author?.email}`}>
              {author?.email}
            </a>
          )}
          <span className="created-at">
            {tCommon('at')} {dayjs(ticketSnapshot.createdAt).format(DateFormats.FULLDATE_HOURS_MINS)} {tCommon('clock')}
          </span>
        </div>
      </UserRoleCheck>
    </>
  );
}

export default TicketInfo;
