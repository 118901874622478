import { useCallback, useEffect } from 'react';
import { Controller, ControllerRenderProps, useFieldArray, UseFormReturn } from 'react-hook-form';
import { Agent } from '../../../../models/monitoring/Agent';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import { mapSnmpVersionsToOptions, useModuleErrors } from '../../../../models/operation/AgentFunctions';
import { SnmpModule } from '../../../../models/monitoring/AgentModule';
import FormFieldWrapper from '../../../../ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '../../../../ui/text-input/TextInput';
import { intOrUndefined } from '../../../../utils/NumberConversion';
import SingleValueInput from '../../../../ui/single-value-input/SingleValueInput';
import { UserResourcePermissions } from '../../../../auth/AuthUserRoles';
import { SnmpVersion } from '../../../../models/snmp/Snmp';
import { SnmpAuthForm } from './SnmpAuthForm';

import { SnmpModuleFieldForm } from './SnmpModuleFieldForm';
import { SnmpModuleTableForm } from './SnmpModuleTableForm';
import { CollapsibleWithButtons } from '../../../../ui/collapsible/CollapsibleWithButtons';

interface SnmpModuleProps {
  index: number;
  form: UseFormReturn<Agent, object>;
}

export function SnmpForm({ index, form }: SnmpModuleProps) {
  const { t } = useTranslationText('agents');
  const { control, formState, watch, setValue } = form;
  const getErrors = useModuleErrors<SnmpModule>(formState, index);

  const snmpVersion = watch(`modules.${index}.version`) as SnmpVersion | undefined;

  useEffect(() => {
    if (snmpVersion !== 'VERSION_3') {
      setValue(`modules.${index}.auth`, undefined);
    }
  }, [snmpVersion, index, setValue]);

  const {
    append: appendTable,
    fields: snmpTables,
    remove: removeTable,
  } = useFieldArray({
    control,
    name: `modules.${index}.tables`,
  });

  const {
    append: appendField,
    fields: snmpFields,
    remove: removeField,
  } = useFieldArray({
    control,
    name: `modules.${index}.fields`,
  });

  const AddressInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.address`> }) => (
      <FormFieldWrapper error={getErrors('address')} label={t('moduleSnmpAddress')} isRequired>
        <TextInput
          dataRole={`module-${index}-address`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('address')}
          key={`module-${index}-address`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const CommunityInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.community`> }) => (
      <FormFieldWrapper error={getErrors('community')} label={t('moduleSnmpCommunity')} isRequired>
        <TextInput
          dataRole={`module-${index}-community`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('community')}
          key={`module-${index}-community`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const TimeoutInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.timeoutInSeconds`> }) => (
      <FormFieldWrapper error={getErrors('timeoutInSeconds')} label={t('moduleSnmpTimeout')}>
        <TextInput
          dataRole={`module-${index}-timeout`}
          onValueChange={(val) => field.onChange(intOrUndefined(val))}
          value={field.value}
          error={getErrors('timeoutInSeconds')}
          type="number"
          key={`module-${index}-timeout`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const VersionInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.version`> }) => (
      <FormFieldWrapper error={getErrors('version')} label={t('moduleSnmpVersion')} isRequired>
        <SingleValueInput
          onChange={(value) => field.onChange(value)}
          value={field.value}
          requiredPermission={UserResourcePermissions.Agent.Update}
          placeholder={t('moduleSnmpVersionPlaceholder')}
          options={mapSnmpVersionsToOptions(t)}
          key={`module-${index}-version`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  return (
    <div className="horizontal-wrapper">
      <div className="columns">
        <div className="column is-9">
          <Controller name={`modules.${index}.address`} control={control} render={AddressInput} />
        </div>
        <div className="column is-3">
          <Controller name={`modules.${index}.community`} control={control} render={CommunityInput} />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6">
          <Controller name={`modules.${index}.timeoutInSeconds`} control={control} render={TimeoutInput} />
        </div>
        <div className="column is-6">
          <Controller name={`modules.${index}.version`} control={control} render={VersionInput} />
        </div>
      </div>
      {snmpVersion === 'VERSION_3' && (
        <div className="columns">
          <div className="column is-12">
            <SnmpAuthForm index={index} form={form} />
          </div>
        </div>
      )}

      <div className="columns">
        <div className="column is-12">
          <CollapsibleWithButtons
            header={t('snmpFields')}
            isInitiallyCollapsed={false}
            addPermission={UserResourcePermissions.Agent.Update}
            addBtnClicked={() => appendField({ id: undefined, oid: '', name: '', metricIsTagged: false })}
          >
            <div>
              {snmpFields.map((field, fieldIndex) => (
                <SnmpModuleFieldForm
                  form={form}
                  moduleIndex={index}
                  fieldIndex={fieldIndex}
                  onRemove={() => removeField(fieldIndex)}
                  key={field.id}
                />
              ))}
            </div>
          </CollapsibleWithButtons>
        </div>
      </div>
      <div className="columns">
        <div className="column is-12">
          <CollapsibleWithButtons
            header={t('snmpTables')}
            isInitiallyCollapsed={false}
            addPermission={UserResourcePermissions.Agent.Update}
            addBtnClicked={() => appendTable({ id: undefined, oid: '', name: '', fields: [] })}
          >
            <div>
              {snmpTables.map((table, tableIndex) => (
                <SnmpModuleTableForm
                  form={form}
                  moduleIndex={index}
                  tableIndex={tableIndex}
                  onRemove={() => removeTable(tableIndex)}
                  key={table.id}
                />
              ))}
            </div>
          </CollapsibleWithButtons>
        </div>
      </div>
    </div>
  );
}
