import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { Collapsible } from '@aos/styleguide-react';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import TextArea from '../../ui/text-area/TextArea';
import TextInput from '../../ui/text-input/TextInput';

interface DetailsFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
}

export default function DetailsForm({ form }: DetailsFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { control, formState } = form;

  const NameInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, 'name'> }) => (
      <FormFieldWrapper error={formState.errors?.name} label={t('nameLabel')} isRequired>
        <TextInput
          placeholder={t('namePlaceholder')}
          dataRole="component-type-name"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.name}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.name, t],
  );

  const DescriptionInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, 'description'> }) => (
      <FormFieldWrapper label={t('descriptionLabel')}>
        <TextArea
          placeholder={t('descriptionPlaceholder')}
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.description}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.description, t],
  );

  const IconUrlInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, 'iconUrl'> }) => (
      <FormFieldWrapper label={t('iconUrlLabel')}>
        <TextInput
          placeholder={t('iconUrlPlaceholder')}
          dataRole="component-type-icon-url"
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.description}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.description, t],
  );

  return (
    <Collapsible header={t('componentTypeDetails')} isInitiallyCollapsed={false}>
      <div>
        <Controller name="name" control={control} render={NameInput} />
        <Controller name="description" control={control} render={DescriptionInput} />
        <Controller name="iconUrl" control={control} render={IconUrlInput} />
      </div>
    </Collapsible>
  );
}
