import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { CreateSystemModel, defaultCreateSystemStage, UpdateSystemModel } from '../../models/operation/SystemModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import { SystemStagesForm } from './SystemStageForm';
import { CollapsibleWithButtons } from '../../ui/collapsible/CollapsibleWithButtons';

interface SystemStagesProps {
  form: UseFormReturn<CreateSystemModel | UpdateSystemModel, object>;
}

export function SystemStages({ form }: Readonly<SystemStagesProps>) {
  const { t } = useTranslationText('systems');

  const { control, watch } = form;

  const {
    append,
    fields: systemStages,
    remove,
  } = useFieldArray({
    control,
    name: 'stages',
  });

  return (
    <div className="columns is-align-items-flex-start">
      <div className="column is-12">
        <CollapsibleWithButtons
          header={t('stages')}
          isInitiallyCollapsed={false}
          addPermission={UserResourcePermissions.System.CreateOrUpdate}
          addBtnClicked={() => append(defaultCreateSystemStage)}
          badgeCount={systemStages.length}
        >
          {systemStages.map((field, index) => {
            const name = watch(`stages.${index}.name`);
            return (
              <CollapsibleWithButtons
                header={name === undefined || name === '' ? t('newStage') : name}
                isInitiallyCollapsed={false}
                addPermission={UserResourcePermissions.System.CreateOrUpdate}
                key={field.id}
              >
                <SystemStagesForm form={form} index={index} onRemove={() => remove(index)} />
              </CollapsibleWithButtons>
            );
          })}
        </CollapsibleWithButtons>
      </div>
    </div>
  );
}
