import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import CurrentTickets from '../ticketing/current-tickets/CurrentTickets';
import TicketDetails from '../ticketing/ticket-details/TicketDetails';
import CreateTicket from '../ticketing/create-ticket/CreateTicket';
import AllTickets from '../ticketing/all-tickets/AllTickets';
import AutomationRules from '../automation/AutomationRules';
import EditRule from '../automation/edit-rule/EditRule';
import KnowledgeBaseDashboard from '../knowledge-base/KnowledgeBaseDashboard';
import KnowledgeBaseTable from '../knowledge-base/table/KnowledgeBaseTable';
import Components from '../components/Components';
import ContractManagement from '../contract-management/ContractManagement';
import CreateRule from '../automation/create-rule/CreateRule';
import ArticleDetails from '../knowledge-base/article/article-details/ArticleDetails';
import { CreateArticle } from '@/knowledge-base/article/create-article/CreateArticle';
import EditArticle from '../knowledge-base/article/edit-article/EditArticle';
import ComponentDetails from '../components/ComponentDetails';
import CreateComponent from '../components/create-component/CreateComponent';
import DetailContract from '../contract-management/detail-contract/DetailContract';
import CreateContract from '../contract-management/create-contract/CreateContract';
import Layout from '../ui/layout/Layout';
import { ContractorManagement } from '@/contract-management/contractor/ContractorManagement';
import { CreateContractor } from '@/contract-management/contractor/CreateContractor/CreateContractor';
import ComponentTypes from '../componentTypes/ComponentTypes';
import ContractorDetails from '../contract-management/contractor/detail-contractor/ContractorDetails';
import CreateComponentType from '../componentTypes/create-component-type/CreateComponentType';
import AllTasks from '../ticketing/all-tasks/AllTasks';
import EditComponentType from '../componentTypes/edit-component-type/EditComponentType';
import TaskDetails from '../ticketing/task-details/TaskDetails';
import User from '../user/User';
import Agents from '../agents/Agents';
import { ComponentMap } from '@/component-map/ComponentMap';
import Reports from '../components/Reports';
import AgentDetails from '../agents/agent-details/AgentDetails';
import ImportComponentType from '../componentTypes/import-component-type/ImportComponentType';
import CreateSystem from '../systems/create-system/CreateSystem';
import Systems from '../systems/Systems';
import EditSystem from '../systems/edit-system/EditSystem';
import CreateMaintenanceRequest from '../maintenance/create-request/CreateMaintenanceRequest';
import MaintenanceOverview from '../maintenance/overview/MaintenanceOverview';
import MaintenanceRequests from '../maintenance/maintenance-requests/MaintenanceRequests';
import MaintenanceDetails from '../maintenance/maintenance-details/MaintenanceDetails';
import ActiveAlertOverview from '../components/active-alert-overview/ActiveAlertOverview';
import { ColumnConfigProvider } from '@/components/active-alert-overview/ColumnConfig';
import EditTicket from '../ticketing/edit-ticket/EditTicket';
import AdvancedRules from '@/automation/AdvancedRules';
import EditAdvancedRule from '@/automation/EditAdvancedRule';
import CreateAdvancedRulePage from '@/automation/CreateAdvancedRulePage';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="tickets">
          <Route index element={<Navigate to="/tickets/current" replace />} />
          <Route path="current">
            <Route index element={<CurrentTickets />} />
            <Route path=":id" element={<TicketDetails />} />
            <Route path=":id/edit" element={<EditTicket />} />
            <Route path="create" element={<CreateTicket />} />
          </Route>
          <Route path="overview">
            <Route index element={<AllTickets />} />
            <Route path=":id" element={<TicketDetails />} />
            <Route path=":id/edit" element={<EditTicket />} />
            <Route path="create" element={<CreateTicket />} />
          </Route>
          <Route path="tasks">
            <Route path="overview">
              <Route index element={<AllTasks />} />
              <Route path=":id" element={<TaskDetails />} />
            </Route>
          </Route>
          <Route path="automation-rules">
            <Route index element={<AutomationRules />} />
            <Route path="create" element={<CreateRule />} />
            <Route path=":id" element={<EditRule />} />
          </Route>
          <Route path="system-rules">
            <Route index element={<AdvancedRules />} />
            <Route path="create" element={<CreateAdvancedRulePage />} />
            <Route path=":id/edit" element={<EditAdvancedRule />} />
          </Route>
        </Route>
        <Route path="knowledge-base">
          <Route index element={<Navigate to="/knowledge-base/dashboard" replace />} />
          <Route path="dashboard" element={<KnowledgeBaseDashboard />} />
          <Route path="articles">
            <Route index element={<KnowledgeBaseTable />} />
            <Route path="create" element={<CreateArticle />} />
            <Route path=":id" element={<ArticleDetails />} />
            <Route path=":id/edit" element={<EditArticle />} />
          </Route>
        </Route>
        <Route path="components">
          <Route index element={<Navigate to="/components/overview" replace />} />
          <Route path="overview">
            <Route index element={<Components />} />
            <Route path=":id" element={<ComponentDetails />} />
            <Route path="create" element={<CreateComponent />} />
          </Route>
          <Route path="systems">
            <Route index element={<Systems />} />
            <Route path=":id" element={<EditSystem />} />
            <Route path="create" element={<CreateSystem />} />
          </Route>
          <Route path="map" element={<ComponentMap />} />
          <Route path="componentTypes">
            <Route index element={<ComponentTypes />} />
            <Route path=":id" element={<EditComponentType />} />
            <Route path="create" element={<CreateComponentType />} />
            <Route path="import" element={<ImportComponentType />} />
          </Route>
          <Route path="reports" element={<Reports />} />
          <Route
            path="active-alerts"
            element={
              <ColumnConfigProvider>
                <ActiveAlertOverview />
              </ColumnConfigProvider>
            }
          />
          <Route path="agents">
            <Route index element={<Agents />} />
            <Route path=":id" element={<AgentDetails />} />
          </Route>
        </Route>
        <Route path="maintenance">
          <Route index element={<MaintenanceOverview />} />
          <Route path="overview">
            <Route index element={<MaintenanceOverview />} />
          </Route>
          <Route path="requests">
            <Route index element={<MaintenanceRequests />} />
            <Route path=":id" element={<MaintenanceDetails />} />
            <Route path="create" element={<CreateMaintenanceRequest />} />
          </Route>
        </Route>
        <Route path="contracts">
          <Route index element={<Navigate to="/contracts/overview" replace />} />
          <Route path="overview">
            <Route index element={<ContractManagement />} />
            <Route path=":id" element={<DetailContract />} />
            <Route path="create" element={<CreateContract />} />
          </Route>
          <Route path="contractors">
            <Route index element={<ContractorManagement />} />
            <Route path="create" element={<CreateContractor />} />
            <Route path=":id" element={<ContractorDetails />} />
          </Route>
        </Route>
        <Route path="user" element={<User />} />
      </Route>
    </Routes>
  );
}
