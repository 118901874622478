import { DecodedValueMap } from 'use-query-params';
import { MaintenanceQuery, MaintenanceQueryParamConfigMap } from '../../../models/maintenance/MaintenanceQuery';
import { useTranslationText } from '../../../translation/TranslationHooks';
import CollapsableSideCard from '../../../ui/collapsable-side-card/CollapsableSideCard';
import ComponentFilter from '../../../ui/filter/ComponentFilter';
import DateIntervalFilter from '../../../ui/filter/DateIntervalFilter';
import FilterResetButton from '../../../ui/filter/FilterResetButton';
import MaintenanceStatusFilter from './MaintenanceStatusFilter';
import './AllMaintenanceFilter.scss';

interface AllMaintenanceFilterProps {
  maintenanceFilter: DecodedValueMap<MaintenanceQueryParamConfigMap>;
  handleFilterChange: (name: keyof MaintenanceQuery, value: string | string[] | boolean | undefined) => void;
  isFilterHidden: boolean;
  hideFilter: () => void;
  resetMaintenanceFilter: () => void;
}

export default function AllMaintenanceFilter({
  maintenanceFilter,
  handleFilterChange,
  resetMaintenanceFilter,
  isFilterHidden,
  hideFilter,
}: AllMaintenanceFilterProps) {
  const { t } = useTranslationText('maintenances');

  const hasFilter =
    !!maintenanceFilter.maintenanceBetweenAfter ||
    !!maintenanceFilter.maintenanceBetweenUntil ||
    !!maintenanceFilter.status ||
    !!maintenanceFilter.components;

  const filterHeader = () => (
    <>
      <div className="filter-title">{t('moreFilters')}</div>
      {hasFilter && <FilterResetButton handleReset={resetMaintenanceFilter} />}
    </>
  );

  return (
    <div className="filter-menu">
      <CollapsableSideCard isHidden={isFilterHidden} header={filterHeader()} onHideToggle={() => hideFilter()}>
        <div className="all-maintenance-filter">
          <MaintenanceStatusFilter
            label={t('status')}
            selectedStates={maintenanceFilter.status}
            onChange={handleFilterChange}
          />
          <ComponentFilter
            label={t('component')}
            selectedComponentIds={maintenanceFilter.components}
            onChange={handleFilterChange}
          />
          <DateIntervalFilter
            label={t('maintenanceBetween')}
            startDate={
              maintenanceFilter.maintenanceBetweenAfter
                ? new Date(maintenanceFilter.maintenanceBetweenAfter)
                : undefined
            }
            onStartDateChange={(startDate) => {
              handleFilterChange('maintenanceBetweenAfter', startDate?.toISOString());
            }}
            endDate={
              maintenanceFilter.maintenanceBetweenUntil
                ? new Date(maintenanceFilter.maintenanceBetweenUntil)
                : undefined
            }
            onEndDateChange={(endDate) => {
              handleFilterChange('maintenanceBetweenUntil', endDate?.toISOString());
            }}
            startDateInputId="maintenance-between-start"
            endDateInputId="maintenance-between-end"
            includeEndDay
          />
        </div>
      </CollapsableSideCard>
    </div>
  );
}
