import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { PatchContractModel } from '../../models/contract-management/ContractManagementModel';
import { CreateServiceTimeModel } from '../../models/contract-management/ServiceTimeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import { DaysOfWeekPicker } from '../../ui/days-of-week-picker/DaysOfWeekPicker';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import ListItemWrapper from '../../ui/list-item-wrapper/ListItemWrapper';
import { SwitchInput } from '../../ui/switch/SwitchInput';
import { TimePicker } from '../../ui/time-picker/TimePicker';
import './EditServiceTime.scss';

interface EditServiceTimeProps {
  form: UseFormReturn<PatchContractModel>;
  index: number;
  onRemove: () => void;
}

export function EditServiceTime({ form, index, onRemove }: EditServiceTimeProps) {
  const { t } = useTranslationText('contractManagements');
  const { control, formState, trigger } = form;

  const getErrors = useCallback(
    (field: keyof CreateServiceTimeModel) =>
      formState.errors?.serviceTimes !== undefined && formState.errors?.serviceTimes[index] !== undefined
        ? formState?.errors?.serviceTimes.at?.(index)?.[field]
        : undefined,
    [formState.errors?.serviceTimes, index],
  );

  const DaysInput = useCallback(
    ({ field }: { field: ControllerRenderProps<PatchContractModel, `serviceTimes.${typeof index}.days`> }) => (
      <FormFieldWrapper error={getErrors('days')} label={t('days')} isRequired>
        <DaysOfWeekPicker selectedDays={field.value} onChange={field.onChange} />
      </FormFieldWrapper>
    ),
    [getErrors, t],
  );

  const BeginInput = useCallback(
    ({ field }: { field: ControllerRenderProps<PatchContractModel, `serviceTimes.${typeof index}.begin`> }) => (
      <FormFieldWrapper error={getErrors('begin')} label={t('serviceTime_begin')} isRequired>
        <TimePicker
          key={`contract-service-times-begin-${index}`}
          placeholder={t('serviceTime_begin')}
          onValueChange={(newDate) => {
            field.onChange(newDate);
            // trigger validation of corresponding end time, to check if one is before or after the other
            trigger(`serviceTimes.${index}.end`).catch((error) => console.error(error));
          }}
          time={field.value}
          isError={getErrors('begin') !== undefined}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t, trigger],
  );

  const EndInput = useCallback(
    ({ field }: { field: ControllerRenderProps<PatchContractModel, `serviceTimes.${typeof index}.end`> }) => (
      <FormFieldWrapper error={getErrors('end')} label={t('serviceTime_end')} isRequired>
        <TimePicker
          placeholder={t('serviceTime_end')}
          key={`contract-service-times-end-${index}`}
          onValueChange={(newDate) => {
            field.onChange(newDate);
            // trigger validation of corresponding begin time, to check if one is before or after the other
            trigger(`serviceTimes.${index}.begin`).catch((error) => console.error(error));
          }}
          time={field.value}
          isError={getErrors('end') !== undefined}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t, trigger],
  );

  const PublicHolidaysIncludedInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<PatchContractModel, `serviceTimes.${typeof index}.publicHolidaysIncluded`>;
    }) => (
      <FormFieldWrapper error={getErrors('publicHolidaysIncluded')} label={t('serviceTime_publicHolidaysIncluded')}>
        <SwitchInput
          id={`contract-service-times-${index}-public-holidays-switch`}
          onChange={field.onChange}
          checked={field.value ?? false}
          isError={getErrors('publicHolidaysIncluded') !== undefined}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  return (
    <ListItemWrapper
      mode="item"
      field="serviceTime"
      index={index}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.Contract.Delete}
      hasLabelSpacing
    >
      <div className="edit-service-time-item" data-role={`service-time-${index}`}>
        <Controller name={`serviceTimes.${index}.days`} control={control} render={DaysInput} defaultValue={[]} />
        <Controller name={`serviceTimes.${index}.begin`} control={control} render={BeginInput} />
        <Controller name={`serviceTimes.${index}.end`} control={control} render={EndInput} />
        <Controller
          name={`serviceTimes.${index}.publicHolidaysIncluded`}
          control={control}
          render={PublicHolidaysIncludedInput}
        />
      </div>
    </ListItemWrapper>
  );
}
