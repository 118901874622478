import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { HttpModule } from '../../../models/monitoring/AgentModule';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { Agent } from '../../../models/monitoring/Agent';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import SingleValueInput from '../../../ui/single-value-input/SingleValueInput';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { mapHttpMethodsToOptions, useModuleErrors } from '../../../models/operation/AgentFunctions';
import TextInput from '../../../ui/text-input/TextInput';
import { HttpMethod } from '../../../models/monitoring/HttpMethod';
import TextArea from '../../../ui/text-area/TextArea';

interface HttpModuleProps {
  index: number;
  form: UseFormReturn<Agent, object>;
}

export function HttpModuleForm({ form, index }: HttpModuleProps) {
  const { t } = useTranslationText('agents');
  const { control, formState } = form;
  const getErrors = useModuleErrors<HttpModule>(formState, index);

  const NameInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.name`> }) => (
      <FormFieldWrapper error={getErrors('name')} label={t('moduleName')} isRequired>
        <TextInput
          dataRole={`module-name-${index}`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('name')}
          key={`module-name-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const MethodInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.moduleMethod`> }) => (
      <FormFieldWrapper error={getErrors('moduleMethod')} label={t('moduleMethodLabel')} isRequired>
        <SingleValueInput
          onChange={(value) => field.onChange(value)}
          value={field.value}
          requiredPermission={UserResourcePermissions.Agent.Update}
          placeholder={t('moduleMethodPlaceholder')}
          options={mapHttpMethodsToOptions(t, Object.values(HttpMethod))}
          key={`module-module-method-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const UrlInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.url`> }) => (
      <FormFieldWrapper error={getErrors('url')} label={t('moduleUrl')} isRequired>
        <TextInput
          dataRole={`module-url-${index}`}
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('url')}
          key={`module-url-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  const BodyInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.body`> }) => (
      <FormFieldWrapper error={getErrors('body')} label={t('moduleBody')}>
        <TextArea
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('body')}
          key={`module-body-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, t],
  );

  return (
    <>
      <div className="horizontal-wrapper">
        <Controller name={`modules.${index}.name`} control={control} render={NameInput} />
      </div>
      <div className="horizontal-wrapper">
        <div className="columns">
          <div className="column is-3">
            <Controller name={`modules.${index}.moduleMethod`} control={control} render={MethodInput} />
          </div>
          <div className="column is-9">
            <Controller name={`modules.${index}.url`} control={control} render={UrlInput} />
          </div>
        </div>
      </div>
      <div className="horizontal-wrapper">
        <Controller name={`modules.${index}.body`} control={control} render={BodyInput} />
      </div>
    </>
  );
}
