import { faFilter } from '@fortawesome/free-solid-svg-icons';
import './FilterMenuButton.scss';
import { DecodedValueMap } from 'use-query-params';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FilterMenuButtonProps {
  query: DecodedValueMap<any>;
  countWhen?: (propertyName: string) => boolean;
  toggleMenu: () => void;
}

function validProperty(propertyName: string, value?: any): boolean {
  return (
    propertyName !== 'sort' &&
    propertyName !== 'page' &&
    propertyName !== 'size' &&
    value !== undefined &&
    value !== null &&
    value !== ''
  );
}

export default function FilterMenuButton({ query, toggleMenu, countWhen = () => true }: FilterMenuButtonProps) {
  const filterCount = useMemo(
    () =>
      Object.entries(query)
        .filter(
          ([, item]) =>
            typeof item === 'string' ||
            typeof item === 'number' ||
            typeof item === 'boolean' ||
            Array.isArray(item) ||
            typeof item === 'object',
        )
        .reduce(
          (accumulator: number, [propertyName, value]) =>
            countWhen(propertyName) && validProperty(propertyName, value)
              ? accumulator + (typeof value === 'object' ? Object.keys(value).length : 1)
              : accumulator,
          0,
        ),
    [query, countWhen],
  );

  return (
    <div key="filter-btn-all-tickets" className="filter-menu-button" data-role="filter-menu-btn" onClick={toggleMenu}>
      {filterCount > 0 && <div className="count-badge">{filterCount}</div>}
      <FontAwesomeIcon className="filter-icon" icon={faFilter} onKeyPress={toggleMenu} role="button" tabIndex={0} />
    </div>
  );
}
