import { ReactChild, useEffect, useState } from 'react';

interface InputSpoilerProps {
  title: JSX.Element;
  children: ReactChild;
  active?: boolean;
}

function InputSpoiler({ title, children, active = false }: InputSpoilerProps) {
  const [isActive, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (!active) {
      setActive(active);
    }
  }, [active]);

  return <div>{isActive ? <>{children}</> : <div onClick={() => setActive(true)}>{title}</div>}</div>;
}

export default InputSpoiler;
