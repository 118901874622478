const jsonContentType = 'application/json';
const jsonCharsetContentType = `${jsonContentType}; charset=utf-8`;
const jsonHateoasContentType = 'application/hal+json';

function useDefaultHeaders() {
  const headers = new Headers();
  headers.set('Content-Type', jsonContentType);
  headers.set('Accept', jsonContentType);
  return headers;
}

export class NoAuthFetchError extends Error {
  constructor(
    message: string,
    public isNetworkError: boolean,
    public status?: number,
    public statusText?: string,
  ) {
    super(message);
  }

  toString() {
    return `${this.status} ${this.statusText}`;
  }
}

const hasJsonBody = (response: Response) => {
  const contentType = response.headers.get('Content-Type');
  return (
    contentType != null && [jsonContentType, jsonHateoasContentType, jsonCharsetContentType].indexOf(contentType) !== -1
  );
};

export default function useNoAuthCustomFetch() {
  const headers = useDefaultHeaders();

  return async function customFetch(path: string, init?: RequestInit) {
    let response: Response;
    try {
      response = await fetch(path, {
        headers,
        ...init,
      });
    } catch (err) {
      throw new NoAuthFetchError(`Failed to make request`, true);
    }
    if (!response.ok) {
      throw new NoAuthFetchError(
        `Network response was ${response.status} ${response.statusText}`,
        false,
        response.status,
        response.statusText,
      );
    }
    if (hasJsonBody(response)) {
      return response.json();
    }
    return response;
  };
}
