import classNames from 'classnames';
import './StreetLoadingSpinner.scss';

export interface StreetLoadingSpinnerProps {
  children?: React.ReactNode;
  isLoading: boolean;
  absolute?: boolean;
  showContent?: boolean;
}

export default function StreetLoadingSpinner({
  children,
  isLoading,
  showContent,
  absolute,
}: StreetLoadingSpinnerProps) {
  const content = showContent ? children : <></>;
  if (isLoading) {
    return (
      <>
        <div
          className={classNames({
            spinnerWrapper: true,
            absolute,
          })}
        >
          <div className="streetLoading" />
        </div>
        {content}
      </>
    );
  }
  return <>{children}</>;
}
