import { Dispatch, useState } from 'react';
import { GroupBase } from 'react-select';
import { Response } from 'react-select-async-paginate';
import { UserFilterModel, UserModel } from '../models/operation/UserModel';
import { TicketingApiPrefix, useGetUsers } from './TicketingSystemApi';
import { DEFAULT_PAGE_SIZE_DROPDOWN, Page } from '../models/pagination/Pagination';
import { ReactSelectOption } from '../ui/search-dropdown/SearchDropdown';
import { mapUsersToOptions } from '../models/operation/TicketFunctions';
import { queryStringFromObject } from './QueryUtils';
import { QueryOptions } from './GetQuery';

export default function useGetAllUsers(userIds?: string[], options?: QueryOptions<Page<UserModel>>) {
  const [userQuery, setUserQuery] = useState('');
  const [size, setSize] = useState<number>();
  const [page, setPage] = useState<number>();

  const { data, isLoading, isError } = useGetUsers(options, {
    userQuery,
    size: size?.toString(),
    userIds,
    page: page?.toString(),
  });

  return { data, isLoading, isError, userQuery, setUserQuery, setSize, setPage };
}

export async function loadUserOptions(
  searchInput: string,
  prev: ReactSelectOption[] | undefined,
  setIsError: Dispatch<React.SetStateAction<boolean>>,
  customFetch: (path: string, init?: RequestInit | undefined) => Promise<any>,
  pageSize: number = DEFAULT_PAGE_SIZE_DROPDOWN,
) {
  setIsError(false);

  const page = prev ? Math.trunc(prev.length / DEFAULT_PAGE_SIZE_DROPDOWN) : 0;
  const filter: UserFilterModel = {
    userQuery: searchInput,
    page: page.toString(),
    size: pageSize.toString(),
  };
  const path = `${TicketingApiPrefix}/user?${queryStringFromObject(filter)}`;

  const userItemPage = await customFetch(path);

  if (userItemPage === null) {
    setIsError(true);
    return { options: [] };
  }

  return {
    options: mapUsersToOptions(userItemPage?.content),
    hasMore: !userItemPage.last,
  } as Response<ReactSelectOption, GroupBase<ReactSelectOption>, any>;
}
