export function dateByAddingDays(date: Date | undefined, days: number) {
  if (!date) return undefined;

  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
}

export function dateByAddingMonths(date: Date, months: number) {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);
  return newDate;
}

export function isDateBefore(date: Date, other: Date) {
  return date.getTime() < other.getTime();
}

export function isDateAfter(date: Date, other: Date) {
  return date.getTime() > other.getTime();
}

// 00:00[:00] - 23:59[:59]
const timeRegex = /^(2[0-3]|[01]?[0-9]):([0-5][0-9])(?::[0-5][0-9])?$/;

export function isTimeAfter(time: string, other: string) {
  if (!timeRegex.test(time)) {
    throw new Error(`Invalid time format: ${time}`);
  }

  if (!timeRegex.test(other)) {
    throw new Error(`Invalid time format: ${time}`);
  }

  const [, hoursString, minutesString] = time.match(timeRegex)!;
  const [, otherHoursString, otherMinutesString] = other.match(timeRegex)!;

  const hours = parseInt(hoursString, 10);
  const minutes = parseInt(minutesString, 10);

  const otherHours = parseInt(otherHoursString, 10);
  const otherMinutes = parseInt(otherMinutesString, 10);

  return hours > otherHours || (hours === otherHours && minutes > otherMinutes);
}
