import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import AlertingRulesForm from './AlertingRulesForm';
import { Criticality } from '../../components/criticality/Criticality';
import { CollapsibleWithButtons } from '../../ui/collapsible/CollapsibleWithButtons';

interface AlertingRulesFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
}

export default function AlertingRulesFormCollapsible({ form }: Readonly<AlertingRulesFormProps>) {
  const { t } = useTranslationText('componentTypes');
  const { t: tCommon } = useTranslationText('commons');
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: `alertingRules`,
  });

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('alertingRules')}
      badgeCount={fields.length}
      addBtnClicked={() =>
        append({
          id: undefined,
          query: '',
          status: 'HEALTHY',
          criticality: Criticality.NONE,
          title: '',
          description: '',
          notifyAfterSeconds: undefined,
          ticketGenerationEnabled: true,
        })
      }
      addPermission={UserResourcePermissions.ComponentType.Create}
    >
      {fields.length === 0 && tCommon('noItemsFoundAdd')}
      {fields.map((field, index: number) => (
        <AlertingRulesForm form={form} key={field.id} index={index} onRemove={() => remove(index)} />
      ))}
    </CollapsibleWithButtons>
  );
}
