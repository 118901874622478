import classNames from 'classnames';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Epsg3857Coordinates } from '../../models/operation/ComponentModel';
import Editable, { DisplayComponentProps, EditingComponentProps } from '../editable-inputs/Editable';
import { UserPermission } from '../../auth/UserPermission';
import { useTranslationText } from '../../translation/TranslationHooks';

function EditingLocationPicker({
  value,
  label,
  isRequired,
  isLoading,
  isEdited,
  onCancel,
  onAcceptNewValue,
  onChange,
}: EditingComponentProps<Partial<Epsg3857Coordinates> | undefined>) {
  const { t } = useTranslationText('components');

  const xLabel = label ? `${label}x` : 'x';
  const yLabel = label ? `${label}y` : 'y';

  const [currentValue, setCurrentValue] = useState<Partial<Epsg3857Coordinates> | undefined>(value);

  const overwrittenOnChange = (update: Partial<Epsg3857Coordinates>) => {
    setCurrentValue((curr) => {
      if (onChange) {
        const newValue = !curr?.x && !curr?.y ? undefined : curr;
        onChange({ ...newValue, ...update });
      }
      return {
        ...curr,
        ...update,
      };
    });
  };

  return (
    <div className="field">
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className="control">
        <div className="columns is-vcentered">
          <div className="column is-1 is-flex is-vcentered">
            <label className="label" htmlFor={xLabel}>
              {t('coordinatesX')}
            </label>
          </div>
          <div className="column is-5">
            <input
              id={xLabel}
              className={classNames('input')}
              type="number"
              onChange={(e) =>
                overwrittenOnChange({
                  x: +e.target.value,
                })
              }
              value={currentValue?.x || ''}
            />
          </div>
          <div className="column is-1 is-flex is-vcentered">
            <label className="label" htmlFor={yLabel}>
              {t('coordinatesY')}
            </label>
          </div>
          <div className="column is-5">
            <input
              id={yLabel}
              className={classNames('input')}
              type="number"
              onChange={(e) =>
                overwrittenOnChange({
                  y: +e.target.value,
                })
              }
              value={currentValue?.y || ''}
            />
          </div>
        </div>
      </div>
      <div className="field is-grouped mt-2">
        <div className="control is-full-height" tabIndex={-1}>
          <button
            className={classNames('button', 'is-primary', {
              'is-loading': isLoading,
              'is-warning': isEdited,
            })}
            disabled={isLoading}
            onMouseDown={(e) => {
              e.preventDefault();
              if (onAcceptNewValue) {
                onAcceptNewValue();
              }
            }}
            type="submit"
            tabIndex={-1}
            data-role="save-button"
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
        <div className="control  is-full-height">
          <button
            className="button"
            disabled={isLoading}
            onMouseDown={(e) => {
              e.preventDefault();
              onCancel();
            }}
            tabIndex={-1}
            type="button"
            data-role="cancel-button"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
    </div>
  );
}

function LocationLabel({ value, isRequired, label }: DisplayComponentProps<Partial<Epsg3857Coordinates> | undefined>) {
  const xLabel = label ? `${label}x` : 'x';
  const yLabel = label ? `${label}y` : 'y';

  const { t } = useTranslationText('components');

  return (
    <div className="field is-fullwidth">
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className="columns is-vcentered">
        <div className="column is-1 is-flex is-vcentered">
          <label className="label" htmlFor={xLabel}>
            {t('coordinatesX')}
          </label>
        </div>
        <div className="column is-5">
          <input id={xLabel} className={classNames('input')} type="number" readOnly value={value?.x} />
        </div>
        <div className="column is-1 is-flex is-vcentered">
          <label className="label" htmlFor={yLabel}>
            {t('coordinatesY')}
          </label>
        </div>
        <div className="column is-5">
          <input id={yLabel} className={classNames('input')} type="number" readOnly value={value?.y} />
        </div>
      </div>
    </div>
  );
}

interface LocationPickerProps {
  error?: string;
  label?: string;
  onAcceptNewValue?: (
    newValue: Partial<Epsg3857Coordinates> | undefined,
    setEndEditing: (endEditing: boolean) => void,
  ) => void;
  onChange: (newValue: Partial<Epsg3857Coordinates> | undefined) => void;
  isLoading: boolean;
  value: Partial<Epsg3857Coordinates> | undefined;
  requiredPermission: UserPermission;
  dataRole?: string;
}

export default function LocationPicker({
  error,
  isLoading,
  label,
  onAcceptNewValue,
  onChange,
  value,
  requiredPermission,
  dataRole,
}: LocationPickerProps) {
  return (
    <Editable
      label={label}
      error={error}
      dataRole={dataRole}
      isLoading={isLoading}
      displayComponent={LocationLabel}
      editingComponent={EditingLocationPicker}
      value={value}
      onChange={onChange}
      onAcceptNewValue={onAcceptNewValue}
      requiredPermission={requiredPermission}
    />
  );
}
