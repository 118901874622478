import { NumberParam, QueryParamConfigMap, StringParam } from 'use-query-params';

export interface ContractorQuery {
  companyName: string;
  size: number;
  page: number;
  sort: string;
}
export const ContractorQueryParamConfigMap: ContractorQueryParamConfigModel = {
  companyName: StringParam,
  size: NumberParam,
  page: NumberParam,
  sort: StringParam,
};

export interface ContractorQueryParamConfigModel extends QueryParamConfigMap {
  companyName: typeof StringParam;
  size: typeof NumberParam;
  page: typeof NumberParam;
  sort: typeof StringParam;
}

export enum ContractorSort {
  CompanyNameAsc = 'companyName:asc',
  CompanyNameDesc = 'companyName:desc',
  cityAsc = 'city:asc',
  cityDesc = 'city:desc',
  streetAsc = 'street:asc',
  streetDesc = 'street:desc',
  postalCodeAsc = 'postalCode:asc',
  postalCodeDesc = 'postalCode:desc',
}
