import { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  FieldErrorsImpl,
  FormState,
  Merge,
  UseFormReturn,
} from 'react-hook-form';
import { Agent } from '../../../../models/monitoring/Agent';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../../../auth/AuthUserRoles';
import ListItemWrapper from '../../../../ui/list-item-wrapper/ListItemWrapper';
import { SnmpModule } from '../../../../models/monitoring/AgentModule';
import { SnmpField } from '../../../../models/snmp/Snmp';
import FormFieldWrapper from '../../../../ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '../../../../ui/text-input/TextInput';
import { useAuthUser } from '../../../../auth/UserRoleCheck';
import { SwitchInput } from '../../../../ui/switch/SwitchInput';

interface SnmpModuleFieldFormProps {
  form: UseFormReturn<Agent, object>;
  moduleIndex: number;
  fieldIndex: number;
  onRemove: () => void;
}

function useSnmpModuleFieldErrors(formState: FormState<Agent>, moduleIndex: number, fieldIndex: number) {
  return useCallback(
    (field: keyof SnmpField): FieldError | undefined => {
      const modules = formState.errors?.modules;
      if (!modules) {
        return undefined;
      }
      const module = modules[moduleIndex];
      if (!module) {
        return undefined;
      }
      const typedModule = module as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpModule>>>;
      if (!typedModule) {
        return undefined;
      }
      const snmpFields = typedModule.fields as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpField[]>>>;
      if (!snmpFields) {
        return undefined;
      }
      const snmpField = snmpFields[fieldIndex] as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpField>>>;
      if (!snmpField) {
        return undefined;
      }
      return snmpField[field] as FieldError;
    },
    [formState.errors?.modules, moduleIndex, fieldIndex],
  );
}

export function SnmpModuleFieldForm({ form, moduleIndex, onRemove, fieldIndex }: SnmpModuleFieldFormProps) {
  const { t } = useTranslationText('agents');

  const { control, formState } = form;
  const getErrors = useSnmpModuleFieldErrors(formState, moduleIndex, fieldIndex);
  const { hasPermission } = useAuthUser();

  const OidInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<Agent, `modules.${typeof moduleIndex}.fields.${typeof fieldIndex}.oid`>;
    }) => (
      <FormFieldWrapper error={getErrors('oid')} label={t('moduleSnmpFieldOid')} isRequired>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('oid')}
          dataRole={`module-${moduleIndex}-fields-${fieldIndex}-oid`}
          key={`module-${moduleIndex}-fields-${fieldIndex}-oid`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, t, moduleIndex, fieldIndex],
  );

  const NameInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<Agent, `modules.${typeof moduleIndex}.fields.${typeof fieldIndex}.name`>;
    }) => (
      <FormFieldWrapper error={getErrors('name')} label={t('moduleSnmpFieldName')} isRequired>
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={getErrors('name')}
          dataRole={`module-${moduleIndex}-fields-${fieldIndex}-name`}
          key={`module-${moduleIndex}-fields-${fieldIndex}-name`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, t, moduleIndex, fieldIndex],
  );

  const TaggedInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<Agent, `modules.${typeof moduleIndex}.fields.${typeof fieldIndex}.metricIsTagged`>;
    }) => (
      <FormFieldWrapper error={formState.errors?.accepted} label={t('moduleSnmpFieldMetricIsTagged')} isRequired>
        <SwitchInput
          checked={field.value}
          onChange={field.onChange}
          disabled={!hasPermission(UserResourcePermissions.Agent.Update)}
          id={`module-${moduleIndex}-fields-${fieldIndex}-metric-is-tagged`}
          key={`module-${moduleIndex}-fields-${fieldIndex}-metric-is-tagged`}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.accepted, hasPermission, t, moduleIndex, fieldIndex],
  );

  return (
    <ListItemWrapper
      mode="item"
      field={`modules.${moduleIndex}.fields.${fieldIndex}`}
      index={fieldIndex}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.Agent.Update}
      key={`agent-module-${moduleIndex}-snmp-field-${fieldIndex}`}
    >
      <div className="horizontal-wrapper">
        <div className="columns">
          <div className="column is-5">
            <Controller name={`modules.${moduleIndex}.fields.${fieldIndex}.oid`} control={control} render={OidInput} />
          </div>
          <div className="column is-5">
            <Controller
              name={`modules.${moduleIndex}.fields.${fieldIndex}.name`}
              control={control}
              render={NameInput}
            />
          </div>
          <div className="column is-2">
            <Controller
              name={`modules.${moduleIndex}.fields.${fieldIndex}.metricIsTagged`}
              control={control}
              render={TaggedInput}
            />
          </div>
        </div>
      </div>
    </ListItemWrapper>
  );
}
