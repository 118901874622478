import { FieldError } from 'react-hook-form';
import classNames from 'classnames';
import { useMemo } from 'react';

interface TextInputProps {
  onValueChange?: (newValue: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: string | number;
  dataRole?: string;
  error?: FieldError;
  type?: React.HTMLInputTypeAttribute;
  disabled?: boolean;
}

function TextInput({
  onValueChange,
  placeholder,
  readOnly,
  dataRole,
  value,
  error,
  type = 'text',
  disabled,
}: TextInputProps) {
  const displayValue = useMemo(() => (value || !Number.isNaN(value) ? value : ''), [value]);

  return (
    <input
      tabIndex={0}
      className={classNames('aos-textinput', {
        'is-error': error !== undefined,
      })}
      onChange={(event) => onValueChange?.(event.target.value)}
      placeholder={placeholder}
      readOnly={readOnly}
      type={type}
      value={displayValue}
      data-role={dataRole}
      disabled={disabled}
    />
  );
}

export default TextInput;
