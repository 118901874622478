import { DescendentComponentStats } from '../../models/operation/ComponentQuery';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { DescendentComponentStatusTags } from '../child-component-status/DescendentComponentStatusTags';

export function ComponentDescendentComponents(props: {
  label: string;
  isLoading: boolean;
  descendentComponentStats?: DescendentComponentStats;
}) {
  const { label, isLoading, descendentComponentStats } = props;
  return (
    <div className="descendent-component-status">
      <span className="label">{label}</span>
      <LoadingSpinner isLoading={isLoading}>
        <DescendentComponentStatusTags descendentComponentStats={descendentComponentStats} />
      </LoadingSpinner>
    </div>
  );
}
