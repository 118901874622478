import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslationText } from '../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { ComponentForm } from './ComponentForm';
import { MaintenanceLocation } from '../../models/maintenance/MaintenanceLocation';
import { patchComponentLocationsValidation } from '../MaintenanceValidationSchema';
import useYupLocal from '../../translation/YupLocal';
import { PatchBaseMaintenance } from '../../models/maintenance/Maintenance';
import { CollapsibleWithButtons } from '../../ui/collapsible/CollapsibleWithButtons';

interface EditableComponentFormCollapsibleProps {
  currentComponentLocations: MaintenanceLocation[];
  readOnly?: boolean;
  isSaving?: boolean;
  onSubmit: (
    newValue: MaintenanceLocation[] | undefined,
    callback: (savedData: MaintenanceLocation[] | undefined) => void,
  ) => void;
}

export function EditableComponentFormCollapsible({
  currentComponentLocations,
  isSaving,
  onSubmit,
  readOnly = false,
}: Readonly<EditableComponentFormCollapsibleProps>) {
  const { t } = useTranslationText('maintenances');
  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();

  const form = useForm<PatchBaseMaintenance>({
    mode: 'onChange',
    resolver: yupResolver(patchComponentLocationsValidation(yup)),
    defaultValues: {
      maintenanceLocations: currentComponentLocations,
    },
  });

  const { control, getValues, reset, trigger } = form;

  const { append, fields, remove } = useFieldArray({ control, name: `maintenanceLocations` });

  const onDiscard = () => {
    reset({ maintenanceLocations: currentComponentLocations });
  };

  const onSave = () => {
    trigger('maintenanceLocations')
      .then((isFormValid) => {
        if (isFormValid) {
          onSubmit(getValues('maintenanceLocations'), (savedData) => {
            if (savedData) {
              reset({ maintenanceLocations: savedData });
            }
          });
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('components')}
      badgeCount={fields.length}
      addBtnClicked={() => append({ componentId: '' })}
      addPermission={UserResourcePermissions.MaintenanceRequest.Update}
      addButtonDisabled={readOnly}
      isValid={form.formState.isValid}
      dirtyState={form.formState.isDirty}
      isSaving={isSaving}
      discardBtnClicked={onDiscard}
      saveBtnClicked={onSave}
    >
      {fields.length === 0 && tCommon('noItemsFoundAdd')}
      {fields.map((field, index: number) => (
        <ComponentForm key={field.id} form={form} onRemove={() => remove(index)} index={index} readOnly={readOnly} />
      ))}
    </CollapsibleWithButtons>
  );
}
