export const isKeyPressed = (event: React.KeyboardEvent<HTMLElement>, keys: string[], callback: () => void) =>
  keys.includes(event.key) && callback();

export enum KeyboardEventKeys {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  TAB = 'Tab',
  SHIFT = 'Shift',
  SPACE = ' ',
}
