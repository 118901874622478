import { useGetContractById } from '../../../react-query/ContractManagementSystemApi';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface ContractorDisplayProps {
  contractId: string;
}

export function ContractorDisplay({ contractId }: ContractorDisplayProps) {
  const { t } = useTranslationText('contractManagements');
  const { data: contract, error } = useGetContractById(contractId);

  if (error) {
    return <>{t('contractorDoesNotExist')}</>;
  }

  return <>{contract?.contractor.companyName}</>;
}
