import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { PatchBaseMaintenance } from '../../models/maintenance/Maintenance';
import { MaintenanceLocation } from '../../models/maintenance/MaintenanceLocation';
import { useGetComponent } from '../../react-query/ComponentApi';
import SingleComponentSelect from '../../components/component-selects/SingleComponentSelect/SingleComponentSelect';
import { useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import ListItemWrapper from '../../ui/list-item-wrapper/ListItemWrapper';
import TextArea from '../../ui/text-area/TextArea';

interface ComponentFormProps {
  form: UseFormReturn<PatchBaseMaintenance>;
  onRemove: () => void;
  index: number;
  readOnly?: boolean;
}

export function ComponentForm({ form, onRemove, index, readOnly = false }: ComponentFormProps) {
  const { t } = useTranslationText('maintenances');
  const { t: tError } = useTranslationText('errorTexts');
  const { control, formState, watch } = form;

  const selectedComponentId = watch(`maintenanceLocations.${index}.componentId`);
  const { data: maintenanceComponent, isError: unknownComponent } = useGetComponent(selectedComponentId, {
    enabled: !!selectedComponentId,
    suppressErrorToast: true,
  });

  const getErrors = useCallback(
    (field: keyof MaintenanceLocation) =>
      formState.errors?.maintenanceLocations !== undefined &&
      formState.errors?.maintenanceLocations[index] !== undefined
        ? formState?.errors?.maintenanceLocations.at?.(index)?.[field]
        : undefined,
    [formState.errors?.maintenanceLocations, index],
  );

  const ComponentInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<PatchBaseMaintenance, `maintenanceLocations.${typeof index}.componentId`>;
    }) => (
      <FormFieldWrapper error={getErrors('componentId')} isRequired label={t('component')}>
        <SingleComponentSelect
          onChange={(component) => {
            field.onChange(component?.id);
          }}
          value={maintenanceComponent || null}
          key={`maintenance-request-component-${index}`}
          isRequired
          isDisabled={readOnly}
        />
      </FormFieldWrapper>
    ),
    [index, t, maintenanceComponent, readOnly, getErrors],
  );

  const LocationDescriptionInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<PatchBaseMaintenance, `maintenanceLocations.${typeof index}.description`>;
    }) => (
      <FormFieldWrapper error={getErrors('componentId')} label={t('locationDescription')}>
        <TextArea
          key={`maintenance-request-location-description-${index}`}
          value={field.value}
          onValueChange={field.onChange}
          readOnly={readOnly}
        />
      </FormFieldWrapper>
    ),
    [index, readOnly, t, getErrors],
  );

  return (
    <ListItemWrapper
      mode="item"
      field="physicalLocationOfComponents"
      index={index}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
      deleteDisabled={readOnly}
    >
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name={`maintenanceLocations.${index}.componentId`} control={control} render={ComponentInput} />
          {unknownComponent && <div>{tError('404_component', { id: selectedComponentId })}</div>}
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller
            name={`maintenanceLocations.${index}.description`}
            control={control}
            render={LocationDescriptionInput}
          />
        </div>
      </div>
    </ListItemWrapper>
  );
}
