import dayjs from 'dayjs';
import { Collapsible } from '@aos/styleguide-react';
import useRuntimeConfig from '../../../runtime-config/RuntimeConfig';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface ParallelMaintenancesProps {
  from?: Date;
  to?: Date;
  maintenanceId: string;
}

export function ParallelMaintenances({ from, to, maintenanceId }: Readonly<ParallelMaintenancesProps>) {
  const { t } = useTranslationText('maintenances');
  const [runtimeConfig] = useRuntimeConfig();
  const fromValue = dayjs(from).subtract(1, 'hour').valueOf();
  const toValue = dayjs(to).add(1, 'hour').valueOf();
  const queryString = `?kiosk=tv&theme=light&orgId=1&from=${fromValue}&to=${toValue}&var-maintenanceId=${maintenanceId}`;

  return (
    <Collapsible header={t('parallelMaintenances')} isInitiallyCollapsed={false}>
      <div className="parallel-maintenances-container">
        {runtimeConfig && (
          <iframe
            title={t('parallelMaintenances')}
            className="component-attributes-metrics-panel"
            src={`${runtimeConfig.dashboardUrl}/Wartungskalender${queryString}`}
          />
        )}
      </div>
    </Collapsible>
  );
}
