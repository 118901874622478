import { ComponentProps } from 'react';
import { type TOptions } from 'i18next';
import { Tag } from '@aos/styleguide-react';
import {
  faCircleCheck,
  faCircleExclamation,
  faCirclePause,
  faCirclePlay,
  faCirclePlus,
  faCircleStop,
} from '@fortawesome/free-solid-svg-icons';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { type TagColor } from '@aos/styleguide-react/dist/common/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TicketStateTagConfig {
  color: TagColor;
  icon: IconDefinition;
}

function configureTag(state: string): TicketStateTagConfig {
  switch (state.toLowerCase()) {
    case 'todo': {
      return {
        color: 'is-default',
        icon: faCirclePlus,
      };
    }
    case 'working': {
      return {
        color: 'is-primary',
        icon: faCirclePlay,
      };
    }
    case 'checking': {
      return {
        color: 'is-accent-orange',
        icon: faCircleExclamation,
      };
    }
    case 'wait': {
      return {
        color: 'is-danger',
        icon: faCirclePause,
      };
    }
    case 'done': {
      return {
        color: 'is-success',
        icon: faCircleCheck,
      };
    }
    case 'archived': {
      return {
        color: 'is-additional-bluegreen',
        icon: faCircleStop,
      };
    }
    default: {
      // technically impossible
      return {
        color: 'is-default',
        icon: faCirclePlus,
      };
    }
  }
}

interface TicketStateTagProps extends ComponentProps<'span'> {
  state: string;
  t: (key: string, options?: TOptions) => string;
}

function TicketStateTag({ state, t }: TicketStateTagProps) {
  const tagConfig = configureTag(state);

  return (
    <Tag color={tagConfig.color}>
      <FontAwesomeIcon icon={tagConfig.icon} /> {t(state)}
    </Tag>
  );
}

export default TicketStateTag;
