import { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { Collapsible } from '@aos/styleguide-react';
import { useTranslationText } from '../translation/TranslationHooks';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import { useGetComponent, useGetDescendentComponentStats } from '../react-query/ComponentApi';
import ComponentAlertItem from './component-alert/ComponentAlertItem';
import ComponentRelations from './component-relations/ComponentRelations';
import { TicketingItem } from '../ticketing/item/TicketingItem';
import { ComponentItem } from '../models/operation/ComponentModel';
import { MutationKey, MutationPath, useDeleteMutation, usePatchMutation } from '../react-query/MutationQueries';
import { Patch, replaceProperty } from '../models/patch/PatchModel';
import ServerStateKeysEnum from '../react-query/ServerStateKeysEnum';
import ArticleCard from '../knowledge-base/article/article-card/ArticleCard';
import DetailInfoField from '../ui/detail-info-field/DetailInfoField';
import { ComponentAlerts } from './component-detail-items/ComponentAlerts';
import { ComponentRelatedArticles } from './component-detail-items/ComponentRelatedArticles';
import { ComponentAssociatedTickets } from './component-detail-items/ComponentAssociatedTickets';
import { ComponentMetrics } from './component-detail-items/ComponentMetrics';
import { ComponentDescendentComponents } from './component-detail-items/ComponentDescendentComponents';
import useParamId from '../custom-hooks/UseParamId';
import { ComponentContracts } from './component-detail-items/ComponentContracts';
import ComponentContractItem from './component-contract-item/ComponentContractItem';
import ComponentStatusTagWithLabel from './component-status-tag/ComponentStatusTagWithLabel';
import { ComponentMap } from '../component-map/ComponentMap';
import ComponentDetailsActionBar from './component-detail-items/ComponentDetailsActionBar';
import { DisplayNameAlt1Input, DisplayNameInput } from './component-detail-items/ComponentEditableInputs';
import ComponentTechnicalData from './component-detail-items/ComponentTechnicalData';
import Modal from '../ui/modal/Modal';
import { invalidateComponentRelations, invalidateComponents } from '../react-query/InvalidationQueries';
import { MonitoringApiPrefix } from '../react-query/MonitoringApi';
import { SwitchInput } from '../ui/switch/SwitchInput';
import UserRoleCheck from '../auth/UserRoleCheck';
import { UserResourcePermissions } from '../auth/AuthUserRoles';
import FavoriteComponentToggle from './favorite-component-toggle/FavoriteComponentToggle';
import FormFieldWrapper from '../ui/form-field-wrapper/FormFieldWrapper';

export default function ComponentDetails() {
  const { id: componentId, ErrorPage } = useParamId();
  const {
    data: component,
    isLoading: isComponentLoading,
    isError,
  } = useGetComponent(componentId, { suppressErrorToast: true });

  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);

  const navigate = useNavigate();

  const {
    data: descendentComponentStats,
    isLoading: isDescendentComponentStatsLoading,
    refetch: refreshDescendentComponentStats,
  } = useGetDescendentComponentStats(componentId, 'part-of');

  const { t } = useTranslationText('components');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tCommon } = useTranslationText('commons');
  const { t: tSuccess } = useTranslationText('successTexts');
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isMutationLoading,
    variables,
  } = usePatchMutation<Patch, ComponentItem>(MutationKey.PatchComponent);

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteComponent, {
    onSuccess: async () => {
      await invalidateComponents(queryClient).then(() => navigate(-1));
      await invalidateComponentRelations(queryClient, componentId);
      toast.success(tSuccess('deleteComponent'));
    },
    onError: () => {
      toast.error(`${tError('error')}: ${tError('componentDeleteError')}`);
    },
  });

  function onSubmit(
    newValue: any,
    property: keyof ComponentItem,
    isFormValid: boolean = true,
    saveCompleted?: (endEditing: boolean) => void,
  ) {
    if (component !== undefined && isFormValid) {
      mutate(
        {
          body: [replaceProperty(property, newValue)],
          path: MutationPath.PatchComponent(component.id.toString()),
        },
        {
          onSuccess: async () => {
            saveCompleted?.(true);
            await Promise.all([
              queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.Component, componentId] }),
              queryClient.invalidateQueries({ queryKey: [ServerStateKeysEnum.ComponentRelations] }),
            ]);
          },
          onError: () => {
            saveCompleted?.(false);
            toast.error(<Trans i18nKey="errorTexts.patchError.text" values={{ component: tError(property) }} />);
          },
        },
      );
    }
  }

  const handleComponentRelationRefresh = async () => {
    await refreshDescendentComponentStats();
  };

  const printRef = useRef<HTMLDivElement>(null);

  if (ErrorPage) {
    return ErrorPage;
  }

  const onComponentDelete = () => {
    setIsDeleteModalActive(true);
  };

  const onDelete = () => {
    setIsDeleteModalActive(false);
    deleteMutate({ path: `${MonitoringApiPrefix}/component/${componentId}` });
    navigate(-1);
  };

  return (
    <LoadingSpinner
      isLoading={isComponentLoading}
      errors={
        isError ? <Trans i18nKey="errorTexts.componentWithIdLoadError.text" values={{ id: componentId }} /> : undefined
      }
    >
      {component && ( // to avoid mass of optional chaining
        <>
          <ComponentDetailsActionBar
            printRef={printRef}
            component={component}
            right={t('createTicket')}
            onComponentDelete={onComponentDelete}
          />
          <div ref={printRef} className="component-details columns is-multiline" data-role="component-details">
            <div className="column is-7 main-content border-right">
              <div className="columns is-multiline mt-1 is-mobile">
                <div className="column is-full-mobile is-one-quarter-tablet is-one-quarter-widescreen">
                  <ComponentStatusTagWithLabel status={component.status} />
                </div>
                <div className="column is-full-mobile is-three-quarters-tablet is-half-widescreen">
                  <DisplayNameInput
                    component={component}
                    variables={variables}
                    isMutationLoading={isMutationLoading}
                    onSubmit={onSubmit}
                  />
                </div>
                <div className="column is-full-mobile is-full-tablet is-one-quarter-widescreen">
                  <DetailInfoField
                    label={t('componentType')}
                    value={component.componentType}
                    dataRole="component-type-metadata"
                  />
                </div>
                <div className="column is-full-mobile is-full-tablet is-half-widescreen">
                  <DisplayNameAlt1Input
                    component={component}
                    variables={variables}
                    isMutationLoading={isMutationLoading}
                    onSubmit={onSubmit}
                  />
                </div>
                <UserRoleCheck requiredPermission={UserResourcePermissions.Component.Update}>
                  <div className="column is-full-mobile is-full-tablet is-one-fifth-widescreen">
                    <FormFieldWrapper label={t('maintenanceMode')}>
                      <SwitchInput
                        id="maintenance"
                        checked={component.inMaintenance}
                        onChange={(checked) => onSubmit(checked, 'inMaintenance')}
                      />
                    </FormFieldWrapper>
                  </div>
                </UserRoleCheck>
                <UserRoleCheck
                  requiredPermission={UserResourcePermissions.FavoriteComponent.Create.and(
                    UserResourcePermissions.FavoriteComponent.Delete,
                  )}
                >
                  <div className="column is-full-mobile is-full-tablet is-one-fifth-widescreen">
                    <FavoriteComponentToggle componentId={component.id} toggleState={component.isFavorite} />
                  </div>
                </UserRoleCheck>
              </div>
              {component.permanentId && (
                <div className="columns mt-1">
                  <div className="column is-full-mobile is-full-tablet is-half-widescreen">
                    <DetailInfoField
                      label={t('permanentId')}
                      value={component.permanentId}
                      dataRole="component-permanent-id"
                    />
                  </div>
                </div>
              )}
              <div className="columns mt-1">
                <div className="column is-12">
                  <ComponentDescendentComponents
                    label={t('statusDescendentComponents')}
                    isLoading={isDescendentComponentStatsLoading}
                    descendentComponentStats={descendentComponentStats}
                  />
                </div>
              </div>
              <ComponentRelations component={component!} onRefresh={handleComponentRelationRefresh} />
              <ComponentMetrics title={t('metrics')} component={component} />
              <ComponentTechnicalData
                component={component}
                variables={variables}
                isMutationLoading={isMutationLoading}
                onSubmit={onSubmit}
              />
              <ComponentAlerts
                id={componentId}
                title={t('alerts')}
                renderElement={(alert) => <ComponentAlertItem key={alert.id} alert={alert} />}
              />
              <Collapsible header={t('map')} isInitiallyCollapsed={!component.coordinates}>
                <div className="map-container-400">
                  <ComponentMap componentId={component.id} />
                </div>
              </Collapsible>
            </div>
            <div className="column is-5">
              <ComponentContracts
                title={t('contracts')}
                component={component}
                onSubmit={onSubmit}
                renderElement={(ancestor) => (
                  <ComponentContractItem
                    onSubmit={onSubmit}
                    key={ancestor.id}
                    sourceComponent={ancestor}
                    currentComponent={component!}
                    readOnly
                  />
                )}
              />
              <ComponentAssociatedTickets
                id={componentId}
                title={t('associatedTickets')}
                renderElement={(ticket, index) => (
                  <TicketingItem key={`associated-tickets-${ticket.id}`} index={index} ticket={ticket} showState />
                )}
              />
              <ComponentRelatedArticles
                component={component}
                title={t('articles')}
                renderElement={(article) => <ArticleCard article={article} key={article.id} />}
              />
            </div>
          </div>
        </>
      )}
      <Modal
        isVisible={isDeleteModalActive}
        title={t('deleteComponent')}
        cancelBtnText={tCommon('abort')}
        confirmBtnText={tCommon('delete')}
        onCancel={() => setIsDeleteModalActive(false)}
        onConfirm={onDelete}
        onClose={() => setIsDeleteModalActive(false)}
      >
        <p>{t('warningComponentDelete')}</p>
      </Modal>
    </LoadingSpinner>
  );
}
