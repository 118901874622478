import { ReactElement } from 'react';
import { Table as AosTable } from '@aos/styleguide-react';
import { SummarizedComponent } from '../../models/operation/ComponentModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import { ComponentTableRow } from './ComponentTableRow';
import './ComponentTable.scss';
import TableHeader, { TableColumn } from '../../ui/table-header/TableHeader';
import { columnVisible, UserOverviewColumnConfig, UserOverviewColumnType } from './UserOverviewConfig';

interface ComponentTableProps {
  components: SummarizedComponent[] | undefined;
  config?: Map<UserOverviewColumnType, UserOverviewColumnConfig>;
  currentSort: string | undefined;
  relationType?: string;
  onSortChange: (property: string, sortState: string | undefined) => void;
  onExpand?: (src: SummarizedComponent, relationType?: string) => SummarizedComponent[] | undefined;
  canExpand?: (item: SummarizedComponent) => boolean;
  sortable: boolean;
  paginationFooter: ReactElement;
  isSmall?: boolean;
  isNarrow?: boolean;
}

function ComponentTable({
  components,
  config,
  currentSort,
  relationType,
  onExpand,
  canExpand,
  onSortChange,
  sortable,
  paginationFooter,
  isSmall = false,
  isNarrow = false,
}: ComponentTableProps) {
  const { t } = useTranslationText('components');

  const tableColumns: TableColumn[] = [
    {
      property: 'displayName',
      translationKey: 'component',
      width: '50%',
      visible: columnVisible(UserOverviewColumnType.DISPLAY_NAME, config),
      disableSort: !sortable,
    },
    {
      property: 'componentType',
      translationKey: 'componentType',
      visible: columnVisible(UserOverviewColumnType.TYPE, config),
      disableSort: !sortable,
    },
    {
      property: 'status',
      translationKey: 'status',
      visible: columnVisible(UserOverviewColumnType.STATUS, config),
      disableSort: !sortable,
    },
    {
      property: 'inMaintenance',
      translationKey: 'maintenance',
      visible: columnVisible(UserOverviewColumnType.MAINTENANCE, config),
      disableSort: true,
    },
    {
      property: 'isFavorite',
      translationKey: 'favorite',
      visible: columnVisible(UserOverviewColumnType.DISPLAY_NAME, config),
      disableSort: true,
    },
    {
      property: 'statusDescendentComponents',
      translationKey: 'statusDescendentComponents',
      disableSort: true,
      visible: columnVisible(UserOverviewColumnType.CHILD_STATUS, config),
    },
  ];

  return (
    <div className="component-table-container" data-role="components-container">
      {components && components.length > 0 ? (
        <>
          <AosTable isSmall={isSmall} isNarrow={isNarrow} className="has-tree-structure">
            <TableHeader
              columns={tableColumns}
              currentSort={currentSort}
              onSortChange={onSortChange}
              translationSection="components"
            />
            <AosTable.Body>
              {components?.map((component) => (
                <ComponentTableRow
                  component={component}
                  config={config}
                  key={component.id}
                  relationType={relationType}
                  onExpand={onExpand}
                  canExpand={canExpand}
                  isSmall={isSmall}
                />
              ))}
            </AosTable.Body>
          </AosTable>
          {paginationFooter}
        </>
      ) : (
        t('noComponentsFound')
      )}
    </div>
  );
}

export default ComponentTable;
