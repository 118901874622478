import { toast } from 'react-toastify';
import { useTranslationText } from '../translation/TranslationHooks';

export function useBulkOperationNotifications(): <T>(promise: Promise<T>) => Promise<T> {
  const { t: tCommons } = useTranslationText('commons');
  return (p) => {
    toast.info(tCommons('bulkActionInProgress'));
    return p.then((result) => {
      toast.success(tCommons('bulkActionCompleted'));
      return result;
    });
  };
}

function concatPromises<TOutput>(p: Promise<TOutput[]>, nextPromiseGenerator: () => Promise<TOutput>) {
  return p.then((resolvedAggregator) =>
    nextPromiseGenerator().then((resolvedNextPromise) => [...resolvedAggregator, resolvedNextPromise]),
  );
}

interface BulkOperationOptions<TInput, TOutput> {
  onSingleSuccess?: (input: TInput, output: TOutput) => void;
  onSingleFailure?: (input: TInput, reason: any) => TOutput;
}

export function performBulkOperation<TInput, TOutput>(
  elements: TInput[],
  mapper: (element: TInput) => Promise<TOutput>,
  { onSingleFailure, onSingleSuccess }: BulkOperationOptions<TInput, TOutput> = {
    onSingleFailure: undefined,
    onSingleSuccess: undefined,
  },
) {
  return elements
    .map(
      (srcElement) => () =>
        mapper(srcElement).then(
          (result) => {
            if (onSingleSuccess) {
              onSingleSuccess(srcElement, result);
            }
            return result;
          },
          onSingleFailure !== undefined ? (rejection) => onSingleFailure(srcElement, rejection) : undefined,
        ),
    )
    .reduce(concatPromises, Promise.resolve([] as TOutput[]));
}
