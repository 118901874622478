import { AdvancedFilter } from '@aos/styleguide-react';
import { DecodedValueMap } from 'use-query-params';
import { TaskQuery, TaskQueryParamConfigModel } from '../../../models/tasks/TaskQuery';
import { useTranslationText } from '../../../translation/TranslationHooks';
import DateIntervalFilter from '../../../ui/filter/DateIntervalFilter';
import '../../../ui/filter/AdvancedFilter.scss';
import ContractFilter from './ContractFilter';
import UserRoleCheck from '../../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import TaskStateFilter from '../../../ui/filter/TaskStateFilter';
import OpenQuestionFilter from '../../../ui/filter/OpenQuestionFilter';
import TaskFavoritesFilter from '../../../ui/filter/TaskFavoritesFilter';
import TaskTitleFilter from '../../../ui/filter/TaskTitleFilter';
import ContractorFilter from './ContractorFilter';
import FilterResetButton from '../../../ui/filter/FilterResetButton';

interface AllTaskFilterProps {
  taskFilter: DecodedValueMap<TaskQueryParamConfigModel>;
  handleFilterChange: (name: keyof TaskQuery, value: string | string[] | undefined) => void;
  resetTaskFilter: () => void;
  isFilterHidden: boolean;
  hideFilter: () => void;
}

export default function AllTaskFilter({
  taskFilter,
  handleFilterChange,
  resetTaskFilter,
  isFilterHidden,
  hideFilter,
}: AllTaskFilterProps) {
  const { t } = useTranslationText('tickets');
  const { t: tCommons } = useTranslationText('commons');

  const resetFilter = () => {
    resetTaskFilter();
  };

  const hasFilter =
    !!taskFilter.states ||
    !!taskFilter.startedAfter ||
    !!taskFilter.startedBefore ||
    !!taskFilter.contractIds ||
    !!taskFilter.hasOpenQuestions ||
    !!taskFilter.onlyFavorites ||
    !!taskFilter.title ||
    !!taskFilter.contractors;

  return (
    <AdvancedFilter
      isOpen={isFilterHidden}
      header={<AdvancedFilter.Header headerText={t('filterHeader')} onClose={() => hideFilter()} />}
      reset={
        hasFilter ? (
          <div className="footer">
            <FilterResetButton handleReset={resetFilter} />
          </div>
        ) : (
          <></>
        )
      }
      className="with-extra-styles"
    >
      <div className="filter-container">
        <div className="filter-elements">
          <TaskTitleFilter label={t('taskTitleFilter')} value={taskFilter.title} onChange={handleFilterChange} />
          <TaskStateFilter
            label={t('taskStateFilter')}
            selectedStates={taskFilter.states}
            onChange={handleFilterChange}
          />
          <UserRoleCheck requiredPermission={UserResourcePermissions.Contract.Read}>
            <ContractFilter
              label={t('contracts')}
              onChange={handleFilterChange}
              selectedContracts={taskFilter.contractIds}
            />
          </UserRoleCheck>
          <UserRoleCheck requiredPermission={UserResourcePermissions.Contract.Read}>
            <ContractorFilter
              label={t('contractor')}
              onChange={handleFilterChange}
              selectedContractors={taskFilter.contractors}
            />
          </UserRoleCheck>
          <DateIntervalFilter
            label={t('created')}
            startDateInputId="task-started-at-start"
            endDateInputId="task-started-at-end"
            startDate={taskFilter.startedAfter ? new Date(taskFilter.startedAfter) : undefined}
            onStartDateChange={(startDate) => {
              handleFilterChange('startedAfter', startDate?.toISOString());
            }}
            endDate={taskFilter.startedBefore ? new Date(taskFilter.startedBefore) : undefined}
            onEndDateChange={(endDate) => {
              handleFilterChange('startedBefore', endDate?.toISOString());
            }}
            includeEndDay
          />
          <OpenQuestionFilter
            onChange={handleFilterChange}
            isChecked={!!taskFilter.hasOpenQuestions}
            secondLabel={taskFilter.hasOpenQuestions ? tCommons('showHasOpenQuestions') : tCommons('showAll')}
          />
          <TaskFavoritesFilter
            isChecked={!!taskFilter.onlyFavorites}
            onChange={handleFilterChange}
            secondLabel={taskFilter.onlyFavorites ? tCommons('showFavorites') : tCommons('showAll')}
          />
        </div>
      </div>
    </AdvancedFilter>
  );
}
