import { ComponentType } from '../models/operation/ComponentTypeModel';
import { QueryOptions, useGetQuery } from './GetQuery';
import { MonitoringApiPrefix } from './MonitoringApi';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { Page } from '../models/pagination/Pagination';

export function useGetComponentTypes(options?: QueryOptions<Page<ComponentType>>) {
  return useGetQuery<Page<ComponentType>>(
    ServerStateKeysEnum.ComponentType,
    `${MonitoringApiPrefix}/componentType`,
    options,
  );
}

export function useGetComponentType(componentTypeId: string, options?: QueryOptions<ComponentType>) {
  return useGetQuery<ComponentType>(
    [ServerStateKeysEnum.ComponentTypeDetails, componentTypeId],
    `${MonitoringApiPrefix}/componentType/${componentTypeId}`,
    options,
  );
}
