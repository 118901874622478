import React from 'react';

interface ErrorMessageProps {
  title?: string;
  message: string | React.ReactNode;
}

function ErrorMessage({ title, message }: ErrorMessageProps) {
  return (
    <article className="message is-danger">
      {title && (
        <div className="message-header">
          <p>{title}</p>
        </div>
      )}
      <div className="message-body">{message}</div>
    </article>
  );
}

export default ErrorMessage;
