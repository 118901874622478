export function snakeToPascalCase(value: string) {
  return value
    .split('_')
    .map((p) => p.toLowerCase())
    .map((p) => `${p.substring(0, 1).toUpperCase()}${p.substring(1).toLowerCase()}`)
    .join('');
}

export function snakeToCamelCase(value: string) {
  const pascalCase = snakeToPascalCase(value);
  return `${pascalCase.substring(0, 1).toLowerCase()}${pascalCase.substring(1)}`;
}
