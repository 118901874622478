import { ComponentStatus } from '../../models/operation/ComponentModel';

type ComponentStatusBarItemModel = { order: number; key: ComponentStatus; percentage: number; width: number };
type ComponentStatusModel = { order: number; key: ComponentStatus; percentage: number };
const minimumWidth: number = 13;

const getPercentage = (current: number, total: number) => (current / total) * 100;

function getSum<T>(values: T[], getValue: (value: T) => number): number {
  return values.reduce((accumulator: number, value) => accumulator + getValue(value), 0);
}

function roundUpToDecimals(value: number, decimals: number = 1): number {
  return Math.round(value * (10 * decimals)) / (10 * decimals);
}

function mapToStatusBarItemModel(state: ComponentStatusModel, width: number): ComponentStatusBarItemModel {
  return { ...state, percentage: roundUpToDecimals(state.percentage), width };
}

/**
 * Recursive function to reduce the width of the states by the sum and preventing that the reduced width is under the minimum.
 * @param {ComponentStatusModel} states States with the percentage value
 * @param {number} sum Sum to be reduced from the width of the states
 * @returns {ComponentStatusBarItemModel[]} States with the percentage value and the adjusted width
 */
function reduceWidth(states: ComponentStatusModel[], sum: number): ComponentStatusBarItemModel[] {
  // base case
  if (sum === 0) {
    return states.map((state) => mapToStatusBarItemModel(state, state.percentage));
  }

  let result: ComponentStatusBarItemModel[] = [];
  const statesPercentagesSum = getSum(states, (state) => state.percentage);

  for (let i = 0; i < states.length; i) {
    const state = states[i];

    // calculating proportionally reducing value
    const percentage = state.percentage / statesPercentagesSum;
    const proportionallyReducingWidth = sum * percentage;
    let reducedWidth = state.percentage - proportionallyReducingWidth;

    // prevent reducing width under minimum and reset width to original width
    if (reducedWidth < minimumWidth) {
      reducedWidth = state.percentage;
    }

    const reducingWidthStates = [...states.filter((sa) => sa.key !== state.key)];
    const reducedSum = sum - (state.percentage - reducedWidth);
    // recursive call until sum is zero
    result = [mapToStatusBarItemModel(state, reducedWidth), ...reduceWidth(reducingWidthStates, reducedSum)];

    i += 1;
  }

  return result;
}

/**
 * Rounds up the width of the states to the minimum and reduces the rounding up sum proportionally from the others states
 * @param {ComponentStatusModel} states States with the percentage value
 * @returns {ComponentStatusBarItemModel[]} States with the percentage value and the adjusted width
 */
function calcWidthsForStates(states: ComponentStatusModel[]): ComponentStatusBarItemModel[] {
  // states where the width must be rounded up to the minimum
  const statesRoundUpWidth = states.filter((state) => state.percentage > 0 && state.percentage < minimumWidth);

  // states where the width must be reduced cause of the rounded up widths
  const statesReduceWidth = states.filter((state) => state.percentage > minimumWidth);

  let componentStatusBarItems = states
    .filter((state) => state.percentage === 0)
    .map((state) => mapToStatusBarItemModel(state, state.percentage));

  // calculate rounding up sum
  const roundingUpSum = getSum(statesRoundUpWidth, (state) => minimumWidth - state.percentage);

  // add states with the minimum width
  componentStatusBarItems = [
    ...componentStatusBarItems,
    ...statesRoundUpWidth.map((state) => mapToStatusBarItemModel(state, minimumWidth)),
  ];

  // reduces the rounding up sum from the width of states
  const reducedWidthStates = reduceWidth(statesReduceWidth, roundingUpSum);
  componentStatusBarItems = [...componentStatusBarItems, ...reducedWidthStates];

  // sorting of the states because order was changed by calculation process
  return componentStatusBarItems.sort((a, b) => a.order - b.order);
}

export function getComponentStatusBarItems(
  healthy: number,
  disrupted: number,
  failed: number,
  unknown: number,
): ComponentStatusBarItemModel[] {
  const total = healthy + disrupted + failed + unknown;

  const states: ComponentStatusModel[] = [
    { order: 0, key: 'HEALTHY', percentage: getPercentage(healthy, total) },
    { order: 1, key: 'DISRUPTED', percentage: getPercentage(disrupted, total) },
    { order: 2, key: 'FAILED', percentage: getPercentage(failed, total) },
    { order: 3, key: 'UNKNOWN', percentage: getPercentage(unknown, total) },
  ];

  // check if some state is lower than the minimum width percentage to be able to display the percentage value
  if (states.some((stat) => stat.percentage > 0 && stat.percentage < minimumWidth)) {
    return calcWidthsForStates(states);
  }

  return states.map((state) => mapToStatusBarItemModel(state, state.percentage));
}
