import { ObjectSchema } from 'yup';
import { YupType } from '../translation/YupLocal';
import CreateTicketModel from '../models/operation/CreateTicketModel';

const ticketValidationSchema = (validation: YupType) =>
  validation
    .object({
      title: validation.string().required().max(255).trim(),
      assigneeId: validation.string().required(),
    })
    .required() as ObjectSchema<CreateTicketModel>;

export default ticketValidationSchema;
