import { ObjectSchema } from 'yup';
import { AgentModuleType, NetworkResponseProtocols } from '../models/monitoring/AgentModule';
import { YupType } from '../translation/YupLocal';
import { snmpEncryptionAlgorithms, SnmpSecurityLevel, snmpSecurityLevels, snmpVersions } from '../models/snmp/Snmp';
import { Agent } from '../models/monitoring/Agent';

const httpModuleSchema = (validation: YupType) =>
  validation.object({
    type: validation.string().required().oneOf(['http-endpoint']),
    name: validation.string().required().max(255).trim(),
    moduleMethod: validation.string().required(),
    url: validation.string().required().max(255).trim(),
    body: validation.string().optional(),
  });

const networkResponseModuleSchema = (validation: YupType) =>
  validation.object({
    type: validation.string().required().oneOf(['network-response']),
    protocol: validation.string().required().oneOf(NetworkResponseProtocols),
    host: validation.string().required(),
    port: validation.number().required(),
  });

const snmpFieldSchema = (validation: YupType) =>
  validation.object({
    oid: validation.string().required().max(255).trim(),
    name: validation.string().required().max(255).trim(),
    metricIsTagged: validation.boolean().required(),
  });

const snmpTableSchema = (validation: YupType) =>
  validation.object({
    oid: validation.string().required().max(255).trim(),
    name: validation.string().required().max(255).trim(),
    fields: validation.array().of(snmpFieldSchema(validation)),
  });

const snmpModuleSchema = (validation: YupType) =>
  validation.object({
    type: validation.string().required().oneOf(['snmp']),
    address: validation.string().required().max(255).trim(),
    timeoutInSeconds: validation.number().nullable(),
    version: validation.string().required().oneOf(snmpVersions),
    community: validation.string().required(),
    auth: validation.object().when('version', {
      is: 'VERSION_3',
      then: (authSchema) =>
        authSchema
          .shape({
            contextName: validation.string().max(255).trim(),
            securityLevel: validation.string().required().oneOf(snmpSecurityLevels),

            protocol: validation.string().when('securityLevel', {
              is: (value: SnmpSecurityLevel | undefined) => value === 'AUTH_NO_PRIV' || value === 'AUTH_PRIV',
              then: (schema) => schema.required(),
            }),
            username: validation
              .string()
              .max(255)
              .trim()
              .when('securityLevel', {
                is: (value: SnmpSecurityLevel | undefined) => value === 'AUTH_NO_PRIV' || value === 'AUTH_PRIV',
                then: (schema) => schema.required(),
              }),
            password: validation
              .string()
              .max(255)
              .trim()
              .when('securityLevel', {
                is: (value: SnmpSecurityLevel | undefined) => value === 'AUTH_NO_PRIV' || value === 'AUTH_PRIV',
                then: (schema) => schema.required(),
              }),
            encryptionAlgorithm: validation
              .string()
              .oneOf(snmpEncryptionAlgorithms)
              .when('securityLevel', {
                is: (value: SnmpSecurityLevel | undefined) => value === 'AUTH_PRIV',
                then: (schema) => schema.required(),
              }),
            preSharedKey: validation
              .string()
              .max(255)
              .trim()
              .when('securityLevel', {
                is: (value: SnmpSecurityLevel | undefined) => value === 'AUTH_PRIV',
                then: (schema) => schema.required(),
              }),
          })
          .required(),
    }),
    fields: validation.array().of(snmpFieldSchema(validation)),
    tables: validation.array().of(snmpTableSchema(validation)),
  });

const tomlModuleSchema = (validation: YupType) =>
  validation.object({
    type: validation.string().required().oneOf(['toml']),
    toml: validation.string().required(),
  });

const buildPortal = (validation: YupType) => (item: any) => {
  const kind = item?.type as AgentModuleType;
  switch (kind) {
    case 'http-endpoint':
      return httpModuleSchema(validation);
    case 'network-response':
      return networkResponseModuleSchema(validation);
    case 'snmp':
      return snmpModuleSchema(validation);
    case 'toml':
      return tomlModuleSchema(validation);
    default:
      return validation.object({
        type: validation.string().oneOf([undefined]).required(),
      });
  }
};

const agentValidationSchema = (validation: YupType) =>
  validation
    .object({
      lastSeen: validation.string().required(),
      hostname: validation.string().required().max(255).trim(),
      plattform: validation.string().required().max(255).trim(),
      architecture: validation.string().required().max(255).trim(),
      modules: validation.array().of(validation.lazy(buildPortal(validation)) as any),
    })
    .required() as ObjectSchema<Agent>;

export default agentValidationSchema;
