import { ShortcutOptions, WellKnownKey } from '../models/shortcuts/ShortcutTypes';

class KeyCombination {
  key: WellKnownKey | string;

  options: ShortcutOptions;

  constructor(key: WellKnownKey | string, options: ShortcutOptions = {}) {
    this.key = key.toLocaleLowerCase();
    this.options = options;
  }

  static CancelAction = new KeyCombination('escape', { disabledWithinInputs: false });

  static TabAction = new KeyCombination('tab', { disabledWithinInputs: false, shift: { required: false } });

  static ShiftTabAction = new KeyCombination('tab', { disabledWithinInputs: false, shift: { required: true } });

  static SubmitWithinMultilineEnvironment = new KeyCombination('enter', { ctrl: { required: true } });

  static Submit = new KeyCombination('enter', { disabledWithinInputs: false });

  static CreateNewRelation = new KeyCombination('r');
}

export default KeyCombination;
