import { SingleValue } from 'react-select';
import SearchDropdown, { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import { Contract } from '../../models/contract-management/ContractManagementModel';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';

export interface ContractSearchDropdownProps {
  contract?: Contract | null;
  mapValueToSelectOption?: (value: Contract | null) => SingleValue<ReactSelectOption>;
  options: ReactSelectOption[];
  onChange: (selectedOption: SingleValue<ReactSelectOption>) => void;
  isLoading?: boolean;
  isError?: boolean;
  onInputChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
}

export default function ContractSearchDropdown({
  contract,
  onInputChange,
  onChange,
  mapValueToSelectOption,
  options,
  isLoading,
  isError,
  label,
  placeholder,
}: ContractSearchDropdownProps) {
  const { t } = useTranslationText('components');

  return (
    <FormFieldWrapper label={label}>
      <SearchDropdown<Contract | null>
        value={contract}
        mapValueToSelectOption={mapValueToSelectOption}
        options={options}
        onChange={onChange}
        onInputChange={onInputChange}
        requiredPermission={UserResourcePermissions.Component.CreateOrUpdate}
        placeholder={placeholder}
        isLoading={isLoading}
        isError={isError}
        isClearable
        noOptionsMessage={t('noContractFound')}
        isLoadingMessage={t('contractsLoading')}
      />
    </FormFieldWrapper>
  );
}
