import { ObjectSchema } from 'yup';
import { YupType } from '../../translation/YupLocal';
import { CreateContractorModel } from './ContractorModel';

const createContractorValidationSchema = (validation: YupType, t: (key: string) => string) =>
  validation
    .object({
      companyName: validation.string().trim().max(255).required(),
      street: validation.string().trim().max(255).required(),
      houseNumber: validation.string().max(255).trim().required(),
      city: validation.string().trim().max(255).required(),
      postalCode: validation.string().trim().required(),
      phoneNumber: validation.string().trim().max(255).optional(),
      faxNumber: validation.string().trim().max(255).optional(),
      email: validation.string().email(t('emailValidationError')).max(255).optional(),
      assigneeIds: validation.array().of(validation.string()).optional(),
    })
    .required() as ObjectSchema<CreateContractorModel>;

export { createContractorValidationSchema };
