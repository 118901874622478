import { useId } from 'react';
import { UserModel, userToInitials } from '../../models/operation/UserModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import './UserInfo.scss';
import Tooltip from '../tooltip/Tooltip';

interface UserInfoProps {
  email?: string;
  user?: UserModel;
  isError?: boolean;
}

function emailToUserInitials(email: string) {
  return `${email.at(0)?.toLocaleUpperCase()}${email.at(email.indexOf('.') + 1)?.toLocaleUpperCase()}`;
}

function UserInfo({ email, user, isError }: UserInfoProps) {
  const { t: tError } = useTranslationText('errorTexts');
  const anchorId = useId();

  if (user) {
    return (
      <>
        <div className="user-info" id={anchorId}>
          <span>{userToInitials(user)}</span>
        </div>
        <Tooltip anchorId={anchorId} content={`${user.firstName} ${user.lastName}`} />
      </>
    );
  }
  if (email) {
    return (
      <>
        <div className="user-info" id={anchorId}>
          <span>{emailToUserInitials(email)}</span>
        </div>
        <Tooltip anchorId={anchorId} content={email} />
      </>
    );
  }

  return (
    <>
      {isError && (
        <>
          (
          <div className="user-info user-avatar-error" id={anchorId}>
            ?
          </div>
          <Tooltip anchorId={anchorId} content={tError('assignedUserError')} />)
        </>
      )}
    </>
  );
}

export default UserInfo;
