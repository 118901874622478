import { ComponentStatus } from '../../models/operation/ComponentModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import ComponentStatusTag from './ComponentStatusTag';
import './ComponentStatusTagWithLabel.scss';

interface ComponentStatusWithLabelProps {
  status?: ComponentStatus;
}
export default function ComponentStatusTagWithLabel({ status }: ComponentStatusWithLabelProps) {
  const { t } = useTranslationText('components');

  return (
    <div className="component-status-tag-label">
      <span className="label">{t('status')}</span>
      <div className="tag-wrapper">
        <ComponentStatusTag status={status} />
      </div>
    </div>
  );
}
