import { toResourcePermissions } from './ResourcePermissionFunction';

export abstract class UserResourcePermissions {
  static readonly Ticket = toResourcePermissions('ticketing', 'ticket');

  static readonly TicketFavorite = toResourcePermissions('ticketing', 'ticket/favorite');

  static readonly TicketReportableProcess = toResourcePermissions('ticketing', 'ticket/reportable-process');

  static readonly TicketComment = toResourcePermissions('ticketing', 'ticket/comment');

  static readonly TicketAlerts = toResourcePermissions('ticketing', 'ticket/alert');

  static readonly TaskComment = toResourcePermissions('ticketing', 'task/comment');

  static readonly Article = toResourcePermissions('knowledgeBase', 'article');

  static readonly ArticleComment = toResourcePermissions('knowledgeBase', 'article/comment');

  static readonly Component = toResourcePermissions('monitoring', 'component');

  static readonly ComponentType = toResourcePermissions('monitoring', 'componentType');

  static readonly ComponentRelation = toResourcePermissions('monitoring', 'component/relation');

  static readonly Discovery = toResourcePermissions('monitoring', 'discovery');

  static readonly RelationType = toResourcePermissions('monitoring', 'relationType');

  static readonly Contract = toResourcePermissions('contractManagement', 'contract');

  static readonly Rule = toResourcePermissions('automation', 'rule');

  static readonly AdvancedRule = toResourcePermissions('automation', 'advancedRule');

  static readonly Contractor = toResourcePermissions('contractManagement', 'contractor');

  static readonly Task = toResourcePermissions('ticketing', 'task');

  static readonly Agent = toResourcePermissions('monitoring', 'agent');

  static readonly System = toResourcePermissions('monitoring', 'system');

  static readonly MaintenanceRequest = toResourcePermissions('monitoring', 'maintenance/request');

  static readonly TicketRelation = toResourcePermissions('ticketing', 'ticket/relation');

  static readonly FavoriteComponent = toResourcePermissions('monitoring', 'component/favorite');

  static readonly TaskFavorite = toResourcePermissions('ticketing', 'task/favorite');

  static readonly TicketFilter = toResourcePermissions('ticketing', 'filter');

  static readonly ComponentFilter = toResourcePermissions('monitoring', 'component-filter');

  static readonly ActiveAlertOverview = toResourcePermissions('monitoring', 'activeAlertOverview');

  static readonly ActiveAlertFilter = toResourcePermissions('monitoring', 'active-alert-filter');
}
