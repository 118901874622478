import { TicketSeverity } from '@/models/operation/TicketSeveritiesModel';
import { mapTValuesToLoadOptions, OptionLabelBaseProps, SelectOption } from '@/ui/select/SelectUtils';
import { TOptions } from 'i18next';
import TicketSeverityIcon from '@/ticketing/ticket-severity-icon/TicketSeverityIcon';
import { Tag } from '@aos/styleguide-react';
import { useTranslationText } from '@/translation/TranslationHooks';

export function findSeverityByKey(key: string, severities: TicketSeverity[]): TicketSeverity {
  return severities.find((severity) => severity.key === key)!;
}

export function mapSeverityToOption(severity: TicketSeverity): SelectOption<TicketSeverity> {
  return {
    value: severity.key,
    label: severity.translationKey,
    payload: severity,
  };
}

export function mapSeveritiesToOptions(severities: TicketSeverity[]): SelectOption<TicketSeverity>[] {
  return severities.map((severity) => mapSeverityToOption(severity));
}

export function severityLoadOptions(severities: TicketSeverity[]) {
  return mapTValuesToLoadOptions<TicketSeverity>(severities, mapSeveritiesToOptions);
}

export function SeverityOptionLabel({ payload }: OptionLabelBaseProps<TicketSeverity>) {
  const { t } = useTranslationText('tickets');

  return <TicketSeverityIcon severity={payload.key} text={t(payload.translationKey)} />;
}

interface MultiSeverityValueProps {
  data: SelectOption<TicketSeverity>;
  handleDelete: (deletedSeverityKey: string) => void;
  t: (key: string, options?: TOptions) => string;
}

export function MultiSeverityValue({ data, handleDelete, t }: MultiSeverityValueProps) {
  return (
    <Tag onDelete={() => handleDelete(data.payload.key)}>
      <TicketSeverityIcon severity={data.payload.key} text={t(data.payload.translationKey)} />
    </Tag>
  );
}
