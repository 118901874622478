import { SingleValue } from 'react-select';
import { mapTypesToOptions, mapTypeToOption } from '../../models/operation/TicketFunctions';
import { TicketQuery } from '../../models/operation/TicketQuery';
import { useGetTypes } from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import { ReactSelectOption } from '../search-dropdown/SearchDropdown';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import SingleSelectDropdown from '../single-select-dropdown/SingleSelectDropdown';
import { formattedTypeLabel } from '../../ticketing/create-ticket/CreateTicket';
import FormFieldWrapper from '../form-field-wrapper/FormFieldWrapper';

interface TypeFilterProps {
  selectedType: string | null | undefined;
  onChange: (name: keyof TicketQuery, value: any) => void;
  label?: string | undefined;
}

export default function TypeFilter({ selectedType, onChange, label }: TypeFilterProps) {
  const { t } = useTranslationText('tickets');
  const { data: types, isLoading: isLoadingType } = useGetTypes();

  const selectedTicketType = types?.find((type) => type.key === selectedType);

  const handleTypeOnChange = (selectedOption: SingleValue<ReactSelectOption>): void => {
    onChange('type', selectedOption?.value);
  };

  return (
    <FormFieldWrapper label={label} isLoading={isLoadingType} size="is-small">
      <SingleSelectDropdown
        requiredPermission={UserResourcePermissions.Ticket.Read}
        isLoading={isLoadingType}
        options={mapTypesToOptions(t, types)}
        value={selectedTicketType ? mapTypeToOption(t, selectedTicketType) : null}
        onChange={handleTypeOnChange}
        formatOptionLabel={formattedTypeLabel}
        placeholder={t('selectType')}
        size="is-small"
      />
    </FormFieldWrapper>
  );
}
