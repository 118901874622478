import { useCallback, useState } from 'react';
import { Button } from '@aos/styleguide-react';
import { toast } from 'react-toastify';
import {
  CreateRelationDto,
  RelationDirections,
  RelationSpec,
  TicketRelationDto,
} from '@/models/operation/RelationTypeModel';
import { Ticket } from '@/models/operation/TicketModel';
import { MutationKey, MutationPath, usePostMutation } from '@/react-query/MutationQueries';
import { useTranslationText } from '@/translation/TranslationHooks';
import './CreateTicketRelationMenu.scss';
import useGetRelations from '@/react-query/TicketRelationApi';
import SingleRelationTypeSelectWithDisableableOptions from '@/ticketing/ticket-relations/create-ticket-relation-menu/single-relation-type-select-with-disableable-options/SingleRelationTypeSelectWithDisableableOptions';
import SingleTicketSelectWithDisableableOptions from '@/ticketing/ticket-relations/create-ticket-relation-menu/single-ticket-select-with-disableable-options/SingleTicketSelectWithDisableableOptions';

interface CreateTicketRelationMenuProps {
  currentTicketId: number;
  onSuccess?: () => void;
  readOnly: boolean;
}

function CreateTicketRelationMenu({ currentTicketId, onSuccess, readOnly }: CreateTicketRelationMenuProps) {
  const { t } = useTranslationText('commons');
  const { t: tErrorTexts } = useTranslationText('errorTexts');
  const { data: relationsForCurrentTicket, refetch: refetchRelations } = useGetRelations(currentTicketId.toString());
  const [selectedRelationSpec, setSelectedRelationSpec] = useState<RelationSpec | null>(null);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);

  function resetCreateTicketMenu(refetch = false) {
    setSelectedRelationSpec(null);
    setSelectedTicket(null);

    if (refetch) {
      void refetchRelations();
    }
  }

  function ticketAvailableForRelation(ticketId: number) {
    if (!selectedRelationSpec) return true;

    const relevantRelations = relationsForCurrentTicket?.content.filter(
      (relation) => relation.type === selectedRelationSpec.type.identifier,
    );
    if (!relevantRelations?.length) return true;

    return !relevantRelations.filter((relation) => {
      if (selectedRelationSpec.direction === RelationDirections.FORWARD) {
        return relation.target?.id === ticketId;
      } else {
        return relation.source?.id === ticketId;
      }
    }).length;
  }

  function relationSpecAvailableForRelation(relationSpec: RelationSpec) {
    if (!selectedTicket) return true;

    const relevantRelations = relationsForCurrentTicket?.content.filter(
      (relation) => relation.source?.id === selectedTicket.id || relation.target?.id === selectedTicket.id,
    );
    if (!relevantRelations?.length) return true;

    return !relevantRelations.filter((relation) => {
      if (relationSpec.direction === RelationDirections.FORWARD) {
        return !!relation.target && relation.type === relationSpec.type.identifier;
      } else {
        return !!relation.source && relation.type === relationSpec.type.identifier;
      }
    }).length;
  }

  const { mutate } = usePostMutation<CreateRelationDto, TicketRelationDto>(MutationKey.PostTicketRelation, {
    onSuccess: () => {
      if (onSuccess) onSuccess();
      resetCreateTicketMenu(true);
    },
    onError: () => {
      toast.error(tErrorTexts('createTicketRelationError'));
    },
  });

  const canCreateRelation = !!selectedRelationSpec && !!selectedTicket;

  const submitCreateTicket = useCallback(() => {
    if (!selectedTicket || !selectedRelationSpec) return;

    const isForwardRelation = selectedRelationSpec.direction === RelationDirections.FORWARD;
    const forwardRelationSourceTicketId = isForwardRelation ? currentTicketId.toString() : selectedTicket.id.toString();
    const forwardRelationTargetTicketRef = {
      id: isForwardRelation ? selectedTicket.id.toString() : currentTicketId.toString(),
    };

    const newTicketRelation = {
      target: forwardRelationTargetTicketRef,
      type: selectedRelationSpec.type.identifier,
    };

    mutate({
      body: newTicketRelation,
      path: MutationPath.CreateTicketRelation(forwardRelationSourceTicketId),
    });
  }, [selectedTicket, selectedRelationSpec, currentTicketId, mutate]);

  return (
    <div className="create-ticket-relation-menu">
      <SingleRelationTypeSelectWithDisableableOptions
        key={selectedTicket?.id ?? 'no-id-for-single-relation-type-select-with-disableable-options-yet'}
        relationSpecAvailableForRelation={relationSpecAvailableForRelation}
        onChange={setSelectedRelationSpec}
        value={selectedRelationSpec}
        label={t('relationType')}
        size="is-small"
      />
      <SingleTicketSelectWithDisableableOptions
        key={selectedRelationSpec?.identifier ?? 'no-id-for-single-ticket-select-with-disableable-options-yet'}
        ticketAvailableForRelation={ticketAvailableForRelation}
        onChange={setSelectedTicket}
        value={selectedTicket}
        size="is-small"
        excludeTickets={[currentTicketId]}
      />
      <div className="menu-button-wrapper">
        <Button
          className="menu-button"
          isConfirm
          color="is-success"
          icon="check"
          disabled={readOnly || !canCreateRelation}
          onClick={submitCreateTicket}
        />
        <Button
          className="menu-button"
          isConfirm
          color="is-danger"
          icon="trash"
          disabled={readOnly || !canCreateRelation}
          onClick={resetCreateTicketMenu}
        />
      </div>
    </div>
  );
}

export default CreateTicketRelationMenu;
