import { formatDurationInMinutesToHours } from '../../../utils/dateFormatting';

interface ContractTimeItemProps {
  label: string;
  time?: number;
}

// TODO: ADB-285 Reaktions- und Wiederherstellungszeiten für Verträge müssen als einfach Zahlen vorhanden sein um einen Fortschrittsbalken anzuzeigen
export default function ContractTimeItem({ label, time }: ContractTimeItemProps) {
  const durationString = formatDurationInMinutesToHours(time ?? 0);

  return (
    <div className="contract-time-item">
      <div>
        <span className="label">{label}</span>
      </div>
      {durationString}
    </div>
  );
}
