import { createContext, useContext } from 'react';
import TenantEnum from './TenantEnum';

export interface CurrentTenantContextType {
  currentTenant: TenantEnum;
  setCurrentTenant: (tenant: TenantEnum) => void;
}

export const CurrentTenantContext = createContext<CurrentTenantContextType | 'not set'>('not set');

export function useCurrentTenant(): CurrentTenantContextType {
  const context = useContext(CurrentTenantContext);

  if (context === 'not set') {
    throw new Error('TenantContext is not set');
  }

  return {
    currentTenant: context.currentTenant,
    setCurrentTenant: (tenant) => {
      context.setCurrentTenant(tenant);
    },
  };
}
