import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faExclamationCircle,
  faMinusCircle,
  faQuestionCircle,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslationText } from '../translation/TranslationHooks';
import './StatusTable.scss';
import { SystemStageStatusType } from '../models/operation/StatusModel';

interface ComponentStatusTagProps {
  status?: SystemStageStatusType;
}

export default function StatusTableTag({ status }: ComponentStatusTagProps) {
  const { t } = useTranslationText('statuses');

  let statusIcon: IconDefinition;

  switch (status) {
    case 'HEALTHY':
      statusIcon = faCheckCircle;
      break;
    case 'FAILED':
      statusIcon = faExclamationCircle;
      break;
    case 'DISRUPTED':
      statusIcon = faTriangleExclamation;
      break;
    case 'UNKNOWN':
      statusIcon = faQuestionCircle;
      break;
    default:
      statusIcon = faMinusCircle;
  }

  return (
    <div className={`tag ${status?.toLowerCase() || ''}`}>
      <FontAwesomeIcon className="fa-fw" icon={statusIcon} size="lg" />
      {status && t(`status-${status.toLowerCase()}`)}
    </div>
  );
}
