import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import useParamId from '../../custom-hooks/UseParamId';
import useToggle from '../../custom-hooks/useToggle';
import { SystemModel, UpdateSystemModel } from '../../models/operation/SystemModel';
import { invalidateComponents, invalidateSystems } from '../../react-query/InvalidationQueries';
import { MutationKey, MutationPath, useDeleteMutation, usePutMutation } from '../../react-query/MutationQueries';
import { useGetSystem } from '../../react-query/SystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import useYupLocal from '../../translation/YupLocal';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import Modal from '../../ui/modal/Modal';
import { SystemDetails } from '../system-details/SystemDetails';
import { SystemStages } from '../system-stage/SystemStages';
import systemValidationSchema from '../SystemValidationSchema';
import { EditSystemActionBar } from './EditSystemActionBar';

export default function EditSystem() {
  const { id: systemId, ErrorPage } = useParamId();

  const { t } = useTranslationText('systems');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tCommon } = useTranslationText('commons');

  const { yup } = useYupLocal();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [preventNavigation, setPreventNavigation] = useState<boolean>(true);
  const formDataRef = useRef<HTMLFormElement>(null);
  const [isModalVisible, toggleModal] = useToggle(false);

  const systemFormReturn = useForm<UpdateSystemModel>({
    mode: 'onChange',
    resolver: yupResolver(systemValidationSchema(yup, tError)),
  });
  const { handleSubmit, formState, reset } = systemFormReturn;

  const {
    data: system,
    isLoading: systemIsLoading,
    isError,
  } = useGetSystem(systemId, {
    gcTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (system) {
      reset(system);
    }
  }, [reset, system]);

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const { mutate, isPending } = usePutMutation<UpdateSystemModel, SystemModel>(MutationKey.PutSystem, {
    onSuccess: async () => {
      await invalidateSystems(queryClient);
      navigate(-1);
      toast.success(tSuccess('updateSystem'));
    },
    onError: () => {
      toast.error(tError('systemPutError'));
    },
  });

  const onSubmit = (updateSystem: UpdateSystemModel) => {
    setPreventNavigation(false);
    mutate({
      body: {
        name: updateSystem.name,
        supportContactPerson: updateSystem.supportContactPerson,
        supportContactAddress: updateSystem.supportContactAddress,
        description: updateSystem.description,
        integrationsscheinNaming: updateSystem.integrationsscheinNaming,
        stages: updateSystem.stages,
      },
      path: MutationPath.PutSystem(systemId),
    });
  };

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteComponentType, {
    onSuccess: async () => {
      await invalidateSystems(queryClient);
      await invalidateComponents(queryClient);
      navigate(-1);
      toast.success(tSuccess('deleteSystem'));
    },
    onError: () => {
      toast.error(`${tError('systemDeleteError')}`);
    },
  });

  const onDelete = () => deleteMutate({ path: MutationPath.DeleteSystem(systemId) });

  return (
    <LoadingSpinner
      isLoading={systemIsLoading}
      errors={[
        isError ? <Trans i18nKey="errorTexts.SystemError.text" values={{ id: systemId }} /> : undefined,
        ErrorPage,
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)} ref={formDataRef}>
        <EditSystemActionBar formState={formState} isLoading={isPending} toggleDeleteSystemModal={toggleModal} />
        <SystemDetails form={systemFormReturn} />
        <SystemStages form={systemFormReturn} />
      </form>
      <Modal
        isVisible={isModalVisible}
        title={tCommon('deleteSystem')}
        confirmBtnText={tCommon('delete')}
        cancelBtnText={tCommon('back')}
        onCancel={() => toggleModal()}
        onClose={() => toggleModal()}
        onConfirm={onDelete}
      >
        <p>{t('warningSystemDelete')}</p>
      </Modal>
    </LoadingSpinner>
  );
}
