import { QueryParamConfigMap, StringParam } from 'use-query-params';

export interface AutomationRuleQuery {
  name: string;
}

export const AutomationRuleQueryParamConfigMap: AutomationRuleQueryParamConfigModel = {
  name: StringParam,
};

export interface AutomationRuleQueryParamConfigModel extends QueryParamConfigMap {
  name: typeof StringParam;
}
