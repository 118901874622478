import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { CreateSystemModel, LinkTypes, UpdateSystemModel } from '../../models/operation/SystemModel';
import { TTranslateFn, useTranslationText } from '../../translation/TranslationHooks';
import TextInput from '../../ui/text-input/TextInput';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import SingleValueInput from '../../ui/single-value-input/SingleValueInput';
import { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import ListItemWrapper from '../../ui/list-item-wrapper/ListItemWrapper';

interface SystemStageLinkProps {
  form: UseFormReturn<CreateSystemModel | UpdateSystemModel, object>;
  stageIndex: number;
  linkIndex: number;
  onRemove: () => void;
}

export function SystemStageLinksForm({ form, stageIndex, linkIndex, onRemove }: SystemStageLinkProps) {
  const { t } = useTranslationText('systems');
  const { control, formState } = form;

  const UrlInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<
        CreateSystemModel | UpdateSystemModel,
        `stages.${typeof stageIndex}.links.${typeof linkIndex}.url`
      >;
    }) => (
      <FormFieldWrapper
        error={formState?.errors?.stages?.at?.(stageIndex)?.links?.at?.(linkIndex)?.url}
        isRequired
        label={t('url')}
      >
        <TextInput
          onValueChange={field.onChange}
          value={field.value}
          error={formState?.errors?.stages?.at?.(stageIndex)?.links?.at?.(linkIndex)?.url}
          key={`stages.${typeof stageIndex}.links.${typeof linkIndex}.url`}
        />
      </FormFieldWrapper>
    ),
    [formState?.errors?.stages, stageIndex, linkIndex, t],
  );

  const mapLinkTypesToOptions = (tr: TTranslateFn): ReactSelectOption[] =>
    LinkTypes.map((linkType) => ({
      value: linkType,
      label: tr(`${linkType}`),
    }));

  const LinkTypeInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<
        CreateSystemModel | UpdateSystemModel,
        `stages.${typeof stageIndex}.links.${typeof linkIndex}.linkType`
      >;
    }) => (
      <FormFieldWrapper
        error={formState?.errors?.stages?.at?.(stageIndex)?.links?.at?.(linkIndex)?.linkType}
        label={t('linkType')}
        isRequired
      >
        <SingleValueInput
          onChange={(value) => field.onChange(value)}
          value={field.value}
          placeholder={t('stageLinkPlaceholder')}
          requiredPermission={UserResourcePermissions.System.Update}
          options={mapLinkTypesToOptions(t)}
          key={`stages.${typeof stageIndex}.links.${typeof linkIndex}.linkType`}
        />
      </FormFieldWrapper>
    ),
    [formState?.errors?.stages, stageIndex, linkIndex, t],
  );

  return (
    <ListItemWrapper
      mode="item"
      field={`stages.${stageIndex}.links.${linkIndex}`}
      index={linkIndex}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.System.Update}
      key={`stages.${stageIndex}.links.${linkIndex}`}
    >
      <div className="horizontal-wrapper">
        <div className="columns">
          <div className="column is-12">
            <Controller name={`stages.${stageIndex}.links.${linkIndex}.url`} control={control} render={UrlInput} />
          </div>
        </div>
        <div className="columns">
          <div className="column is-12">
            <Controller
              name={`stages.${stageIndex}.links.${linkIndex}.linkType`}
              control={control}
              render={LinkTypeInput}
            />
          </div>
        </div>
      </div>
    </ListItemWrapper>
  );
}
