import { ObjectSchema } from 'yup';
import { YupType } from '@/translation/YupLocal';
import { CreateAdvancedRule } from '@/automation/advanced-rules/AdvancedRuleModels';

function advancedRuleValidationSchema(validation: YupType) {
  return validation.object({
    name: validation.string().required().max(255).trim(),
    description: validation.string().notRequired().nullable().trim(),
    definition: validation.string().required().trim(),
    active: validation.boolean().required(),
  }) as ObjectSchema<CreateAdvancedRule>;
}

export default advancedRuleValidationSchema;
