import { QueryKey, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useCallback, useEffect } from 'react';
import ServerStateKeysEnum from './ServerStateKeysEnum';
import { useTranslationText } from '../translation/TranslationHooks';
import useNoAuthCustomFetch from './NoAuthCustomFetch';
import { QueryOptions } from './GetQuery';
import { FetchError } from './FetchError';

type ServerStateQueryKey = [ServerStateKeysEnum, ...Array<unknown>] | ServerStateKeysEnum;

export function useGetNoAuthQuery<ResponseBodyType>(
  queryKey: ServerStateQueryKey,
  path: string,
  options?: QueryOptions<ResponseBodyType>,
) {
  const customFetch = useNoAuthCustomFetch();
  const { t } = useTranslationText('errorTexts');

  const showErrorToasty = useCallback(
    (error: FetchError) => {
      if (error) {
        toast.error(t(`generalError`) + error.message, {
          toastId: error.status,
        });
      }
    },
    [t],
  );

  const queryResult = useQuery<ResponseBodyType, FetchError>({
    queryKey: queryKey as QueryKey,
    queryFn: async () => {
      const response = await customFetch(path);
      return (await response) as ResponseBodyType;
    },
    ...options,
  });

  useEffect(() => {
    if (queryResult.isError) {
      showErrorToasty(queryResult.error);
    }
  }, [queryResult.isError, queryResult.error, showErrorToasty]);

  return queryResult;
}
