import { gql } from '@apollo/client';

const GET_TRANSLATIONS = gql`
  query GetTranslations {
    alerts {
      key
      text
      locale
    }
    navigations {
      key
      text
      locale
    }
    errorTexts {
      key
      text
      locale
    }
    components {
      key
      text
      locale
    }
    componentTypes {
      key
      text
      locale
    }
    configurations {
      key
      text
      locale
    }
    relationTypes {
      key
      forward
      reverse
      locale
    }
    tickets {
      key
      text
      locale
    }
    histories {
      key
      text
      locale
    }
    comments {
      key
      text
      locale
    }
    knowledgeBases {
      key
      text
      locale
    }
    commons {
      key
      text
      locale
    }
    successTexts {
      key
      text
      locale
    }
    contractManagements {
      key
      text
      locale
    }
    automations {
      key
      text
      locale
    }
    agents {
      key
      text
      locale
    }
    actors {
      key
      text
      locale
    }
    systems {
      key
      text
      locale
    }
    statuses {
      key
      text
      locale
    }
    maintenances {
      key
      text
      locale
    }
  }
`;
export default GET_TRANSLATIONS;
