import { Trans } from 'react-i18next';
import dayjs from 'dayjs';
import WidgetWrapper from '../../WidgetWrapper';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import WidgetArticleView from '../widget-content/WidgetArticleView';
import { useGetKnowledgeBaseArticles } from '../../../../react-query/KnowledgeBaseApi';
import DateFormats from '../../../../models/date-formats/DateFormats';

export default function LastEditedArticles() {
  const { t } = useTranslationText('knowledgeBases');
  const {
    data: articles = [],
    isLoading,
    isError,
  } = useGetKnowledgeBaseArticles({ limit: 5, sort: 'updated_at:DESC' });

  return (
    <WidgetWrapper
      title={t('lastEdited')}
      isLoading={isLoading}
      isError={isError}
      noContent={t('noRecentlyEditedArticles')}
    >
      {articles?.map((article) => (
        <WidgetArticleView
          key={article.id}
          id={article.id}
          title={article.title}
          subTitle={
            <Trans
              i18nKey="knowledgeBases.editedAtBy.text"
              values={{
                currentEditor: article.currentEditor,
                date: `${dayjs(article.updatedAt).format(DateFormats.FULLDATE_HOURS_MINS)}`,
              }}
            />
          }
        />
      ))}
    </WidgetWrapper>
  );
}
