import './ComponentMultiSelectDropdown.scss';
import { useQueryClient } from '@tanstack/react-query';
import ComponentsMultiSelectContext from '../ComponentsMultiSelectContext';
import MultiSelectDropdown from '../../ui/multiselect/multiselect-dropdown/MultiSelectDropdown';
import InputSpoiler from '../../ui/input-spoiler/InputSpoiler';
import { useTranslationText } from '../../translation/TranslationHooks';
import SingleComponentSelect from '../component-selects/SingleComponentSelect/SingleComponentSelect';
import { MultiSelect } from '../../ui/multiselect/MultiSelectContext';
import { SummarizedComponent } from '../../models/operation/ComponentModel';
import { MutationKey, MutationPath, usePatchMutation, usePostMutation } from '../../react-query/MutationQueries';
import { ComponentRelationDto, CreateRelationDto } from '../../models/operation/RelationTypeModel';
import { invalidateComponentRelations, invalidateComponents } from '../../react-query/InvalidationQueries';
import { performBulkOperation, useBulkOperationNotifications } from '../../custom-hooks/BulkActions';
import { DropdownItemContext } from '../../ui/dropdown/Dropdown';
import { Patch, replaceProperty } from '../../models/patch/PatchModel';
import ContractSearchDropdown from '../component-contract-item/ContractSearchDropdown';
import { mapContractItemsToOptions } from '../../models/contract-management/ContractFunctions';
import useGetContractComponent from '../useGetContractComponent';

interface ComponentBulkRelationActionProps {
  relationType: 'part-of' | 'depends-on';
  relationTranslationKeySuffix: string;
  multiSelect: MultiSelect<SummarizedComponent> | undefined;
  ctx: DropdownItemContext;
}

function ComponentBulkRelationAction({
  relationType,
  relationTranslationKeySuffix,
  ctx,
  multiSelect,
}: ComponentBulkRelationActionProps) {
  const { t: tComponents } = useTranslationText('components');
  const notifyBulkAction = useBulkOperationNotifications();
  const { mutateAsync } = usePostMutation<CreateRelationDto, ComponentRelationDto>(MutationKey.PostComponentRelation);

  const queryClient = useQueryClient();

  const relationForAll = (targetComponentId: string) => {
    if (multiSelect?.selected) {
      void notifyBulkAction(
        performBulkOperation(
          multiSelect.selected,
          (srcComponent) =>
            mutateAsync({
              path: MutationPath.CreateComponentRelation(srcComponent.id),
              body: {
                target: {
                  id: targetComponentId,
                },
                type: relationType,
              } as CreateRelationDto,
            }),
          {
            onSingleSuccess: (oldComponent) => {
              multiSelect.setSelected(oldComponent, false, false);
              return invalidateComponentRelations(queryClient, targetComponentId);
            },
          },
        ),
      ).finally(() => {
        multiSelect.clear();
        return invalidateComponents(queryClient);
      });
    }
  };

  return (
    <InputSpoiler
      title={<div className="dropdown-item">{tComponents(`bulk${relationTranslationKeySuffix}`)}</div>}
      active={ctx.isActive}
    >
      <div className="p-3">
        <SingleComponentSelect
          label={tComponents(`bulk${relationTranslationKeySuffix}Target`)}
          onChange={(suggestion) => {
            if (suggestion?.id) {
              relationForAll(suggestion.id);
            }
            ctx.closeDropdown();
          }}
          value={null}
        />
      </div>
    </InputSpoiler>
  );
}

interface ComponentBulkSetContractActionProps {
  multiSelect: MultiSelect<SummarizedComponent> | undefined;
  ctx: DropdownItemContext;
}

function ComponentBulkSetContractAction({ multiSelect, ctx }: ComponentBulkSetContractActionProps) {
  const { t: tComponents } = useTranslationText('components');
  const notifyBulkAction = useBulkOperationNotifications();
  const { mutateAsync } = usePatchMutation<Patch, SummarizedComponent>(MutationKey.PatchComponent);

  const {
    data: contractsPaged,
    isError: isContractError,
    isLoading: isContractLoading,
    setFilter,
  } = useGetContractComponent();

  const queryClient = useQueryClient();

  const contractForAll = (contractId?: string) => {
    if (multiSelect?.selected && contractId) {
      void notifyBulkAction(
        performBulkOperation(
          multiSelect.selected,
          (component) =>
            mutateAsync({
              path: MutationPath.PatchComponent(component.id),
              body: [replaceProperty('assignedContractId', contractId)],
            }),
          {
            onSingleSuccess: (oldComponent) => {
              multiSelect.setSelected(oldComponent, false, false);
              return invalidateComponents(queryClient);
            },
          },
        ),
      ).finally(() => {
        multiSelect.clear();
        return invalidateComponents(queryClient);
      });
    }
  };

  return (
    <InputSpoiler
      title={<div className="dropdown-item">{tComponents('bulkAssignedContract')}</div>}
      active={ctx.isActive}
    >
      <div className="p-3">
        <ContractSearchDropdown
          options={mapContractItemsToOptions(contractsPaged?.content)}
          onChange={(value) => contractForAll(value?.value)}
          isLoading={isContractLoading}
          isError={isContractError}
          onInputChange={setFilter}
          label={tComponents('bulkAssignedContractTarget')}
          placeholder={tComponents('bulkSearchContract')}
        />
      </div>
    </InputSpoiler>
  );
}

function ComponentMultiSelectDropdown() {
  return (
    <div className="component-multiselect-dropdown">
      <ComponentsMultiSelectContext.Consumer>
        {(multiSelect) => (
          <MultiSelectDropdown
            context={ComponentsMultiSelectContext}
            renderItems={(ctx) => [
              <ComponentBulkRelationAction
                key="part-of-bulk-action"
                relationType="part-of"
                ctx={ctx}
                relationTranslationKeySuffix="PartOf"
                multiSelect={multiSelect}
              />,
              <ComponentBulkRelationAction
                key="depends-on-bulk-action"
                relationType="depends-on"
                ctx={ctx}
                relationTranslationKeySuffix="DependsOn"
                multiSelect={multiSelect}
              />,
              <ComponentBulkSetContractAction key="contract-bulk-action" ctx={ctx} multiSelect={multiSelect} />,
            ]}
          />
        )}
      </ComponentsMultiSelectContext.Consumer>
    </div>
  );
}

export default ComponentMultiSelectDropdown;
