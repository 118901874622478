interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

interface Pageable {
  sort: Sort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

// This is the old page model when using the invalid Jackson/Spring serialization.
// See also: https://github.com/lisegmbh/hessen-mobil-bms/issues/2138
export interface Page<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

// This is the stable page model, which should be used as soon as the API returns the correct models.
// See also: https://github.com/lisegmbh/hessen-mobil-bms/issues/2138
export interface Page2<T> {
  content: T[];
  page: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}

export const DEFAULT_PAGE_SIZE: number = 20;
export const DEFAULT_PAGE: number = 0;
export const DEFAULT_PAGE_SIZE_DROPDOWN = 8;
