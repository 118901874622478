import WidgetWrapper from '../../WidgetWrapper';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import { useGetKnowledgeBaseArticles } from '../../../../react-query/KnowledgeBaseApi';
import WidgetArticleView from '../widget-content/WidgetArticleView';

export default function MostVisitedArticles() {
  const { t } = useTranslationText('knowledgeBases');
  const {
    data: articles,
    isLoading,
    isError,
  } = useGetKnowledgeBaseArticles({
    sort: 'visits',
    limit: 5,
  });

  return (
    <WidgetWrapper
      title={t('oftenSeen')}
      isLoading={isLoading}
      isError={isError}
      dataRole="most-visited-widget"
      noContent={t('noMostVisitedArticles')}
    >
      {articles
        ?.sort((article1, article2) => (article2?.visits || 0) - (article1?.visits || 0))
        .map((article) => (
          <WidgetArticleView
            key={article.id}
            id={article.id}
            title={article.title}
            subTitle={t('numberOfVisits', { numberOfVisits: article.visits ?? '-' })}
          />
        ))}
    </WidgetWrapper>
  );
}
