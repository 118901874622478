import { useContext, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { Outlet } from 'react-router';
import { DropZoneContext } from './DropZoneContext';
import { DropzoneHoverBox } from './DropzoneHoverBox';
import './ui/content/Content.scss';

export default function ContentWrapper() {
  const contentRef = useRef<HTMLDivElement>(null);
  const { isDropZoneEnabled, onDrop } = useContext(DropZoneContext);

  return (
    <div className="aos-content has-extra-styles" ref={contentRef}>
      <Dropzone noKeyboard onDrop={onDrop} noDrag={!isDropZoneEnabled}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div className="content-wrapper" {...getRootProps()} onClick={() => false}>
            <DropzoneHoverBox inputProps={getInputProps()} dragActive={isDragActive} />
            <Outlet />
          </div>
        )}
      </Dropzone>
    </div>
  );
}
