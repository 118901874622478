import { useCallback } from 'react';
import { FieldError, FieldErrorsImpl, FormState, Merge } from 'react-hook-form';
import { TTranslateFn } from '../../translation/TranslationHooks';
import { ReactSelectOption } from '../../ui/search-dropdown/SearchDropdown';
import { Agent } from '../monitoring/Agent';
import { AgentModuleTypes, BaseAgentModule, NetworkResponseProtocols, SnmpModule } from '../monitoring/AgentModule';
import {
  snmpAuthProtocols,
  snmpEncryptionAlgorithms,
  snmpSecurityLevels,
  SnmpV3Auth,
  snmpVersions,
} from '../snmp/Snmp';

export const mapToModuleTypesToOptions = (t: TTranslateFn): ReactSelectOption[] =>
  AgentModuleTypes.map((element) => ({
    value: element,
    label: t(`module-type-${element}`),
  }));

export const mapHttpMethodsToOptions = (t: TTranslateFn, moduleMethods?: string[]): ReactSelectOption[] =>
  moduleMethods?.map(
    (element): ReactSelectOption => ({
      value: element,
      label: element,
    }),
  ) ?? [];

export const mapSnmpVersionsToOptions = (t: TTranslateFn): ReactSelectOption[] =>
  snmpVersions.map((snmpVersion) => ({
    value: snmpVersion,
    label: t(`SNMP_${snmpVersion}`),
  }));

export const mapNetworkResponseProtocolsToOptions = (): ReactSelectOption[] =>
  NetworkResponseProtocols.map((protocol) => ({
    value: protocol,
    label: protocol.toUpperCase(),
  }));

export const mapSnmpAuthProtocolsToOptions = (t: TTranslateFn): ReactSelectOption[] =>
  snmpAuthProtocols.map((protocol) => ({
    value: protocol,
    label: t(`SNMP_AUTH_${protocol}`),
  }));

export const mapSnmpSecurityLevelToOptions = (t: TTranslateFn): ReactSelectOption[] =>
  snmpSecurityLevels.map((securityLevel) => ({
    value: securityLevel,
    label: t(`SNMP_SECURITY_LEVEL_${securityLevel}`),
  }));

export const mapSnmpEncryptionAlgorithmToOptions = (t: TTranslateFn): ReactSelectOption[] =>
  snmpEncryptionAlgorithms.map((encryptionAlgorithm) => ({
    value: encryptionAlgorithm,
    label: t(`SNMP_ENCRYPTION_ALGORITHM_${encryptionAlgorithm}`),
  }));

export function useModuleErrors<T extends BaseAgentModule>(formState: FormState<Agent>, index: number) {
  return useCallback(
    (field: keyof T): FieldError | undefined => {
      const modules = formState.errors?.modules;
      if (!modules) {
        return undefined;
      }
      const module = modules[index];
      if (!module) {
        return undefined;
      }
      const typedModule = module as Merge<FieldError, FieldErrorsImpl<NonNullable<T>>>;
      return typedModule[field] as FieldError;
    },
    [formState.errors?.modules, index],
  );
}

export function useSnmpModuleAuthErrors(formState: FormState<Agent>, index: number) {
  return useCallback(
    (field: keyof SnmpV3Auth): FieldError | undefined => {
      const modules = formState.errors?.modules;
      if (!modules) {
        return undefined;
      }
      const module = modules[index];
      if (!module) {
        return undefined;
      }
      const typedModule = module as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpModule>>>;
      const snmpAuth = typedModule.auth as Merge<FieldError, FieldErrorsImpl<NonNullable<SnmpV3Auth>>>;
      if (!snmpAuth) {
        return undefined;
      }
      return snmpAuth[field] as FieldError;
    },
    [formState.errors?.modules, index],
  );
}
