import { ChangeEvent } from 'react';
import './SwitchInput.scss';
import classNames from 'classnames';
import { Switch as AosSwitch } from '@aos/styleguide-react';

interface SwitchInputProps {
  id: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  secondLabel?: string;
  isError?: boolean;
  disabled?: boolean;
  isSmall?: boolean;
  isLoading?: boolean;
}

export function SwitchInput({
  id,
  checked,
  onChange = () => {},
  secondLabel,
  isError,
  disabled,
  isSmall = false,
  isLoading,
}: SwitchInputProps) {
  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked);
  }

  return (
    <AosSwitch
      label={secondLabel ?? ''}
      isSmall={isSmall}
      disabled={disabled || isLoading}
      checked={checked}
      className={classNames({ 'is-danger': isError })}
      name="switch"
      id={id}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={handleOnChange}
    />
  );
}
