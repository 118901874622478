import React, { useEffect, useState } from 'react';
import { isKeyPressed, KeyboardEventKeys } from '../../models/key-handlers/KeyHandlerFunctions';
import { useAuthUser } from '../../auth/UserRoleCheck';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import { useTranslationText } from '../../translation/TranslationHooks';
import { UserPermission } from '../../auth/UserPermission';

export interface EditableInputProps<T> {
  error?: string;
  isLoading: boolean;
  label?: string;
  placeholder?: string;
  onAcceptNewValue?: (newValue: T, setEndEditing: (endEditing: boolean) => void) => void;
  onChange?: (newValue: T) => void;
  value: T;
  requiredPermission: UserPermission;
  dataRole?: string;
  options?: EditableInputOptions;
}

export interface EditableInputOptions {
  isRequired?: boolean;
  isClearable?: boolean;
}

export interface DisplayComponentProps<T> {
  label?: string;
  placeholder?: string;
  value: T;
  dataRole?: string;
  isUpdated?: boolean;
  isRequired?: boolean;
}

export interface EditingComponentProps<T> extends DisplayComponentProps<T> {
  error?: string;
  isEdited: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onChange?: (value: T) => void;
  onAcceptNewValue?: () => void;
  isClearable?: boolean;
  isRequired?: boolean;
}

interface EditableProps<T> extends EditableInputProps<T> {
  displayComponent: React.ComponentType<DisplayComponentProps<T>>;
  editingComponent: React.ComponentType<EditingComponentProps<T>>;
}

export default function Editable<T>({
  error,
  isLoading,
  label,
  onAcceptNewValue,
  onChange,
  displayComponent: DisplayComponent,
  editingComponent: EditingComponent,
  placeholder,
  value,
  requiredPermission,
  dataRole,
  options,
}: EditableProps<T>) {
  const [isEditing, setIsEditing] = useState(false);
  const [oldValue, setOldValue] = useState<T>(value);
  const [editedValue, setEditedValue] = useState<T>(value);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const { hasPermission } = useAuthUser();
  const { t: tCommon } = useTranslationText('commons');

  useEffect(() => {
    if (value === oldValue || oldValue === undefined) {
      setOldValue(value);
    }
  }, [oldValue, value]);

  usePreventNavigation(isEditing ? value !== oldValue : false, tCommon('discardOpenChangesQuestion'));

  function handleOnChange(newValue: T) {
    setEditedValue(newValue);
    if (onChange !== undefined) {
      onChange(newValue);
    }
  }

  function handleAcceptNewValue() {
    if (onAcceptNewValue !== undefined) {
      onAcceptNewValue(editedValue, (endEditing) => {
        if (endEditing) {
          setIsEditing(false);
          setOldValue(value);
          setIsUpdated(true);
        }
      });
    }
  }

  function handleCancelClick() {
    handleOnChange(oldValue);
    setIsEditing(false);
    setIsUpdated(false);
  }

  function enterEditMode() {
    if (!isEditing) {
      setEditedValue(value);
      setIsEditing(true);
    }
  }

  if (!hasPermission(requiredPermission)) {
    return <DisplayComponent label={label} placeholder={placeholder} value={value} />;
  }

  return (
    <div
      onClick={enterEditMode}
      onFocus={enterEditMode}
      onKeyUp={(e) => isKeyPressed(e, [KeyboardEventKeys.ESCAPE], handleCancelClick)} // edit when pressing space or enter
    >
      {!isEditing ? (
        <DisplayComponent
          label={label}
          placeholder={placeholder}
          value={value}
          dataRole={`display-${dataRole}`}
          isUpdated={isUpdated}
          isRequired={options?.isRequired}
        />
      ) : (
        <EditingComponent
          error={error}
          isEdited={isEditing && editedValue !== oldValue}
          isLoading={isLoading}
          label={label}
          onCancel={handleCancelClick}
          onChange={handleOnChange}
          onAcceptNewValue={oldValue === editedValue ? handleCancelClick : handleAcceptNewValue}
          placeholder={placeholder}
          value={editedValue}
          dataRole={`edit-${dataRole}`}
          isClearable={options?.isClearable}
          isRequired={options?.isRequired}
        />
      )}
    </div>
  );
}
