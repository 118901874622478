import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { Collapsible } from '@aos/styleguide-react';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import MultiValueInput from '../../ui/multi-value-input/MultiValueInput';
import TextArea from '../../ui/text-area/TextArea';

interface ComponentSelectorFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
}

export default function ComponentSelectorForm({ form }: ComponentSelectorFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { control, formState } = form;

  const QueryInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, 'componentSelector.query'> }) => (
      <FormFieldWrapper
        error={formState.errors?.componentSelector?.query}
        label={t('componentSelectorQueryLabel')}
        isRequired
      >
        <TextArea
          placeholder={t('componentSelectorQueryPlaceholder')}
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.componentSelector?.query}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.componentSelector?.query],
  );

  const LabelsInput = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, 'componentSelector.labels'> }) => (
      <FormFieldWrapper
        error={formState.errors?.componentSelector?.labels}
        label={t('componentSelectorLabelsLabel')}
        isRequired
      >
        <MultiValueInput
          placeholder={t('componentSelectorLabelsPlaceholder')}
          onChange={field.onChange}
          values={field.value}
          isError={formState.errors?.componentSelector?.labels !== undefined ?? false}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.componentSelector?.labels],
  );

  return (
    <Collapsible isInitiallyCollapsed={false} header={t('componentSelector')}>
      <Controller name="componentSelector.query" control={control} render={QueryInput} />
      <Controller name="componentSelector.labels" control={control} render={LabelsInput} />
    </Collapsible>
  );
}
