import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flatpickr from 'react-flatpickr';
import { German } from 'flatpickr/dist/l10n/de';
import React, { useEffect, useRef, useState } from 'react';
import Editable, { EditableInputProps, EditingComponentProps } from './Editable';
import DateLabel from '../date-label/DateLabel';
import { FLATPICKR_DATE_FORMAT } from '../../models/dates/dateConstants';

function useFlatpickrAutoFocus() {
  const flatpickrRef = useRef<Flatpickr | null>(null);
  const controlDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const fp = flatpickrRef?.current?.flatpickr;
    if (fp) {
      fp.open();
      if (controlDivRef.current) {
        controlDivRef.current.focus();
      }
    }
  }, [flatpickrRef, controlDivRef]);

  return {
    flatpickrRef,
    controlDivRef,
  };
}

function EditingDatePickerComponent({
  error,
  isEdited,
  isLoading,
  label,
  onCancel,
  onChange,
  onAcceptNewValue,
  placeholder,
  value,
  dataRole,
  isClearable = false,
  isRequired,
}: EditingComponentProps<Date | undefined>) {
  const [date, setDate] = useState(value);

  const { flatpickrRef, controlDivRef } = useFlatpickrAutoFocus();
  const [isOpen, setOpen] = useState<boolean>(false);

  // The following opens the flatpickr selector immediately after the input
  // changed into editing mode (aka. has been clicked)
  useEffect(() => {
    if (flatpickrRef.current?.flatpickr) {
      flatpickrRef.current?.flatpickr.open();
    }
  }, [flatpickrRef]);

  const isFocusGoingToFlatpickrInput = (e: React.FocusEvent<HTMLDivElement, Element>) =>
    e.relatedTarget?.tagName?.toLocaleLowerCase() === 'input' && e.relatedTarget?.id === label;
  const isBubblingEvent = (e: React.FocusEvent<HTMLDivElement, Element>) => e.target !== controlDivRef.current;

  const handleControlBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    if (
      isBubblingEvent(e) ||
      isFocusGoingToFlatpickrInput(e) ||
      isOpen // The selector is still open
    ) {
      return;
    }
    if (onAcceptNewValue) {
      onAcceptNewValue();
    }
  };

  return (
    <div className="field">
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className="field has-addons has-addons-right">
        <div
          className="control is-expanded no-focus-border"
          ref={controlDivRef}
          onBlur={handleControlBlur}
          onClick={() => flatpickrRef.current?.flatpickr?.open()}
          tabIndex={0}
        >
          <Flatpickr
            ref={flatpickrRef}
            className={classNames('input', {
              'is-warning': isEdited,
              'is-danger': error !== undefined,
            })}
            id={label}
            onChange={([newDate]) => {
              if (newDate || isClearable) {
                setDate(newDate);
                onChange?.(newDate);
              }
            }}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              if (isOpen) {
                setOpen(false);
                controlDivRef.current?.focus(); // Move focus to entire div, so we do not loose focus
              }
            }}
            placeholder={placeholder}
            readOnly={isLoading}
            value={date}
            data-role={dataRole}
            options={{
              allowInput: true,
              dateFormat: FLATPICKR_DATE_FORMAT,
              locale: German,
            }}
          />
        </div>
        <div>
          <div className="control is-full-height">
            <button
              className={classNames('button', 'is-primary', 'is-full-height', {
                'is-loading': isLoading,
                'is-warning': isEdited,
              })}
              disabled={isLoading}
              onMouseDown={(e) => {
                e.preventDefault();
                if (onAcceptNewValue) {
                  onAcceptNewValue();
                }
              }}
              type="submit"
              data-role="save-button"
              tabIndex={-1}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        </div>
        <div>
          <div className="control is-full-height">
            <button
              tabIndex={-1}
              className="button is-full-height"
              disabled={isLoading}
              onMouseDown={(e) => {
                e.preventDefault();
                onCancel();
              }}
              type="button"
              data-role="cancel-button"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function EditableDatePicker({
  error,
  isLoading,
  label,
  placeholder,
  onAcceptNewValue,
  onChange,
  value,
  requiredPermission,
  dataRole,
  options,
}: EditableInputProps<Date | undefined>) {
  return (
    <Editable<Date | undefined>
      displayComponent={DateLabel}
      editingComponent={EditingDatePickerComponent}
      error={error}
      isLoading={isLoading}
      label={label}
      placeholder={placeholder}
      onAcceptNewValue={onAcceptNewValue}
      onChange={onChange}
      value={value}
      requiredPermission={requiredPermission}
      dataRole={dataRole}
      options={options}
    />
  );
}
