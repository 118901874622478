import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import Editable, { EditableInputProps, EditingComponentProps } from './Editable';
import TextLabel from '../text-label/TextLabel';

function EditingTextInputComponent({
  error,
  isEdited,
  isLoading,
  label,
  onCancel,
  onChange,
  onAcceptNewValue,
  placeholder,
  value,
  dataRole,
  isRequired,
}: EditingComponentProps<string>) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="field">
      {label && (
        <label className={classNames('label', { 'is-required': isRequired })} htmlFor={label}>
          {label}
        </label>
      )}
      <div className="field has-addons has-addons-right">
        <div className="control is-expanded">
          <input
            className={classNames('input', {
              'is-warning': isEdited,
              'is-danger': error !== undefined,
            })}
            id={label}
            tabIndex={0}
            onChange={(e) => {
              if (onChange !== undefined) {
                onChange(e.target.value);
              }
            }}
            onBlur={() => {
              if (onAcceptNewValue) {
                onAcceptNewValue();
              }
            }}
            placeholder={placeholder}
            readOnly={isLoading}
            ref={inputRef}
            type="text"
            value={value}
            data-role={dataRole}
          />
        </div>
        <div>
          <div className="control is-full-height">
            <button
              className={classNames('button', 'is-primary', 'is-full-height', {
                'is-loading': isLoading,
                'is-warning': isEdited,
              })}
              disabled={isLoading}
              onMouseDown={(e) => {
                e.preventDefault();
                if (onAcceptNewValue) {
                  onAcceptNewValue();
                }
              }}
              type="submit"
              data-role="save-button"
              tabIndex={-1}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        </div>
        <div>
          <div className="control is-full-height">
            <button
              tabIndex={-1}
              className="button is-full-height"
              disabled={isLoading}
              onMouseDown={(e) => {
                e.preventDefault();
                onCancel();
              }}
              type="button"
              data-role="cancel-button"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function EditableTextInput({
  error,
  isLoading,
  label,
  placeholder,
  onAcceptNewValue,
  onChange,
  value,
  requiredPermission,
  dataRole,
  options,
}: EditableInputProps<string>) {
  return (
    <Editable
      displayComponent={TextLabel}
      editingComponent={EditingTextInputComponent}
      error={error}
      isLoading={isLoading}
      label={label}
      placeholder={placeholder}
      onAcceptNewValue={onAcceptNewValue}
      onChange={onChange}
      value={value}
      requiredPermission={requiredPermission}
      dataRole={dataRole}
      options={options}
    />
  );
}
