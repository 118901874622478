import { useState } from 'react';
import { useGetComponent } from '../../../react-query/ComponentApi';
import { SwitchInput } from '../../../ui/switch/SwitchInput';
import SingleComponentSelect from '../../../components/component-selects/SingleComponentSelect/SingleComponentSelect';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { GenericConditionConfigurationProps } from './GenericConditionConfigurationProps';
import { RuleCondition } from '../../../models/operation/AutomationRuleModel';
import { BaseComponentItem } from '../../../models/operation/ComponentModel';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';

interface HasAssignedComponentConfiguration extends RuleCondition {
  type: 'hasAssignedComponent';
  withDescendents: boolean;
  componentId: string;
}

export default function HasAssignedComponentCondition({
  condition,
  handleConfigurationUpdate,
  onClear,
}: Readonly<GenericConditionConfigurationProps<HasAssignedComponentConfiguration>>) {
  const [componentId, setComponentId] = useState<string | undefined>(condition?.componentId);
  const [withDescendents, setWithDescendents] = useState<boolean>(condition?.withDescendents ?? false);
  const { t } = useTranslationText('automations');
  const { t: tError } = useTranslationText('errorTexts');

  const update = (id: string | undefined, descendants: boolean) => {
    if (id) {
      handleConfigurationUpdate({
        type: 'hasAssignedComponent',
        componentId: id,
        withDescendents: descendants,
      } as HasAssignedComponentConfiguration);
    }
  };

  const handleComponentsChanged = (component: BaseComponentItem | null) => {
    setComponentId(component?.id);
    if (!component) {
      onClear();
    }
    update(component?.id, withDescendents);
  };

  const handleSwitchChange = (value: boolean) => {
    setWithDescendents(value);
    update(componentId, value);
  };

  const { data: selectedComponent, isError: unknownComponent } = useGetComponent(componentId!, {
    enabled: !!componentId,
    suppressErrorToast: true,
  });

  return (
    <div>
      <SingleComponentSelect onChange={handleComponentsChanged} value={selectedComponent || null} />
      {componentId && !unknownComponent && (
        <FormFieldWrapper label={t('withDescendentComponents')}>
          <SwitchInput checked={withDescendents} id="with-descendents" disabled={false} onChange={handleSwitchChange} />
        </FormFieldWrapper>
      )}
      {unknownComponent && <div>{tError('404_component', { id: componentId })}</div>}
    </div>
  );
}
