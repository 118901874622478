import { useTranslationText } from '../../../translation/TranslationHooks';
import FormFieldWrapper from '../../form-field-wrapper/FormFieldWrapper';
import { SwitchInput } from '../../switch/SwitchInput';

interface ComponentOnlyFavoritesFilterProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export default function ComponentOnlyFavoritesFilter({ isChecked, onChange }: ComponentOnlyFavoritesFilterProps) {
  const { t } = useTranslationText('components');

  return (
    <FormFieldWrapper label={t('onlyFavorites')}>
      <SwitchInput checked={isChecked} onChange={onChange} id="only-favorites" />
    </FormFieldWrapper>
  );
}
