import { Ticket } from '@/models/operation/TicketModel';
import { SelectOption } from '@/ui/select/SelectUtils';

export function mapTicketToDisableableOption(
  ticket: Ticket,
  ticketAvailable?: (ticket: Ticket) => boolean,
): SelectOption<Ticket> {
  return {
    value: ticket.id.toString(),
    label: ticket.title,
    payload: ticket,
    isDisabled: ticketAvailable ? ticketAvailable(ticket) : undefined,
  };
}
