import {
  TitleBarMenu as AosTitleBarMenu,
  UserInfoTitleBarItem as AosUserInfoTitleBarItem,
} from '@aos/styleguide-react';
import { getInitials } from '@aos/styleguide-react/dist/stories/elements/user-info/UserInfo';
import { type User } from 'oidc-client-ts';
import './LayoutUserInfoTitleBarItem.scss';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { TenantMenu } from './TenantMenu';
import NavigationRoutes from '../../routing/NavigationRoutes';
import { usePresenceHeartbeat } from './usePresenceHeartbeat';
import { useTranslationText } from '../../translation/TranslationHooks';

const ONLINE_TIMEOUT = 10 * 60 * 1000; // ms
const UPDATE_INTERVAL = 2000; // s

interface LayoutUserInfoTitleBarItemProps {
  user: User;
}

enum UserStatus {
  Online = 'is-online',
  Offline = 'is-offline',
  Absent = 'is-absent',
}

function calculateUserStatus(lastSeenAt: number): UserStatus {
  if (!lastSeenAt) {
    return UserStatus.Offline;
  }

  if (Date.now() - lastSeenAt > ONLINE_TIMEOUT) {
    return UserStatus.Absent;
  }
  return UserStatus.Online;
}

export function LayoutUserInfoTitleBarItem({ user }: LayoutUserInfoTitleBarItemProps) {
  const { t } = useTranslationText('commons');
  const userName = `${user.profile.given_name} ${user.profile.family_name}`;
  const userInitials = getInitials(userName);
  const [lastSeenAt, setLastSeenAt] = useState(Date.now());
  const [onlineStatus, setOnlineStatus] = useState(calculateUserStatus(lastSeenAt));
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setOnlineStatus(calculateUserStatus(lastSeenAt));
    }, UPDATE_INTERVAL);

    return () => clearInterval(interval);
  }, [lastSeenAt]);

  function handleHeartbeat() {
    setLastSeenAt(Date.now());
    setOnlineStatus(UserStatus.Online);
  }

  usePresenceHeartbeat(handleHeartbeat);

  return (
    <div className="is-align-content-center">
      <AosUserInfoTitleBarItem userName={userName} showFullName initials={userInitials} status={onlineStatus}>
        <AosTitleBarMenu.Item
          icon="user-large"
          text={t('TitleBar-UserArea')}
          onClick={() => {
            navigate(NavigationRoutes.User);
          }}
        />
        <TenantMenu />
        {/* ADB-2358: Temporarily enforce the Light theme until Dark Mode support is fully implemented and stable */}
        {/* https://lise.atlassian.net/browse/ADB-2358 */}
        {/* <ThemeMenu /> */}
      </AosUserInfoTitleBarItem>
    </div>
  );
}
