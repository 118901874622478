import './ComponentMap.scss';
import { useEffect, useRef, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import { useGetComponentTypeIcon, useGetLocatedComponents } from '../react-query/ComponentApi';
import { LocatedComponent } from '../models/operation/ComponentModel';
import useRuntimeConfig from '../runtime-config/RuntimeConfig';

interface MapFrameInterface {
  setComponents(
    items: LocatedComponent[],
    componentId: string | undefined | null,
    defaultIcon: string | undefined,
    legendIcon: string | undefined,
  ): void;
}

interface ComponentMapProps {
  componentId?: string;
}

export function ComponentMap({ componentId }: ComponentMapProps) {
  const mapFrame = useRef<HTMLIFrameElement>();

  const [mapFacade, setMapFacade] = useState<MapFrameInterface | null>();

  const [query] = useQueryParams({
    componentId: StringParam,
  });

  const [runtimeConfig, isLoadingConfig] = useRuntimeConfig();

  const { data: defaultIcon } = useGetComponentTypeIcon('system-Logical', 'HEALTHY');
  const { data: legendIcon } = useGetComponentTypeIcon('system-Logical', 'HEALTHY', 30);

  const { data: locatedComponentsQuery } = useGetLocatedComponents();
  const focusedComponentId = componentId ?? query.componentId;

  const setupCallback = () => {
    const currentWindow = mapFrame.current?.contentWindow;
    const iframeDoc = currentWindow as any;

    if (iframeDoc) {
      iframeDoc.onMounted = (mountedFacade: MapFrameInterface) => {
        setMapFacade(mountedFacade);
        if (currentWindow) {
          currentWindow.onerror = () => {};
        }
      };
    }
  };

  useEffect(() => {
    setupCallback();
  }, []);

  useEffect(() => {
    if (mapFacade && locatedComponentsQuery) {
      mapFacade.setComponents(
        locatedComponentsQuery,
        focusedComponentId,
        defaultIcon?.base64Icon,
        legendIcon?.base64Icon,
      );
    }
  }, [mapFacade, locatedComponentsQuery, focusedComponentId, defaultIcon, legendIcon]);

  return (
    <div className="map-container">
      {runtimeConfig?.mapType && (
        <iframe
          ref={mapFrame as any}
          title="e21x-geoservice-map"
          className="map-container"
          src={`/map/map-${runtimeConfig?.mapType}.html`}
          onLoad={setupCallback}
        />
      )}
      <LoadingSpinner
        isLoading={isLoadingConfig || !mapFacade || !runtimeConfig?.mapType || !locatedComponentsQuery}
        showContent
        absolute
      />
    </div>
  );
}
