import { ServiceTimeModel } from '../../../models/contract-management/ServiceTimeModel';
import { useTranslationText } from '../../../translation/TranslationHooks';
import DetailInfoField from '../../../ui/detail-info-field/DetailInfoField';
import { DaysOfWeekPicker } from '../../../ui/days-of-week-picker/DaysOfWeekPicker';
import { SwitchInput } from '../../../ui/switch/SwitchInput';
import './ServiceTimeDetails.scss';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';

function parseTimeString(time?: string): string | undefined {
  return time?.substring(0, 5);
}

interface ServiceTimeDetailsProps {
  serviceTime: ServiceTimeModel;
  index: number;
}

export function ServiceTimeDetails({ serviceTime, index }: ServiceTimeDetailsProps) {
  const { t } = useTranslationText('contractManagements');

  return (
    <div className="service-time-details" data-role={`service-time-${index}`}>
      <FormFieldWrapper label={t('days')}>
        <DaysOfWeekPicker selectedDays={serviceTime.days} disabled />
      </FormFieldWrapper>
      <DetailInfoField
        label={t('serviceTime_begin')}
        value={parseTimeString(serviceTime.begin)}
        dataRole={`contract-service-times-begin-${index}`}
      />
      <DetailInfoField
        label={t('serviceTime_end')}
        value={parseTimeString(serviceTime.end)}
        dataRole={`contract-service-times-end-${index}`}
      />
      <FormFieldWrapper label={t('serviceTime_publicHolidaysIncluded')} size="is-small">
        <SwitchInput
          checked={serviceTime.publicHolidaysIncluded}
          disabled
          isSmall
          id={`contract-service-times-${serviceTime.id}-public-holidays-switch`}
        />
      </FormFieldWrapper>
    </div>
  );
}
