import { DefaultUserPermission } from './DefaultUserPermission';
import { ResourcePermissions } from './PermissionTypes';
import { UserPermissionBuilder } from './UserPermissionBuilder';

export type BmsService = 'ticketing' | 'monitoring' | 'knowledgeBase' | 'contractManagement' | 'automation';

export const AllBmsServices: BmsService[] = [
  'ticketing',
  'monitoring',
  'knowledgeBase',
  'contractManagement',
  'automation',
];

export function toResourcePermissions(service: BmsService, resource: string): ResourcePermissions {
  const create = new UserPermissionBuilder(new DefaultUserPermission(service, resource, 'create'));
  const update = new UserPermissionBuilder(new DefaultUserPermission(service, resource, 'update'));
  return {
    Create: create,
    Read: new UserPermissionBuilder(new DefaultUserPermission(service, resource, 'read')),
    Update: update,
    Delete: new UserPermissionBuilder(new DefaultUserPermission(service, resource, 'delete')),
    CreateOrUpdate: create.or(update),
  } as ResourcePermissions;
}
