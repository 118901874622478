import { NumberParam, QueryParamConfigMap, StringParam } from 'use-query-params';

export interface ContractQuery {
  query: string;
  endDateUntil: string;
  ids: string[];
  size: number;
  page: number;
  sort: string;
}

export const contractQueryParamConfigMap: ContractQueryParamConfigModel = {
  query: StringParam,
  endDateUntil: StringParam,
  size: NumberParam,
  page: NumberParam,
  sort: StringParam,
};

export interface ContractQueryParamConfigModel extends QueryParamConfigMap {
  query: typeof StringParam;
  endDateUntil: typeof StringParam;
  size: typeof NumberParam;
  page: typeof NumberParam;
  sort: typeof StringParam;
}

export enum ContractSort {
  InternalNumberDesc = 'internalNumber:desc',
  InternalNumberAsc = 'internalNumber:asc',
  ContractualObjectDesc = 'contractualObject:desc',
  ContractualObjectAsc = 'contractualObject:asc',
  ContractorCompanyNameDesc = 'contractor.companyName:desc',
  ContractorCompanyNameAsc = 'contractor.companyName:asc',
}
