import dayjs from 'dayjs';
import DateFormats from '../../models/date-formats/DateFormats';
import { Maintenance } from '../../models/maintenance/Maintenance';
import { UserModel } from '../../models/operation/UserModel';
import { Page } from '../../models/pagination/Pagination';
import useGetComponentsSummarized from '../../react-query/ComponentApi';
import NavigationRoutes from '../../routing/NavigationRoutes';
import { useTranslationText } from '../../translation/TranslationHooks';
import LinkTableCell from '../../ui/link-table-cell/LinkTableCell';

interface MaintenanceOverviewRowProps {
  maintenance: Maintenance;
  users: Page<UserModel> | undefined;
  isGetUserError: boolean;
}

export default function MaintenanceOverviewRow({ maintenance, users, isGetUserError }: MaintenanceOverviewRowProps) {
  const { t } = useTranslationText('maintenances');
  const createdBy = users?.content.find((user) => user.id === maintenance.author?.id ?? '');
  const { data: components } = useGetComponentsSummarized(
    {},
    { componentIds: maintenance.maintenanceLocations.map((c) => c.componentId) },
  );

  return (
    <tr key={maintenance.id}>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {maintenance.actionNumber}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {maintenance.title}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {t(maintenance.status?.toString() ?? '')}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {!isGetUserError ? `${createdBy?.firstName || ''} ${createdBy?.lastName || ''}` : '?'}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {maintenance.maintenanceLocations
          .map((component) => components?.content.find((c) => c.id === component.componentId)?.displayName)
          .join(', ')}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {dayjs(maintenance.startOfAction).format(DateFormats.FULLDATE_HOURS_MINS)}
      </LinkTableCell>
      <LinkTableCell to={NavigationRoutes.MaintenanceRequestId(maintenance.id.toString())}>
        {dayjs(maintenance.endOfAction).format(DateFormats.FULLDATE_HOURS_MINS)}
      </LinkTableCell>
    </tr>
  );
}
