import { FieldError } from 'react-hook-form';
import { ComponentItem, Epsg3857Coordinates } from '../../models/operation/ComponentModel';
import componentValidationSchema from '../ComponentValidationSchema';
import EditableTextInput from '../../ui/editable-inputs/EditableTextInput';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import FormPatchWrapper from '../../ui/form-patch-wrapper/FormPatchWrapper';
import useYupLocal from '../../translation/YupLocal';
import { useTranslationText } from '../../translation/TranslationHooks';
import LocationPicker from '../../ui/location-picker/LocationPicker';
import EditableTextArea from '../../ui/editable-inputs/EditableTextArea';
import DatePicker from '../../ui/date-picker/DatePicker';

export interface ComponentEditableInputProps {
  component: ComponentItem;
  variables: any;
  isMutationLoading: boolean;
  onSubmit: (
    newValue: any,
    property: keyof ComponentItem,
    isFormValid: boolean,
    saveCompleted?: (endEditing: boolean) => void,
  ) => void;
}
const isPropertyMutated = (variables: any, property: keyof ComponentItem): boolean =>
  variables?.body[0].path === `/${property}`;

export function DisplayNameInput({ component, variables, isMutationLoading, onSubmit }: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');
  const { yup } = useYupLocal();

  return (
    <FormPatchWrapper<ComponentItem, string, FieldError>
      patchProperty="displayName"
      defaultValue={component.displayName}
      validationSchema={yup.object({ displayName: componentValidationSchema(yup).fields.displayName })}
      render={({ field }, property, isFormValid, error) => (
        <EditableTextInput
          requiredPermission={UserResourcePermissions.Component.Update}
          label={t('displayName')}
          value={field.value}
          isLoading={isPropertyMutated(variables, 'displayName') && isMutationLoading}
          onChange={(newValue) => field.onChange(newValue)}
          onAcceptNewValue={(value, callback) => {
            onSubmit(value, property, isFormValid, callback);
          }}
          dataRole="component-displayName"
          error={error?.message}
          options={{
            isRequired: true,
          }}
        />
      )}
    />
  );
}

export function DisplayNameAlt1Input({
  component,
  variables,
  isMutationLoading,
  onSubmit,
}: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');
  const { yup } = useYupLocal();

  return (
    <FormPatchWrapper<ComponentItem, string, FieldError>
      patchProperty="displayNameAlt1"
      defaultValue={component.displayNameAlt1}
      validationSchema={yup.object({ displayNameAlt1: componentValidationSchema(yup).fields.displayNameAlt1 })}
      render={({ field }, property, isFormValid, error) => (
        <EditableTextInput
          requiredPermission={UserResourcePermissions.Component.Update}
          label={t('displayNameAlt1')}
          value={field.value}
          isLoading={isPropertyMutated(variables, 'displayNameAlt1') && isMutationLoading}
          onChange={(newValue) => field.onChange(newValue)}
          onAcceptNewValue={(value, callback) => {
            onSubmit(value, property, isFormValid, callback);
          }}
          dataRole="component-displayNameAlt1"
          error={error?.message}
        />
      )}
    />
  );
}

export function SerialNumberInput({ component, variables, isMutationLoading, onSubmit }: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');
  const { yup } = useYupLocal();

  return (
    <FormPatchWrapper<ComponentItem, string, FieldError>
      patchProperty="serialNumber"
      defaultValue={component.serialNumber || ''}
      validationSchema={yup.object({
        serialNumber: componentValidationSchema(yup).fields.serialNumber,
      })}
      render={({ field }, property, isFormValid, error) => (
        <EditableTextInput
          requiredPermission={UserResourcePermissions.Component.Update}
          label={t('serialNumber')}
          value={field.value}
          isLoading={isPropertyMutated(variables, 'serialNumber') && isMutationLoading}
          onChange={(newValue) => field.onChange(newValue)}
          onAcceptNewValue={(value, callback) => {
            onSubmit(value, property, isFormValid, callback);
          }}
          dataRole="component-serialNumber"
          error={error?.message}
        />
      )}
    />
  );
}

export function ManufacturerInput({ component, variables, isMutationLoading, onSubmit }: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');
  const { yup } = useYupLocal();

  return (
    <FormPatchWrapper<ComponentItem, string, FieldError>
      patchProperty="manufacturer"
      defaultValue={component.manufacturer || ''}
      validationSchema={yup.object({
        manufacturer: componentValidationSchema(yup).fields.manufacturer,
      })}
      render={({ field }, property, isFormValid, error) => (
        <EditableTextInput
          requiredPermission={UserResourcePermissions.Component.Update}
          label={t('manufacturer')}
          isLoading={isPropertyMutated(variables, 'manufacturer') && isMutationLoading}
          value={field.value}
          onChange={(newValue) => field.onChange(newValue)}
          onAcceptNewValue={(value, callback) => {
            onSubmit(value, property, isFormValid, callback);
          }}
          dataRole="component-manufacturer"
          error={error?.message}
        />
      )}
    />
  );
}

export function ModelInput({ component, variables, isMutationLoading, onSubmit }: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');
  const { yup } = useYupLocal();

  return (
    <FormPatchWrapper<ComponentItem, string, FieldError>
      patchProperty="model"
      defaultValue={component.model || ''}
      validationSchema={yup.object({ model: componentValidationSchema(yup).fields.model })}
      render={({ field }, property, isFormValid, error) => (
        <EditableTextInput
          requiredPermission={UserResourcePermissions.Component.Update}
          label={t('model')}
          value={field.value}
          isLoading={isPropertyMutated(variables, 'model') && isMutationLoading}
          onChange={(newValue) => field.onChange(newValue)}
          onAcceptNewValue={(value, callback) => {
            onSubmit(value, property, isFormValid, callback);
          }}
          dataRole="component-model"
          error={error?.message}
        />
      )}
    />
  );
}

export function LocationInput({ component, variables, isMutationLoading, onSubmit }: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');
  const { yup } = useYupLocal();

  return (
    <FormPatchWrapper<ComponentItem, string, FieldError>
      patchProperty="location"
      defaultValue={component.location || ''}
      validationSchema={yup.object({ location: componentValidationSchema(yup).fields.location })}
      render={({ field }, property, isFormValid, error) => (
        <EditableTextInput
          requiredPermission={UserResourcePermissions.Component.Update}
          label={t('location')}
          value={field.value}
          isLoading={isPropertyMutated(variables, 'location') && isMutationLoading}
          onChange={(newValue) => field.onChange(newValue)}
          onAcceptNewValue={(value, callback) => {
            onSubmit(value, property, isFormValid, callback);
          }}
          dataRole="component-location"
          error={error?.message}
        />
      )}
    />
  );
}

export function CoordinatesInput({ component, isMutationLoading, onSubmit }: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');

  return (
    <FormPatchWrapper<ComponentItem, Epsg3857Coordinates, FieldError>
      patchProperty="coordinates"
      render={({ field }, property, isFormValid, error) => (
        <LocationPicker
          requiredPermission={UserResourcePermissions.Component.Update}
          label={t('epsg3857Coordinates')}
          value={component.coordinates ?? undefined}
          isLoading={isMutationLoading}
          onChange={(newValue) => field.onChange(newValue)}
          onAcceptNewValue={(value, callback) => {
            onSubmit(value, property, isFormValid, callback);
          }}
          dataRole="component-coordinates"
          error={error?.message}
        />
      )}
    />
  );
}

export function DocumentationInput({ component, variables, isMutationLoading, onSubmit }: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');
  const { yup } = useYupLocal();

  return (
    <FormPatchWrapper<ComponentItem, string, FieldError>
      patchProperty="documentation"
      defaultValue={component.documentation || ''}
      validationSchema={yup.object({
        displayName: componentValidationSchema(yup).fields.documentation,
      })}
      render={({ field }, property, isFormValid, error) => (
        <EditableTextArea
          requiredPermission={UserResourcePermissions.Component.Update}
          label={t('documentation')}
          value={field.value}
          isLoading={isPropertyMutated(variables, 'location') && isMutationLoading}
          onChange={(newValue) => field.onChange(newValue)}
          onAcceptNewValue={(value, callback) => {
            onSubmit(value, property, isFormValid, callback);
          }}
          dataRole="component-documentation"
          error={error?.message}
        />
      )}
    />
  );
}

export function PurchaseDateInput({ component, variables, isMutationLoading, onSubmit }: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');

  return (
    /*  Validierung wird hier über den flatpicker gelöst - freie Eingabe (allowInput) ist deaktiviert */
    <DatePicker
      value={component.purchaseDate ? new Date(component.purchaseDate) : undefined}
      onValueChange={(value) => onSubmit(value, 'purchaseDate', true)}
      label={t('purchaseDate')}
      allowInput={false}
      isError={isPropertyMutated(variables, 'purchaseDate') && isMutationLoading}
      isLoading={isPropertyMutated(variables, 'purchaseDate') && isMutationLoading}
    />
  );
}

export function SupportEndDateInput({
  component,
  variables,
  isMutationLoading,
  onSubmit,
}: ComponentEditableInputProps) {
  const { t } = useTranslationText('components');

  return (
    /*  Validierung wird hier über den flatpicker gelöst - freie Eingabe (allowInput) ist deaktiviert */
    <DatePicker
      value={component.supportEndDate ? new Date(component.supportEndDate) : undefined}
      onValueChange={(value) => onSubmit(value, 'supportEndDate', true)}
      label={t('supportEndDate')}
      allowInput={false}
      isError={isPropertyMutated(variables, 'supportEndDate') && isMutationLoading}
      isLoading={isPropertyMutated(variables, 'supportEndDate') && isMutationLoading}
    />
  );
}
