import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  faCheck,
  faEllipsisH,
  faPause,
  faPlay,
  faQuestion,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Tag } from '@aos/styleguide-react';
import useToggle from '../../custom-hooks/useToggle';
import useErrorsCollector from '../../custom-hooks/UseErrorsCollector';
import { useLongParamId } from '../../custom-hooks/UseParamId';
import { getTaskStateColor, TaskDto, TaskState } from '../../models/operation/TaskDto';
import { useGetContractById } from '../../react-query/ContractManagementSystemApi';
import { MutationKey, MutationPath, useDeleteMutation, usePatchMutation } from '../../react-query/MutationQueries';
import { useGetTaskById } from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import ActionBar from '../../ui/action-bar/ActionBar';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import QuestionCommentContainer from './question-container/QuestionCommentContainer';
import TaskTabs from './task-tabs/TaskTabs';
import './TaskDetails.scss';
import TicketInfo from './ticket-info/TicketInfo';
import UserRoleCheck, { useAuthUser } from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { invalidateAllTasks, invalidateTask, invalidateTaskHistory } from '../../react-query/InvalidationQueries';
import { Patch, replaceProperty } from '../../models/patch/PatchModel';
import { Dropdown } from '../../ui/dropdown/Dropdown';
import Modal from '../../ui/modal/Modal';
import NavigationRoutes from '../../routing/NavigationRoutes';
import TaskMedia from './task-media/TaskMedia';
import { TicketDescription } from './ticket-description/TicketDescription';
import TaskOpenQuestion from '../../ui/task-open-question/TaskOpenQuestion';
import FavoriteInput from './favorite-input/FavoriteInput';

interface ContractorDisplayProps {
  contractId: string;
}

export function ContractorDisplay({ contractId }: ContractorDisplayProps) {
  const { data: contract, error } = useGetContractById(contractId, { suppressErrorToast: true });
  const { t } = useTranslationText('tickets');
  const { t: tCommon } = useTranslationText('commons');
  const { t: tContractManagement } = useTranslationText('contractManagements');

  if (error)
    return (
      <div className="contractor">
        <span className="label">{t('contractor')}</span>
        <div className="contract-link-wrapper">{tContractManagement('contractorDoesNotExist')}</div>{' '}
      </div>
    );

  return (
    <>
      {contract && (
        <div className="contractor">
          <span className="label">{t('contractor')}</span>
          <div className="contract-link-wrapper">
            <span className="name">{contract?.contractor.companyName}</span>
            <Link
              to={NavigationRoutes.ContractId(contractId)}
              className="link"
              title={tCommon('linkToContractTooltip', { contractName: contract.contractualObject })}
            >
              <FontAwesomeIcon icon={faUpRightFromSquare} />
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default function TaskDetails() {
  const { id, ErrorPage } = useLongParamId();
  const { data: task, isLoading, error, refetch } = useGetTaskById(id);
  const [showQuestionCommentContainer, toggleShowQuestionCommentContainer] = useToggle(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslationText('tickets');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tCommon } = useTranslationText('commons');
  const { hasPermission } = useAuthUser();

  const { mutate, isPending: isMutationLoading } = usePatchMutation<Patch, TaskDto>(MutationKey.PatchTask);

  const errors = useErrorsCollector([
    {
      fetchError: error,
      errorText: <Trans i18nKey="errorTexts.404_task.text" values={{ id }} />,
    },
  ]);

  async function showSuccessMessage(state: TaskState) {
    if (task !== undefined) {
      await refetch();
      await invalidateTaskHistory(queryClient, task.id);
      toast.success(
        <Trans i18nKey="successTexts.successfullyChangeTaskState.text" values={{ state: t(state.toLowerCase()) }} />,
      );
    }
  }

  function handleTaskStateUpdate(newStateValue: TaskState) {
    if (task !== undefined) {
      mutate(
        {
          body: [replaceProperty('state', newStateValue), replaceProperty('hasOpenQuestions', false)],
          path: MutationPath.PatchTask(task.id),
        },
        {
          onSuccess: async () => {
            await showSuccessMessage(TaskState.WORKING);
          },
          onError: () => {
            toast.error(tError('taskPatchError'));
          },
        },
      );
    }
  }

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteTask, {
    onSuccess: async () => {
      await invalidateAllTasks(queryClient).then(() => navigate(-1));
      toast.success(tSuccess('deleteTask'));
    },
    onError: () => {
      toast.error(`${tError('error')}: ${tError('taskDeleteError')}`);
    },
  });

  const onDelete = () => {
    if (task !== undefined) {
      deleteMutate({ path: MutationPath.DeleteTask(task?.id.toString()) });
    }
  };

  function getActionsButtons(state: TaskState) {
    switch (state) {
      case TaskState.TODO:
        return (
          <UserRoleCheck requiredPermission={UserResourcePermissions.Task.Update} key="start-task-button">
            <button
              className={`button is-primary ${isMutationLoading ? 'is-loading' : ''}`}
              type="button"
              onClick={() => handleTaskStateUpdate(TaskState.WORKING)}
              data-role="start-task-button"
            >
              <span className="is-hidden-mobile">{t('startTaskProcessing')}</span>
              <FontAwesomeIcon icon={faPlay} className="is-hidden-tablet" />
            </button>
            <button
              className={`button ${isMutationLoading ? 'is-loading' : ''}`}
              type="button"
              data-role="ask-question-button"
              onClick={() => toggleShowQuestionCommentContainer()}
              disabled={showQuestionCommentContainer}
            >
              <span className="is-hidden-mobile">{t('question')}</span>
              <FontAwesomeIcon icon={faQuestion} className="is-hidden-tablet" />
            </button>
          </UserRoleCheck>
        );
      case TaskState.WORKING:
        return (
          <UserRoleCheck requiredPermission={UserResourcePermissions.Task.Update} key="pause-task-button">
            <UserRoleCheck requiredPermission={UserResourcePermissions.Ticket.Update}>
              <button
                className={`button ${isMutationLoading ? 'is-loading' : ''}`}
                type="button"
                onClick={() => handleTaskStateUpdate(TaskState.PAUSED)}
              >
                <span className="is-hidden-mobile">{t('pause')}</span>
                <FontAwesomeIcon icon={faPause} className="is-hidden-tablet" />
              </button>
            </UserRoleCheck>
            <button
              className={`button ${isMutationLoading ? 'is-loading' : ''}`}
              type="button"
              data-role="ask-question-button"
              onClick={() => toggleShowQuestionCommentContainer()}
              disabled={showQuestionCommentContainer}
            >
              <span className="is-hidden-mobile">{t('question')}</span>
              <FontAwesomeIcon icon={faQuestion} className="is-hidden-tablet" />
            </button>
            <button
              className={`button is-primary ${isMutationLoading ? 'is-loading' : ''}`}
              type="button"
              onClick={() => handleTaskStateUpdate(TaskState.DONE)}
            >
              <span className="is-hidden-mobile">{t('finishTask')}</span>
              <FontAwesomeIcon icon={faCheck} className="is-hidden-tablet" />
            </button>
          </UserRoleCheck>
        );
      case TaskState.PAUSED:
        return (
          <UserRoleCheck requiredPermission={UserResourcePermissions.Task.Update} key="continue-task-button">
            <button
              className={`button is-primary ${isMutationLoading ? 'is-loading' : ''}`}
              type="button"
              onClick={() => handleTaskStateUpdate(TaskState.WORKING)}
            >
              <span className="is-hidden-mobile">{t('continueTask')}</span>
              <FontAwesomeIcon icon={faPlay} className="is-hidden-tablet" />
            </button>
          </UserRoleCheck>
        );
      case TaskState.DONE:
      default:
        return null;
    }
  }

  const getItems = () => {
    const renderItems: JSX.Element[] = [];

    if (hasPermission(UserResourcePermissions.Task.Delete)) {
      renderItems.push(
        <a
          className="dropdown-item"
          onClick={() => setIsModalVisible(true)}
          data-role="task-delete-button"
          key="task-delete-button"
        >
          {t('taskDelete')}
        </a>,
      );
    }

    return renderItems;
  };

  return (
    <LoadingSpinner isLoading={isLoading} errors={[...errors, ErrorPage]}>
      {task && (
        <>
          <ActionBar
            right={
              <>
                {getActionsButtons(task.state)}
                <Dropdown key="task-dropdown" title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />
              </>
            }
          />
          <div className="task-details container" data-role="task-detail">
            <QuestionCommentContainer
              setShow={toggleShowQuestionCommentContainer}
              show={showQuestionCommentContainer}
              task={task}
              onSubmit={async () => {
                await invalidateTask(queryClient, task.id);
                toggleShowQuestionCommentContainer();
              }}
            />
            <div className="task-header">
              <div className="task-header-column">
                {t('task')} {task?.id}
                <div className="second-line">
                  <div>
                    <Tag color={getTaskStateColor(task.state)} size="is-medium">
                      {t(task?.state.toLowerCase()!)}
                    </Tag>
                    {task.hasOpenQuestions ? <TaskOpenQuestion isMedium /> : ''}
                  </div>
                  <div className="title-link-wrapper">
                    <span className="task-title">{task?.ticketSnapshot.title}</span>
                    <UserRoleCheck requiredPermission={UserResourcePermissions.Ticket.Read}>
                      <Link
                        to={NavigationRoutes.TicketCurrentId(task.ticketSnapshot.originalTicketId)}
                        className="link"
                        title={t('linkToTicketTooltip', { ticketId: task.ticketSnapshot.originalTicketId })}
                      >
                        <FontAwesomeIcon icon={faUpRightFromSquare} />
                      </Link>
                    </UserRoleCheck>
                  </div>
                </div>
              </div>
            </div>
            <UserRoleCheck requiredPermission={UserResourcePermissions.Contract.Read}>
              {task?.contractId && <ContractorDisplay contractId={task?.contractId} />}
            </UserRoleCheck>
            <TicketInfo ticketSnapshot={task.ticketSnapshot} />
            <div className="task-info">
              <FavoriteInput task={task} />
              <TicketDescription ticketSnapshot={task.ticketSnapshot} />
              <TaskMedia ticketId={task.ticketSnapshot.originalTicketId} />
              <TaskTabs taskId={task.id} />
            </div>
          </div>
          <Modal
            isVisible={isModalVisible}
            title={t('taskDelete')}
            cancelBtnText={tCommon('abort')}
            confirmBtnText={tCommon('delete')}
            onCancel={() => setIsModalVisible(false)}
            onConfirm={onDelete}
            onClose={() => setIsModalVisible(false)}
          >
            <p>{t('warningTaskDelete')}</p>
          </Modal>
        </>
      )}
    </LoadingSpinner>
  );
}
