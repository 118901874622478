import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { Agent } from '../../../models/monitoring/Agent';
import { AgentModuleType, BaseAgentModule } from '../../../models/monitoring/AgentModule';
import { mapToModuleTypesToOptions } from '../../../models/operation/AgentFunctions';
import { useTranslationText } from '../../../translation/TranslationHooks';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';
import SingleValueInput from '../../../ui/single-value-input/SingleValueInput';
import { HttpModuleForm } from './HttpModule';
import { NetworkResponseForm } from './NetworkResponseModule';
import { SnmpForm } from './snmp/SnmpModule';
import { TomlModuleForm } from './TomlModuleForm';

interface AgentModuleProps {
  form: UseFormReturn<Agent, object>;
  index: number;
  onRemove: () => void;
}

function AgentModulePortal({
  form,
  index,
  type,
}: {
  form: UseFormReturn<Agent, object>;
  index: number;
  type: AgentModuleType | undefined;
}): JSX.Element {
  switch (type) {
    case 'snmp':
      return <SnmpForm form={form} index={index} />;
    case 'http-endpoint':
      return <HttpModuleForm form={form} index={index} />;
    case 'network-response':
      return <NetworkResponseForm form={form} index={index} />;
    case 'toml':
      return <TomlModuleForm form={form} index={index} />;
    case undefined:
    default:
      return <></>;
  }
}

export function AgentModuleForm({ form, index, onRemove }: AgentModuleProps) {
  const { t } = useTranslationText('agents');

  const { control, formState, watch, setValue } = form;
  const type = watch(`modules.${index}.type`);

  const getErrors = useCallback(
    (field: keyof BaseAgentModule) =>
      formState.errors?.modules !== undefined && formState.errors?.modules[index] !== undefined
        ? formState?.errors?.modules.at?.(index)?.[field]
        : undefined,
    [formState.errors?.modules, index],
  );

  const ModuleTypeInput = useCallback(
    ({ field }: { field: ControllerRenderProps<Agent, `modules.${typeof index}.type`> }) => (
      <FormFieldWrapper error={getErrors('type') as any} label={t('moduleTypeLabel')} isRequired>
        <SingleValueInput
          onChange={(value) => {
            setValue(`modules.${index}`, {
              type: value as AgentModuleType,
              id: undefined,
            });
          }}
          value={field.value}
          requiredPermission={UserResourcePermissions.Agent.Update}
          placeholder={t('moduleTypePlaceholder')}
          options={mapToModuleTypesToOptions(t)}
          key={`module-module-type-${index}`}
        />
      </FormFieldWrapper>
    ),
    [getErrors, index, setValue, t],
  );

  return (
    <ListItemWrapper
      mode="item"
      field="modules"
      index={index}
      onRemove={onRemove}
      deletePermission={UserResourcePermissions.Agent.Update}
      key={`agent-module-${index}`}
    >
      <div className="horizontal-wrapper">
        <Controller name={`modules.${index}.type`} control={control} render={ModuleTypeInput} />
      </div>
      <div className="horizotal-wrapper">
        <AgentModulePortal form={form} type={type} index={index} />
      </div>
    </ListItemWrapper>
  );
}
