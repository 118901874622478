import { useTranslationText } from '@/translation/TranslationHooks';
import useYupLocal from '@/translation/YupLocal';
import advancedRuleValidationSchema from '@/automation/advanced-rules/AdvancedRuleValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { AdvancedRule, CreateAdvancedRule } from '@/automation/advanced-rules/AdvancedRuleModels';
import { MutationKey, MutationPath, usePostMutation } from '@/react-query/MutationQueries';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import UserRoleCheck from '@/auth/UserRoleCheck';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import LoadingSpinner from '@/ui/loading-spinner/LoadingSpinner';
import ActionBar from '@/ui/action-bar/ActionBar';
import FormFieldWrapper from '@/ui/form-field-wrapper/FormFieldWrapper';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import { Button, TextArea, TextInput } from '@aos/styleguide-react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faXmark } from '@fortawesome/free-solid-svg-icons';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import './EditAdvancedRule.scss';
import { useState } from 'react';

function CreateAdvancedRulePage() {
  const { t } = useTranslationText('automations');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();
  const navigate = useNavigate();

  const parseError = { message: tError('invalidJson') };
  const [hasParseError, setHasParseError] = useState(false);

  const { handleSubmit, control, formState, setValue } = useForm<CreateAdvancedRule>({
    mode: 'onChange',
    resolver: yupResolver(advancedRuleValidationSchema(yup)),
  });
  setValue('active', false, { shouldDirty: true });

  const { mutate, isPending } = usePostMutation<CreateAdvancedRule, AdvancedRule>(MutationKey.PostAdvancedRule);

  function parseToJSONSecure(string: string): string | undefined {
    let jsonObject;
    try {
      jsonObject = JSON.parse(string.replace(/\n/g, ' '));
    } catch (e) {
      console.error(e);
      setHasParseError(true);
    }
    return jsonObject;
  }

  function onSubmit(createAdvancedRule: CreateAdvancedRule) {
    const parsedDefinition = parseToJSONSecure(createAdvancedRule.definition);
    if (!parsedDefinition) return;

    mutate(
      {
        body: {
          name: createAdvancedRule.name,
          description: createAdvancedRule.description?.length ? createAdvancedRule.description : undefined,
          definition: parsedDefinition,
          active: createAdvancedRule.active,
        },
        path: MutationPath.CreateAdvancedRule,
      },
      { onSuccess: () => navigate(-1), onError: () => toast.error(tError('advancedRulePostError')) },
    );
  }

  return (
    <UserRoleCheck requiredPermission={UserResourcePermissions.AdvancedRule.Create}>
      <LoadingSpinner isLoading={isPending}>
        <form
          className="is-fullwidth advanced-rule-edit-form"
          onSubmit={async (e) => {
            e.preventDefault();
            await handleSubmit(onSubmit)();
          }}
        >
          <ActionBar
            right={
              <>
                <Button
                  className="text-icon-button"
                  type="button"
                  aria-label="cancel-button"
                  onClick={() => navigate(-1)}
                >
                  <FontAwesomeIcon icon={faXmark} className="aos-icon" />
                  {tCommon('abort')}
                </Button>
                <Button
                  className={classNames('text-icon-button', { 'is-loading': isPending })}
                  type="submit"
                  aria-label="save-button"
                  isConfirm
                  disabled={!formState.isValid || !formState.isDirty || hasParseError}
                >
                  <FontAwesomeIcon icon={faSave} />
                  {tCommon('update')}
                </Button>
              </>
            }
          />
          <div className="advanced-rule-edit-form-outer-layout-container">
            <div className="advanced-rule-edit-form-text-container">
              <div className="advanced-rule-edit-form-name-active-container">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormFieldWrapper error={formState.errors?.name} isRequired label={t('advancedRuleName')}>
                      <TextInput
                        value={field.value}
                        placeholder={t('advancedRuleNamePlaceholder')}
                        onChange={field.onChange}
                      />
                    </FormFieldWrapper>
                  )}
                />
                <Controller
                  name="active"
                  control={control}
                  render={({ field }) => (
                    <FormFieldWrapper error={formState.errors?.active} label={t('advancedRuleActive')}>
                      <SwitchInput
                        id="new_advanced_rule_active_switch"
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    </FormFieldWrapper>
                  )}
                />
              </div>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <FormFieldWrapper error={formState.errors?.description} label={t('advancedRuleDescription')}>
                    <TextArea
                      value={field.value}
                      placeholder={t('advancedRuleDescriptionPlaceholder')}
                      onChange={field.onChange}
                    />
                  </FormFieldWrapper>
                )}
              />
            </div>
            <div className="advanced-rule-edit-form-json-editor-container">
              <Controller
                name="definition"
                control={control}
                render={({ field }) => (
                  <FormFieldWrapper
                    error={formState.errors?.definition ?? (hasParseError ? parseError : undefined)}
                    label={t('advancedRuleJSONDefinition')}
                    controlClassnames="is-full-height"
                    isRequired
                  >
                    <AceEditor
                      mode="json"
                      placeholder={t('advancedRuleJSONDefinitionPlaceholder')}
                      value={field.value}
                      onChange={(value) => {
                        setHasParseError(false);
                        field.onChange(value);
                      }}
                      theme="github"
                      className="advanced-rule-edit-form-json-editor"
                      setOptions={{
                        enableLiveAutocompletion: true,
                        enableBasicAutocompletion: true,
                        useWorker: false,
                      }}
                      // styles need to be used to overwrite AceEditor's default styles
                      style={{ width: '100%', height: '100%' }}
                    />
                  </FormFieldWrapper>
                )}
              />
            </div>
          </div>
        </form>
      </LoadingSpinner>
    </UserRoleCheck>
  );
}

export default CreateAdvancedRulePage;
