import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { useCallback, useState } from 'react';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { ComponentTypeModel } from '../../../models/operation/ComponentTypeModel';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '../../../ui/text-input/TextInput';
import { useRelationTypeTranslations, useTranslationText } from '../../../translation/TranslationHooks';
import SingleSelectDropdown from '../../../ui/single-select-dropdown/SingleSelectDropdown';
import { RelationType } from '../../../models/operation/RelationTypeModel';
import TextArea from '../../../ui/text-area/TextArea';
import MultiValueInput from '../../../ui/multi-value-input/MultiValueInput';
import '../RelationTemplates.scss';
import { SwitchInput } from '../../../ui/switch/SwitchInput';

interface RelationTemplateItemProps {
  form: UseFormReturn<ComponentTypeModel>;
  index: number;
  isReverse: boolean;
  onRemove: () => void;
  relationTypes?: RelationType[];
  isLoading: boolean;
}

function toOption(translation: (key: string) => string, identifier?: string, relationTypes?: RelationType[]) {
  return {
    label: translation(relationTypes?.find((it) => it.identifier === identifier)?.identifier ?? ''),
    value: identifier,
  };
}

function mapToOptions(translation: (key: string) => string, relationTypes?: RelationType[]) {
  return (
    relationTypes?.map((it) => ({
      label: translation(it.identifier),
      value: it.identifier,
    })) ?? []
  );
}

export default function RelationTemplateItem({
  form,
  index,
  isReverse,
  onRemove,
  relationTypes,
  isLoading,
}: RelationTemplateItemProps) {
  const [reverse, setReverse] = useState(isReverse);

  const { t } = useTranslationText('componentTypes');
  const { forward: forwardT, reverse: reverseT } = useRelationTypeTranslations();
  const { control, formState } = form;

  const nameField = useCallback(
    ({ field }: { field: ControllerRenderProps<ComponentTypeModel, `relationTemplates.${typeof index}.name`> }) => (
      <FormFieldWrapper
        error={formState.errors?.relationTemplates?.[index]?.name}
        label={t('relationTemplateName')}
        isRequired
      >
        <TextInput
          key={`relation-template-name-${index}`}
          placeholder={t('relationTemplateName')}
          dataRole={`relation-template-name-${index}`}
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.relationTemplates?.[index]?.name}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.relationTemplates, index, t],
  );

  const relationTypeField = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<ComponentTypeModel, `relationTemplates.${typeof index}.relationType`>;
    }) => (
      <FormFieldWrapper
        error={formState.errors?.relationTemplates?.[index]?.relationType}
        label={t('relationTemplateRelationType')}
      >
        <SingleSelectDropdown<string | undefined>
          isLoading={isLoading}
          options={mapToOptions(reverse ? reverseT : forwardT, relationTypes)}
          isError={formState.errors?.relationTemplates?.[index]?.relationType !== undefined ?? false}
          placeholder={t('relationTemplateRelationType')}
          onChange={(selectedOption) => {
            field.onChange(selectedOption?.value);
          }}
          value={toOption(reverse ? reverseT : forwardT, field.value, relationTypes)}
          requiredPermission={UserResourcePermissions.ComponentType.CreateOrUpdate}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.relationTemplates, forwardT, index, isLoading, relationTypes, reverse, reverseT, t],
  );

  const isReverseField = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<ComponentTypeModel, `relationTemplates.${typeof index}.isReverse`>;
    }) => (
      <FormFieldWrapper
        error={formState.errors?.relationTemplates?.[index]?.isReverse}
        isRequired
        label={t('relationTemplateIsReverse')}
      >
        <SwitchInput
          id={`relation-template-isReverse-${index}`}
          isError={formState.errors?.relationTemplates?.[index]?.isReverse !== undefined ?? false}
          onChange={(checked) => {
            field.onChange(checked);
            setReverse(checked);
          }}
          checked={field.value}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.relationTemplates, index, t],
  );

  const componentSelectorQueryField = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<ComponentTypeModel, `relationTemplates.${typeof index}.componentSelector.query`>;
    }) => (
      <FormFieldWrapper
        error={formState.errors?.relationTemplates?.[index]?.componentSelector?.query}
        label={t('componentSelectorQueryLabel')}
        isRequired
      >
        <TextArea
          placeholder={t('componentSelectorQueryPlaceholder')}
          onValueChange={field.onChange}
          value={field.value}
          error={formState.errors?.relationTemplates?.[index]?.componentSelector?.query}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.relationTemplates, index, t],
  );

  const componentSelectorLabelField = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<ComponentTypeModel, `relationTemplates.${typeof index}.componentSelector.labels`>;
    }) => (
      <FormFieldWrapper
        error={formState.errors?.relationTemplates?.[index]?.componentSelector?.labels}
        label={t('componentSelectorLabelsLabel')}
        isRequired
      >
        <MultiValueInput
          placeholder={t('componentSelectorLabelsPlaceholder')}
          onChange={field.onChange}
          values={field.value}
          isError={formState.errors?.relationTemplates?.[index]?.componentSelector?.labels !== undefined ?? false}
        />
      </FormFieldWrapper>
    ),
    [formState.errors.relationTemplates, index, t],
  );

  return (
    <ListItemWrapper
      mode="item"
      field="relationTemplates"
      index={index}
      deletePermission={UserResourcePermissions.ComponentType.Delete}
      onRemove={onRemove}
    >
      <Controller name={`relationTemplates.${index}.name`} control={control} render={nameField} />
      <div className="horizontal-wrapper">
        <div className="three-quarters">
          <Controller name={`relationTemplates.${index}.relationType`} control={control} render={relationTypeField} />
        </div>
        <div className="one-quarter">
          <Controller name={`relationTemplates.${index}.isReverse`} control={control} render={isReverseField} />
        </div>
      </div>
      <Controller
        name={`relationTemplates.${index}.componentSelector.query`}
        control={control}
        render={componentSelectorQueryField}
      />
      <Controller
        name={`relationTemplates.${index}.componentSelector.labels`}
        control={control}
        render={componentSelectorLabelField}
      />
    </ListItemWrapper>
  );
}
