import dayjs from 'dayjs';
import WidgetWrapper from '../../WidgetWrapper';
import { useTranslationText } from '../../../../translation/TranslationHooks';
import { useGetKnowledgeBaseArticles } from '../../../../react-query/KnowledgeBaseApi';
import WidgetArticleView from '../widget-content/WidgetArticleView';
import DateFormats from '../../../../models/date-formats/DateFormats';

export default function RecentlyVisitedArticles() {
  const { t } = useTranslationText('knowledgeBases');
  const {
    data: articles = [],
    isLoading,
    isError,
  } = useGetKnowledgeBaseArticles({
    sort: 'recently-visited',
    limit: 5,
  });

  return (
    <WidgetWrapper
      title={t('lastViewed')}
      isLoading={isLoading}
      isError={isError}
      dataRole="recently-visited-widget"
      noContent={t('noRecentlyVisitedArticles')}
    >
      {articles
        .sort((article1, article2) => -dayjs(article1?.lastVisitedAt).diff(dayjs(article2?.lastVisitedAt || 0)) || 0)
        .map((article) => (
          <WidgetArticleView
            key={article.id}
            id={article.id}
            title={article.title}
            subTitle={t('lastVisitedAt', {
              date: article.lastVisitedAt
                ? dayjs(article.lastVisitedAt).format(DateFormats.FULLDATE_MONTHTEXT_HOURS_MINS_SECS)
                : '---',
            })}
          />
        ))}
    </WidgetWrapper>
  );
}
