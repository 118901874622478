import { SupportContactPersonModel } from '../../models/contract-management/SupportContactPersonModel';
import DetailInfoField from '../../ui/detail-info-field/DetailInfoField';
import { useTranslationText } from '../../translation/TranslationHooks';
import { ContractRole } from '../../models/contract-management/ContractRole';

interface SupportContactPersonDetailsProps {
  supportContactPerson: SupportContactPersonModel;
  contractRoles: ContractRole[] | undefined;
  index: number;
}

export function SupportContactPersonDetails({
  supportContactPerson,
  contractRoles,
  index,
}: SupportContactPersonDetailsProps) {
  const { t } = useTranslationText('contractManagements');

  const contractRole =
    contractRoles?.find((aContractRole) => aContractRole.key === supportContactPerson.role) ?? undefined;

  return (
    <div className="contract-contact-persons">
      <div className="columns">
        <div className="column">
          <DetailInfoField
            label={t('roleLabel')}
            value={contractRole ? t(contractRole.translationKey) : '-'}
            dataRole={`contract-support-contact-person-role-${index}`}
          />
        </div>
        <div className="column">
          <DetailInfoField
            label={t('supportContactCompanyNameLabel')}
            value={supportContactPerson.companyName}
            dataRole={`contract-support-contact-person-company-name-${index}`}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <DetailInfoField
            label={t('phoneNumberLabel')}
            value={supportContactPerson.phoneNumber}
            dataRole={`contract-support-contact-person-phone-number-${index}`}
          />
        </div>
        <div className="column">
          <DetailInfoField
            label={t('faxNumberLabel')}
            value={supportContactPerson.telefax}
            dataRole={`contract-support-contact-person-telefax-${index}`}
          />
        </div>
        <div className="column">
          <DetailInfoField
            label={t('emailLabel')}
            value={supportContactPerson.email}
            dataRole={`contract-support-contact-person-email-${index}`}
          />
        </div>
      </div>
    </div>
  );
}
