import './AosActionBar.scss';

interface ActionBarProps {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
}

export default function AosActionBar({ left, center, right }: ActionBarProps) {
  return (
    <div className="aos-action-bar">
      {left && <div className="action-bar-items left">{left}</div>}
      {center && <div className="action-bar-items center">{center}</div>}
      {right && <div className="action-bar-items right">{right}</div>}
    </div>
  );
}
