import { ComponentAttribute } from '../../models/operation/ComponentModel';
import DetailInfoField from '../../ui/detail-info-field/DetailInfoField';

interface ComponentAttributeProps {
  attribute: ComponentAttribute;
}

function ComponentAttributeItem({ attribute }: ComponentAttributeProps) {
  return <DetailInfoField value={attribute.value} label={attribute.name} />;
}

export default ComponentAttributeItem;
