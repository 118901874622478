import { Collapsible } from '@aos/styleguide-react';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { AttributeValueSelector } from '../../../models/operation/ComponentTypeModel';
import TextLabel from '../../../ui/text-label/TextLabel';
import TextAreaLabel from '../../../ui/text-area-label/TextAreaLabel';

interface DisplayNameSelectorDetailsProps {
  displayNameSelector: AttributeValueSelector;
}

export function DisplayNameSelectorDetails({ displayNameSelector }: Readonly<DisplayNameSelectorDetailsProps>) {
  const { t } = useTranslationText('componentTypes');

  return (
    <Collapsible isInitiallyCollapsed={false} header={t('displayNameSelector')}>
      <TextAreaLabel label={t('componentSelectorQueryLabel')} value={displayNameSelector.query} autosizeOnLoad />
      <TextLabel label={t('componentSelectorLabelsLabel')} value={displayNameSelector.label} />
    </Collapsible>
  );
}
