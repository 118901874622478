import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum SortState {
  None = 'none',
  Descending = 'desc',
  Ascending = 'asc',
}

export function getNextSortState(sortState: string | undefined, resetSortState: boolean) {
  if (resetSortState) {
    return SortState.Ascending;
  }

  switch (sortState) {
    case SortState.None:
      return SortState.Ascending;
    case SortState.Descending:
      return SortState.None;
    case SortState.Ascending:
      return SortState.Descending;
    default:
      return SortState.None;
  }
}

interface SortIconProps {
  property: string;
  currentSortState: string | undefined;
}

export default function TableSort({ property, currentSortState }: SortIconProps) {
  const sortedPropertyArray = currentSortState?.split(':');

  const isVariableSorted = sortedPropertyArray?.[0] === property;

  let sortState;
  if (isVariableSorted && sortedPropertyArray) {
    sortState = [...sortedPropertyArray].at(1);
  } else {
    sortState = SortState.None;
  }

  switch (sortState) {
    case SortState.Ascending:
      return <FontAwesomeIcon size="sm" icon={faSortUp} className="ml-1" />;
    case SortState.Descending:
      return <FontAwesomeIcon size="sm" icon={faSortDown} className="ml-1" />;
    default:
      return <span />;
  }
}
