import { RelationSpec } from '../models/operation/RelationTypeModel';
import { OptionLabelBaseProps, SelectOption } from '../ui/select/SelectUtils';

export function mapRelationSpecToOption(relationSpec: RelationSpec): SelectOption<RelationSpec> {
  return {
    value: relationSpec.identifier,
    label: relationSpec.identifier,
    payload: relationSpec,
  };
}

export function mapRelationSpecToOptions(relationSpecs: RelationSpec[]): SelectOption<RelationSpec>[] {
  return relationSpecs.map((relationSpec) => mapRelationSpecToOption(relationSpec));
}

export function RelationTypeOptionLabel({ payload }: OptionLabelBaseProps<string>) {
  return <span>{payload}</span>;
}
