import { StrapiFile } from '../strapi/StrapiFile';
import { BmsUrl } from '../../utils/BmsUrl';

export type MediaUrlBuilder = (f: StrapiFile) => BmsUrl;

export interface MediaUrlBuilders {
  mediaSource: MediaUrlBuilder;
  mediaThumbnail: MediaUrlBuilder;
  mediaDownload: MediaUrlBuilder;
}

export interface SupportedFileTypes {
  regex: RegExp;
  descriptionTranslationKey: string;
  acceptedTypes: string;
}

export const allowImagesAndPdf: SupportedFileTypes = {
  regex: /^(image\/.*|application\/pdf)$/,
  descriptionTranslationKey: 'allowImagesAndPdf',
  acceptedTypes: 'image/*,.pdf',
};
