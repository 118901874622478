import { useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';

export function useAutoSignIn() {
  const auth = useAuth();

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      void auth.signinRedirect();
    }
  }, [auth]);
}
