import './TicketCommentsTab.scss';
import CommentListing from './comment-listing/CommentListing';
import { useGetTicketComments } from '../../react-query/TicketingSystemApi';
import { useTranslationText } from '../../translation/TranslationHooks';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { CreateTicketComment } from './create-comment/CreateComment';

interface TicketCommentsTabProps {
  ticketId: number;
}

export default function TicketCommentsTab({ ticketId }: TicketCommentsTabProps) {
  const { t: tError } = useTranslationText('errorTexts');

  const { data: comments, isLoading: commentsAreLoading, isError } = useGetTicketComments(ticketId);

  return (
    <div className="comments-tab">
      <UserRoleCheck requiredPermission={UserResourcePermissions.TicketComment.Create}>
        <CreateTicketComment ticketId={ticketId} />
      </UserRoleCheck>
      {comments && (
        <CommentListing
          comments={comments}
          isLoading={commentsAreLoading}
          error={isError ? tError('404_comments') : undefined}
        />
      )}
    </div>
  );
}
