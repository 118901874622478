import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { ComponentTypeModel } from '../../models/operation/ComponentTypeModel';
import { useTranslationText } from '../../translation/TranslationHooks';
import AttributesForm from './AttributesForm';
import { CollapsibleWithButtons } from '../../ui/collapsible/CollapsibleWithButtons';

interface AttributesFormProps {
  form: UseFormReturn<ComponentTypeModel, object>;
}

export default function AttributesFormCollapsible({ form }: AttributesFormProps) {
  const { t } = useTranslationText('componentTypes');
  const { t: tCommon } = useTranslationText('commons');
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: `attributes`,
  });

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('attributes')}
      badgeCount={fields.length}
      addBtnClicked={() => append({ name: '', valueSelector: { query: '', label: '' } })}
      addPermission={UserResourcePermissions.ComponentType.Create}
    >
      {fields.length === 0 && tCommon('noItemsFoundAdd')}
      {fields.map((field, index: number) => (
        <AttributesForm form={form} key={field.id} index={index} onRemove={() => remove(index)} />
      ))}
    </CollapsibleWithButtons>
  );
}
