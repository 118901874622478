export interface SystemModel {
  id: string;
  name: string;
  supportContactPerson: string;
  supportContactAddress: string;
  description?: string;
  integrationsscheinNaming?: string;
  stages: SystemStageModel[];
}

export interface CreateSystemModel {
  name: string;
  supportContactPerson: string;
  supportContactAddress: string;
  description?: string;
  integrationsscheinNaming?: string;
  stages: SystemStageModel[];
}

export interface UpdateSystemModel {
  name: string;
  supportContactPerson: string;
  supportContactAddress: string;
  description?: string;
  integrationsscheinNaming?: string;
  stages: SystemStageModel[];
}

export type LinkType = 'DOCUMENTATION' | 'SOURCE_CODE' | 'ADMIN_PANEL' | 'ISSUE_TRACKER';
export const LinkTypes: LinkType[] = ['DOCUMENTATION', 'SOURCE_CODE', 'ADMIN_PANEL', 'ISSUE_TRACKER'];

export const StageNameSuggestions: string[] = ['PRODUCTION', 'REFERENCE', 'TEST'];

export interface SystemStageLinkModel {
  id?: string;
  url: string;
  linkType: LinkType;
}

export interface SystemStageModel {
  id?: string;
  name: string;
  version?: string;
  lastUpdated?: Date;
  integrationsscheinNaming?: string;
  links: SystemStageLinkModel[];
  integrationsscheinIds: string[];
}

export const defaultCreateSystemStage: SystemStageModel = {
  name: '',
  links: [],
  integrationsscheinIds: [],
};

export const defaultCreateSystemStageLink: SystemStageLinkModel = {
  url: '',
  linkType: 'DOCUMENTATION',
};
