import { ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import { ActionMeta, GroupBase, MenuPlacement, MultiValueGenericProps, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './SingleSelectCreatableDropdown.scss';
import { selectClassNames, selectStyles } from '../select/SelectUtils';

interface ReactSelectOption<T> {
  label: string;
  value: T;
}

interface SingleSelectCreatableDropdownProps<T> {
  isError?: boolean;
  createLabel: (inputValue: string) => ReactNode;
  isLoading?: boolean;
  noOptionsMessage?: string;
  onChange: (newValue: SingleValue<ReactSelectOption<T>>, actionMeta: ActionMeta<ReactSelectOption<T>>) => void;
  onCreateOption: (inputValue: string) => void;
  placeholder?: string;
  value?: ReactSelectOption<T> | null;
  options: ReactSelectOption<T>[];
  menuPlacement?: MenuPlacement | undefined;
  isValidNewOption?: (inputValue: string, options: readonly ReactSelectOption<T>[]) => boolean;
}

export default function SingleSelectCreatableDropdown<T>({
  isError,
  createLabel,
  isLoading,
  noOptionsMessage,
  onChange,
  onCreateOption,
  placeholder,
  value,
  options,
  menuPlacement,
  isValidNewOption,
}: SingleSelectCreatableDropdownProps<T>) {
  const FormatCreateLabel = useCallback(
    (inputValue: string) => <div data-role="single-select-create-button">{createLabel(inputValue)}</div>,
    [createLabel],
  );

  const MultiValueLabel = useCallback(
    ({ innerProps, data }: MultiValueGenericProps<ReactSelectOption<T>>) => (
      <div {...innerProps} data-role={`label-${data.label}`}>
        {data.label}
      </div>
    ),
    [],
  );

  return (
    <CreatableSelect
      className={classNames({
        'is-error': isError,
      })}
      classNamePrefix="react-select"
      components={{ MultiValueLabel }}
      formatCreateLabel={FormatCreateLabel}
      isLoading={isLoading}
      isMulti={false}
      isValidNewOption={isValidNewOption ? (inputValue) => isValidNewOption(inputValue, options) : undefined}
      options={options}
      noOptionsMessage={() => noOptionsMessage}
      onChange={onChange}
      onCreateOption={onCreateOption}
      placeholder={placeholder}
      value={value}
      inputId="creatable-select-input"
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      styles={selectStyles}
      classNames={selectClassNames<ReactSelectOption<T>, GroupBase<ReactSelectOption<T>>>('is-normal')}
    />
  );
}
