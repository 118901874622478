import { useCallback } from 'react';
import { ActionType, RuleAction } from '../../models/operation/AutomationRuleModel';
import ChangeTicketAssigneeAction from './actions/ChangeTicketAssigneeAction';
import SendEmailMessageAction from './actions/SendEmailMessageAction';
import ChangeTicketSeverityAction from './actions/ChangeTicketSeverityAction';
import ChangeTicketStateAction from './actions/ChangeTicketStateAction';
import CollapsibleCheck from '../../ui/collapsible/CollapsibleCheck';
import { useOptionalTranslationText, useTranslationText } from '../../translation/TranslationHooks';

export interface SelectActionsProps {
  action?: RuleAction;
  actionType: ActionType;
  handleChange: (action: RuleAction) => void;
  onUncheck: (actionConfiguration?: RuleAction) => void;
}

interface ActionConfigurationProps {
  actionType: ActionType;
  configuration?: any;
  handleConfigurationUpdate: (config: any) => void;
}

function ActionConfiguration({ handleConfigurationUpdate, configuration, actionType }: ActionConfigurationProps) {
  switch (actionType) {
    case 'changeTicketAssignee':
      return (
        <ChangeTicketAssigneeAction
          configuration={configuration}
          handleConfigurationUpdate={handleConfigurationUpdate}
        />
      );
    case 'changeTicketSeverity':
      return (
        <ChangeTicketSeverityAction
          configuration={configuration}
          handleConfigurationUpdate={handleConfigurationUpdate}
        />
      );
    case 'changeTicketState':
      return (
        <ChangeTicketStateAction configuration={configuration} handleConfigurationUpdate={handleConfigurationUpdate} />
      );
    case 'sendEmailMessage':
      return (
        <SendEmailMessageAction configuration={configuration} handleConfigurationUpdate={handleConfigurationUpdate} />
      );
    default:
      return <></>;
  }
}
export default function RuleActionItem({ action, handleChange, onUncheck, actionType }: SelectActionsProps) {
  const { t } = useTranslationText('automations');
  const { t: optionalTranslationText } = useOptionalTranslationText('automations');
  const description = optionalTranslationText(`${actionType}_description`);

  const handleConfigurationUpdate = useCallback((facts: RuleAction) => handleChange(facts), [handleChange]);

  return (
    <div key={actionType} className="condition-item control mt-2" data-actiontype={actionType}>
      <CollapsibleCheck
        isChecked={!!action}
        title={t(`${actionType}_title`)}
        onToggle={(isChecked) => !isChecked && onUncheck(action)}
      >
        {description && <div>{description}</div>}
        <ActionConfiguration
          actionType={actionType}
          configuration={action}
          handleConfigurationUpdate={handleConfigurationUpdate}
        />
      </CollapsibleCheck>
    </div>
  );
}
