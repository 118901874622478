import dayjs from 'dayjs';

export function shouldShowStartOfActionHint(startOfAction: Date): boolean {
  const dateValue = dayjs(startOfAction);
  const hour = dateValue.get('hour');
  const minute = dateValue.get('minute');

  if ((hour >= 10 && hour < 14) || (hour === 14 && minute === 0)) {
    return false;
  }
  return true;
}
