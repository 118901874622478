import { useCallback } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import { AlternativeTime } from '../../../models/maintenance/AlternativeTime';
import { BaseMaintenance } from '../../../models/maintenance/Maintenance';
import { shouldShowStartOfActionHint } from '../../../models/operation/MaintenanceFunctions';
import { useTranslationText } from '../../../translation/TranslationHooks';
import DatePicker from '../../../ui/date-picker/DatePicker';
import ListItemWrapper from '../../../ui/list-item-wrapper/ListItemWrapper';

interface AlternativeTimesFormProps {
  form: UseFormReturn<BaseMaintenance>;
  onRemove: () => void;
  index: number;
  readOnly: boolean;
}

export function AlternativeTimesForm({ form, onRemove, index, readOnly }: AlternativeTimesFormProps) {
  const { t } = useTranslationText('maintenances');
  const { control, formState } = form;

  const getErrors = useCallback(
    (field: keyof AlternativeTime) =>
      formState.errors?.alternativeTimes !== undefined && formState.errors?.alternativeTimes[index] !== undefined
        ? formState?.errors?.alternativeTimes.at?.(index)?.[field]
        : undefined,
    [formState.errors?.alternativeTimes, index],
  );

  const StartOfActionInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<BaseMaintenance, `alternativeTimes.${typeof index}.startOfAction`>;
    }) => (
      <>
        <DatePicker
          error={getErrors('startOfAction')}
          enableTime
          value={field.value}
          label={t('startOfAction')}
          placeholder={t('startOfActionPlaceholder')}
          key={`maintenance-alternative-times-start-of-action-${index}`}
          onValueChange={(newDate) => {
            field.onChange(newDate);
            void form.trigger('alternativeTimes');
          }}
          isError={!!getErrors('startOfAction')}
          isRequired
          disabled={readOnly}
        />
        {shouldShowStartOfActionHint(field.value) && field.value !== undefined && (
          <div className="help is-info">{t('startOfActionHint')}</div>
        )}
      </>
    ),
    [form, getErrors, index, readOnly, t],
  );

  const EndOfActionInput = useCallback(
    ({ field }: { field: ControllerRenderProps<BaseMaintenance, `alternativeTimes.${typeof index}.endOfAction`> }) => (
      <DatePicker
        error={getErrors('endOfAction')}
        enableTime
        value={field.value}
        label={t('endOfAction')}
        placeholder={t('endOfActionPlaceholder')}
        key={`maintenance-alternative-times-end-of-action-${index}`}
        onValueChange={(newDate) => {
          field.onChange(newDate);
          void form.trigger();
        }}
        isError={!!getErrors('endOfAction')}
        isRequired
        disabled={readOnly}
      />
    ),
    [form, getErrors, index, readOnly, t],
  );
  return (
    <ListItemWrapper
      mode="item"
      field="alternativeTimes"
      index={index}
      onRemove={readOnly ? undefined : onRemove}
      deletePermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
      deleteDisabled={readOnly}
    >
      <div className="columns is-align-items-flex-start">
        <div className="column is-6">
          <Controller name={`alternativeTimes.${index}.startOfAction`} control={control} render={StartOfActionInput} />
        </div>
        <div className="column is-6">
          <Controller name={`alternativeTimes.${index}.endOfAction`} control={control} render={EndOfActionInput} />
        </div>
      </div>
    </ListItemWrapper>
  );
}
