import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Collapsible } from '@aos/styleguide-react';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../../auth/AuthUserRoles';
import UserRoleCheck from '../../../auth/UserRoleCheck';
import useYupLocal from '../../../translation/YupLocal';
import { patchServiceTimesValidation } from '../../../models/contract-management/ContractValidationSchema';
import { PatchContractModel } from '../../../models/contract-management/ContractManagementModel';
import { CreateServiceTimeModel, ServiceTimeModel } from '../../../models/contract-management/ServiceTimeModel';
import { EditServiceTime } from '../../EditServiceTime/EditServiceTime';
import { ServiceTimeDetails } from '../ServiceTimeDetails/ServiceTimeDetails';
import { CollapsibleWithButtons } from '../../../ui/collapsible/CollapsibleWithButtons';

function mapServiceTimesToCreateServiceTimes(serviceTimes: ServiceTimeModel[]): CreateServiceTimeModel[] {
  return serviceTimes.map(({ id, ...rest }) => rest);
}

interface EditableServiceTimeProps {
  currentServiceTimes: ServiceTimeModel[];
  onSubmit: (
    property: 'serviceTimes',
    newValue: CreateServiceTimeModel[] | undefined,
    isFormValid: boolean,
    callback: (endEditing: boolean) => void,
  ) => void;
  isSaving?: boolean;
}

interface DisabledServiceTimeProps {
  currentServiceTimes: ServiceTimeModel[];
}

function ServiceTimesDetailsDisabled({ currentServiceTimes }: Readonly<DisabledServiceTimeProps>): JSX.Element {
  const { t } = useTranslationText('contractManagements');
  return (
    <Collapsible badgeCount={currentServiceTimes.length} isInitiallyCollapsed={false} header={t('serviceTimes')}>
      {currentServiceTimes.map((serviceTime, index) => (
        <ServiceTimeDetails key={serviceTime.id} serviceTime={serviceTime} index={index} />
      ))}
    </Collapsible>
  );
}

export default function EditableServiceTimes({
  currentServiceTimes,
  onSubmit,
  isSaving,
}: Readonly<EditableServiceTimeProps>) {
  const { t } = useTranslationText('contractManagements');
  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();

  const form = useForm<PatchContractModel>({
    mode: 'onChange',
    resolver: yupResolver(patchServiceTimesValidation(yup, t)),
    defaultValues: { serviceTimes: mapServiceTimesToCreateServiceTimes(currentServiceTimes) },
  });

  const { getValues, control, formState, reset, trigger } = form;

  const { isValid } = formState;

  const {
    append,
    fields: newServiceTimeFields,
    remove,
  } = useFieldArray({
    control,
    name: `serviceTimes`,
  });

  function onDiscard() {
    reset({ serviceTimes: mapServiceTimesToCreateServiceTimes(currentServiceTimes) });
  }

  function onSave() {
    trigger(`serviceTimes`)
      .then((isFormValid) => {
        onSubmit('serviceTimes', getValues('serviceTimes'), isFormValid, (endEditing) => {
          if (endEditing) {
            reset({ serviceTimes: getValues('serviceTimes') });
          }
        });
      })
      .catch((error) => console.error(error));
  }

  return (
    <UserRoleCheck
      requiredPermission={UserResourcePermissions.Contract.Update}
      renderNoPermission={() => <ServiceTimesDetailsDisabled currentServiceTimes={currentServiceTimes} />}
    >
      <CollapsibleWithButtons
        badgeCount={newServiceTimeFields.length}
        isInitiallyCollapsed={false}
        dirtyState={formState.isDirty}
        discardBtnClicked={onDiscard}
        saveBtnClicked={onSave}
        isSaving={isSaving}
        isValid={isValid}
        header={t('serviceTimes')}
        addBtnClicked={() => append({ publicHolidaysIncluded: false, days: [], begin: '', end: '' })}
        addPermission={UserResourcePermissions.Contract.Update}
      >
        {newServiceTimeFields.length === 0 && tCommon('noItemsFoundAdd')}
        <div className="changeable-service-times">
          {newServiceTimeFields.map((field, index) => (
            <EditServiceTime form={form} key={field.id} index={index} onRemove={() => remove(index)} />
          ))}
        </div>
      </CollapsibleWithButtons>
    </UserRoleCheck>
  );
}
