import { useState } from 'react';
import { OptionsOrGroups, SingleValue } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { Ticket } from '../../models/operation/TicketModel';
import Select from '../../ui/select/Select';
import { mapOptionToTValue, SelectOption, SelectPaginationAdditional } from '../../ui/select/SelectUtils';
import { loadTicketsOptions, mapTicketToOption, TicketOptionLabel } from './TicketSelectUtils';
import { useTranslationText } from '../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import useCustomFetch from '../../react-query/CustomFetch';
import { LoadOptions, Response } from 'react-select-async-paginate';

export interface SingleTicketSelectProps {
  onChange: (ticket: Ticket | null) => void;
  value: Ticket | null;
  label?: string;
  disableLabel?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  size?: BulmaSize;
  excludeTickets?: number[];
  customMapTicketToOption?: (ticket: Ticket) => SelectOption<Ticket>;
  loadOptionMapper?: (
    loadOptions: Response<SelectOption<Ticket>, never, SelectPaginationAdditional>,
  ) => Response<SelectOption<Ticket>, never, SelectPaginationAdditional>;

  customLoadOptions?: LoadOptions<SelectOption<Ticket>, never, SelectPaginationAdditional>;
}

function SingleTicketSelect({
  onChange,
  value,
  label,
  disableLabel,
  isRequired,
  isDisabled,
  size,
  excludeTickets,
  customMapTicketToOption,
  loadOptionMapper,

  customLoadOptions,
}: SingleTicketSelectProps) {
  const { t } = useTranslationText('tickets');
  const customFetch = useCustomFetch();
  const [isError, setIsError] = useState(false);

  function handleSelectedTicketChange(newSelectedTicket: SingleValue<SelectOption<Ticket>>) {
    onChange(mapOptionToTValue<Ticket>(newSelectedTicket));
  }

  async function loadOptions(
    searchInput: string,
    _prev: OptionsOrGroups<SelectOption<Ticket>, never>,
    pageToLoad?: SelectPaginationAdditional,
  ) {
    const ticketLoadOptions = await loadTicketsOptions(
      searchInput,
      pageToLoad ?? { page: 0 },
      setIsError,
      customFetch,
      excludeTickets,
    );
    return loadOptionMapper ? loadOptionMapper(ticketLoadOptions) : ticketLoadOptions;
  }

  return (
    <Select<Ticket, never, SelectPaginationAdditional, false>
      onChange={handleSelectedTicketChange}
      value={
        value === null ? null : customMapTicketToOption ? customMapTicketToOption(value) : mapTicketToOption(value)
      }
      loadOptions={customLoadOptions ? customLoadOptions : loadOptions}
      openMenuOnClick={false}
      label={disableLabel ? undefined : (label ?? t('ticket'))}
      noOptionsMessage={() => t('noTicketForSearch')}
      loadingMessage={() => t('ticketsAreLoading')}
      placeholder={t('emptyTicketSearchField')}
      requiredPermission={UserResourcePermissions.Ticket.Read}
      isClearable
      isRequired={isRequired}
      isDisabled={isDisabled}
      size={size}
      isError={isError}
      additional={{ page: 0 }}
      formatOptionLabel={(opt, metadata) => (
        <TicketOptionLabel payload={opt.payload} isOption={metadata.context === 'menu'} />
      )}
    />
  );
}

export default SingleTicketSelect;
