import { TitleBarMenu } from '@aos/styleguide-react/dist/stories/layout/layout/title-bar/title-bar-menu/TitleBarMenu';
import { useGetUserInfo } from '../../react-query/GetUserSettings';
import { useCurrentTenant } from '../../user/tenant-context/CurrentTenantContext';
import TenantUtil from '../../user/tenant-context/TenantUtil';
import TenantEnum from '../../user/tenant-context/TenantEnum';

export function formatTenant(tenant: TenantEnum): string {
  return `VZ ${TenantUtil.toString(tenant)}`;
}

export function TenantMenu() {
  const { data: currentUser } = useGetUserInfo();
  const { currentTenant, setCurrentTenant } = useCurrentTenant();

  if (!currentUser || !currentTenant) {
    return null;
  }

  const hasMultipleTenants = currentUser.assignedTenants?.length > 1;

  return (
    <TitleBarMenu.Item icon="house-user" text={formatTenant(currentTenant)}>
      {hasMultipleTenants &&
        currentUser.assignedTenants.map(
          (tenant) =>
            tenant && (
              <TitleBarMenu.SubItem
                key={tenant}
                text={formatTenant(tenant)}
                isActive={currentTenant === tenant}
                onClick={() => {
                  setCurrentTenant(tenant);
                }}
              />
            ),
        )}
    </TitleBarMenu.Item>
  );
}
