export type Patch = PatchOperation[];

export interface PatchOperation {
  op: 'replace';
  path: string;
}

export interface ReplacePatchOperation<T> extends PatchOperation {
  op: 'replace';
  value: T | null;
}

export function replaceProperty<T>(property: string, newValue: T): ReplacePatchOperation<T> {
  return {
    op: 'replace',
    path: property.startsWith('/') ? property : `/${property}`,
    value: newValue !== undefined ? newValue : null,
  };
}
