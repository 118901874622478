import { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslationText } from '@/translation/TranslationHooks';
import { useGetTriggerTypes } from '@/react-query/AutomationApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import '../AutomationRules.scss';
import RuleTriggerItem from './RuleTriggerItem';
import { RuleTrigger } from '@/models/operation/AutomationRuleModel';
import RuleTriggerAdded from './RuleTriggerAdded';
import FormFieldWrapper from '@/ui/form-field-wrapper/FormFieldWrapper';

export interface RuleTriggerOverviewProps {
  trigger?: RuleTrigger;
  showOnlySummary: boolean;
  validate?: boolean;
  onValidation: (isValid: boolean) => void;
  onTriggerSelect: (trigger?: RuleTrigger) => void;
  onContinueClicked: () => void;
}

export default function RuleTriggerOverview({
  trigger,
  showOnlySummary,
  onTriggerSelect,
  onContinueClicked,
  onValidation,
  validate,
}: RuleTriggerOverviewProps) {
  const { data: triggerTypes, isLoading: isLoadingTrigger, isError } = useGetTriggerTypes();
  const { t: tCommon } = useTranslationText('commons');
  const { t } = useTranslationText('automations');

  const isValid = useCallback(() => !!trigger, [trigger]);

  useEffect(() => {
    onValidation(isValid());
  }, [isValid, onValidation]);

  return (
    <LoadingSpinner isLoading={isLoadingTrigger} errors={isError && 'RuleTrigger load failed'}>
      <div className="rule-wrapper">
        <FormFieldWrapper isRequired label={t('selectTriggerTitle')} />
        {!showOnlySummary && (
          <p
            className={classNames('mb-5', 'mt-1', {
              isInvalid: validate && !isValid(),
            })}
          >
            {t('selectTriggerDescription')}
          </p>
        )}
        <div className={classNames({ summaryGrid: showOnlySummary })}>
          {showOnlySummary ? (
            <RuleTriggerAdded value={trigger} />
          ) : (
            <div className="control radio-list mt-2" onClick={(e) => e.stopPropagation()}>
              {triggerTypes
                ?.sort((a, b) => a.localeCompare(b))
                .map((triggerType) => (
                  <RuleTriggerItem
                    key={triggerType}
                    trigger={trigger?.type === triggerType ? trigger : undefined}
                    triggerType={triggerType}
                    handleChange={onTriggerSelect}
                    onUncheck={() => onTriggerSelect(undefined)}
                  />
                ))}
            </div>
          )}
          <div
            className={classNames('actions', { isVisible: trigger && !showOnlySummary })}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="is-divider" />
            <div className="is-flex is-justify-content-end">
              <button type="button" className="button is-primary" onClick={onContinueClicked}>
                {tCommon('continue')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </LoadingSpinner>
  );
}
