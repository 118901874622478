import { keyForTicketCommentModel, TicketCommentModel } from '../../../models/operation/TicketModel';
import CommentListingItem from './item/CommentListingItem';
import LoadingSpinner from '../../../ui/loading-spinner/LoadingSpinner';
import './CommentListing.scss';

interface CommentListingProps {
  comments: TicketCommentModel[];
  isLoading: boolean;
  error: string | undefined;
}

export default function CommentListing({ comments, isLoading, error }: CommentListingProps) {
  const commentListingElement = comments
    .slice()
    .reverse()
    .map((record) => <CommentListingItem key={keyForTicketCommentModel(record)} comment={record} />);

  return (
    <div data-role="ticket-comments" className="comment-listing">
      <LoadingSpinner isLoading={isLoading} errors={error}>
        {commentListingElement}
      </LoadingSpinner>
    </div>
  );
}
