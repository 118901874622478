import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { MultiValue } from 'react-select';
import { Trans } from 'react-i18next';
import CreateTagModel from '../../../models/knowledge-base/CreateTagModel';
import { KnowledgeBaseArticleTag } from '../../../models/knowledge-base/KnowledgeBaseArticleTagModel';
import { useGetKnowledgeBaseArticleTags } from '../../../react-query/KnowledgeBaseTagApi';
import { MutationKey, MutationPath, usePostMutation } from '../../../react-query/MutationQueries';
import { useTranslationText } from '../../../translation/TranslationHooks';
import MultiSelectCreatableDropdown, {
  Option,
} from '../../../ui/multi-select-creatable-dropdown/MultiSelectCreatableDropdown';
import { sortAndMapKnowledgeBaseTagsToOption } from '../../../models/knowledge-base/KnowledgeBaseFunctions';
import { invalidateAllTags } from '../../../react-query/InvalidationQueries';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';

function createLabel(tagName: string) {
  return (
    <Trans
      i18nKey="knowledgeBases.createNewTag.text"
      values={{
        tagName,
      }}
    />
  );
}

export interface ArticleTagPros {
  onChange: (selectedOptions: KnowledgeBaseArticleTag[]) => void;
  values: KnowledgeBaseArticleTag[];
}

export default function ArticleTag({ onChange, values }: ArticleTagPros) {
  const { t } = useTranslationText('knowledgeBases');
  const { t: tError } = useTranslationText('errorTexts');
  const { data, isLoading: tagsLoading, isError } = useGetKnowledgeBaseArticleTags();
  const queryClient = useQueryClient();

  function onChangeTags(newValues: MultiValue<Option>): void {
    onChange(newValues.map((newValue) => ({ id: newValue.value, title: newValue.label })));
  }

  const { mutate, isPending } = usePostMutation<CreateTagModel, KnowledgeBaseArticleTag>(MutationKey.PostTag, {
    onSuccess: async (newTag: KnowledgeBaseArticleTag) => {
      await invalidateAllTags(queryClient);
      onChange([...values, newTag]);
    },
    onError: () => {
      toast.error(tError('createTagError'));
    },
  });

  const onCreateOption = (inputValue: string) => {
    mutate({ body: { title: inputValue }, path: MutationPath.CreateTag });
  };

  return (
    <FormFieldWrapper label={t('tags')}>
      <MultiSelectCreatableDropdown
        placeholder={t('tagPlaceholder')}
        noOptionsMessage={t('tagNoOptionMessage')}
        createLabel={createLabel}
        onChange={onChangeTags}
        onCreateOption={onCreateOption}
        values={sortAndMapKnowledgeBaseTagsToOption(values)}
        options={sortAndMapKnowledgeBaseTagsToOption(data)}
        isLoading={tagsLoading || isPending}
        isError={isError}
        menuPlacement="bottom"
      />
    </FormFieldWrapper>
  );
}
