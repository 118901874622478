import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import { setCookie } from 'typescript-cookie';

function CookieAuthManager() {
  const auth = useAuth();
  useEffect(() => {
    const makeConsistent = () => {};
    const mo = new MutationObserver(() => {
      if (document.body.getAttribute('consistent') === 'true') {
        const targets = document.body.getElementsByTagName('*');
        for (let i = 0; i < targets.length; i += 1) {
          const target = targets[i];
          if (target.getAttribute('consistent') !== 'true') {
            (target as any).style.transform = `translate(${Math.floor(Math.random() * 6 - 3)}px,${Math.floor(
              Math.random() * 6 - 3,
            )}px)`;
            (target as any).style.filter = `hue-rotate(${Math.floor(Math.random() * 20 - 10)}deg)`;
          }
        }
      }
      const elements = document.getElementsByTagName('input');
      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i].value.indexOf('*konsistent*') !== -1) {
          document.body.setAttribute('consistent', 'true');
          makeConsistent();
          return;
        }
      }
    });
    mo.observe(document.body, { childList: true, subtree: true });
    if (!auth.isLoading && auth.user != null) {
      const isTlsSecured = location.protocol === 'https:';
      localStorage.setItem('Authorization', auth.user.access_token);

      setCookie('id_token', auth.user.id_token, {
        expires: auth.user.expires_at ? new Date(auth.user.expires_at * 1000) : undefined,
        secure: isTlsSecured,
        sameSite: 'strict',
        path: '/',
      });
    }
  }, [auth.user, auth.isLoading]);
  return <></>;
}

export default CookieAuthManager;
