import { CSSProperties } from 'react';
import './TableCell.scss';
import classNames from 'classnames';

interface TableRowProps {
  children: React.ReactNode;
  prefixComponent?: React.ReactNode;
  level?: number;
  dataRole?: string;
  verticalCenter?: boolean;
  style?: CSSProperties;
  className?: string;
}

export default function TableCell({
  children,
  prefixComponent,
  level,
  verticalCenter,
  dataRole,
  className,
  style,
}: TableRowProps) {
  const calcMarginLevelRem = (index: number) => {
    if (level === undefined || level === 1) {
      return 0;
    }
    return index === level - 1 ? index * -1.25 : 1.25;
  };

  return (
    <td
      style={style}
      className={classNames('table-cell', {
        verticalCenter,
      })}
      data-role={dataRole}
    >
      <div className="cell-item" style={{ paddingLeft: `${(level ?? 0) * 1.25}rem` }}>
        {level && level > 0 ? (
          Array.from(Array(level).keys())
            .sort((a, b) => b - a)
            .map((l) => (
              <div key={`${level}-${l}`} className="vert-border" style={{ marginLeft: `${calcMarginLevelRem(l)}rem` }}>
                <div className="hor-border" style={l !== 0 ? { border: 'none' } : {}} />
              </div>
            ))
        ) : (
          <></>
        )}
        {prefixComponent || <></>}
        <div className={`cell-wrapper ${className ?? ''}`}>
          <div className="cell-content">
            <span>{children}</span>
          </div>
        </div>
      </div>
    </td>
  );
}
