import { ComponentProps } from 'react';
import { faCircleArrowDown, faCircleArrowRight, faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
import { type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Icon } from '@aos/styleguide-react';
import './TicketSeverityIcon.scss';

interface TicketSeverityIconConfig {
  color: string;
  icon: IconDefinition;
}

function configureIcon(severity: string): TicketSeverityIconConfig {
  switch (severity.toLowerCase()) {
    case 'low': {
      return {
        color: 'secondary',
        icon: faCircleArrowDown,
      };
    }
    case 'medium': {
      return {
        color: 'warning-dark',
        icon: faCircleArrowRight,
      };
    }
    case 'high': {
      return {
        color: 'danger',
        icon: faCircleArrowUp,
      };
    }
    default: {
      // technically impossible
      return {
        color: 'secondary',
        icon: faCircleArrowDown,
      };
    }
  }
}

interface TicketSeverityIconProps extends ComponentProps<'span'> {
  severity: string;
  text?: string;
}

function TicketSeverityIcon({ severity, text, ...props }: TicketSeverityIconProps) {
  const iconConfig = configureIcon(severity);

  return (
    <div className="ticket-severity-icon-mapper">
      <Icon {...props} name={iconConfig.icon.iconName} />
      {text && <span>{text}</span>}
    </div>
  );
}

export default TicketSeverityIcon;
