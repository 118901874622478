import { NumberParam, StringParam, withDefault } from 'use-query-params';
import './ComponentRelations.scss';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useGetComponentRelationTypes } from '../../react-query/RelationTypeApi';
import { ComponentItem } from '../../models/operation/ComponentModel';
import { MutationKey, useDeleteMutation } from '../../react-query/MutationQueries';
import useGetRelations from '../../react-query/ComponentRelationApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import CreateComponentRelation from './create-component-relation/CreateComponentRelation';
import ComponentRelationItem from './component-relation-item/ComponentRelationItem';
import { useTranslationText } from '../../translation/TranslationHooks';
import { useShortcut } from '../../custom-hooks/Shortcuts';
import KeyCombination from '../../custom-hooks/KeyCombination';
import { toRelationSpecs } from '../../models/operation/RelationTypeFunctions';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { MonitoringApiPrefix } from '../../react-query/MonitoringApi';
import Modal from '../../ui/modal/Modal';
import useToggle from '../../custom-hooks/useToggle';
import Pagination from '../../ui/pagination/Pagination';
import useQueryParamsWithPageReset from '../../custom-hooks/useQueryParamsWithPageReset';
import { CollapsibleWithButtons } from '../../ui/collapsible/CollapsibleWithButtons';

interface ComponentRelationsProps {
  component: ComponentItem;
  onRefresh: () => void;
}

export enum ComponentRelationSort {
  DisplayNameDesc = 'displayName:desc',
  DisplayNameAsc = 'displayName:asc',
  StatusDesc = 'status:desc',
  StatusAsc = 'status:asc',
}

function ComponentRelations({ component, onRefresh }: Readonly<ComponentRelationsProps>) {
  const { data: relationTypes } = useGetComponentRelationTypes();
  const [query, setQuery] = useQueryParamsWithPageReset({
    sort: withDefault(StringParam, ComponentRelationSort.StatusDesc),
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 10),
  });
  const {
    data: relations,
    isLoading,
    refetch: refreshRelations,
  } = useGetRelations(component.id, {
    size: query.size.toString(),
    page: query.page.toString(),
    sort: query.sort,
  });
  const { t } = useTranslationText('components');
  const { t: tCommon } = useTranslationText('commons');
  const relationSpecs = toRelationSpecs(relationTypes ?? []);

  const [isAddingComponent, setIsAddingComponent] = useState<boolean>(false);

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteComponentRelation, {
    onSuccess: async () => {
      await refreshRelations();
      onRefresh();
    },
    onError: () => {
      toast.error(t('createTicketError'));
    },
  });

  useShortcut(KeyCombination.CreateNewRelation, () => setIsAddingComponent(true));

  const AddRelationButton = isAddingComponent && (
    <CreateComponentRelation
      relationSpecs={relationSpecs}
      currentComponent={component}
      onCancel={() => setIsAddingComponent(false)}
      onSuccess={async () => {
        setIsAddingComponent(false);
        await refreshRelations();
        onRefresh();
      }}
    />
  );

  const [relationToDeleteId, setRelationToDeleteId] = useState<string>();
  const [isConfirmationModalVisible, toggleConfirmationModal] = useToggle(false);

  const onDelete = () => {
    if (relationToDeleteId !== undefined) {
      deleteMutate({
        path: `${MonitoringApiPrefix}/component/${component.id}/relation/${relationToDeleteId}`,
      });
      setRelationToDeleteId(undefined);
    }
    toggleConfirmationModal();
  };

  const handleDelete = (id: string) => {
    setRelationToDeleteId(id);
    toggleConfirmationModal();
  };

  const handlePageChange = (page: number) => {
    setQuery((latestValues) => ({ ...latestValues, page }));
  };

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('relations')}
      addBtnClicked={() => setIsAddingComponent(true)}
      addPermission={UserResourcePermissions.ComponentRelation.Create}
      className="component-relations-collapsible"
    >
      <LoadingSpinner isLoading={isLoading}>
        <div className="component-relations-wrapper">
          <UserRoleCheck requiredPermission={UserResourcePermissions.ComponentRelation.Read}>
            {AddRelationButton}
            <div className="component-relations-scroll-container">
              {(relations?.content ?? [])
                .map((relation, index) => (
                  <ComponentRelationItem
                    index={index}
                    key={relation.id}
                    relation={relation}
                    onDelete={() => handleDelete(relation.id)}
                  />
                ))
                .reverse()}
            </div>
          </UserRoleCheck>
        </div>
        <Modal
          isVisible={isConfirmationModalVisible}
          title={tCommon('deleteComponentRelation')}
          confirmBtnText={tCommon('delete')}
          cancelBtnText={tCommon('back')}
          onCancel={() => toggleConfirmationModal()}
          onClose={() => toggleConfirmationModal()}
          onConfirm={onDelete}
        >
          <p>{tCommon('warningComponentRelationDelete')}</p>
        </Modal>
      </LoadingSpinner>
      <Pagination
        currentPage={query.page ?? 0}
        totalPages={relations?.totalPages ?? 0}
        handleOnPageChange={handlePageChange}
        size="is-small"
      />
    </CollapsibleWithButtons>
  );
}

export default ComponentRelations;
