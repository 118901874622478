import { useState } from 'react';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import classNames from 'classnames';
import { AsyncPaginate, type AsyncPaginateProps, wrapMenuList } from 'react-select-async-paginate';
import { GroupBase, InputActionMeta } from 'react-select';
import { FieldError, Merge } from 'react-hook-form';
import { useAuthUser } from '@/auth/UserRoleCheck';
import { UserPermission } from '@/auth/UserPermission';
import { CustomMenuList, selectClassNames, SelectOption, selectStyles } from './SelectUtils';
import FormFieldWrapper from '@/ui/form-field-wrapper/FormFieldWrapper';

interface SelectProps<TValue, TGroup extends GroupBase<SelectOption<TValue>>, TAdditional, TIsMulti extends boolean>
  extends Omit<
    AsyncPaginateProps<SelectOption<TValue>, TGroup, TAdditional, TIsMulti>,
    'menuPortalTarget' | 'filterOption' | 'onInputChange'
  > {
  // override required props to be NOT required
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  inputValue?: string;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  // end of override
  label?: string;
  isRequired?: boolean;
  size?: BulmaSize;
  requiredPermission?: UserPermission;
  isError?: boolean;
  isSmall?: boolean;
  error?: FieldError | Merge<FieldError, (FieldError | undefined)[]> | undefined;
  disableLabel?: boolean;
}

export default function Select<
  TValue,
  TGroup extends GroupBase<SelectOption<TValue>>,
  TAdditional,
  TIsMulti extends boolean,
>({
  label,
  isRequired,
  isDisabled,
  size,
  requiredPermission,
  isError,
  isSmall,
  components,
  isMulti,
  onInputChange,
  error,
  disableLabel,
  ...reactSelectProps
}: SelectProps<TValue, TGroup, TAdditional, TIsMulti>) {
  const { hasPermission } = useAuthUser();
  const isDisabledInternal = isDisabled || (requiredPermission && !hasPermission(requiredPermission));
  const [inputValue, setInputValue] = useState('');

  return (
    <FormFieldWrapper
      label={disableLabel ? undefined : label}
      isRequired={isRequired}
      size={size}
      error={error}
      noGridControl
    >
      <AsyncPaginate
        {...reactSelectProps}
        isMulti={isMulti}
        onInputChange={(newInput, inputAction) => {
          if (onInputChange) onInputChange(newInput, inputAction);
          if (inputAction.action === 'input-change') setInputValue(newInput);
        }}
        inputValue={isMulti ? inputValue : undefined}
        isDisabled={isDisabledInternal}
        styles={selectStyles}
        menuPortalTarget={document.body}
        className={classNames(
          {
            'is-error': isError,
            'is-small': isSmall,
          },
          'is-fullwidth',
        )}
        classNames={selectClassNames<SelectOption<TValue>, TGroup>(size!)}
        components={{
          MenuList: wrapMenuList(CustomMenuList),
          ...components,
        }}
        filterOption={() => true}
        onMenuClose={() => setInputValue('')}
      />
    </FormFieldWrapper>
  );
}
