import './ComponentContractItem.scss';
import { ComponentItem } from '../../models/operation/ComponentModel';
import { ComponentPatchSubmit } from '../../models/patch/ComponentPatchSubmitType';
import { AssignContractItem } from './AssignContractItem';
import { ExistingContractItem } from './ExistingContractItem';

interface ComponentContractItemProps {
  sourceComponent: ComponentItem;
  currentComponent: ComponentItem;
  onSubmit?: ComponentPatchSubmit;
  readOnly?: boolean;
}

function ComponentContractItem({ sourceComponent, currentComponent, onSubmit, readOnly }: ComponentContractItemProps) {
  return (
    <>
      {!!sourceComponent.assignedContractId && currentComponent ? (
        <ExistingContractItem
          onSubmit={onSubmit}
          sourceComponent={sourceComponent}
          currentComponent={currentComponent}
          readOnly={readOnly}
        />
      ) : (
        <AssignContractItem onSubmit={onSubmit!} component={currentComponent} />
      )}
    </>
  );
}

export default ComponentContractItem;
