import {
  components,
  ControlProps,
  FormatOptionLabelMeta,
  GroupBase,
  MenuListProps,
  MultiValue,
  OptionProps,
  SingleValue,
} from 'react-select';
import { Response } from 'react-select-async-paginate';
import classNames from 'classnames';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { ComponentType, ReactNode } from 'react';
import { Checkbox } from '@aos/styleguide-react';
import './Select.scss';

export interface SelectPaginationAdditional {
  page: number;
}

export interface SelectOption<TValue> {
  label: ReactNode;
  value: string; // MUST be a unique ID
  payload: TValue;
  isDisabled?: boolean;
}

export function mapOptionToTValue<TValue>(option: SingleValue<SelectOption<TValue>>) {
  return option?.payload ?? null;
}

export function mapOptionsToTValues<TValue>(options: MultiValue<SelectOption<TValue>>): TValue[] | null {
  return (
    (options
      .map((option) => mapOptionToTValue<TValue>(option))
      .filter((component) => component !== null) as TValue[]) ?? null
  );
}

export function mapTValuesToLoadOptions<TValue>(
  tValues: TValue[],
  mapTValuesToOptions: (tValues: TValue[]) => SelectOption<TValue>[],
): Response<SelectOption<TValue>, never, never> {
  return {
    options: mapTValuesToOptions(tValues),
  };
}

export const selectStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  clearIndicator: () => ({ display: 'none' }),
  control: () => ({ paddingLeft: 0 }),
  dropdownIndicator: () => ({}),
  option: () => ({ display: 'flex', gap: '0.5rem', cursor: 'pointer' }),
  menuList: (existingStyles: any) => ({ ...existingStyles, overflow: 'auto' }),
  menu: () => ({}),
  container: () => ({ gridArea: 'content' }),
  valueContainer: (existingStyles: any) => ({
    ...existingStyles,
    paddingLeft: '0.5em',
    paddingRight: '0.75em',
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    lineHeight: 1.5,
  }),
  singleValue: (existingStyles: any) => ({ ...existingStyles, display: 'flex', gap: '0.5rem' }),
};

export const selectClassNames = <T, TGroup extends GroupBase<T>>(size: BulmaSize) => ({
  control: (state: ControlProps<T, boolean, TGroup>) =>
    classNames('aos-select-trigger', state.menuIsOpen && 'is-open', state.isDisabled && 'is-disabled', size),
  menuList: () => 'aos-select-content',
  option: (state: OptionProps<T, boolean, TGroup>) =>
    classNames('select-item', state.isFocused && 'is-hovered', state.isDisabled && 'is-disabled', size),
  indicatorsContainer: () => 'select-icon',
  dropdownIndicator: () => 'aos-icon',
  menuPortal: () => 'react-select-menu-portal',
});

export interface OptionLabelBaseProps<TValue> {
  payload: TValue;
  isOption?: boolean;
}

interface MultiSelectOptionLabelProps<TValue> {
  opt: SelectOption<TValue>;
  selectedValueIds: string[];
  OptionLabel: ComponentType<OptionLabelBaseProps<TValue>>;
  MenuOptionLabel?: ComponentType<OptionLabelBaseProps<TValue>>;
  metadata?: FormatOptionLabelMeta<SelectOption<TValue>>;
}

export function MultiSelectOptionLabel<TValue>({
  opt,
  selectedValueIds,
  OptionLabel,
  MenuOptionLabel,
  metadata,
}: MultiSelectOptionLabelProps<TValue>): ReactNode {
  return metadata?.context === 'menu' ? (
    <div className="multi-select-option-label-menu-wrapper">
      <Checkbox checked={selectedValueIds.includes(opt.value)} readOnly />
      <OptionLabel payload={opt.payload} />
    </div>
  ) : MenuOptionLabel ? (
    <MenuOptionLabel payload={opt.payload} />
  ) : (
    <OptionLabel payload={opt.payload} />
  );
}

export function CustomMenuList<TValue, TIsMulti extends boolean, TGroup extends GroupBase<TValue>>({
  children,
  ...props
}: MenuListProps<TValue, TIsMulti, TGroup>) {
  return (
    <components.MenuList {...props}>
      <div className="scroll-area-root">
        <div className="scroll-area-viewport">
          <div className="select-item-list">{children}</div>
        </div>
      </div>
    </components.MenuList>
  );
}
