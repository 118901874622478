import { MultiValue } from 'react-select';
import { MaintenanceQuery } from '../../../models/maintenance/MaintenanceQuery';
import { MaintenanceStatus } from '../../../models/maintenance/MaintenanceStatus';
import { useTranslationText } from '../../../translation/TranslationHooks';
import MultiSelectDropdown from '../../../ui/multi-select-dropdown/MultiSelectDropdown';
import { ReactSelectOption } from '../../../ui/search-dropdown/SearchDropdown';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';

interface MaintenanceStatusFilterProps {
  label?: string;
  selectedStates: (string | null)[] | null | undefined;
  onChange: (name: keyof MaintenanceQuery, value: string[]) => void;
}

export default function MaintenanceStatusFilter({ label, selectedStates, onChange }: MaintenanceStatusFilterProps) {
  const { t } = useTranslationText('maintenances');

  const handleOnChange = (selectedOptions: MultiValue<ReactSelectOption>): void => {
    onChange(
      'status',
      selectedOptions.map((value) => value.value),
    );
  };

  const statusOptions: ReactSelectOption[] = (
    Object.keys(MaintenanceStatus) as Array<keyof typeof MaintenanceStatus>
  ).map((key) => ({
    value: key,
    label: t(key),
  }));

  const mappedSelectedValues = statusOptions.filter((status) => selectedStates?.includes(status.value));

  return (
    <FormFieldWrapper label={label}>
      <MultiSelectDropdown
        onChange={handleOnChange}
        options={statusOptions}
        openMenuOnClick
        isSearchable={false}
        placeholder={t('selectStatus')}
        mappedValues={mappedSelectedValues}
      />
    </FormFieldWrapper>
  );
}
