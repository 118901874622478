import queryString from 'query-string';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';

interface ChangelogQuery {
  version?: boolean;
  environment?: boolean;
  patchnotes?: boolean;
}

export default interface Changelog {
  version: string;
  environment: string;
  patchnotes: string;
}

export enum Environment {
  Test = 'Test-Umgebung',
  LiseTest = 'lise Test',
  Ref = 'Referenz-Umgebung',
  Prod = 'Produktiv-Umgebung',
  Demo = 'Demo',
}

export function useGetChangelog(options?: QueryOptions<Changelog>, query?: ChangelogQuery) {
  const queryParams = query ? `?${queryString.stringify(query)}` : '';
  return useGetQuery<Changelog>([ServerStateKeysEnum.Changelog], `/api/knowledgeBase/changelog${queryParams}`, options);
}
