import classNames from 'classnames';
import { Tag } from '@aos/styleguide-react';
import { TagColor } from '@aos/styleguide-react/dist/common/constants';
import { AlertItem, AlertStatus } from '../../models/operation/AlertModel';
import './ComponentAlertItem.scss';
import { useTranslationText } from '../../translation/TranslationHooks';

interface ComponentAlertItemProps {
  alert: AlertItem;
}
function alertStatusToColor(status: AlertStatus): TagColor {
  switch (status) {
    case 'ACTIVE':
      return 'is-danger';
    case 'INACTIVE':
      return 'is-additional-bluegreen';
    default:
      return 'is-default';
  }
}

function ComponentAlertItem({ alert }: ComponentAlertItemProps) {
  const { t } = useTranslationText('alerts');
  return (
    <div className={classNames('alert-item', alert.status.toLocaleLowerCase())}>
      <Tag color={alertStatusToColor(alert.status)}>{t(alert.status)}</Tag>
      <div className="alert-content">
        <div className="alert-title">{alert.title}</div>
        <div className="alert-description">{alert.message}</div>
      </div>
    </div>
  );
}

export default ComponentAlertItem;
