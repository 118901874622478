import './ActionBar.scss';

interface ActionBarProps {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
}

export default function ActionBar({ left, center, right }: ActionBarProps) {
  return (
    <div className="action-bar">
      <div className="action-bar-items">{left}</div>
      <div className="action-bar-items">{center}</div>
      <div className="action-bar-items right">{right}</div>
    </div>
  );
}
