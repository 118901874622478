import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Control, Field, Label, TextInput } from '@aos/styleguide-react';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import useDebounceForDependency from '../../../custom-hooks/useDebounceForDependency';

interface SearchBarProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size'> {
  isLoading: boolean;
  label?: string;
  size?: BulmaSize;
}

export function AosSearchBar({ isLoading, label, size, ...props }: SearchBarProps) {
  return (
    <Field>
      <Label size={size}>{label}</Label>
      <Control
        className={classNames('aos-search-bar', {
          'is-loading': isLoading,
        })}
        rightIcon="search"
        size={size}
      >
        <TextInput {...props} size={size} />
      </Control>
    </Field>
  );
}

interface DebouncedSearchBarProps extends Omit<SearchBarProps, 'onChange' | 'value'> {
  onChangeDebounced: (newValue: string) => void;
  value: string;
}

export function DebouncedAosSearchBar({ onChangeDebounced, value, ...props }: DebouncedSearchBarProps) {
  const [input, setInput] = useState(value);
  useEffect(() => {
    setInput(value);
  }, [value]);
  const isDebouncing = useDebounceForDependency(() => {
    onChangeDebounced(input);
  }, [input]);

  return (
    <AosSearchBar
      {...props}
      isLoading={props.isLoading || isDebouncing}
      onChange={(e) => setInput(e.target.value)}
      value={input}
    />
  );
}
