import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { AlternativeTime } from '../../../models/maintenance/AlternativeTime';
import { BaseMaintenance } from '../../../models/maintenance/Maintenance';
import { useTranslationText } from '../../../translation/TranslationHooks';
import { TimeSlotPickerItem } from './TimeSlotPickerItem';

interface TimeSlotPickerProps {
  form: UseFormReturn<BaseMaintenance>;
  setTimeSlot: (startDate: Date, endDate: Date) => void;
}

export function TimeSlotPicker({ form, setTimeSlot }: TimeSlotPickerProps) {
  const { t } = useTranslationText('maintenances');
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<number | undefined>();

  const { watch } = form;

  const alternativeTimes: AlternativeTime[] = watch('alternativeTimes');
  const startOfAction: Date = watch('startOfAction');
  const endOfAction: Date = watch('endOfAction');

  const updateSelectedTimeSlot = (startDate: Date, endDate: Date, index?: number) => {
    setTimeSlot(startDate, endDate);
    setSelectedTimeSlot(index);
  };

  return (
    <>
      <div className="label">{t('maintenanceTime')}</div>
      <div className="mb-3">
        <TimeSlotPickerItem
          startOfAction={startOfAction}
          endOfAction={endOfAction}
          isChecked={selectedTimeSlot === undefined}
          updateSelectedTimeSlot={updateSelectedTimeSlot}
        />
        {alternativeTimes &&
          alternativeTimes.map((alternativeTime, index) => (
            <TimeSlotPickerItem
              startOfAction={alternativeTime.startOfAction}
              endOfAction={alternativeTime.endOfAction}
              index={index}
              isChecked={selectedTimeSlot === index}
              updateSelectedTimeSlot={updateSelectedTimeSlot}
            />
          ))}
      </div>
    </>
  );
}
