import { FieldError } from 'react-hook-form';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import Media from '../../ui/media/Media';
import { ContractMediaUrlBuilders } from '../../react-query/ContractManagementSystemApi';
import FormPatchWrapper from '../../ui/form-patch-wrapper/FormPatchWrapper';
import { PatchContractModel } from '../../models/contract-management/ContractManagementModel';
import { StrapiFile } from '../../models/strapi/StrapiFile';
import { MutationKey, MutationPath } from '../../react-query/MutationQueries';

interface ChangeableAttachmentsProps {
  contract?: PatchContractModel;
  onSubmit: (property: keyof PatchContractModel, newValue?: StrapiFile[], isFormValid?: boolean) => void;
}

export default function ChangeableAttachments({ contract, onSubmit }: ChangeableAttachmentsProps) {
  return (
    <UserRoleCheck
      requiredPermission={UserResourcePermissions.Contract.Update}
      renderNoPermission={() => (
        <Media media={contract?.attachments || []} urlBuilders={ContractMediaUrlBuilders} isEditable={false} />
      )}
    >
      <FormPatchWrapper<PatchContractModel, StrapiFile[], FieldError>
        patchProperty="attachments"
        defaultValue={contract?.attachments}
        render={({ field }, property, isFormValid) => (
          <Media
            deletionPath={MutationPath.DeleteContractMedia}
            mutationPath={MutationPath.UploadContractMedia}
            mutationKey={MutationKey.PostContractMedia}
            media={field.value || []}
            urlBuilders={ContractMediaUrlBuilders}
            isEditable
            onMediaChange={(media) => {
              field.onChange(media);
              onSubmit(property, media, isFormValid);
            }}
            showCopyToClipboardButton={false}
          />
        )}
      />
    </UserRoleCheck>
  );
}
