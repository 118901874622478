import parse from 'html-react-parser';
import { TaskTicketSnapshot } from '../../../models/operation/TaskTicketSnapshot';
import { useTranslationText } from '../../../translation/TranslationHooks';

interface TicketDescriptionProps {
  ticketSnapshot: TaskTicketSnapshot;
}

export function TicketDescription({ ticketSnapshot }: TicketDescriptionProps) {
  const { t } = useTranslationText('tickets');

  return (
    <div className="ticket-description">
      <div className="label">{t('descriptionLabel')}</div>
      <div className="content">{ticketSnapshot.description ? parse(ticketSnapshot.description) : '-'}</div>
    </div>
  );
}
