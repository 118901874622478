import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FavoriteStar.scss';
import { useId } from 'react';
import Tooltip from '../tooltip/Tooltip';

interface FavoriteStarProps {
  tooltipText?: string;
}

function FavoriteStar({ tooltipText }: FavoriteStarProps) {
  const anchorId = useId();

  return (
    <div className="favorite-container">
      <FontAwesomeIcon icon={faStar} id={anchorId} />
      {tooltipText && <Tooltip anchorId={anchorId} content={tooltipText} />}
    </div>
  );
}

export default FavoriteStar;
