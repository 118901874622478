import { TaskMediaUrlBuilders, useGetTaskMediaByTicketId } from '../../../react-query/TicketingSystemApi';
import LoadingSpinner from '../../../ui/loading-spinner/LoadingSpinner';
import Media from '../../../ui/media/Media';

interface TaskMediaProps {
  ticketId: number;
}

export default function TaskMedia({ ticketId }: TaskMediaProps) {
  const { data, isLoading } = useGetTaskMediaByTicketId(ticketId);

  return (
    <div className="task-media">
      <LoadingSpinner isLoading={isLoading}>
        {data && <Media media={data} label="Anhänge" isSideView urlBuilders={TaskMediaUrlBuilders} />}
      </LoadingSpinner>
    </div>
  );
}
