import classNames from 'classnames';
import Flatpickr from 'react-flatpickr';
import { German } from 'flatpickr/dist/l10n/de';
import { Control, Field, Label } from '@aos/styleguide-react';
import { useTranslationText } from '../../translation/TranslationHooks';
import { FLATPICKR_DATE_FORMAT } from '../../models/dates/dateConstants';
import { dateByAddingDays, isDateBefore } from '../../models/dates/dateFunctions';

interface DateIntervalFilterProps {
  label?: string;
  startDate?: Date;
  endDate?: Date;
  onStartDateChange: (startDate?: Date) => void;
  onEndDateChange: (endDate?: Date) => void;
  startDateInputId: string;
  endDateInputId?: string;
  includeEndDay: boolean;
}

const flatPickrOptions = {
  dateFormat: FLATPICKR_DATE_FORMAT,
  locale: German,
};

export default function DateIntervalFilter({
  label,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  startDateInputId,
  endDateInputId,
  includeEndDay,
}: DateIntervalFilterProps) {
  const { t } = useTranslationText('commons');
  const { t: tError } = useTranslationText('errorTexts');

  // endDate -1 day if includeEndDay
  const displayedEndDate = includeEndDay ? dateByAddingDays(endDate, -1) : endDate;

  const isEndDateBeforeStartDate = startDate && displayedEndDate && isDateBefore(displayedEndDate, startDate);

  return (
    <Field>
      {label && (
        <Label htmlFor={startDateInputId} size="is-small">
          {label}
        </Label>
      )}
      <div className="form-grid">
        <Label size="is-small" isThin>
          von
        </Label>
        <Control>
          <Flatpickr
            className={classNames('input', {
              'is-danger': isEndDateBeforeStartDate,
            })}
            onChange={([newDate]) => onStartDateChange(newDate)}
            placeholder={t('timestamp')}
            value={startDate}
            options={{ ...flatPickrOptions, maxDate: displayedEndDate }}
            id={startDateInputId}
          />
        </Control>
        <Label size="is-small" isThin>
          bis
        </Label>
        <Control>
          <Flatpickr
            className={classNames('input', {
              'is-danger': !!isEndDateBeforeStartDate,
            })}
            onChange={([newDate]) => {
              const newEndDate = includeEndDay ? dateByAddingDays(newDate, +1) : newDate;
              onEndDateChange(newEndDate);
            }}
            placeholder={t('timestamp')}
            value={displayedEndDate}
            options={{ ...flatPickrOptions, minDate: startDate }}
            id={endDateInputId}
          />
        </Control>
      </div>
      {isEndDateBeforeStartDate && <p className="help is-danger">{tError('endDateBeforeStartDate')}</p>}
    </Field>
  );
}
